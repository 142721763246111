import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { onAuthStateChanged } from 'firebase/auth';
import { LogoutConfirmationDialogComponent } from 'src/app/shared/components/logout-confirmation-dialog/logout-confirmation-dialog.component';
import { FirebaseAuthService } from 'src/app/shared/services/firebase-auth.service';
import { auth } from 'src/firebase';

@Component({
  selector: 'app-admin-portal',
  templateUrl: './admin-portal.component.html',
  styleUrls: ['./admin-portal.component.scss']
})
export class AdminPortalComponent implements OnInit {
  lang: string;

  // rerendering language selection after language is loaded
  public trigger = 0;

  // logged in for logo
  loggedIn = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private firebaseAuth: FirebaseAuthService,
    public translate: TranslateService
  ) {
    // language
    this.lang = translate.currentLang;

    translate.onLangChange.subscribe(() => this.trigger++);
  }

  ngOnInit() {
    // admin handle routing of subdomains
    if (
      location.hostname !== 'admin.integribox.com' &&
      location.hostname !== 'admin.integrity-box.com' &&
      location.hostname !== 'localhost'
    ) {
      this.navigateTo('/client');
    } else if (
      (location.hostname === 'admin.integrity-box.com' ||
        location.hostname === 'admin.integribox.com') &&
      this.route.snapshot.params.management === 'management'
    ) {
      this.navigateTo('/admin');
    }

    // check if user is logged in for logo (subscribe to auth state)
    onAuthStateChanged(auth, async (user) => {
      if (user !== null) {
        // get firebase auth
        const claims = (await auth.currentUser.getIdTokenResult()).claims;

        // redirect user to case if logged in
        if (claims.role === 'admin') {
          // user is logged in
          this.loggedIn = true;
        } else {
          // user is not logged in
          this.loggedIn = false;
        }
      } else {
        // user is not logged in
        this.loggedIn = false;
      }
    });
  }

  // navigation
  navigateTo(url: string) {
    this.router.navigateByUrl(url);
  }

  // logout option
  async logout() {
    // sign out with firebase auth api
    await this.firebaseAuth.signOutUser();

    // redirect user to login
    this.router.navigate(['admin', 'login']);
  }

  // display logout dialog
  openLogoutConfirmationDialog() {
    const dialogRef = this.dialog.open(LogoutConfirmationDialogComponent);
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result === true) {
        this.logout();
      }
    });
  }
}
