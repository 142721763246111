import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ImprintComponent } from './shared/components/imprint/imprint.component';
import { PrivacyComponent } from './shared/components/privacy/privacy.component';
import { AdminPortalComponent } from './admin-portal/admin-portal/admin-portal.component';
import { AdminPortalHomeComponent } from './admin-portal/admin-portal-home/admin-portal-home.component';
import { AdminPortalLspComponent } from './admin-portal/admin-portal-lsp/admin-portal-lsp.component';
import { AdminPortalClientComponent } from './admin-portal/admin-portal-client/admin-portal-client.component';
import { AdminPortalSettingsComponent } from './admin-portal/admin-portal-settings/admin-portal-settings.component';
import { AdminPortalLoginComponent } from './admin-portal/admin-portal-login/admin-portal-login.component';
import { CaseManagementPortalComponent } from './case-management-portal/case-management-portal/case-management-portal.component';
import { CaseManagementPortalHomeComponent } from './case-management-portal/case-management-portal-home/case-management-portal-home.component';
import { CaseManagementPortalCaseComponent } from './case-management-portal/case-management-portal-case/case-management-portal-case.component';
import { CaseManagementPortalChartsComponent } from './case-management-portal/case-management-portal-charts/case-management-portal-charts.component';
import { CaseManagementPortalSettingsComponent } from './case-management-portal/case-management-portal-settings/case-management-portal-settings.component';
import { CaseManagementPortalLoginComponent } from './case-management-portal/case-management-portal-login/case-management-portal-login.component';
import { CaseReportingPortalComponent } from './case-reporting-portal/case-reporting-portal/case-reporting-portal.component';
import { CaseReportingPortalHomeComponent } from './case-reporting-portal/case-reporting-portal-home/case-reporting-portal-home.component';
import { CaseReportingPortalReportCaseComponent } from './case-reporting-portal/case-reporting-portal-report-case/case-reporting-portal-report-case.component';
import { CaseReportingPortalCaseComponent } from './case-reporting-portal/case-reporting-portal-case/case-reporting-portal-case.component';
import { CaseReportingPortalLoginComponent } from './case-reporting-portal/case-reporting-portal-login/case-reporting-portal-login.component';

const routes: Routes = [
  // case reporting portal routes
  {
    path: 'client',
    component: CaseReportingPortalComponent,
    children: [
      { path: '', component: CaseReportingPortalHomeComponent },
      { path: 'report', component: CaseReportingPortalReportCaseComponent },
      { path: 'case', component: CaseReportingPortalCaseComponent },
      { path: 'login', component: CaseReportingPortalLoginComponent },
      { path: '**', redirectTo: '', pathMatch: 'full' }
    ]
  },
  // case management portal routes
  {
    path: 'management',
    component: CaseManagementPortalComponent,
    children: [
      { path: '', component: CaseManagementPortalHomeComponent },
      { path: 'case', component: CaseManagementPortalCaseComponent },
      { path: 'analytics', component: CaseManagementPortalChartsComponent },
      { path: 'settings', component: CaseManagementPortalSettingsComponent },
      { path: 'login', component: CaseManagementPortalLoginComponent },
      { path: '**', redirectTo: '', pathMatch: 'full' }
    ]
  },
  // admin portal routes
  {
    path: 'admin',
    component: AdminPortalComponent,
    children: [
      { path: '', component: AdminPortalHomeComponent },
      { path: 'lsp/:lsp_id', component: AdminPortalLspComponent },
      {
        path: 'lsp/:lsp_id/client/:client_id',
        component: AdminPortalClientComponent
      },
      { path: 'settings', component: AdminPortalSettingsComponent },
      { path: 'login', component: AdminPortalLoginComponent },
      { path: '**', redirectTo: '', pathMatch: 'full' }
    ]
  },
  // other routes
  { path: 'privacy/:language_id', component: PrivacyComponent },
  { path: 'imprint/:language_id', component: ImprintComponent },
  { path: '**', redirectTo: 'client', pathMatch: 'full' }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
