<div class="cover-spin" *ngIf="!case || isLoading"></div>
<img
  *ngIf="caseReportingPortalComponent.logoUrl"
  class="responsive m-auto mt-3"
  width="80%"
  [src]="caseReportingPortalComponent.logoUrl"
  [alt]="caseReportingPortalComponent.client.name + ' logo'"
/>
<mat-card class="m-5" *ngIf="case">
  <mat-tab-group
    mat-stretch-tabs
    (selectedTabChange)="
      scrollToBottom(); activeTab = $event.index; decryptChatCaseUser()
    "
  >
    <!-- Overview of case -->
    <mat-tab
      [label]="
        'case_reporting_portal.case.carousel_headings.left.content' | translate
      "
    >
      <div class="p-5 tab-content">
        <div class="h5">
          {{
            'case_reporting_portal.case.case_overview_page.heading.content'
              | translate
          }}
        </div>
        <hr />
        <p>
          {{
            'case_reporting_portal.case.case_overview_page.case_sent_to.content'
              | translate
          }}
          <b>{{ caseReportingPortalComponent.client.lsp_name }}</b
          ><br />
          {{
            'case_reporting_portal.case.case_overview_page.date_time_of_reporting.content'
              | translate
          }}
          <b>{{ case.created_formatted }}</b>
        </p>
        <hr />
        <!-- Sub-Client (only for shared clients) -->
        <mat-form-field
          *ngIf="case.sub_client_name"
          id="newFirmName"
          appearance="outline"
        >
          <mat-label>{{
            'case_reporting_portal.report_case.second_page.report_form.sub_client_form_field.label.content'
              | translate
          }}</mat-label>
          <input matInput [value]="case.sub_client_name" readonly />
        </mat-form-field>

        <!-- Subject of case -->
        <mat-form-field appearance="outline">
          <mat-label>{{
            'case_reporting_portal.report_case.second_page.report_form.subject_form_field.label.content'
              | translate
          }}</mat-label>
          <input matInput [value]="case.subject" readonly />
        </mat-form-field>

        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field appearance="outline">
              <mat-label>{{
                'case_reporting_portal.report_case.second_page.report_form.name_selection.label.content'
                  | translate
              }}</mat-label>
              <mat-select
                [(ngModel)]="nameSelection"
                [ngModelOptions]="{ standalone: true }"
                disabled
              >
                <mat-option [value]="false">{{
                  'case_reporting_portal.report_case.second_page.report_form.name_selection.responses.no_selection.content'
                    | translate
                }}</mat-option>
                <mat-option [value]="true">{{
                  'case_reporting_portal.report_case.second_page.report_form.name_selection.responses.yes_selection.content'
                    | translate
                }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-6" *ngIf="nameSelection">
            <mat-form-field appearance="outline">
              <mat-label>{{
                'case_reporting_portal.report_case.second_page.report_form.name_form_field.label.content'
                  | translate
              }}</mat-label>
              <input matInput [value]="case.name" readonly />
            </mat-form-field>
            <p>
              {{
                'case_reporting_portal.report_case.second_page.report_form.name_form_field.disclaimer.content'
                  | translate
              }}
            </p>
          </div>
        </div>

        <hr />
        <p>
          {{
            'case_reporting_portal.report_case.second_page.report_form.case_description_note.content'
              | translate
          }}
        </p>

        <!-- Description of case -->
        <mat-form-field class="mt-2" appearance="outline">
          <mat-label>{{
            'case_reporting_portal.report_case.second_page.report_form.description_form_field.label.content'
              | translate
          }}</mat-label>
          <textarea
            matInput
            [value]="case.description"
            cdkTextareaAutosize
            readonly
          ></textarea>
        </mat-form-field>

        <!-- Date of incident -->
        <mat-form-field class="mt-2" appearance="outline">
          <mat-label>{{
            'case_reporting_portal.report_case.second_page.report_form.date_form_field.label.content'
              | translate
          }}</mat-label>
          <textarea
            matInput
            [value]="case.date"
            cdkTextareaAutosize
            readonly
          ></textarea>
        </mat-form-field>

        <!-- Location of incident -->
        <mat-form-field class="mt-2" appearance="outline">
          <mat-label>{{
            'case_reporting_portal.report_case.second_page.report_form.location_form_field.label.content'
              | translate
          }}</mat-label>
          <textarea
            matInput
            [value]="case.location"
            cdkTextareaAutosize
            readonly
          ></textarea>
        </mat-form-field>

        <!-- Affected persons -->
        <mat-form-field class="mt-2" appearance="outline">
          <mat-label>{{
            'case_reporting_portal.report_case.second_page.report_form.affected_form_field.label.content'
              | translate
          }}</mat-label>
          <textarea
            matInput
            [value]="case.affected"
            cdkTextareaAutosize
            readonly
          ></textarea>
        </mat-form-field>

        <!-- Type of observation -->
        <mat-form-field class="mt-2" appearance="outline">
          <mat-label>{{
            'case_reporting_portal.report_case.second_page.report_form.observation_form_field.label.content'
              | translate
          }}</mat-label>
          <textarea
            matInput
            [value]="case.observation"
            cdkTextareaAutosize
            readonly
          ></textarea>
        </mat-form-field>

        <!-- Relationship to affected company -->
        <mat-form-field class="mt-2" appearance="outline">
          <mat-label>{{
            'case_reporting_portal.report_case.second_page.report_form.relationship_form_field.label.content'
              | translate: this.caseReportingPortalComponent.client
          }}</mat-label>
          <textarea
            matInput
            [value]="case.relationship"
            cdkTextareaAutosize
            readonly
          ></textarea>
        </mat-form-field>

        <!-- Any additional information -->
        <mat-form-field class="mt-2" appearance="outline">
          <mat-label>{{
            'case_reporting_portal.report_case.second_page.report_form.additional_information_form_field.label.content'
              | translate
          }}</mat-label>
          <textarea
            matInput
            [value]="case.additional_information"
            cdkTextareaAutosize
            readonly
          ></textarea>
        </mat-form-field>

        <hr />
        <p>
          {{
            'case_reporting_portal.report_case.second_page.report_form.email_form_field.disclaimer.content'
              | translate: this.caseReportingPortalComponent.client
          }}
        </p>

        <!-- E-Mail Address of reporter -->
        <mat-form-field class="mt-2" appearance="outline">
          <mat-label>{{
            'case_reporting_portal.report_case.second_page.report_form.email_form_field.label.content'
              | translate
          }}</mat-label>
          <input *ngIf="!case.email" matInput disabled />
          <input *ngIf="case.email" matInput [value]="case.email" readonly />
        </mat-form-field>
        <hr />
        <p>
          {{
            'case_reporting_portal.report_case.second_page.report_form.further_information_request.content'
              | translate
          }}
        </p>

        <!-- Country of case -->
        <mat-form-field appearance="outline">
          <mat-label>{{
            'case_reporting_portal.report_case.second_page.report_form.country_form_field.label.content'
              | translate
          }}</mat-label>
          <mat-select [value]="case.country" disabled>
            <mat-option
              *ngFor="let country of countries"
              [value]="country.code"
              >{{ country.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
    </mat-tab>

    <!-- Chat with case manager -->
    <mat-tab
      [label]="
        'case_reporting_portal.case.carousel_headings.center.content'
          | translate
      "
    >
      <div class="p-5 tab-content">
        <div class="row">
          <div class="col-12 col-md-8">
            <div class="h5">
              {{
                'case_reporting_portal.case.chat_page.heading.content'
                  | translate
              }}
            </div>
          </div>
          <div class="col-12 col-md-4">
            <mat-form-field appearance="fill">
              <mat-label>{{
                'case_reporting_portal.case.chat_page.translation_selection.label.content'
                  | translate
              }}</mat-label>
              <mat-select
                [(ngModel)]="chatMessagesLangCode"
                (selectionChange)="
                  translateChatMessages(chatMessagesLangCode, chatMessages)
                "
                [ngModelOptions]="{ standalone: true }"
              >
                <mat-option
                  *ngFor="
                    let lang of chatMessagesPossibleLangCodes
                      | keyvalue: returnZero
                  "
                  [value]="lang.key"
                  >{{ lang.value | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <hr />
        <p>
          {{
            'case_reporting_portal.case.chat_page.lsp_contact.content'
              | translate
          }}
          <b>{{ caseReportingPortalComponent.client.lsp_name }}</b>
        </p>
        <hr />
        <div class="chat-box w-100" #content #messages>
          <div class="row w-100 mb-3" *ngFor="let chat_message of chatMessages">
            <div *ngIf="!chat_message.is_case_user" class="col-10 col-md-8">
              <!-- Message of case manager-->
              <div class="chat-message from-them p-3 text-left">
                <p
                  class="mb-1"
                  *ngIf="!chat_message.translation"
                  [innerText]="chat_message.text"
                ></p>
                <p
                  class="mb-1"
                  *ngIf="chat_message.translation"
                  [innerText]="chat_message.translation"
                ></p>
                <div class="small">
                  {{ chat_message.case_manager_name }} ({{
                    'case_reporting_portal.case.chat_page.chat.case_manager.content'
                      | translate
                  }}), {{ chat_message.created_formatted }}<br />
                </div>
              </div>
            </div>
            <div class="col-2 col-md-4"></div>
            <div *ngIf="chat_message.is_case_user" class="col-10 col-md-8">
              <!-- Message of case user-->
              <div class="chat-message from-me p-3 text-right">
                <p class="mb-1" [innerText]="chat_message.text"></p>
                <div class="small" *ngIf="!case.name">
                  {{
                    'case_reporting_portal.case.chat_page.chat.whistleblower.content'
                      | translate
                  }}, {{ chat_message.created_formatted }}<br />
                </div>
                <div class="small" *ngIf="case.name">
                  {{ case.name }} ({{
                    'case_reporting_portal.case.chat_page.chat.whistleblower.content'
                      | translate
                  }}), {{ chat_message.created_formatted }}<br />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Send chat message  -->
        <mat-form-field class="mt-2" appearance="outline">
          <mat-label>{{
            'case_reporting_portal.case.chat_page.chat.message_form_field.label.content'
              | translate
          }}</mat-label>
          <textarea
            [(ngModel)]="message"
            matInput
            rows="6"
            [disabled]="isLoading"
            [maxlength]="messageMaxLength"
          ></textarea>
          <mat-hint
            align="end"
            [innerText]="message.length + '/' + messageMaxLength"
          ></mat-hint>
        </mat-form-field>
        <button
          mat-raised-button
          (click)="onChatMessageFormSubmit(message)"
          [disabled]="message.length === 0 || isLoading"
          color="primary"
        >
          {{
            'case_reporting_portal.case.chat_page.chat.send_message_button.content'
              | translate
          }}
        </button>
        <p
          *ngIf="chatErrorMessage"
          class="error mt-3 text-center pt-0"
          [innerText]="chatErrorMessage"
        ></p>
      </div>
    </mat-tab>

    <!-- Files -->
    <mat-tab
      [label]="
        'case_reporting_portal.case.carousel_headings.right.content' | translate
      "
    >
      <div class="p-5 tab-content">
        <div class="h5">
          {{
            'case_reporting_portal.case.files_page.heading.content' | translate
          }}
        </div>
        <hr />
        <div class="tablecontainer mat-elevation-z8">
          <table mat-table [dataSource]="dataSource">
            <!-- Display files -->
            <!-- File ID -->
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef>ID</th>
              <td mat-cell *matCellDef="let file">{{ file.id }}</td>
            </ng-container>

            <!-- File author (case user or case manager) -->
            <ng-container matColumnDef="author">
              <th mat-header-cell *matHeaderCellDef>
                {{
                  'case_reporting_portal.case.files_page.files_table.columns.author.heading.content'
                    | translate
                }}
              </th>
              <td mat-cell *matCellDef="let file">
                <div *ngIf="file.is_case_user_file">
                  {{
                    'case_reporting_portal.case.files_page.files_table.columns.author.case_user.content'
                      | translate
                  }}
                </div>
                <div *ngIf="!file.is_case_user_file">
                  {{
                    'case_reporting_portal.case.files_page.files_table.columns.author.case_manager.content'
                      | translate
                  }}
                </div>
              </td>
            </ng-container>

            <!-- File upload timestamp -->
            <ng-container matColumnDef="uploaded">
              <th mat-header-cell *matHeaderCellDef>
                {{
                  'case_reporting_portal.case.files_page.files_table.columns.uploaded.heading.content'
                    | translate
                }}
              </th>
              <td mat-cell *matCellDef="let file">
                {{ file.uploaded_formatted }}
              </td>
            </ng-container>

            <!-- Download file button -->
            <ng-container matColumnDef="download">
              <th mat-header-cell *matHeaderCellDef>
                {{
                  'case_reporting_portal.case.files_page.files_table.columns.download.heading.content'
                    | translate
                }}
              </th>
              <td mat-cell *matCellDef="let file">
                <button
                  mat-icon-button
                  (click)="downloadFile(file)"
                  color="primary"
                >
                  <mat-icon>download</mat-icon>
                </button>
              </td>
            </ng-container>
            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
        <div class="h5 mt-5">
          {{
            'case_reporting_portal.case.files_page.upload_further_files.heading.content'
              | translate
          }}
        </div>
        <hr />
        <p
          [innerHTML]="
            'case_reporting_portal.case.files_page.upload_further_files.files_upload_disclaimer.first_disclaimer.content'
              | translate
          "
        ></p>
        <p
          [innerHTML]="
            'case_reporting_portal.case.files_page.upload_further_files.files_upload_disclaimer.second_disclaimer.content'
              | translate
          "
        ></p>

        <input
          class="d-none"
          (change)="onFileChange($event)"
          type="file"
          [multiple]="true"
          #fileUpload
        />
        <button
          type="button"
          class="my-3 py-1 d-block w-100"
          mat-stroked-button
          (click)="fileUpload.click()"
          color="primary"
        >
          {{
            'case_reporting_portal.case.files_page.upload_further_files.files_upload_form_field.label.content'
              | translate
          }}
        </button>
        <table
          *ngIf="files.length > 0"
          class="my-3 w-100 mat-elevation-z8"
          mat-table
          [dataSource]="filesDataSource"
        >
          <!-- Display file -->
          <!-- File name -->
          <ng-container matColumnDef="file_name">
            <th mat-header-cell *matHeaderCellDef>
              {{
                'case_reporting_portal.case.files_page.upload_further_files.uploaded_files_table.left_column_heading.content'
                  | translate
              }}
            </th>
            <td mat-cell *matCellDef="let file">{{ file.name }}</td>
          </ng-container>
          <!-- Remove file button -->
          <ng-container matColumnDef="remove">
            <th mat-header-cell *matHeaderCellDef>
              {{
                'case_reporting_portal.case.files_page.upload_further_files.uploaded_files_table.right_column_heading.content'
                  | translate
              }}
            </th>
            <td mat-cell *matCellDef="let file">
              <button
                mat-icon-button
                type="button"
                (click)="removeFile(file)"
                color="warn"
                aria-label="Remove file"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </ng-container>
          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumnsFilesTable; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumnsFilesTable"
          ></tr>
        </table>
        <button
          mat-raised-button
          class="my-3 mt-4 py-1 d-block w-100"
          (click)="onFilesSubmit()"
          [disabled]="files.length === 0 || isLoading"
          color="primary"
        >
          {{
            'case_reporting_portal.case.files_page.upload_further_files.submit_file_upload_button.content'
              | translate
          }}
        </button>
        <p
          *ngIf="filesSuccessMessage"
          class="success mt-3 text-center pt-0"
          [innerText]="filesSuccessMessage"
        ></p>
        <p
          *ngIf="filesErrorMessage"
          class="error mt-3 text-center pt-0"
          [innerText]="filesErrorMessage"
        ></p>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-card>
