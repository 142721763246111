<div class="cover-spin" *ngIf="isLoading"></div>
<div mat-dialog-content class="mt-3">
  <mat-form-field appearance="outline">
    <mat-label
      [innerHTML]="
        'case_management_portal.manager_dialog.case.label.content'
          | translate: data.case
      "
    ></mat-label>
    <mat-chip-grid #chipList aria-label="Manager selection">
      <mat-chip-row
        *ngFor="let manager of caseManager"
        (removed)="remove(manager)"
      >
        {{ manager.name }}
        <button matChipRemove *ngIf="manager.role !== 'case_manager_admin'">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip-row>
      <input
        placeholder="{{
          'case_management_portal.manager_dialog.manager.placeholder.content'
            | translate
        }}"
        #managerInput
        [formControl]="managerCtrl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="add($event)"
      />
    </mat-chip-grid>
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="selected($event)"
    >
      <mat-option
        *ngFor="let manager of filteredCaseManager | async"
        [value]="manager"
      >
        {{ manager.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <div class="success" *ngIf="success">
    <small
      [innerHTML]="
        'case_management_portal.manager_dialog.success_message.content'
          | translate
      "
    ></small>
  </div>
  <div class="error" *ngIf="error">
    <small
      [innerHTML]="
        'case_management_portal.manager_dialog.error_message.content'
          | translate
      "
    ></small>
  </div>
</div>
<div mat-dialog-actions>
  <button
    mat-button
    [mat-dialog-close]="caseManager"
    [innerHTML]="'global.back_button.content' | translate"
  ></button>
</div>
