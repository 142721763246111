import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { onAuthStateChanged } from 'firebase/auth';
import { collectionGroup, getDocs, query, where } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import { LogoutConfirmationDialogComponent } from 'src/app/shared/components/logout-confirmation-dialog/logout-confirmation-dialog.component';
import { Client } from 'src/app/shared/models/client.model';
import { FirebaseAuthService } from 'src/app/shared/services/firebase-auth.service';
import { auth, firestore, storage } from 'src/firebase';

@Component({
  selector: 'app-case-reporting-portal',
  templateUrl: './case-reporting-portal.component.html',
  styleUrls: ['./case-reporting-portal.component.scss']
})
export class CaseReportingPortalComponent implements OnInit {
  // client object
  client: Client;
  subdomain: string = 'mustermann';
  logoUrl: string;

  languages: string[] = this.translate.getLangs();

  // selected language (de)
  lang = 'de';

  // logged in var for footer logout button
  isLoggedIn = false;

  // whistleblower country
  public country = '';

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private firebaseAuth: FirebaseAuthService,
    public translate: TranslateService
  ) {}

  // get data of client
  async getClient(subdomain: string) {
    try {
      const docsRef = await getDocs(
        query(
          collectionGroup(firestore, 'clients'),
          where('subdomain', '==', subdomain)
        )
      );
      if (docsRef.size !== 1) {
        // redirect to landing page (wix)
        window.open('https://www.integribox.com', '_self');
      } else {
        docsRef.forEach((doc) => {
          const data = doc.data();
          this.client = {
            id: doc.id,
            subdomain: data.subdomain,
            lsp_id: data.lsp_id,
            lsp_name: data.lsp_name,
            lsp_name_short: data.lsp_name_short,
            lsp_withdrawal_email: data.lsp_id,
            name: data.name,
            name_short: data.name_short,
            default_language: data.default_language,
            is_shared: data.is_shared,
            is_active: data.is_active,
            languages: data.languages,
            reporting_portal_several_texts: data.reporting_portal_several_texts,
            reporting_portal_report_texts: data.reporting_portal_report_texts,
            sub_client_names: data.sub_client_names
          };
        });

        // check if client is active
        if (this.client.is_active !== true) {
          // redirect to landing page (wix)
          window.open('https://www.integribox.com', '_self');
        }

        this.languages = this.client.languages;
        this.lang = this.client.default_language;
        this.translate.use(this.lang);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getClientLogoUrl(lspId: string, clientId: string) {
    try {
      // firebase storage reference of logo
      const logoRef = ref(
        storage,
        'legal_service_providers/' +
          lspId +
          '/clients/' +
          clientId +
          '/logo.png'
      );
      // get the download URL
      this.logoUrl = await getDownloadURL(logoRef);
    } catch (error) {
      console.error(error);
    }
  }

  async ngOnInit() {
    if (
      location.hostname == 'admin.integribox.com' ||
      location.hostname == 'admin.integrity-box.com'
    ) {
      // reporting admin handle routing of subdomains
      this.navigateTo('/admin');
    } else if (
      location.hostname == 'management.integribox.com' ||
      location.hostname == 'management.integrity-box.com'
    ) {
      // reporting management handle routing of subdomains
      this.navigateTo('/management');
    } else {
      if (location.hostname !== 'localhost') {
        // get client id from route
        this.subdomain = location.hostname.split('.')[0];
      }

      // check if client exists and get client data
      await this.getClient(this.subdomain);

      // check if user is logged in for logo (subscribe to auth state)
      onAuthStateChanged(auth, async (user) => {
        if (user && auth.currentUser) {
          // get firebase auth
          const claims = (await auth.currentUser.getIdTokenResult()).claims;

          // redirect user to case if logged in
          if (
            claims['role'] === 'case_user' &&
            claims['client_id'] === this.client.id
          ) {
            // user is logged in
            this.isLoggedIn = true;
          } else {
            // user is not logged in
            this.isLoggedIn = false;
          }
        } else {
          // user is not logged in
          this.isLoggedIn = false;
        }
      });

      // get client logo url if existing
      await this.getClientLogoUrl(this.client.lsp_id, this.client.id);
    }
  }

  // navigation
  navigateTo(url: string) {
    this.router.navigateByUrl(url);
  }

  // logout option
  async logout() {
    try {
      // sign out with firebase auth api
      await this.firebaseAuth.signOutUser();

      // redirect user to login
      this.router.navigate(['client', 'login']);
    } catch (error) {
      console.error(error);
    }
  }

  // display logout dialog
  openLogoutConfirmationDialog() {
    const dialogRef = this.dialog.open(LogoutConfirmationDialogComponent);
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result === true) {
        this.logout();
      }
    });
  }
}
