<div class="cover-spin" *ngIf="isLoading"></div>
<mat-card class="mt-5 d-block m-auto" *ngIf="caseManager">
  <div
    class="h4 mb-3"
    [innerHTML]="'case_management_portal.settings.heading.content' | translate"
  ></div>
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title
          [innerHTML]="
            'case_management_portal.settings.first_panel.title.content'
              | translate
          "
        ></mat-panel-title>
        <mat-panel-description
          [innerHTML]="
            'case_management_portal.settings.first_panel.description.content'
              | translate
          "
        >
          <mat-icon class="non-responsive">account_circle</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <mat-form-field appearance="outline">
        <mat-label
          [innerHTML]="
            'case_management_portal.settings.first_panel.body.name_form_field.label.content'
              | translate
          "
        ></mat-label>
        <input matInput [value]="caseManager.name" readonly />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label
          [innerHTML]="
            'case_management_portal.settings.first_panel.body.email_form_field.label.content'
              | translate
          "
        ></mat-label>
        <input matInput [value]="caseManager.email" readonly />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label
          [innerHTML]="
            'case_management_portal.settings.first_panel.body.role_form_field.label.content'
              | translate
          "
        ></mat-label>
        <mat-select [value]="caseManager.role" disabled>
          <mat-option *ngFor="let role of possibleRoles" [value]="role">{{
            'case_management_portal.settings.first_panel.body.role_form_field.' +
              role | translate
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title
          [innerHTML]="
            'case_management_portal.settings.second_panel.title.content'
              | translate
          "
        ></mat-panel-title>
        <mat-panel-description>
          {{
            'case_management_portal.settings.second_panel.description.content'
              | translate
          }}
          <mat-icon class="non-responsive">description</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <!-- Default receipt confirmation text -->
      <mat-form-field class="mt-2" appearance="outline">
        <mat-label
          [innerHTML]="
            'case_management_portal.settings.second_panel.body.receipt_form_field.label.content'
              | translate
          "
        ></mat-label>
        <textarea
          matInput
          (change)="
            updateCaseManagerDefaultReceiptConfirmationText(
              caseManager.default_receipt_confirmation_text
            )
          "
          [(ngModel)]="caseManager.default_receipt_confirmation_text"
          rows="6"
          maxlength="4096"
        ></textarea>
        <mat-hint
          align="end"
          [innerText]="
            caseManager.default_receipt_confirmation_text.length + ' / 4096'
          "
        ></mat-hint>
      </mat-form-field>
      <hr />
      <!-- Default closing confirmation text -->
      <mat-form-field class="mt-2" appearance="outline">
        <mat-label
          [innerHTML]="
            'case_management_portal.settings.second_panel.body.closing_form_field.label.content'
              | translate
          "
        ></mat-label>
        <textarea
          matInput
          (change)="
            updateCaseManagerDefaultClosingConfirmationText(
              caseManager.default_closing_confirmation_text
            )
          "
          [(ngModel)]="caseManager.default_closing_confirmation_text"
          rows="6"
          maxlength="4096"
        ></textarea>
        <mat-hint
          align="end"
          [innerText]="
            caseManager.default_closing_confirmation_text.length + ' / 4096'
          "
        ></mat-hint>
      </mat-form-field>

      <p
        class="success"
        *ngIf="defaultTextsSuccess"
        [innerHTML]="
          'case_management_portal.settings.second_panel.body.success_message.content'
            | translate
        "
      ></p>
      <p
        class="error"
        *ngIf="defaultTextsError"
        [innerHTML]="
          'case_management_portal.settings.second_panel.body.error_message.content'
            | translate
        "
      ></p>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title
          [innerHTML]="
            'case_management_portal.settings.third_panel.title.content'
              | translate
          "
        ></mat-panel-title>
        <mat-panel-description>
          {{
            'case_management_portal.settings.third_panel.description.content'
              | translate
          }}
          <mat-icon class="non-responsive">library_add</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-slide-toggle [(ngModel)]="caseManager.is_multi_factor_user">{{
        'case_management_portal.settings.third_panel.body.activate_multi_factor_switch.label.content'
          | translate
      }}</mat-slide-toggle>
      <div class="row mt-3">
        <div class="col-12 col-md-3">
          <!-- Country code form field -->
          <mat-form-field appearance="outline">
            <mat-label>{{
              'case_management_portal.settings.third_panel.body.country_code_form_field.label.content'
                | translate
            }}</mat-label>
            <mat-select
              [(ngModel)]="caseManager.country_code"
              [disabled]="!caseManager.is_multi_factor_user"
            >
              <mat-option
                *ngFor="let country of countries"
                [value]="country.code"
                >{{ country.name + ' (' + country.code + ')' }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12 col-md-9">
          <!-- Phone number form field -->
          <mat-form-field appearance="outline">
            <mat-label
              [innerHTML]="
                'case_management_portal.settings.third_panel.body.phone_number_form_field.label.content'
                  | translate
              "
            ></mat-label>
            <input
              matInput
              maxlength="20"
              placeholder="123 45678910"
              [(ngModel)]="caseManager.phone_number"
              [disabled]="!caseManager.is_multi_factor_user"
            />
            <mat-icon matSuffix>phone</mat-icon>
            <mat-hint
              align="end"
              [innerText]="caseManager.phone_number.length + ' / 20'"
            ></mat-hint>
          </mat-form-field>
        </div>
      </div>
      <!-- Submit button -->
      <button
        class="w-100 mt-2"
        (click)="updateCaseManagerMultiFactor()"
        mat-raised-button
        color="primary"
        [disabled]="
          (caseManager.is_multi_factor_user &&
            (!caseManager.country_code ||
              !caseManager.phone_number ||
              caseManager.phone_number.length > 20)) ||
          isLoading
        "
        [innerHTML]="
          'case_management_portal.settings.third_panel.body.save_button.content'
            | translate
        "
      ></button>
      <p
        class="success mt-3"
        *ngIf="updateMultiFactorSuccess"
        [innerHTML]="
          'case_management_portal.settings.third_panel.body.success_message.content'
            | translate
        "
      ></p>
      <p
        class="error mt-3"
        *ngIf="updateMultiFactorError"
        [innerHTML]="
          'case_management_portal.settings.third_panel.body.error_message.content'
            | translate
        "
      ></p>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title
          [innerHTML]="
            'case_management_portal.settings.fourth_panel.title.content'
              | translate
          "
        ></mat-panel-title>
        <mat-panel-description>
          {{
            'case_management_portal.settings.fourth_panel.description.content'
              | translate
          }}
          <mat-icon class="non-responsive">lock</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <form [formGroup]="changePasswordForm" (ngSubmit)="onFormSubmit()">
        <!-- Old password -->
        <mat-form-field appearance="outline">
          <mat-label
            [innerHTML]="
              'case_management_portal.settings.fourth_panel.body.current_password_form_field.label.content'
                | translate
            "
          ></mat-label>
          <input
            matInput
            [type]="hide ? 'password' : 'text'"
            maxlength="100"
            formControlName="password"
          />
          <button
            type="button"
            mat-icon-button
            matSuffix
            (click)="hide = !hide"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide"
          >
            <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
          <mat-hint
            align="end"
            [innerText]="changePasswordForm.value.password.length + ' / 100'"
          ></mat-hint>
          <mat-error>
            <span
              *ngIf="
                !changePasswordForm.get('password').valid &&
                changePasswordForm.get('password').touched
              "
              [innerHTML]="
                'case_management_portal.settings.fourth_panel.body.current_password_form_field.error_message.content'
                  | translate
              "
            ></span>
          </mat-error>
        </mat-form-field>
        <!-- New password -->
        <mat-form-field appearance="outline">
          <mat-label
            [innerHTML]="
              'case_management_portal.settings.fourth_panel.body.new_password_form_field.label.content'
                | translate
            "
          ></mat-label>
          <input
            matInput
            [type]="hide ? 'password' : 'text'"
            maxlength="100"
            formControlName="new_password"
          />
          <button
            type="button"
            mat-icon-button
            matSuffix
            (click)="hide = !hide"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide"
          >
            <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
          <mat-hint
            align="end"
            [innerText]="
              changePasswordForm.value.new_password.length + ' / 100'
            "
          ></mat-hint>
          <mat-error>
            <span
              *ngIf="
                !changePasswordForm.get('new_password').valid &&
                changePasswordForm.get('new_password').touched
              "
              [innerHTML]="
                'case_management_portal.settings.fourth_panel.body.new_password_form_field.error_message.content'
                  | translate
              "
            ></span>
          </mat-error>
        </mat-form-field>
        <!-- New password (repeated) -->
        <mat-form-field appearance="outline">
          <mat-label
            [innerHTML]="
              'case_management_portal.settings.fourth_panel.body.new_password2_form_field.label.content'
                | translate
            "
          ></mat-label>
          <input
            matInput
            [type]="hide ? 'password' : 'text'"
            maxlength="100"
            formControlName="new_password2"
          />
          <button
            type="button"
            mat-icon-button
            matSuffix
            (click)="hide = !hide"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide"
          >
            <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
          <mat-hint
            align="end"
            [innerText]="
              changePasswordForm.value.new_password2.length + ' / 100'
            "
          ></mat-hint>
          <mat-error>
            <span
              *ngIf="
                !changePasswordForm.get('new_password2').valid &&
                changePasswordForm.get('new_password2').touched
              "
              [innerHTML]="
                'case_management_portal.settings.fourth_panel.body.new_password_form_field.error_message.content'
                  | translate
              "
            ></span>
          </mat-error>
        </mat-form-field>
        <!-- Submit button -->
        <button
          class="w-100 mt-2"
          type="submit"
          mat-raised-button
          color="primary"
          [disabled]="!changePasswordForm.valid || isLoading"
          [innerHTML]="
            'case_management_portal.settings.fourth_panel.body.submit_button.content'
              | translate
          "
        ></button>
        <p
          class="success mt-3"
          *ngIf="changePasswordSuccessMessage"
          [innerHTML]="changePasswordSuccessMessage"
        ></p>
        <p
          class="error mt-3"
          *ngIf="changePasswordErrorMessage"
          [innerHTML]="changePasswordErrorMessage"
        ></p>
      </form>
    </mat-expansion-panel>
  </mat-accordion>
  <div class="mt-3">
    <button
      mat-flat-button
      [routerLink]="['/management']"
      routerLinkActive="router-link-active"
    >
      {{ 'global.back_button.content' | translate }}
    </button>
  </div>
</mat-card>
