<div class="cover-spin" *ngIf="isLoading"></div>
<img
  *ngIf="caseReportingPortalComponent.logoUrl"
  class="responsive m-auto mt-3"
  width="80%"
  [src]="caseReportingPortalComponent.logoUrl"
  [alt]="caseReportingPortalComponent.client.name + ' logo'"
/>
<mat-card class="my-5 p-5">
  <mat-stepper linear [orientation]="stepperOrientation" #stepper>
    <mat-step>
      <ng-template matStepLabel
        ><div>
          {{
            'case_reporting_portal.report_case.progress_bar_headings.left.content'
              | translate: client
          }}
        </div></ng-template
      >
      <hr class="mt-2" />
      <!-- Anonymity text-->

      <div *ngIf="!this.client || !this.client.reporting_portal_report_texts">
        <div
          [innerHTML]="
            'case_reporting_portal.report_case.report_texts.1'
              | translate: client
          "
        ></div>
        <div
          [innerHTML]="
            'case_reporting_portal.report_case.report_texts.2'
              | translate: client
          "
        ></div>
        <div
          [innerHTML]="
            'case_reporting_portal.report_case.report_texts.3'
              | translate: client
          "
        ></div>
        <div
          [innerHTML]="
            'case_reporting_portal.report_case.report_texts.4'
              | translate: client
          "
        ></div>
        <div
          [innerHTML]="
            'case_reporting_portal.report_case.report_texts.5'
              | translate: client
          "
        ></div>
        <div
          [innerHTML]="
            'case_reporting_portal.report_case.report_texts.6'
              | translate: client
          "
        ></div>
        <div
          [innerHTML]="
            'case_reporting_portal.report_case.report_texts.7'
              | translate: client
          "
        ></div>
        <div
          [innerHTML]="
            'case_reporting_portal.report_case.report_texts.8'
              | translate: client
          "
        ></div>
      </div>

      <div
        *ngIf="
          this.client &&
          this.client.reporting_portal_report_texts &&
          this.client.reporting_portal_report_texts[this.translate.currentLang]
        "
        [innerHTML]="
          this.client.reporting_portal_report_texts[this.translate.currentLang]
        "
      ></div>

      <hr />
      <!-- Captcha -->
      <div class="row">
        <div class="col-12 col-md-6 pt-3">
          <div class="h5">
            {{
              'case_reporting_portal.report_case.first_page.security_captcha.heading.content'
                | translate
            }}
          </div>
          <p>
            {{
              'case_reporting_portal.report_case.first_page.security_captcha.first_paragraph.content'
                | translate
            }}
          </p>
          <p>
            {{
              'case_reporting_portal.report_case.first_page.security_captcha.second_paragraph.content'
                | translate
            }}
          </p>
        </div>
        <div class="col-12 col-md-6 pt-3">
          <button
            class="w-100 mt-5 button-next"
            mat-raised-button
            (click)="scrollTop()"
            matStepperNext
            color="primary"
          >
            {{ 'global.next_button.content' | translate }}
          </button>
        </div>
      </div>
    </mat-step>
    <mat-step [completed]="false">
      <ng-template matStepLabel
        ><div>
          {{
            'case_reporting_portal.report_case.progress_bar_headings.center.content'
              | translate
          }}
        </div></ng-template
      >
      <hr />
      <p>
        {{
          'case_reporting_portal.report_case.second_page.receiver_text.content'
            | translate
        }}
        <b>{{ caseReportingPortalComponent.client.lsp_name }}</b>
      </p>
      <hr />
      <form [formGroup]="reportCaseForm" (ngSubmit)="onFormSubmit()">
        <!-- Optional Field: Sub-Client -->
        <mat-form-field *ngIf="hasSubClient" appearance="outline">
          <mat-label>{{
            'case_reporting_portal.report_case.second_page.report_form.sub_client_form_field.label.content'
              | translate
          }}</mat-label>
          <mat-select formControlName="sub_client_name">
            <mat-option
              *ngFor="let subClient of client.sub_client_names"
              [value]="subClient"
            >
              {{ subClient }}
            </mat-option>
          </mat-select>
          <mat-hint class="input-headline" align="start">{{
            'case_reporting_portal.report_case.second_page.report_form.sub_client_form_field.error_message.content'
              | translate
          }}</mat-hint>
          <mat-error
            *ngIf="
              reportCaseForm.controls['sub_client_name'].hasError('required')
            "
          >
            <span
              *ngIf="
                !reportCaseForm.get('sub_client_name').valid &&
                reportCaseForm.get('sub_client_name').touched
              "
              >{{
                'case_reporting_portal.report_case.second_page.report_form.sub_client_form_field.error_message.content'
                  | translate
              }}</span
            >
          </mat-error>
        </mat-form-field>

        <hr *ngIf="hasSubClient" />

        <!-- Subject of case -->
        <mat-form-field class="w-100 mb-3" appearance="outline">
          <mat-label>{{
            'case_reporting_portal.report_case.second_page.report_form.subject_form_field.label.content'
              | translate
          }}</mat-label>
          <input matInput maxlength="100" formControlName="subject" />
          <mat-hint class="input-headline" align="start">{{
            'case_reporting_portal.report_case.second_page.report_form.subject_form_field.error_message.content'
              | translate
          }}</mat-hint>
          <mat-hint
            class="input-headline"
            align="end"
            [innerText]="reportCaseForm.value.subject.length + ' / 100'"
          ></mat-hint>
          <mat-error class="input-headline">
            <span
              *ngIf="
                !reportCaseForm.get('subject').valid &&
                reportCaseForm.get('subject').touched
              "
              >{{
                'case_reporting_portal.report_case.second_page.report_form.subject_form_field.error_message.content'
                  | translate
              }}</span
            >
          </mat-error>
        </mat-form-field>

        <div class="row mt-3">
          <div class="col-12 col-md-6">
            <mat-form-field appearance="outline">
              <mat-label>{{
                'case_reporting_portal.report_case.second_page.report_form.name_selection.label.content'
                  | translate
              }}</mat-label>
              <mat-select
                [(ngModel)]="nameSelection"
                [ngModelOptions]="{ standalone: true }"
              >
                <mat-option [value]="false">{{
                  'case_reporting_portal.report_case.second_page.report_form.name_selection.responses.no_selection.content'
                    | translate
                }}</mat-option>
                <mat-option [value]="true">{{
                  'case_reporting_portal.report_case.second_page.report_form.name_selection.responses.yes_selection.content'
                    | translate
                }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-6" *ngIf="nameSelection">
            <mat-form-field appearance="outline">
              <mat-label>{{
                'case_reporting_portal.report_case.second_page.report_form.name_form_field.label.content'
                  | translate
              }}</mat-label>
              <input matInput maxlength="100" formControlName="name" />
              <mat-hint
                class="input-headline"
                align="end"
                [innerText]="reportCaseForm.value.name.length + ' / 100'"
              ></mat-hint>
            </mat-form-field>
            <p>
              {{
                'case_reporting_portal.report_case.second_page.report_form.name_form_field.disclaimer.content'
                  | translate: client
              }}
            </p>
          </div>
        </div>

        <hr />
        <p>
          {{
            'case_reporting_portal.report_case.second_page.report_form.case_description_note.content'
              | translate
          }}
        </p>

        <!-- Description of case -->
        <mat-form-field class="mt-2" appearance="outline">
          <mat-label>{{
            'case_reporting_portal.report_case.second_page.report_form.description_form_field.label.content'
              | translate
          }}</mat-label>
          <textarea
            matInput
            formControlName="description"
            rows="6"
            maxlength="4096"
            [errorStateMatcher]="matcher"
          ></textarea>
          <mat-hint class="input-headline" align="start">{{
            'case_reporting_portal.report_case.second_page.report_form.description_form_field.hint.content'
              | translate
          }}</mat-hint>
          <mat-hint
            class="input-headline"
            align="end"
            [innerText]="reportCaseForm.value.description.length + ' / 4096'"
          ></mat-hint>
          <mat-error class="input-headline">
            <span
              *ngIf="
                !reportCaseForm.get('description').valid &&
                reportCaseForm.get('description').touched
              "
              >{{
                'case_reporting_portal.report_case.second_page.report_form.description_form_field.hint.content'
                  | translate
              }}</span
            >
          </mat-error>
        </mat-form-field>
        <hr />

        <!-- Date of incident -->
        <mat-form-field class="mt-2" appearance="outline">
          <mat-label>{{
            'case_reporting_portal.report_case.second_page.report_form.date_form_field.label.content'
              | translate
          }}</mat-label>
          <textarea
            matInput
            formControlName="date"
            rows="3"
            maxlength="100"
            [errorStateMatcher]="matcher"
          ></textarea>
          <mat-hint class="input-headline" align="start">{{
            'case_reporting_portal.report_case.second_page.report_form.date_form_field.hint.content'
              | translate
          }}</mat-hint>
          <mat-hint
            class="input-headline"
            align="end"
            [innerText]="reportCaseForm.value.date.length + ' / 100'"
          ></mat-hint>
          <mat-error class="input-headline">
            <span
              *ngIf="
                !reportCaseForm.get('date').valid &&
                reportCaseForm.get('date').touched
              "
              >{{
                'case_reporting_portal.report_case.second_page.report_form.date_form_field.hint.content'
                  | translate
              }}</span
            >
          </mat-error>
        </mat-form-field>
        <hr />

        <!-- Location of incident -->
        <mat-form-field class="mt-2" appearance="outline">
          <mat-label>{{
            'case_reporting_portal.report_case.second_page.report_form.location_form_field.label.content'
              | translate
          }}</mat-label>
          <textarea
            matInput
            formControlName="location"
            rows="3"
            maxlength="1024"
            [errorStateMatcher]="matcher"
          ></textarea>
          <mat-hint class="input-headline" align="start">{{
            'case_reporting_portal.report_case.second_page.report_form.location_form_field.hint.content'
              | translate
          }}</mat-hint>
          <mat-hint
            class="input-headline"
            align="end"
            [innerText]="reportCaseForm.value.location.length + ' / 1024'"
          ></mat-hint>
          <mat-error class="input-headline">
            <span
              *ngIf="
                !reportCaseForm.get('location').valid &&
                reportCaseForm.get('location').touched
              "
              >{{
                'case_reporting_portal.report_case.second_page.report_form.location_form_field.hint.content'
                  | translate
              }}</span
            >
          </mat-error>
        </mat-form-field>
        <hr />

        <!-- Affected persons -->
        <mat-form-field class="mt-2" appearance="outline">
          <mat-label>{{
            'case_reporting_portal.report_case.second_page.report_form.affected_form_field.label.content'
              | translate
          }}</mat-label>
          <textarea
            matInput
            formControlName="affected"
            rows="3"
            maxlength="1024"
            [errorStateMatcher]="matcher"
          ></textarea>
          <mat-hint class="input-headline" align="start">{{
            'case_reporting_portal.report_case.second_page.report_form.affected_form_field.hint.content'
              | translate
          }}</mat-hint>
          <mat-hint
            class="input-headline"
            align="end"
            [innerText]="reportCaseForm.value.affected.length + ' / 1024'"
          ></mat-hint>
          <mat-error class="input-headline">
            <span
              *ngIf="
                !reportCaseForm.get('affected').valid &&
                reportCaseForm.get('affected').touched
              "
              >{{
                'case_reporting_portal.report_case.second_page.report_form.affected_form_field.hint.content'
                  | translate
              }}</span
            >
          </mat-error>
        </mat-form-field>
        <hr />

        <!-- Type of observation -->
        <mat-form-field class="mt-2" appearance="outline">
          <mat-label>{{
            'case_reporting_portal.report_case.second_page.report_form.observation_form_field.label.content'
              | translate
          }}</mat-label>
          <textarea
            matInput
            formControlName="observation"
            rows="3"
            maxlength="1024"
            [errorStateMatcher]="matcher"
          ></textarea>
          <mat-hint class="input-headline" align="start">{{
            'case_reporting_portal.report_case.second_page.report_form.observation_form_field.hint.content'
              | translate
          }}</mat-hint>
          <mat-hint
            class="input-headline"
            align="end"
            [innerText]="reportCaseForm.value.observation.length + ' / 1024'"
          ></mat-hint>
          <mat-error class="input-headline">
            <span
              *ngIf="
                !reportCaseForm.get('observation').valid &&
                reportCaseForm.get('observation').touched
              "
              >{{
                'case_reporting_portal.report_case.second_page.report_form.observation_form_field.hint.content'
                  | translate
              }}</span
            >
          </mat-error>
        </mat-form-field>
        <hr />

        <!-- Relationship to affected company, no sub clients -->
        <mat-form-field *ngIf="!hasSubClient" class="mt-2" appearance="outline">
          <mat-label>{{
            'case_reporting_portal.report_case.second_page.report_form.relationship_form_field.label.content'
              | translate: client
          }}</mat-label>
          <textarea
            matInput
            formControlName="relationship"
            rows="3"
            maxlength="1024"
            [errorStateMatcher]="matcher"
          ></textarea>
          <mat-hint class="input-headline" align="start">{{
            'case_reporting_portal.report_case.second_page.report_form.relationship_form_field.hint.content'
              | translate: client
          }}</mat-hint>
          <mat-hint
            class="input-headline"
            align="end"
            [innerText]="reportCaseForm.value.relationship.length + ' / 1024'"
          ></mat-hint>
          <mat-error class="input-headline">
            <span
              *ngIf="
                !reportCaseForm.get('relationship').valid &&
                reportCaseForm.get('relationship').touched
              "
              >{{
                'case_reporting_portal.report_case.second_page.report_form.relationship_form_field.hint.content'
                  | translate: client
              }}</span
            >
          </mat-error>
        </mat-form-field>

        <!-- Relationship to affected company, with sub clients -->
        <mat-form-field *ngIf="hasSubClient" class="mt-2" appearance="outline">
          <mat-label>{{
            'case_reporting_portal.report_case.second_page.report_form.relationship_form_field.label.content_sub_client'
              | translate
                : {
                    sub_client_name:
                      reportCaseForm.value.sub_client_name ??
                        'case_reporting_portal.report_case.second_page.report_form.relationship_form_field.default_company.content'
                      | translate
                  }
          }}</mat-label>
          <textarea
            matInput
            formControlName="relationship"
            rows="3"
            maxlength="1024"
            [errorStateMatcher]="matcher"
          ></textarea>
          <mat-hint class="input-headline" align="start">{{
            'case_reporting_portal.report_case.second_page.report_form.relationship_form_field.hint.content_sub_client'
              | translate
                : {
                    sub_client_name:
                      reportCaseForm.value.sub_client_name ??
                        'case_reporting_portal.report_case.second_page.report_form.relationship_form_field.default_company.content'
                      | translate
                  }
          }}</mat-hint>
          <mat-hint
            class="input-headline"
            align="end"
            [innerText]="reportCaseForm.value.relationship.length + ' / 1024'"
          ></mat-hint>
          <mat-error class="input-headline">
            <span
              *ngIf="
                !reportCaseForm.get('relationship').valid &&
                reportCaseForm.get('relationship').touched
              "
              >{{
                'case_reporting_portal.report_case.second_page.report_form.relationship_form_field.hint.content_sub_client'
                  | translate
                    : {
                        sub_client_name:
                          reportCaseForm.value.sub_client_name ??
                            'case_reporting_portal.report_case.second_page.report_form.relationship_form_field.default_company.content'
                          | translate
                      }
              }}</span
            >
          </mat-error>
        </mat-form-field>
        <hr />

        <!-- Any additional information -->
        <mat-form-field class="mt-2" appearance="outline">
          <mat-label>{{
            'case_reporting_portal.report_case.second_page.report_form.additional_information_form_field.label.content'
              | translate
          }}</mat-label>
          <textarea
            matInput
            formControlName="additional_information"
            rows="3"
            maxlength="1024"
            [errorStateMatcher]="matcher"
          ></textarea>
          <mat-hint class="input-headline" align="start">{{
            'case_reporting_portal.report_case.second_page.report_form.additional_information_form_field.hint.content'
              | translate
          }}</mat-hint>
          <mat-hint
            class="input-headline"
            align="end"
            [innerText]="
              reportCaseForm.value.additional_information.length + ' / 1024'
            "
          ></mat-hint>
          <mat-error class="input-headline">
            <span
              *ngIf="
                !reportCaseForm.get('additional_information').valid &&
                reportCaseForm.get('additional_information').touched
              "
              >{{
                'case_reporting_portal.report_case.second_page.report_form.additional_information_form_field.hint.content'
                  | translate
              }}</span
            >
          </mat-error>
        </mat-form-field>

        <hr />
        <p>
          {{
            'case_reporting_portal.report_case.second_page.report_form.email_form_field.disclaimer.content'
              | translate: client
          }}
        </p>

        <!-- E-Mail Address of reporter -->
        <mat-form-field class="mt-2" appearance="outline">
          <mat-label>{{
            'case_reporting_portal.report_case.second_page.report_form.email_form_field.label.content'
              | translate
          }}</mat-label>
          <input
            matInput
            maxlength="100"
            type="email"
            formControlName="email"
            [errorStateMatcher]="matcher"
          />
          <mat-hint
            class="input-headline"
            *ngIf="reportCaseForm.value.email !== undefined"
            align="end"
            [innerText]="reportCaseForm.value.email.length + ' / 100'"
          ></mat-hint>
          <mat-error class="input-headline">
            <span
              *ngIf="
                !reportCaseForm.get('email').valid &&
                reportCaseForm.get('email').touched
              "
              >{{
                'case_reporting_portal.report_case.second_page.report_form.email_form_field.error_message.content'
                  | translate
              }}</span
            >
          </mat-error>
        </mat-form-field>
        <hr />
        <p>
          {{
            'case_reporting_portal.report_case.second_page.report_form.further_information_request.content'
              | translate
          }}
        </p>

        <!-- Country of case -->
        <mat-form-field appearance="outline">
          <mat-label>{{
            'case_reporting_portal.report_case.second_page.report_form.country_form_field.label.content'
              | translate
          }}</mat-label>
          <mat-select formControlName="country" [errorStateMatcher]="matcher">
            <mat-option
              *ngFor="let country of countries"
              [value]="country.code"
              >{{ country.name }}</mat-option
            >
          </mat-select>
          <mat-error class="input-headline">
            <span
              *ngIf="
                !reportCaseForm.get('country').valid &&
                reportCaseForm.get('country').touched
              "
              >{{
                'case_reporting_portal.report_case.second_page.report_form.country_form_field.error_message.content'
                  | translate
              }}</span
            >
          </mat-error>
        </mat-form-field>
        <hr />
        <p
          [innerHTML]="
            'case_reporting_portal.report_case.second_page.report_form.files_upload_disclaimer.first_disclaimer.content'
              | translate
          "
        ></p>
        <p
          [innerHTML]="
            'case_reporting_portal.report_case.second_page.report_form.files_upload_disclaimer.second_disclaimer.content'
              | translate
          "
        ></p>

        <input
          class="d-none"
          (change)="onFileChange($event)"
          type="file"
          [multiple]="true"
          #fileUpload
        />
        <div class="my-3">
          <button
            type="button"
            class="mb-1 py-1"
            mat-stroked-button
            (click)="fileUpload.click()"
            color="primary"
          >
            {{
              'case_reporting_portal.report_case.second_page.report_form.files_upload_form_field.label.content'
                | translate
            }}
          </button>
        </div>
        <table
          *ngIf="files.length > 0"
          class="my-3"
          mat-table
          [dataSource]="filesDataSource"
        >
          <!-- Display file -->
          <!-- File name -->
          <ng-container matColumnDef="file_name">
            <th mat-header-cell *matHeaderCellDef>
              {{
                'case_reporting_portal.report_case.second_page.report_form.uploaded_files_table.left_column_heading.content'
                  | translate
              }}
            </th>
            <td mat-cell *matCellDef="let file">{{ file.name }}</td>
          </ng-container>
          <!-- Remove file button -->
          <ng-container matColumnDef="remove">
            <th mat-header-cell *matHeaderCellDef>
              {{
                'case_reporting_portal.report_case.second_page.report_form.uploaded_files_table.right_column_heading.content'
                  | translate
              }}
            </th>
            <td mat-cell *matCellDef="let file">
              <button
                mat-icon-button
                type="button"
                (click)="removeFile(file)"
                color="warn"
                aria-label="Remove file"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </ng-container>
          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <hr />
        <p>
          {{
            'case_reporting_portal.report_case.second_page.report_form.password_form_field.disclaimer.content'
              | translate
          }}
        </p>
        <!-- Password of case user account -->
        <mat-form-field appearance="outline">
          <mat-label>{{
            'case_reporting_portal.report_case.second_page.report_form.password_form_field.label.content'
              | translate
          }}</mat-label>
          <input
            matInput
            [type]="hide ? 'password' : 'text'"
            maxlength="100"
            formControlName="password"
          />
          <button
            type="button"
            mat-icon-button
            matSuffix
            (click)="hide = !hide"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide"
          >
            <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
          <mat-hint
            class="input-headline"
            align="end"
            [innerText]="reportCaseForm.value.password.length + ' / 100'"
          ></mat-hint>
          <mat-error class="input-headline">
            <span
              *ngIf="
                !reportCaseForm.get('password').valid &&
                reportCaseForm.get('password').touched
              "
              >{{
                'case_reporting_portal.report_case.second_page.report_form.password_form_field.error_message.content'
                  | translate
              }}</span
            >
          </mat-error>
        </mat-form-field>
        <hr />
        <re-captcha
          (resolved)="resolved($event)"
          [siteKey]="reCaptchaSiteKey"
        ></re-captcha>
        <!-- Submit button and messages -->
        <div class="row mt-4 mb-5">
          <div class="col-3">
            <button
              class="w-100 py-1"
              mat-raised-button
              (click)="scrollTop()"
              matStepperPrevious
            >
              {{ 'global.back_button.content' | translate }}
            </button>
          </div>
          <div class="col-9">
            <button
              class="py-1"
              mat-raised-button
              type="submit"
              [disabled]="
                !reportCaseForm.valid || isLoading || !captchaResolved
              "
              color="primary"
            >
              {{
                'case_reporting_portal.report_case.second_page.report_form.submit_button.content'
                  | translate
              }}
            </button>
          </div>
        </div>
        <p class="error" *ngIf="errorMessage">{{ errorMessage }}</p>
      </form>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel
        ><div>
          {{
            'case_reporting_portal.report_case.progress_bar_headings.right.content'
              | translate
          }}
        </div></ng-template
      >
      <div class="h5 my-3">
        {{
          'case_reporting_portal.report_case.third_page.heading.content'
            | translate
        }}
      </div>
      <hr />
      <p
        [innerHTML]="
          'case_reporting_portal.report_case.third_page.receiver_text.content'
            | translate: client
        "
      ></p>
      <hr />
      <p class="error" *ngIf="errorMessage">{{ errorMessage }}</p>
      <p
        [innerHTML]="
          'case_reporting_portal.report_case.third_page.portal_legend.first_paragraph.content'
            | translate
        "
      ></p>
      <p>
        {{
          'case_reporting_portal.report_case.third_page.portal_legend.second_paragraph.content'
            | translate: client
        }}
      </p>
      <mat-form-field appearance="outline">
        <mat-label>{{
          'case_reporting_portal.report_case.third_page.case_id_form_field.label.content'
            | translate
        }}</mat-label>
        <input
          class="credential_input"
          type="text"
          matInput
          [readonly]="true"
          [value]="caseUserCredentials.caseId"
        />
        <button
          matSuffix
          mat-icon-button
          aria-label="Copy case id"
          [cdkCopyToClipboard]="caseUserCredentials.caseId"
        >
          <mat-icon>content_copy</mat-icon>
        </button>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{
          'case_reporting_portal.report_case.third_page.password_form_field.label.content'
            | translate
        }}</mat-label>
        <input
          class="credential_input"
          type="text"
          matInput
          [readonly]="true"
          [value]="caseUserCredentials.password"
        />
        <button
          matSuffix
          mat-icon-button
          aria-label="Copy password"
          [cdkCopyToClipboard]="caseUserCredentials.password"
        >
          <mat-icon>content_copy</mat-icon>
        </button>
      </mat-form-field>
      <hr />
      <button
        class="py-1 w-100"
        (click)="downloadCredentialsAsPDF(caseUserCredentials)"
        mat-raised-button
        color="primary"
      >
        {{
          'case_reporting_portal.report_case.third_page.download_case_credentials_button.content'
            | translate
        }}
      </button>
      <hr />
      <p>
        {{
          'case_reporting_portal.report_case.third_page.close_browser_tab_note.content'
            | translate
        }}
      </p>
    </mat-step>
  </mat-stepper>
</mat-card>
