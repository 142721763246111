import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogDeleteCaseData } from 'src/app/shared/interfaces/caseManagementCase';

@Component({
  selector: 'app-delete-case-dialog',
  templateUrl: './delete-case-dialog.component.html',
  styleUrls: ['./delete-case-dialog.component.scss']
})
export class DeleteCaseDialogComponent {
  confirmationInput = '';
  deleteConfirmed = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogDeleteCaseData,
    private dialogRef: MatDialogRef<DeleteCaseDialogComponent>
  ) {}

  onInputChange() {
    // Check if the input matches the expected string for confirmation
    this.deleteConfirmed = this.confirmationInput === this.data.caseId;
  }

  onCancel() {
    // Close the dialog without performing the deletion
    this.dialogRef.close(false);
  }

  onConfirm() {
    // Close the dialog and confirm the deletion
    this.dialogRef.close(true);
  }
}
