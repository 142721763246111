import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  FormGroupDirective,
  FormGroup,
  Validators,
  FormBuilder
} from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Unsubscribe, User, onAuthStateChanged } from 'firebase/auth';
import {
  query,
  collection,
  onSnapshot,
  collectionGroup,
  getDocs,
  where
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { ref, uploadBytes } from 'firebase/storage';
import { MyErrorStateMatcher } from 'src/app/shared/functions/errorStateMatcher';
import { applyFilter } from 'src/app/shared/functions/tableFiltering';
import { FirebaseAuthService } from 'src/app/shared/services/firebase-auth.service';
import { firestore, auth, functions, storage } from 'src/firebase';
import { AdminPortalComponent } from '../admin-portal/admin-portal.component';
import { EChartsOption } from 'echarts';
import { environment } from 'src/environments/environment';
import { LegalServiceProvider } from 'src/app/shared/models/legalServiceProvider.model';

@Component({
  selector: 'app-admin-portal-home',
  templateUrl: './admin-portal-home.component.html',
  styleUrls: ['./admin-portal-home.component.scss']
})
export class AdminPortalHomeComponent implements OnInit, OnDestroy {
  // admin user currently logged in
  user: User;

  // clear form
  @ViewChild(FormGroupDirective) formDirective: FormGroupDirective;

  // loading animation indicator variable
  isLoading = false;

  // angular material lsp table setup
  lspTableDisplayedColumns: string[] = ['name', 'view'];
  lspDataSource = new MatTableDataSource();

  // angular material admin users table setup
  adminUsersTableDisplayedColumns: string[] = ['name', 'username'];
  adminUsersDataSource = new MatTableDataSource();

  // unsubscribe vars for snapshots
  unsubscribeLegalServiceProviders: Unsubscribe;
  unsubscribeAdminUsers: Unsubscribe;

  // add lsp form
  addLspForm: FormGroup = this.formBuilder.group({
    lsp_id: [
      '',
      Validators.compose([Validators.maxLength(20), Validators.required])
    ],
    lsp_name: ['', Validators.required],
    lsp_name_short: ['', Validators.required],
    lsp_withdrawal_email: [
      '',
      Validators.compose([Validators.required, Validators.email])
    ]
  });

  // lsp logo file
  lspLogoFile: File;
  fileSelectionError = '';

  // error state matcher for form
  matcher = new MyErrorStateMatcher();

  // success and error
  addLspSuccess = false;
  addLspError = false;

  options: EChartsOption;
  mergeOptions: EChartsOption;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private firebaseAuth: FirebaseAuthService,
    public adminPortalComponent: AdminPortalComponent,
    public translate: TranslateService
  ) {}

  // get all legal service providers
  getLegalServiceProviders() {
    // define query
    const q = query(collection(firestore, 'legal_service_providers'));
    // listen to realtime updates
    this.unsubscribeLegalServiceProviders = onSnapshot(
      q,
      (querySnapshot) => {
        const legalServiceProviders = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          const lsp: LegalServiceProvider = {
            id: doc.id,
            name: data.name,
            name_short: data.name_short,
            withdrawal_email: data.withdrawal_email
          };

          legalServiceProviders.push(lsp);
        });

        // array of strings alphabetically
        legalServiceProviders.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });

        // update legal service providers table
        this.lspDataSource = new MatTableDataSource(legalServiceProviders);
      },
      (error) => {
        console.error('Error fetching lsp:', error);
      }
    );
  }

  // get all administrators
  getAdministrators() {
    // define query
    const q = query(collection(firestore, 'admin_users'));
    // listen to realtime updates
    this.unsubscribeAdminUsers = onSnapshot(
      q,
      (querySnapshot) => {
        const adminUsers = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          const adminUser = {
            id: data.id,
            name: data.name,
            username: data.username,
            is_multi_factor_user: data.is_multi_factor_user,
            country_code: data.country_code,
            phone_number: data.phone_number
          };
          adminUsers.push(adminUser);
        });
        // update clients table
        this.adminUsersDataSource = new MatTableDataSource(adminUsers);
      },
      (error) => {
        console.error('Error fetching admin users:', error);
      }
    );
  }

  async getClients() {
    try {
      const clientsGroup = query(
        collectionGroup(firestore, 'clients'),
        where('is_active', '==', true)
      );
      const querySnapshot = await getDocs(clientsGroup);

      // for testing (only in prod)
      let i = 0;
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.lsp_id !== 'kutschker' || environment.production === false) {
          i++;
        }
      });

      let companyText = '';
      // text for chart
      this.translate
        .get('admin_portal.lsp.lsp_clients_panel.heading.content')
        .subscribe((res) => (companyText = res));

      let activeText = '';
      this.translate
        .get('admin_portal.lsp.lsp_clients_panel.table.active_column.content')
        .subscribe((res) => (activeText = res));

      this.options = {
        legend: {},
        tooltip: {}, //
        dataset: {
          // Provide a set of data.
          source: [
            ['product', activeText],
            [companyText, i]
          ]
        },
        // Declare an x-axis (category axis).
        // The category map the first column in the dataset by default.
        xAxis: { type: 'category' },
        // Declare a y-axis (value axis).
        yAxis: {},
        // Declare several 'bar' series,
        // every series will auto-map to each column by default.
        series: [{ type: 'bar' }]
      };
    } catch (error) {
      console.error(error);
    }
  }

  async extract() {
    // start real time snapshot of lsps
    this.getLegalServiceProviders();

    // start real time snapshot of admin accounts
    this.getAdministrators();

    await this.getClients();
  }

  async ngOnInit() {
    // enable loading
    this.isLoading = true;

    // check if user is still logged in (subscribe to auth state)
    onAuthStateChanged(auth, async (user) => {
      if (user !== null) {
        // get firebase auth
        const claims = (await auth.currentUser.getIdTokenResult()).claims;

        // redirect user to case if logged in
        if (claims.role !== 'admin') {
          // user is not logged in => redirect to login
          this.router.navigate(['admin/login']);
        }

        // extract
        await this.extract();

        // disable loading
        this.isLoading = false;
      } else {
        // user is not logged in
        this.router.navigate(['admin/login']);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.unsubscribeAdminUsers) {
      this.unsubscribeAdminUsers();
    }
    if (this.unsubscribeLegalServiceProviders) {
      this.unsubscribeLegalServiceProviders();
    }
  }

  // table filtering
  filterLegalServiceProviders(event: Event) {
    this.lspDataSource.filter = applyFilter(event);
  }

  // logo file was selected
  onFileSelected(event: Event) {
    // validate file type (only png allowed)
    if (
      event.target['files'].length == 1 &&
      event.target['files'][0].name.split('.').pop() == 'png'
    ) {
      this.lspLogoFile = event.target['files'][0];
      // clear selection error
      this.fileSelectionError = '';
    } else {
      // error during file selection or wrong file type
      this.translate
        .get('global.file_selection_format_error.content')
        .subscribe((res) => (this.fileSelectionError = res));
    }
  }

  async createLsp(valueData: LegalServiceProvider) {
    try {
      // callable backend function
      const createLegalServiceProvider = httpsCallable<
        {
          lspId: string;
          lspName: string;
          lspNameShort: string;
          lspWithdrawalEmail: string;
        },
        { lspId: string }
      >(functions, 'createLegalServiceProvider');

      // create legal service provider with keys
      const { data } = await createLegalServiceProvider({
        lspId: valueData.id,
        lspName: valueData.name,
        lspNameShort: valueData.name_short,
        lspWithdrawalEmail: valueData.withdrawal_email
      });

      if (!data.lspId) {
        throw data;
      }

      // upload logo if one was selected
      if (this.lspLogoFile) {
        // storage reference for file
        const storageRef = ref(
          storage,
          'legal_service_providers/' + data.lspId + '/logo.png'
        );
        // upload file
        await uploadBytes(storageRef, this.lspLogoFile);
      }

      // clear form and file selection
      this.formDirective.resetForm();
      this.lspLogoFile = undefined;

      // display success
      this.addLspSuccess = true;
    } catch (error) {
      console.error(error);
      // print error to user
      this.addLspError = true;
    }
    // disable loading
    this.isLoading = false;
  }

  // add legal service provider form
  onFormSubmit() {
    // enable loading
    this.isLoading = true;

    // clear error and success messages
    this.addLspError = this.addLspSuccess = false;
    this.fileSelectionError = '';

    const data = {
      id: this.addLspForm.value.lsp_id,
      name: this.addLspForm.value.lsp_name,
      name_short: this.addLspForm.value.lsp_name_short,
      withdrawal_email: this.addLspForm.value.lsp_withdrawal_email
    };

    // create lsp
    this.createLsp(data);
  }
}
