<img
  *ngIf="caseReportingPortalComponent.logoUrl"
  class="responsive m-auto mt-3"
  width="80%"
  [src]="caseReportingPortalComponent.logoUrl"
  [alt]="caseReportingPortalComponent.client.name + ' logo'"
/>
<div
  class="row my-5 mx-0 px-0"
  *ngIf="caseReportingPortalComponent.client.reporting_portal_several_texts"
>
  <div class="col-12 col-md-4 mb-3 left">
    <mat-card
      (click)="openCountriesDialog()"
      class="text-center d-block m-auto mb-3 py-5 px-4"
    >
      <mat-icon aria-hidden="false" aria-label="Report case Icon"
        >description</mat-icon
      >
      <p class="font-weight-bold">
        {{ 'case_reporting_portal.home.report_case_box.content' | translate }}
      </p>
    </mat-card>
    <mat-card
      [routerLink]="['login']"
      routerLinkActive="router-link-active"
      class="text-center d-block m-auto mb-3 py-5 px-4"
    >
      <mat-icon aria-hidden="false" aria-label="Login Icon">login</mat-icon>
      <p class="font-weight-bold">
        {{ 'case_reporting_portal.home.login_box.content' | translate }}
      </p>
    </mat-card>
  </div>
  <div class="col-12 col-md-8 mb-3 px-4 right">
    <mat-card>
      <div
        *ngIf="
          !caseReportingPortalComponent.client.reporting_portal_several_texts[
            translate.currentLang.toLowerCase()
          ]
        "
        [innerHTML]="
          caseReportingPortalComponent.client.reporting_portal_several_texts[
            'en-us'
          ]
        "
      ></div>
      <div
        *ngIf="
          caseReportingPortalComponent.client.reporting_portal_several_texts[
            translate.currentLang.toLowerCase()
          ]
        "
        [innerHTML]="
          caseReportingPortalComponent.client.reporting_portal_several_texts[
            translate.currentLang.toLowerCase()
          ]
        "
      ></div>
    </mat-card>
  </div>
</div>
