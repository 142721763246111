import { Component, NgZone, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  onAuthStateChanged,
  RecaptchaVerifier,
  getMultiFactorResolver,
  PhoneMultiFactorGenerator,
  PhoneAuthProvider,
  MultiFactorResolver,
  PhoneMultiFactorInfo,
  User
} from 'firebase/auth';
import { MyErrorStateMatcher } from 'src/app/shared/functions/errorStateMatcher';
import { FirebaseAuthService } from 'src/app/shared/services/firebase-auth.service';
import { auth } from 'src/firebase';
import { CaseManagementPortalComponent } from '../case-management-portal/case-management-portal.component';

@Component({
  selector: 'app-case-management-portal-login',
  templateUrl: './case-management-portal-login.component.html',
  styleUrls: ['./case-management-portal-login.component.scss']
})
export class CaseManagementPortalLoginComponent implements OnInit {
  // form variable
  loginForm: FormGroup = this.formBuilder.group({
    email: ['', Validators.compose([Validators.required, Validators.email])],
    password: ['', Validators.required]
  });

  // error state matcher for form
  matcher = new MyErrorStateMatcher();

  // hide password at login form (default)
  hide = true;

  // message variables
  errorMessage = '';

  // prevent duplicated api requests
  isLoading = false;

  // mfa variables
  resolver: MultiFactorResolver;
  verificationId: string;
  verificationCode = '';

  // recaptcha verifier
  recaptchaVerifier: RecaptchaVerifier;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    public caseManagementPortalComponent: CaseManagementPortalComponent,
    private firebaseAuth: FirebaseAuthService,
    private ngZone: NgZone,
    public translate: TranslateService
  ) {}

  async ngOnInit() {
    // wait until auth is initialized
    const user = await this.firebaseAuth.getCurrentUser();

    if (user !== null) {
      // get firebase auth claims
      const claims = (await auth.currentUser.getIdTokenResult()).claims;

      // redirect case manager to home if logged in
      if (
        claims.role === 'case_manager' ||
        claims.role === 'case_manager_admin'
      ) {
        // user is logged in => redirect to home
        this.router.navigate(['management']);
      }
    }

    // check if user is logged in (subscribe to auth state)
    onAuthStateChanged(auth, async (user: User) => {
      if (user !== null) {
        // get firebase auth claims
        const claims = (await auth.currentUser.getIdTokenResult()).claims;

        // redirect case manager to home if logged in
        if (
          claims.role === 'case_manager' ||
          claims.role === 'case_manager_admin'
        ) {
          // user is logged in => redirect to home
          this.ngZone.run(() => this.router.navigate(['management']));
        }
      }
    });

    // setup google recaptcha identity validation for multi factor authentication
    this.recaptchaVerifier = new RecaptchaVerifier(
      auth,
      'recaptcha-container',
      {
        size: 'invisible',
        callback: () => {
          return null;
        }
      }
    );
  }

  // handle login error
  handleLoginError(error: { code: string }) {
    // Handle other errors such as wrong password.
    // logout user in case already logged in
    this.firebaseAuth.signOutUser();
    // empty resolver and verification id variables
    this.resolver = this.verificationId = undefined;
    if (
      error.code == 'auth/invalid-verification-code' ||
      error.code == 'auth/multi-factor-auth-required'
    ) {
      // display mfa error message
      this.translate
        .get('case_management_portal.login.error_messages.mfa_error.content')
        .subscribe((res) => (this.errorMessage = res));
    } else {
      // display error message
      this.translate
        .get(
          'case_management_portal.login.error_messages.wrong_email_or_password.content'
        )
        .subscribe((res) => (this.errorMessage = res));
    }
    // deactivate loading var if login failed
    this.isLoading = false;
  }

  // login case user with credentials
  async login(email: string, password: string) {
    try {
      // sign in user
      await this.firebaseAuth.signInUser(email, password);
      // validate user role for case management portal (user is not enrolled with a second factor)
      this.validateUserRole();
    } catch (error) {
      switch (error.code) {
        case 'auth/multi-factor-auth-required':
          try {
            // get mfa resolver from code
            this.resolver = getMultiFactorResolver(auth, error);
            // check if factor is valid (phone)
            const selectedIndex = 0;
            if (
              this.resolver.hints[selectedIndex].factorId ==
              PhoneMultiFactorGenerator.FACTOR_ID
            ) {
              // set phone info options
              const phoneInfoOptions = {
                multiFactorHint: this.resolver.hints[0],
                session: this.resolver.session
              };
              // initalize phone auth provider
              const phoneAuthProvider = new PhoneAuthProvider(auth);

              // get verification id by sending SMS verification code to user and print verification code input to user
              this.verificationId = await phoneAuthProvider.verifyPhoneNumber(
                phoneInfoOptions,
                this.recaptchaVerifier
              );
            } else {
              throw new Error('Factor ID for MFA is invalid.');
            }
          } catch (error) {
            // logout user in case already logged in
            this.firebaseAuth.signOutUser();
            // unset resolver and verification id variables
            this.resolver = this.verificationId = undefined;
            // display mfa error message
            this.translate
              .get(
                'case_management_portal.login.error_messages.mfa_error.content'
              )
              .subscribe((res) => (this.errorMessage = res));
          }

          break;

        case 'auth/user-disabled':
          // logout user in case already logged in
          this.firebaseAuth.signOutUser();
          // display wrong password error message
          this.translate
            .get(
              'case_management_portal.login.error_messages.account_disabled.content'
            )
            .subscribe((res) => (this.errorMessage = res));

          break;

        default:
          // logout user in case already logged in
          this.firebaseAuth.signOutUser();
          // display wrong password error message
          this.translate
            .get(
              'case_management_portal.login.error_messages.wrong_email_or_password.content'
            )
            .subscribe((res) => (this.errorMessage = res));
          break;
      }
    }
  }

  // verify SMS verification code (mfa)
  async verifySMSVerificationCode() {
    try {
      // enable loading
      this.isLoading = true;

      // validate required variables for sms verification code validation
      if (
        !this.resolver ||
        !this.verificationId ||
        !(this.verificationCode.length == 6)
      ) {
        throw new Error('Missing data for SMS verification code validation.');
      }

      // verify SMS code with Google Identity Platform API
      const cred = PhoneAuthProvider.credential(
        this.verificationId,
        this.verificationCode
      );
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

      // complete user sign-in
      await this.resolver.resolveSignIn(multiFactorAssertion);

      // validate user role for case management portal
      this.validateUserRole();
    } catch (error) {
      // logout user in case already logged in
      this.firebaseAuth.signOutUser();
      // reset verification code input
      this.verificationCode = '';
      // display mfa error message (depending if invalid code)
      if (error.code === 'auth/invalid-verification-code') {
        this.translate
          .get(
            'case_management_portal.login.error_messages.invalid_code.content'
          )
          .subscribe((res) => (this.errorMessage = res));
      } else {
        this.translate
          .get('case_management_portal.login.error_messages.mfa_error.content')
          .subscribe((res) => (this.errorMessage = res));
      }
      // disable loading if login failed
      this.isLoading = false;
    }
  }

  // validate user role when login was successful
  async validateUserRole() {
    try {
      // check if authenticated user is case user
      const claims = (await auth.currentUser.getIdTokenResult()).claims;

      if (
        claims.role !== 'case_manager' &&
        claims.role !== 'case_manager_admin'
      ) {
        // user is not a case manager
        this.firebaseAuth.signOutUser();
        // display error message
        this.translate
          .get(
            'case_management_portal.login.error_messages.wrong_email_or_password.content'
          )
          .subscribe((res) => (this.errorMessage = res));
      } else {
        // redirect case manager to home
        this.router.navigate(['management']);
      }
    } catch (error) {
      // logout user in case already logged in
      this.firebaseAuth.signOutUser();
      // unset resolver and verification id variables
      this.resolver = this.verificationId = undefined;
      // display wrong password error message
      this.translate
        .get(
          'case_management_portal.login.error_messages.wrong_email_or_password.content'
        )
        .subscribe((res) => (this.errorMessage = res));
    }
  }

  async onFormSubmit() {
    // clear error message
    this.errorMessage = '';

    // activate loading var
    this.isLoading = true;

    // retrieve form data and login user
    await this.login(this.loginForm.value.email, this.loginForm.value.password);

    // disable loading if login failed
    this.isLoading = false;
  }

  getPhoneNumber() {
    return (this.resolver.hints[0] as PhoneMultiFactorInfo).phoneNumber;
  }
}
