<div class="cover-spin" *ngIf="isLoading"></div>
<div
  class="h5"
  mat-dialog-title
  [innerHTML]="
    'case_management_portal.case.case_overview_page.pdf_case_documentation_dialog.title.content'
      | translate
  "
></div>
<div mat-dialog-content class="small">
  <div class="h6">
    {{
      'case_management_portal.case.case_overview_page.pdf_case_documentation_dialog.generate_new_case_documentation_subtitle.content'
        | translate
    }}
  </div>
  <hr />
  <form [formGroup]="generateCaseDocumentationForm" (ngSubmit)="onFormSubmit()">
    <!-- Language selection -->
    <mat-form-field appearance="outline">
      <mat-label>{{
        'case_management_portal.case.case_overview_page.pdf_case_documentation_dialog.generate_new_case_documentation_form.language_form_field.label.content'
          | translate
      }}</mat-label>
      <mat-select formControlName="language">
        <mat-option *ngFor="let lang of translate.getLangs()" [value]="lang">{{
          'languages.' + lang | translate
        }}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label
        [innerHTML]="
          'case_management_portal.case.case_overview_page.pdf_case_documentation_dialog.generate_new_case_documentation_form.translation_lang_code_form_field.label.content'
            | translate
        "
      ></mat-label>
      <mat-select formControlName="translation_lang_code">
        <mat-option
          >{{
            'case_management_portal.case.case_overview_page.pdf_case_documentation_dialog.generate_new_case_documentation_form.translation_lang_code_form_field.no-translation.content'
              | translate
          }}
        </mat-option>
        <mat-option *ngFor="let lang of translate.getLangs()" [value]="lang">{{
          'languages.' + lang | translate
        }}</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Anonymised selection -->
    <mat-form-field appearance="outline">
      <mat-label
        [innerHTML]="
          'case_management_portal.case.case_overview_page.pdf_case_documentation_dialog.generate_new_case_documentation_form.anonymised_form_field.label.content'
            | translate
        "
      ></mat-label>
      <mat-select formControlName="anonymised">
        <mat-option [value]="true"
          >{{
            'case_management_portal.case.case_overview_page.pdf_case_documentation_dialog.generate_new_case_documentation_form.anonymised_form_field.answer_options.true'
              | translate
          }}
        </mat-option>
        <mat-option [value]="false"
          >{{
            'case_management_portal.case.case_overview_page.pdf_case_documentation_dialog.generate_new_case_documentation_form.anonymised_form_field.answer_options.false'
              | translate
          }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <hr />
    <!-- Password for PDF-encryption -->
    <p
      [innerHTML]="
        'case_management_portal.case.case_overview_page.pdf_case_documentation_dialog.generate_new_case_documentation_form.password_form_field.disclaimer.content'
          | translate
      "
    ></p>
    <mat-form-field appearance="outline">
      <mat-label
        [innerHTML]="
          'case_management_portal.case.case_overview_page.pdf_case_documentation_dialog.generate_new_case_documentation_form.password_form_field.label.content'
            | translate
        "
      ></mat-label>
      <input
        matInput
        [type]="hide ? 'password' : 'text'"
        maxlength="100"
        formControlName="password"
      />
      <button
        type="button"
        mat-icon-button
        matSuffix
        (click)="hide = !hide"
        [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hide"
      >
        <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
      </button>
      <mat-hint
        align="end"
        [innerText]="
          generateCaseDocumentationForm.value.password.length + ' / 100'
        "
      ></mat-hint>
      <mat-error>
        <span
          *ngIf="
            !generateCaseDocumentationForm.get('password').valid &&
            generateCaseDocumentationForm.get('password').touched
          "
          [innerHTML]="
            'case_management_portal.case.case_overview_page.pdf_case_documentation_dialog.generate_new_case_documentation_form.password_form_field.error_message.content'
              | translate
          "
        ></span>
      </mat-error>
    </mat-form-field>
    <!-- Submit button and messages -->
    <button
      type="submit"
      class="mt-2 py-1"
      [disabled]="!generateCaseDocumentationForm.valid || isLoading"
      mat-raised-button
      color="primary"
      [innerHTML]="
        'case_management_portal.case.case_overview_page.pdf_case_documentation_dialog.generate_new_case_documentation_form.submit_button.content'
          | translate
      "
    ></button>
    <div
      class="success mt-3"
      *ngIf="success"
      [innerHTML]="
        'case_management_portal.case.case_overview_page.pdf_case_documentation_dialog.generate_new_case_documentation_form.success_message.content'
          | translate
      "
    ></div>
    <div
      class="error mt-3"
      *ngIf="error"
      [innerHTML]="
        'case_management_portal.case.case_overview_page.pdf_case_documentation_dialog.generate_new_case_documentation_form.error_message.content'
          | translate
      "
    ></div>
  </form>
  <div
    class="h6 mt-5"
    [innerHTML]="
      'case_management_portal.case.case_overview_page.pdf_case_documentation_dialog.generated_case_documentations_subtitle.content'
        | translate
    "
  ></div>
  <hr />
  <div class="tablecontainer mat-elevation-z8 mb-3">
    <table mat-table [dataSource]="dataSource">
      <!-- Display files -->
      <!-- Pdf ID -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>ID</th>
        <td mat-cell *matCellDef="let file">{{ file.id }}</td>
      </ng-container>

      <!-- Generation timestamp -->
      <ng-container matColumnDef="generated">
        <th
          mat-header-cell
          *matHeaderCellDef
          [innerHTML]="
            'case_management_portal.case.case_overview_page.pdf_case_documentation_dialog.case_documentations_table.column_headings.generated.content'
              | translate
          "
        ></th>
        <td mat-cell *matCellDef="let file">{{ file.generated_formatted }}</td>
      </ng-container>

      <!-- Language of pdf -->
      <ng-container matColumnDef="language">
        <th
          mat-header-cell
          *matHeaderCellDef
          [innerHTML]="
            'case_management_portal.case.case_overview_page.pdf_case_documentation_dialog.case_documentations_table.column_headings.language.content'
              | translate
          "
        ></th>
        <td mat-cell *matCellDef="let file">
          {{ 'languages.' + file.language | translate }}
        </td>
      </ng-container>

      <!-- Password of pdf -->
      <ng-container matColumnDef="password">
        <th
          mat-header-cell
          *matHeaderCellDef
          [innerHTML]="
            'case_management_portal.case.case_overview_page.pdf_case_documentation_dialog.case_documentations_table.column_headings.password.content'
              | translate
          "
        ></th>
        <td mat-cell *matCellDef="let file">{{ file.password }}</td>
      </ng-container>

      <!-- Download pdf button -->
      <ng-container matColumnDef="download">
        <th
          mat-header-cell
          *matHeaderCellDef
          [innerHTML]="
            'case_management_portal.case.case_overview_page.pdf_case_documentation_dialog.case_documentations_table.column_headings.download.content'
              | translate
          "
        ></th>
        <td mat-cell *matCellDef="let file">
          <button mat-icon-button (click)="downloadFile(file)" color="primary">
            <mat-icon>download</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</div>
<div mat-dialog-actions>
  <button
    mat-button
    mat-dialog-close
    [innerHTML]="
      'case_management_portal.case.case_overview_page.pdf_case_documentation_dialog.back_to_case_button.content'
        | translate
    "
  ></button>
</div>
