<div class="cover-spin" *ngIf="isLoading"></div>
<img
  *ngIf="caseManagementPortalComponent.lspLogoUrl"
  class="responsive m-auto mt-3"
  width="80%"
  [src]="caseManagementPortalComponent.lspLogoUrl"
  alt="Logo"
/>
<div class="row mt-3 mx-0">
  <div class="col-12 col-lg-5 left">
    <mat-card
      class="d-block m-auto mt-3"
      *ngIf="auth.currentUser && !isLoading"
    >
      <p
        class="p-0 m-0 h5"
        [innerHTML]="
          'case_management_portal.home.agenda_box.heading.content' | translate
        "
      ></p>
      <p class="h6 mt-1">
        {{
          'case_management_portal.home.agenda_box.subheading.content'
            | translate
        }}
        {{ auth.currentUser.displayName }}
      </p>
      <div class="tablecontainer mat-elevation-z8 mt-3">
        <table mat-table [dataSource]="agendaTasksDataSource">
          <!-- Display agenda deadline -->
          <!-- Deadline of agenda task -->
          <ng-container matColumnDef="deadline">
            <th
              mat-header-cell
              *matHeaderCellDef
              [innerHTML]="
                'case_management_portal.home.agenda_box.agenda_table.column_headings.column1.content'
                  | translate
              "
            ></th>
            <td
              mat-cell
              *matCellDef="let agenda_task"
              [ngStyle]="{ color: agenda_task.highlighted ? 'red' : 'black' }"
            >
              {{ agenda_task.deadlineFormatted }}
            </td>
          </ng-container>

          <!-- Name of client -->
          <ng-container matColumnDef="client">
            <th
              mat-header-cell
              *matHeaderCellDef
              [innerHTML]="
                'case_management_portal.home.agenda_box.agenda_table.column_headings.column2.content'
                  | translate
              "
            ></th>
            <td
              mat-cell
              *matCellDef="let agenda_task"
              [ngStyle]="{ color: agenda_task.highlighted ? 'red' : 'black' }"
            >
              {{
                agenda_task.case.sub_client_name
                  ? agenda_task.case.sub_client_name
                  : agenda_task.case.client_name_short
              }}
              <div
                class="d-inline"
                *ngIf="caseManager.length > 1 && aloneCaseManagers"
                [ngStyle]="{ color: '#004b93' }"
              >
                *
              </div>
            </td>
          </ng-container>

          <!-- Task of agenda task -->
          <ng-container matColumnDef="task">
            <th
              mat-header-cell
              *matHeaderCellDef
              [innerHTML]="
                'case_management_portal.home.agenda_box.agenda_table.column_headings.column3.content'
                  | translate
              "
            ></th>
            <td
              mat-cell
              *matCellDef="let agenda_task"
              [ngStyle]="{ color: agenda_task.highlighted ? 'red' : 'black' }"
            >
              <div
                *ngIf="agenda_task.task === 1"
                [innerHTML]="
                  'case_management_portal.home.agenda_box.agenda_table.agenda_tasks.receipt.content'
                    | translate
                "
              ></div>
              <div
                *ngIf="agenda_task.task === 2"
                [innerHTML]="
                  'case_management_portal.home.agenda_box.agenda_table.agenda_tasks.closing.content'
                    | translate
                "
              ></div>
            </td>
          </ng-container>

          <!-- View case -->
          <ng-container matColumnDef="view">
            <th
              mat-header-cell
              *matHeaderCellDef
              [innerHTML]="
                'case_management_portal.home.agenda_box.agenda_table.column_headings.column4.content'
                  | translate
              "
            ></th>
            <td mat-cell *matCellDef="let agenda_task">
              <button
                mat-icon-button
                [routerLink]="['case']"
                [state]="agenda_task.case"
                routerLinkActive="router-link-active"
                aria-label="View case"
              >
                <mat-icon
                  aria-hidden="false"
                  aria-label="View case"
                  color="primary"
                  >login</mat-icon
                >
              </button>
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumnsAgenda; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumnsAgenda"
          ></tr>
        </table>
      </div>
    </mat-card>
    <mat-card class="m-auto mt-5 pb-0" *ngIf="auth.currentUser">
      <h5
        *ngIf="!isLoading"
        class="text-center mb-3"
        [innerHTML]="
          'case_management_portal.charts.status.title.content' | translate
        "
      ></h5>
      <div #chart1 echarts [options]="options"></div>
    </mat-card>
    <mat-card class="m-auto mt-5 pb-0" *ngIf="auth.currentUser">
      <h5
        *ngIf="!isLoading"
        class="m-0 text-center"
        [innerHTML]="
          'case_management_portal.charts.category.title.content' | translate
        "
      ></h5>
      <div #chart2 echarts [options]="options2"></div>
    </mat-card>
  </div>
  <div class="col-12 col-lg-7 right">
    <mat-card class="mt-3">
      <div
        class="h5 mb-3"
        [innerHTML]="
          'case_management_portal.home.cases_box.heading.content' | translate
        "
      ></div>
      <div class="row">
        <div class="col-12 col-md-6">
          <mat-form-field>
            <mat-label
              [innerHTML]="
                'case_management_portal.home.cases_box.filter.label.content'
                  | translate
              "
            ></mat-label>
            <input
              matInput
              (keyup)="filterCases($event)"
              [placeholder]="
                'case_management_portal.home.cases_box.filter.placeholder.content'
                  | translate
              "
            />
          </mat-form-field>
        </div>
        <div class="col-6 col-md-3">
          <mat-form-field appearance="outline">
            <mat-select
              [(ngModel)]="caseStateFilter"
              (selectionChange)="filterCasesByState()"
            >
              <mat-option value="active">{{
                'case_management_portal.home.cases_box.cases_table.case_states.active.content'
                  | translate
              }}</mat-option>
              <mat-option value="done">{{
                'case_management_portal.home.cases_box.cases_table.case_states.done.content'
                  | translate
              }}</mat-option>
              <mat-option value="deposed">{{
                'case_management_portal.home.cases_box.cases_table.case_states.deposed.content'
                  | translate
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-6 col-md-3">
          <button
            mat-raised-button
            (click)="getCases()"
            color="primary"
            [innerHTML]="
              'case_management_portal.home.cases_box.reload_button.content'
                | translate
            "
          ></button>
        </div>
      </div>
      <div class="tablecontainer mat-elevation-z8 mt-1">
        <table mat-table [dataSource]="dataSource">
          <!-- Display case -->
          <!-- Company of case -->
          <ng-container matColumnDef="client">
            <th
              mat-header-cell
              *matHeaderCellDef
              [innerHTML]="
                'case_management_portal.home.cases_box.cases_table.column_headings.company.content'
                  | translate
              "
            ></th>
            <td mat-cell *matCellDef="let case">
              {{
                case.sub_client_name
                  ? case.sub_client_name
                  : case.client_name_short
              }}
              <div
                class="d-inline"
                *ngIf="caseManager.length > 1 && aloneCaseManagers"
                [ngStyle]="{ color: '#004b93' }"
              >
                *
              </div>
            </td>
          </ng-container>

          <!-- Timestamp of case -->
          <ng-container matColumnDef="created">
            <th
              mat-header-cell
              *matHeaderCellDef
              [innerHTML]="
                'case_management_portal.home.cases_box.cases_table.column_headings.date.content'
                  | translate
              "
            ></th>
            <td mat-cell *matCellDef="let case">
              {{ case.created_formatted }}
            </td>
          </ng-container>

          <!-- State of case -->
          <ng-container matColumnDef="state">
            <th
              mat-header-cell
              *matHeaderCellDef
              [innerHTML]="
                'case_management_portal.home.cases_box.cases_table.column_headings.state.content'
                  | translate
              "
            ></th>
            <td
              mat-cell
              *matCellDef="let case"
              [innerHTML]="
                'case_management_portal.home.cases_box.cases_table.case_states.' +
                  case.state +
                  '.content' | translate
              "
            ></td>
          </ng-container>

          <!-- Record of case -->
          <ng-container matColumnDef="record">
            <th
              mat-header-cell
              *matHeaderCellDef
              [innerHTML]="
                'case_management_portal.home.cases_box.cases_table.column_headings.category.content'
                  | translate
              "
            ></th>
            <td mat-cell *matCellDef="let case">{{ case.record }}</td>
          </ng-container>

          <!-- View case -->
          <ng-container matColumnDef="view">
            <th
              mat-header-cell
              *matHeaderCellDef
              [innerHTML]="
                'case_management_portal.home.cases_box.cases_table.column_headings.view_case.content'
                  | translate
              "
            ></th>
            <td mat-cell *matCellDef="let case">
              <button
                mat-icon-button
                [routerLink]="['case']"
                [state]="case"
                routerLinkActive="router-link-active"
                aria-label="View case"
              >
                <mat-icon
                  aria-hidden="false"
                  aria-label="View case"
                  color="primary"
                  >login</mat-icon
                >
              </button>
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr
            mat-row
            [ngClass]="{
              'font-weight-bold':
                !case.new_case_data_is_read ||
                case.new_case_user_message ||
                case.new_case_user_file
            }"
            class="font-weight-bold"
            *matRowDef="let case; columns: displayedColumns"
          ></tr>
        </table>
      </div>
    </mat-card>
    <mat-card class="mt-5 clients-card">
      <div
        class="h5"
        [innerHTML]="
          'case_management_portal.home.clients_box.heading.content' | translate
        "
      ></div>
      <div class="row mt-1">
        <mat-form-field>
          <mat-label
            [innerHTML]="
              'case_management_portal.home.clients_box.filter.label.content'
                | translate
            "
          ></mat-label>
          <input
            matInput
            (keyup)="filterClients($event)"
            [placeholder]="
              'case_management_portal.home.clients_box.filter.placeholder.content'
                | translate
            "
          />
        </mat-form-field>
      </div>
      <div class="tablecontainer mat-elevation-z8">
        <table mat-table [dataSource]="clientsDataSource">
          <!-- Display client -->
          <!-- Name of client -->
          <ng-container matColumnDef="name">
            <th
              mat-header-cell
              *matHeaderCellDef
              [innerHTML]="
                'case_management_portal.home.clients_box.clients_table.column_headings.name.content'
                  | translate
              "
            ></th>
            <td mat-cell *matCellDef="let client">{{ client.name }}</td>
          </ng-container>

          <!-- Link to clients reporting page -->
          <ng-container matColumnDef="link">
            <th
              mat-header-cell
              *matHeaderCellDef
              [innerHTML]="
                'case_management_portal.home.clients_box.clients_table.column_headings.case_reporting_portal.content'
                  | translate
              "
            ></th>
            <td mat-cell *matCellDef="let client">
              <a
                href="https://{{ client.subdomain }}.{{ hostParts[1] }}.{{
                  hostParts[2]
                }}"
                target="_blank"
                >{{ client.subdomain }}.{{ hostParts[1] }}.{{ hostParts[2] }}</a
              >
            </td>
          </ng-container>

          <!-- View pdf charts -->
          <ng-container matColumnDef="view_analytics">
            <th
              mat-header-cell
              *matHeaderCellDef
              [innerHTML]="
                'case_management_portal.home.charts.chart_dialog.title.content'
                  | translate
              "
            ></th>
            <td mat-cell *matCellDef="let client">
              <button
                class="charts"
                mat-icon-button
                [routerLink]="['analytics', { clientId: client.id }]"
                routerLinkActive="router-link-active"
                aria-label="View analytics"
                color="primary"
              >
                <mat-icon color="primary">analytics</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="clientsDisplayedColumns; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: clientsDisplayedColumns"
          ></tr>
        </table>
      </div>
    </mat-card>
    <div *ngIf="caseManagerRole === 'case_manager_admin'" id="adminSettings">
      <!-- Case manager admin -->
      <mat-card class="mt-5">
        <div
          class="h5 mb-1"
          [innerHTML]="
            'case_management_portal.home.lsp_box.heading.content' | translate
          "
        ></div>
        <p
          class="mb-3"
          [innerHTML]="
            'case_management_portal.home.lsp_box.subheading.content' | translate
          "
        ></p>
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title
                [innerHTML]="
                  'case_management_portal.home.lsp_box.case_manager_panel.heading.content'
                    | translate
                "
              ></mat-panel-title>
              <mat-panel-description
                [innerHTML]="
                  'case_management_portal.home.lsp_box.case_manager_panel.description.content'
                    | translate
                "
              ></mat-panel-description>
            </mat-expansion-panel-header>
            <mat-form-field>
              <mat-label
                [innerHTML]="
                  'case_management_portal.home.lsp_box.case_manager_panel.filter_form_field.label.content'
                    | translate
                "
              ></mat-label>
              <input
                matInput
                (keyup)="filterCaseManager($event)"
                [placeholder]="
                  'case_management_portal.home.lsp_box.case_manager_panel.filter_form_field.placeholder.content'
                    | translate
                "
              />
            </mat-form-field>
            <div class="tablecontainer mat-elevation-z8">
              <table mat-table [dataSource]="caseManagerDataSource">
                <!-- Display case manager of legal service provider -->

                <!-- Name of case manager -->
                <ng-container matColumnDef="name">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    [innerHTML]="
                      'case_management_portal.home.lsp_box.case_manager_panel.table.name_column.content'
                        | translate
                    "
                  ></th>
                  <td mat-cell *matCellDef="let case_manager">
                    {{ case_manager.name }}
                  </td>
                </ng-container>

                <!-- Email of case manager -->
                <ng-container matColumnDef="email">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    [innerHTML]="
                      'case_management_portal.home.lsp_box.case_manager_panel.table.email_column.content'
                        | translate
                    "
                  ></th>
                  <td mat-cell *matCellDef="let case_manager">
                    {{ case_manager.email }}
                  </td>
                </ng-container>

                <!-- Role of case manager -->
                <ng-container matColumnDef="role">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    [innerHTML]="
                      'case_management_portal.home.lsp_box.case_manager_panel.table.role_column.content'
                        | translate
                    "
                  ></th>
                  <td mat-cell *matCellDef="let case_manager">
                    <mat-icon
                      class="success"
                      *ngIf="case_manager.role === 'case_manager_admin'"
                      >done</mat-icon
                    >
                    <mat-icon
                      class="error"
                      *ngIf="case_manager.role === 'case_manager'"
                      >close</mat-icon
                    >
                  </td>
                </ng-container>

                <!-- Is multi factor user -->
                <ng-container matColumnDef="is_multi_factor_user">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    [innerHTML]="
                      'case_management_portal.home.lsp_box.case_manager_panel.table.is_multi_factor_user_column.content'
                        | translate
                    "
                  ></th>
                  <td mat-cell *matCellDef="let case_manager">
                    <mat-icon
                      class="success"
                      *ngIf="case_manager.is_multi_factor_user"
                      >done</mat-icon
                    >
                    <mat-icon
                      class="error"
                      *ngIf="!case_manager.is_multi_factor_user"
                      >close</mat-icon
                    >
                  </td>
                </ng-container>

                <!-- Activate / Deactivate case manager -->
                <ng-container matColumnDef="de_activate">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    [innerHTML]="
                      'case_management_portal.home.lsp_box.case_manager_panel.table.de_activate_column.content'
                        | translate
                    "
                  ></th>
                  <td mat-cell *matCellDef="let case_manager">
                    <mat-slide-toggle
                      [disabled]="case_manager.role === 'case_manager_admin'"
                      [(ngModel)]="case_manager.is_active"
                      (change)="updateCaseManagerActiveState(case_manager)"
                      color="primary"
                    ></mat-slide-toggle>
                  </td>
                </ng-container>

                <tr
                  mat-header-row
                  *matHeaderRowDef="
                    caseManagerTableDisplayedColumns;
                    sticky: true
                  "
                ></tr>
                <tr
                  mat-row
                  *matRowDef="
                    let row;
                    columns: caseManagerTableDisplayedColumns
                  "
                ></tr>
              </table>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title
                [innerHTML]="
                  'case_management_portal.home.lsp_box.add_case_manager_panel.heading.content'
                    | translate
                "
              ></mat-panel-title>
              <mat-panel-description
                [innerHTML]="
                  'case_management_portal.home.lsp_box.add_case_manager_panel.description.content'
                    | translate
                "
              ></mat-panel-description>
            </mat-expansion-panel-header>
            <form
              class="mb-3"
              [formGroup]="addCaseManagerForm"
              (ngSubmit)="createCaseManagerAccount()"
            >
              <!-- Name of case manager -->
              <mat-form-field class="w-100" appearance="outline">
                <mat-label
                  [innerHTML]="
                    'case_management_portal.home.lsp_box.add_case_manager_panel.name_form_field.label.content'
                      | translate
                  "
                ></mat-label>
                <input
                  matInput
                  formControlName="caseManagerName"
                  [errorStateMatcher]="matcher"
                />
                <mat-error>
                  <span
                    *ngIf="
                      !addCaseManagerForm.get('caseManagerName').valid &&
                      addCaseManagerForm.get('caseManagerName').touched
                    "
                    [innerHTML]="
                      'case_management_portal.home.lsp_box.add_case_manager_panel.name_form_field.error_message.content'
                        | translate
                    "
                  ></span>
                </mat-error>
              </mat-form-field>

              <!-- Email address of case manager -->
              <mat-form-field class="w-100" appearance="outline">
                <mat-label
                  [innerHTML]="
                    'case_management_portal.home.lsp_box.add_case_manager_panel.email_form_field.label.content'
                      | translate
                  "
                ></mat-label>
                <input
                  matInput
                  type="email"
                  formControlName="caseManagerEmail"
                  [errorStateMatcher]="matcher"
                />
                <mat-error>
                  <span
                    *ngIf="
                      !addCaseManagerForm.get('caseManagerEmail').valid &&
                      addCaseManagerForm.get('caseManagerEmail').touched
                    "
                    [innerHTML]="
                      'case_management_portal.home.lsp_box.add_case_manager_panel.email_form_field.error_message.content'
                        | translate
                    "
                  ></span>
                </mat-error>
              </mat-form-field>

              <!-- Password of case manager -->
              <mat-form-field class="w-100" appearance="outline">
                <mat-label
                  [innerHTML]="
                    'case_management_portal.home.lsp_box.add_case_manager_panel.password_form_field.label.content'
                      | translate
                  "
                ></mat-label>
                <input
                  type="password"
                  matInput
                  formControlName="caseManagerPassword"
                  [type]="hide ? 'password' : 'text'"
                  [errorStateMatcher]="matcher"
                />
                <button
                  type="button"
                  mat-icon-button
                  matSuffix
                  (click)="hide = !hide"
                  [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="hide"
                >
                  <mat-icon>{{
                    hide ? 'visibility_off' : 'visibility'
                  }}</mat-icon>
                </button>
                <mat-error>
                  <span
                    *ngIf="
                      !addCaseManagerForm.get('caseManagerPassword').valid &&
                      addCaseManagerForm.get('caseManagerPassword').touched
                    "
                    [innerHTML]="
                      'case_management_portal.home.lsp_box.add_case_manager_panel.password_form_field.error_message.content'
                        | translate
                    "
                  ></span>
                </mat-error>
              </mat-form-field>

              <!-- Submit button and messages -->
              <button
                class="w-100 mt-1 py-1"
                mat-raised-button
                type="submit"
                [disabled]="!addCaseManagerForm.valid || isLoading"
                color="primary"
                [innerHTML]="
                  'case_management_portal.home.lsp_box.add_case_manager_panel.submit_button.content'
                    | translate
                "
              ></button>
            </form>
            <p
              *ngIf="addCaseManagerSuccessMessage"
              class="success mt-3 text-center pt-0"
              [innerText]="addCaseManagerSuccessMessage"
            ></p>
            <p
              *ngIf="addCaseManagerErrorMessage"
              class="error mt-3 text-center pt-0"
              [innerText]="addCaseManagerErrorMessage"
            ></p>
          </mat-expansion-panel>
        </mat-accordion>
      </mat-card>

      <!-- Client case manager list -->
      <mat-card class="mt-5">
        <div
          class="h5 mb-1"
          [innerHTML]="
            'case_management_portal.home.managers_admin_box.heading.content'
              | translate
          "
        ></div>
        <p
          class="mb-3"
          [innerHTML]="
            'case_management_portal.home.managers_admin_box.description.content'
              | translate
          "
        ></p>
        <mat-form-field>
          <mat-label
            [innerHTML]="
              'case_management_portal.home.clients_box.filter.label.content'
                | translate
            "
          ></mat-label>
          <input
            matInput
            (keyup)="filterClientsAdmin($event)"
            [placeholder]="
              'case_management_portal.home.clients_box.filter.placeholder.content'
                | translate
            "
          />
        </mat-form-field>
        <div class="tablecontainer mat-elevation-z8">
          <table mat-table [dataSource]="clientsAdminDataSource">
            <!-- Display clients (admin) -->

            <!-- Name of client -->
            <ng-container matColumnDef="name">
              <th
                mat-header-cell
                *matHeaderCellDef
                [innerHTML]="
                  'case_management_portal.home.managers_admin_box.clients_table.column_headings.name.content'
                    | translate
                "
              ></th>
              <td mat-cell *matCellDef="let client">{{ client.name }}</td>
            </ng-container>

            <!-- List of case manager of client -->
            <ng-container matColumnDef="case_manager_list">
              <th
                mat-header-cell
                *matHeaderCellDef
                [innerHTML]="
                  'case_management_portal.home.managers_admin_box.clients_table.column_headings.managers_list.content'
                    | translate
                "
              ></th>
              <td mat-cell *matCellDef="let client">
                <button
                  mat-icon-button
                  (click)="openClientCaseManagerListDialog(client)"
                  color="primary"
                >
                  <mat-icon color="primary">list</mat-icon>
                </button>
              </td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="clientsAdminDisplayedColumns; sticky: true"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: clientsAdminDisplayedColumns"
            ></tr>
          </table>
        </div>
      </mat-card>
    </div>
  </div>
</div>
