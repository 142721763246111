<div class="cover-spin" *ngIf="isLoading"></div>

<mat-card *ngIf="logo && !isLoading" class="m-auto mt-4 py-4">
  <img width="200px" [src]="logo" alt="logo" />
</mat-card>

<mat-card class="m-auto mt-5 pb-0" *ngIf="!isLoading">
  <h5
    class="text-center"
    [innerHTML]="
      'case_management_portal.charts.relevance.title.content' | translate
    "
  ></h5>
  <div class="row">
    <div class="col-5">
      <div #chart3 echarts [options]="options3"></div>
    </div>
    <div class="col-6">
      <div #chart4 echarts [options]="options4"></div>
    </div>
  </div>
</mat-card>

<mat-card class="m-auto mt-5 pb-0" *ngIf="!isLoading">
  <h5
    class="text-center mb-3"
    [innerHTML]="
      'case_management_portal.charts.status.title.content' | translate
    "
  ></h5>
  <div #chart1 echarts [options]="options"></div>
</mat-card>

<mat-card class="m-auto mt-5 pb-0" *ngIf="!isLoading">
  <h5
    class="mb-0 text-center"
    [innerHTML]="
      'case_management_portal.charts.category.title.content' | translate
    "
  ></h5>
  <div #chart2 echarts [options]="options2"></div>
</mat-card>
<hr />

<div class="m-auto mt-5 py-1 text-center" *ngIf="!isLoading">
  <button
    class="mx-3"
    mat-flat-button
    [routerLink]="['/management']"
    routerLinkActive="router-link-active"
    [innerHTML]="'global.back_button.content' | translate"
  ></button>
  <button
    class="mx-3"
    (click)="openPdfChartsDialog()"
    mat-raised-button
    color="primary"
  >
    Analyse-PDF
  </button>
</div>
