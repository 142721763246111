import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CaseReportingPortalComponent } from '../case-reporting-portal/case-reporting-portal.component';
import { CaseReportingPortalSelectCountryDialogComponent } from '../case-reporting-portal-select-country-dialog/case-reporting-portal-select-country-dialog.component';

@Component({
  selector: 'app-case-reporting-portal-home',
  templateUrl: './case-reporting-portal-home.component.html',
  styleUrls: ['./case-reporting-portal-home.component.scss']
})
export class CaseReportingPortalHomeComponent {
  country: string;

  constructor(
    public caseReportingPortalComponent: CaseReportingPortalComponent,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService
  ) {}

  openCountriesDialog() {
    const dialogRef = this.dialog.open(
      CaseReportingPortalSelectCountryDialogComponent,
      {
        maxWidth: '400px'
      }
    );

    dialogRef.afterClosed().subscribe((result: string) => {
      if (result) {
        this.caseReportingPortalComponent.country = result;
        this.router.navigate(['report'], { relativeTo: this.route });
      }
    });
  }
}
