import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { FirebaseError } from 'firebase/app';
import { httpsCallable } from 'firebase/functions';
import { DialogLspPasswordData } from 'src/app/shared/interfaces/adminLsp';
import { functions } from 'src/firebase';

@Component({
  selector: 'app-admin-portal-lsp-password-dialog',
  templateUrl: './admin-portal-lsp-password-dialog.component.html',
  styleUrls: ['./admin-portal-lsp-password-dialog.component.scss']
})
export class AdminPortalLspPasswordDialogComponent {
  changePasswordForm: FormGroup;

  // change password
  changePasswordSuccessMessage = '';
  changePasswordErrorMessage = '';

  // loading var for spinner
  isLoading = false;

  // hide for password input
  hide = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogLspPasswordData,
    private formBuilder: FormBuilder,
    public translate: TranslateService
  ) {
    // setup change password form
    this.changePasswordForm = this.formBuilder.group({
      password: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(100)
        ])
      ],
      newPassword: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(100)
        ])
      ],
      newPassword2: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(100)
        ])
      ]
    });
  }

  async updateUserPassword(
    caseManagerUid: string,
    password: string,
    newPassword: string
  ) {
    try {
      // firebase callable functions
      const updateUserPassword = httpsCallable<
        { caseManagerUid: string; password: string; newPassword: string },
        { result: string; error?: FirebaseError }
      >(functions, 'updateUserPassword');

      // validate and update password in backend
      const { data } = await updateUserPassword({
        caseManagerUid: caseManagerUid,
        password: password,
        newPassword: newPassword
      });

      if (!data.result) {
        throw data.error;
      }

      // clear form
      this.changePasswordForm.reset({
        password: '',
        newPassword: '',
        newPassword2: ''
      });

      // display success
      this.translate
        .get('admin_portal.lsp_password_dialog.password_changed.content')
        .subscribe((message) => {
          this.changePasswordSuccessMessage = message;
        });
    } catch (error) {
      // display error
      this.translate
        .get('admin_portal.lsp_password_dialog.password_change_error.content')
        .subscribe((message) => {
          this.changePasswordErrorMessage = message;
        });
    }
  }

  // change password
  async onFormSubmit() {
    // enable loading
    this.isLoading = true;

    // reset success and error
    this.changePasswordSuccessMessage = this.changePasswordErrorMessage = '';

    if (
      this.changePasswordForm.value.newPassword !==
      this.changePasswordForm.value.newPassword2
    ) {
      // passwords don't match
      this.translate
        .get(
          'admin_portal.settings.change_password_panel.new_passwords_dont_match_error_message.content'
        )
        .subscribe((message) => {
          this.changePasswordErrorMessage = message;
        });
    } else {
      // update password
      await this.updateUserPassword(
        this.data.caseManagerUid,
        this.changePasswordForm.value.password,
        this.changePasswordForm.value.newPassword
      );
    }

    // disable loading
    this.isLoading = false;
  }
}
