<div class="small" mat-dialog-content>
  <p class="h6 text-dark mb-3">
    {{
      'case_reporting_portal.home.language_selection_dialog.description.content'
        | translate
    }}
  </p>
  <!-- Country of whistleblower -->
  <mat-form-field appearance="outline">
    <mat-label>{{
      'case_reporting_portal.home.language_selection_dialog.country_selection_title.content'
        | translate
    }}</mat-label>
    <mat-select [(ngModel)]="whistleblowerCountry">
      <mat-option *ngFor="let country of countries" [value]="country.code">{{
        country.name
      }}</mat-option>
    </mat-select>
  </mat-form-field>
  <!-- Submit buttons -->
  <div class="row mt-2 mb-3">
    <div class="col-3">
      <button class="w-100 py-1" mat-raised-button mat-dialog-close>
        {{ 'global.back_button.content' | translate }}
      </button>
    </div>
    <div class="col-9">
      <button
        class="py-1 w-100"
        mat-raised-button
        [disabled]="!whistleblowerCountry"
        color="primary"
        [mat-dialog-close]="whistleblowerCountry"
      >
        {{ 'global.next_button.content' | translate }}
      </button>
    </div>
  </div>
</div>
