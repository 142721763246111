import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.scss']
})
export class ImprintComponent {
  // selected language (de)
  lang: string = this.activatedRoute.snapshot.params.language_id;

  collected_parts: string[] = [];

  public trigger = 0;

  constructor(
    public translate: TranslateService,
    private activatedRoute: ActivatedRoute
  ) {
    translate.use(this.lang);

    translate.getTranslation(translate.currentLang).subscribe((translation) => {
      Object.keys(translation).forEach((key) => {
        if (!key.startsWith('imprint.content.')) {
          return;
        }

        this.collected_parts.push(key);
      });

      this.trigger++;
    });
  }

  goBack(): void {
    history.back();
  }
}
