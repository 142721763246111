import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FirebaseError } from 'firebase/app';
import { User, onAuthStateChanged } from 'firebase/auth';
import {
  onSnapshot,
  query,
  collection,
  orderBy,
  updateDoc,
  doc,
  getDoc,
  DocumentReference
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { ref, uploadBytes } from 'firebase/storage';
import { COUNTRIES } from 'src/app/shared/data/countries';
import { Case, DecryptedCase } from 'src/app/shared/models/case.model';
import { CaseManager } from 'src/app/shared/models/caseManager.model';
import { Manager } from 'src/app/shared/models/client.model';
import { FirebaseAuthService } from 'src/app/shared/services/firebase-auth.service';
import { functions, firestore, auth, storage } from 'src/firebase';
import { CaseCaseManagerListDialogComponent } from '../case-case-manager-list-dialog/case-case-manager-list-dialog.component';
import { CaseManagementPortalComponent } from '../case-management-portal/case-management-portal.component';
import { PdfCaseDocumentationDialogComponent } from '../pdf-case-documentation-dialog/pdf-case-documentation-dialog.component';
import { UpdateCaseConfirmationStateConfirmationDialogComponent } from '../update-case-confirmation-state-confirmation-dialog/update-case-confirmation-state-confirmation-dialog.component';
import { v4 as uuid } from 'uuid';
import { DeleteCaseDialogComponent } from '../delete-case-dialog/delete-case-dialog.component';
import {
  ChatMessage,
  TranslationChatMessage
} from 'src/app/shared/models/chatMessage.model';
import {
  formatCaseCreatedWeekday,
  formatCreated
} from 'src/app/shared/functions/dateFormat';
import { CaseFile } from 'src/app/shared/models/caseFile.model';
import { FirebaseTimestamp } from 'src/app/shared/models/firebaseTimestamp.model';

@Component({
  selector: 'app-case-management-portal-case',
  templateUrl: './case-management-portal-case.component.html',
  styleUrls: ['./case-management-portal-case.component.scss']
})
export class CaseManagementPortalCaseComponent implements OnInit {
  // name selection in form
  nameSelection = false;

  // countries
  countries = COUNTRIES;

  // case object
  case: Case;

  // language for case
  caseDataLangCode = 'none';
  caseDataChatLangCode = 'none';

  // possible translation languages
  chatMessagesPossibleLangCodes = new Map();

  // possible record categories
  recordCategoryGroups = {
    staff: [
      'staff_working_time',
      'staff_harassment',
      'staff_discrimination',
      'staff_privacy',
      'staff_health',
      'staff_participation',
      'staff_other'
    ],
    acquisition: [
      'acquisition_bribery',
      'acquisition_corruption',
      'acquisition_price_agreements',
      'acquisition_other'
    ],
    production: [
      'production_energy',
      'production_food_safety',
      'production_product_safety',
      'production_quality_assurance',
      'production_environmental_protection',
      'production_other'
    ],
    finance: [
      'finance_shareholder',
      'finance_capital_market',
      'finance_tax_crimes',
      'finance_embezzlement',
      'finance_infidelity',
      'finance_other'
    ]
  };

  categoryViewValue: string = '';

  // success and error messages of updating record or category of case
  successMessage = '';
  errorMessage = '';

  // success and error messages of updating state or confirmation state of case
  stateSuccessMessage = '';
  stateErrorMessage = '';

  // case manager object
  caseManagerRole: string;

  // chat message
  message = '';

  // chat messages
  chatMessages: ChatMessage[] = [];

  // chat message validation
  messageMaxLength = 4096;
  chatErrorMessage = '';

  // update case confirmation state for when default message was pasted
  updateCaseConfirmationState = 0;

  // lang code for message translation
  chatMessagesLangCode = 'none';

  @ViewChildren('messages') messages: ElementRef;
  @ViewChild('content') content: ElementRef;

  // loading
  isLoading = false;

  // chat message loading
  isChatMessageLoading = false;

  // keep chat messages with id
  i = 0;

  // active tab
  activeTab: number;

  // angular material case log entries table setup
  displayedColumnsCaseLogTable: string[] = ['code', 'created_at'];
  dataSourceCaseLogTable = new MatTableDataSource();
  @ViewChild(MatPaginator) paginator: MatPaginator;

  // case files array
  caseFiles = [];

  // angular material files table setup
  displayedColumns: string[] = [
    'id',
    'name',
    'author',
    'uploaded',
    'downloaded',
    'download'
  ];
  dataSource = new MatTableDataSource();

  // custom file name success and error messages
  customFileNameSuccess = false;
  customFileNameError = false;

  // delete (revoke) file success and error messages
  deleteFileSuccess = false;
  deleteFileError = false;

  // files for case
  files: File[] = [];
  docRef: DocumentReference;

  // file validation
  maxFileCount = 20;
  maxFileSize = 5000000;
  filesErrorMessage = '';
  filesSuccessMessage = '';

  // file datasource for table
  filesDataSource = new MatTableDataSource();
  displayedColumnsFilesTable: string[] = ['file_name', 'remove'];

  constructor(
    public caseManagementPortalComponent: CaseManagementPortalComponent,
    private firebaseAuth: FirebaseAuthService,
    private router: Router,
    private dialog: MatDialog,
    private ngZone: NgZone,
    public translate: TranslateService
  ) {}

  async getCaseByCaseManagerData(caseData: Case) {
    try {
      // decrypt case data
      const getCaseByCaseManager = httpsCallable<
        {
          clientCaseManagersUid: string[];
          subject: string;
          description: string;
          date: string;
          location: string;
          affected: string;
          observation: string;
          relationship: string;
          additionalInformation: string;
          country: string;
          name?: string;
        },
        { decryptedCase: DecryptedCase; error?: FirebaseError }
      >(functions, 'getCaseByCaseManager');

      const { data } = await getCaseByCaseManager({
        clientCaseManagersUid: caseData.client_case_manager_uids,
        subject: caseData.subject,
        description: caseData.description,
        date: caseData.date,
        location: caseData.location,
        affected: caseData.affected,
        observation: caseData.observation,
        relationship: caseData.relationship,
        additionalInformation: caseData.additional_information,
        country: caseData.country,
        name: caseData.name
      });

      if (!data.decryptedCase) {
        throw new Error('Error while decrypting case data.');
      }

      // add decryption to case
      this.case = Object.assign(caseData, data.decryptedCase);
    } catch (error) {
      console.error(error);
    }
  }

  getChatMessages(lspId: string, clientId: string, caseId: string) {
    try {
      this.chatMessages = [];
      onSnapshot(
        query(
          collection(
            firestore,
            'legal_service_providers',
            lspId,
            'clients',
            clientId,
            'cases',
            caseId,
            'chat_messages'
          ),
          orderBy('created_at', 'asc')
        ),
        (querySnapshot) => {
          querySnapshot.docChanges().forEach(async (change) => {
            if (change.type === 'added') {
              // activate chat loading
              this.isChatMessageLoading = true;

              const changeData = change.doc.data();

              // decrypt chat message
              const decryptChatCaseManager = httpsCallable<
                {
                  messageText: string;
                },
                { messageText: string; error?: FirebaseError }
              >(functions, 'decryptChatCaseManager');

              const { data } = await decryptChatCaseManager({
                messageText: changeData.text
              });

              if (!data.messageText) {
                throw new Error('Error while decrypting chat case manager.');
              }

              // format message
              const message = {
                id: change.doc.id,
                name: changeData.name,
                case_manager_name: changeData.case_manager_name,
                is_case_user: changeData.is_case_user,
                text: data.messageText,
                created_at: changeData.created_at,
                created_formatted: formatCreated(
                  changeData.created_at.toDate(),
                  this.translate.currentLang
                ),
                read: changeData.read
              };

              this.chatMessages.push(message);

              // sort messages by date
              this.chatMessages.sort((a, b) =>
                a.created_at > b.created_at
                  ? 1
                  : b.created_at > a.created_at
                    ? -1
                    : 0
              );

              this.i++;

              // scroll to bottom in chat window
              setTimeout(() => {
                this.scrollToBottom();
              }, 1);

              // update read state of new chat messages (if currently on chat tab)
              this.updateCaseUserMessagesToRead();

              // translate chat message (depending on lang code)
              this.translateChatMessages(this.chatMessagesLangCode, [message]);

              // deactivate chat loading
              this.isChatMessageLoading = false;
            }
          });
        }
      );

      this.translate.onLangChange.subscribe(() =>
        this.chatMessages.forEach((chatMessage) => {
          chatMessage.created_formatted = formatCreated(
            chatMessage.created_at.toDate(),
            this.translate.currentLang
          );
        })
      );
    } catch (error) {
      console.error(error);
    }
  }

  // get case files
  getCaseFiles(lspId: string, clientId: string, caseId: string) {
    try {
      onSnapshot(
        query(
          collection(
            firestore,
            'legal_service_providers',
            lspId,
            'clients',
            clientId,
            'cases',
            caseId,
            'case_files'
          ),
          orderBy('uploaded', 'asc')
        ),
        (querySnapshot) => {
          querySnapshot.docChanges().forEach(async (change) => {
            if (change.type === 'added') {
              const caseFile = change.doc.data();
              caseFile.id = change.doc.id;
              this.docRef = change.doc.ref;

              // update viewed state of new case files (if currently on chat tab)
              this.updateCaseUserFilesToViewed();

              // format file uploaded date
              caseFile.uploaded = caseFile.uploaded.toDate();
              caseFile.uploaded_formatted = formatCreated(
                caseFile.uploaded,
                this.translate.currentLang
              );

              // decrypt file name (if existing)
              if (caseFile.name) {
                const decryptString = httpsCallable<
                  {
                    string: string;
                  },
                  { decryptedString: string; error?: FirebaseError }
                >(functions, 'decryptString');

                const { data } = await decryptString({
                  string: caseFile.name
                });

                if (!data.decryptedString) {
                  throw new Error('Error while decrypting chat case manager.');
                }

                caseFile.name = data.decryptedString;

                // add case file to case files array
                this.caseFiles.unshift(caseFile);

                // sort case files by date
                this.caseFiles.sort((a, b) =>
                  a.uploaded < b.uploaded ? 1 : b.uploaded < a.uploaded ? -1 : 0
                );

                // update case files table data source
                this.dataSource = new MatTableDataSource(this.caseFiles);
              } else {
                // add file object to case files array
                this.caseFiles.unshift(caseFile);
                // update case files table data source
                this.dataSource = new MatTableDataSource(this.caseFiles);
              }
            }

            if (change.type === 'modified') {
              // revocation expiration or download state of case file was modified (update both fields for file in case files array)
              const i = this.caseFiles.findIndex((x) => x.id == change.doc.id);
              const docData = change.doc.data();
              this.caseFiles[i].downloaded = docData.downloaded;

              // check if file name was changed
              if (docData.name !== this.caseFiles[i].name) {
                // decrypt new name and update name
                const decryptString = httpsCallable<
                  {
                    string: string;
                  },
                  { decryptedString: string; error?: FirebaseError }
                >(functions, 'decryptString');

                const { data } = await decryptString({
                  string: docData.name
                });

                if (!data.decryptedString) {
                  throw new Error('Error while decrypting chat case manager.');
                }

                this.caseFiles[i].decrypted_name = data.decryptedString;

                // update case files table data source
                this.dataSource = new MatTableDataSource(this.caseFiles);
              } else {
                // update case files table data source
                this.dataSource = new MatTableDataSource(this.caseFiles);
              }
            }
          });
        }
      );

      this.translate.onLangChange.subscribe(() =>
        this.caseFiles.forEach((caseFile) => {
          caseFile.uploaded_formatted = formatCreated(
            caseFile.uploaded,
            this.translate.currentLang
          );
        })
      );
    } catch (error) {
      console.error(error);
    }
  }

  // get case log entries
  getCaseLogEntries(lspId: string, clientId: string, caseId: string) {
    try {
      onSnapshot(
        query(
          collection(
            firestore,
            'legal_service_providers',
            lspId,
            'clients',
            clientId,
            'cases',
            caseId,
            'case_log'
          ),
          orderBy('created_at', 'desc')
        ),
        (querySnapshot) => {
          const caseLogEntries = [];
          querySnapshot.forEach((doc) => {
            const caseLogEntry = doc.data();

            // format created at timestamp of log entry
            caseLogEntry.created_at = caseLogEntry.created_at.toDate();
            caseLogEntry.created_at_formatted = formatCreated(
              caseLogEntry.created_at,
              this.translate.currentLang
            );

            caseLogEntries.push(caseLogEntry);
          });

          this.translate.onLangChange.subscribe(() => {
            caseLogEntries.forEach(
              (caseLogEntry) =>
                (caseLogEntry.created_at_formatted = formatCreated(
                  caseLogEntry.created_at,
                  this.translate.currentLang
                ))
            );
          });

          this.dataSourceCaseLogTable = new MatTableDataSource(caseLogEntries);
          this.dataSourceCaseLogTable.paginator = this.paginator;
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  // update case to read
  async updateCaseDataToRead() {
    // update new_case to false in case doc
    await updateDoc(
      doc(
        firestore,
        'legal_service_providers',
        this.case.lsp_id,
        'clients',
        this.case.client_id,
        'cases',
        this.case.id
      ),
      {
        new_case_data_is_read: true
      }
    );
  }

  async extract(caseData: Case) {
    // check if there is case data from route state
    if (!caseData.id) {
      this.router.navigate(['/management']);
    }

    // decrypt passed case data
    await this.getCaseByCaseManagerData(caseData);

    // update new case data to true
    if (!this.case.new_case_data_is_read) {
      await this.updateCaseDataToRead();
    }

    // transform data
    this.transform();

    // get chat messages
    this.getChatMessages(this.case.lsp_id, this.case.client_id, this.case.id);

    // get case files
    this.getCaseFiles(this.case.lsp_id, this.case.client_id, this.case.id);

    // get list of case log
    this.getCaseLogEntries(this.case.lsp_id, this.case.client_id, this.case.id);

    window.scroll(0, 0);
  }

  transform() {
    // format date of case
    this.case.created = new Date(
      (this.case.created as FirebaseTimestamp)._seconds * 1000
    );

    formatCaseCreatedWeekday(this.case.created, this.translate.currentLang);
    this.translate.onLangChange.subscribe(() =>
      formatCaseCreatedWeekday(
        this.case.created as Date,
        this.translate.currentLang
      )
    );

    // structure data for frontend
    if (this.case.name) {
      this.nameSelection = true;
    }
    if (!this.case.record) {
      this.case.record = '';
    }
    if (!this.case.category) {
      this.case.category = '';
    } else {
      // format case category
      this.formatCaseCategory();
    }
    // format files of case for list
    if (!this.case.case_files) {
      this.case.case_files = [];
    }
    this.dataSource = new MatTableDataSource(this.case.case_files);
  }

  formatCaseCategory() {
    switch (this.case.category) {
      case 'other': {
        this.translate
          .get(
            'case_management_portal.case.case_overview_page.report_form.record_category_form.category_form_field.other_option.content'
          )
          .subscribe((res) => (this.categoryViewValue = res));
        break;
      }

      case 'not-relevant': {
        this.translate
          .get(
            'case_management_portal.case.case_overview_page.report_form.record_category_form.category_form_field.not_relevant_option.content'
          )
          .subscribe((res) => (this.categoryViewValue = res));
        break;
      }

      default: {
        const group = this.case.category.split('_')[0];
        const category = this.case.category;

        this.translate
          .get(
            `case_management_portal.case.case_overview_page.report_form.record_category_form.category_form_field.${group}.${category}`
          )
          .subscribe((res) => (this.categoryViewValue = res));
        break;
      }
    }
  }

  async ngOnInit() {
    if (history.length === 50) {
      this.router.navigate(['management']);
    }

    // wait until auth is initialized
    const user = await this.firebaseAuth.getCurrentUser();

    // check initially if user is logged in
    if (user !== null) {
      // get firebase auth claims
      const claims = (await auth.currentUser.getIdTokenResult()).claims;

      // redirect user to case if logged in
      if (
        claims.role !== 'case_manager' &&
        claims.role !== 'case_manager_admin'
      ) {
        // user is not logged in => redirect to login
        this.router.navigate(['management', 'login']);
      } else {
        this.caseManagerRole = claims.role;
        this.extract(history.state);
      }
    } else {
      // user is not logged in
      this.router.navigate(['management', 'login']);
    }

    // check if user is still logged in (subscribe to auth state)
    onAuthStateChanged(auth, async (user: User) => {
      if (user !== null) {
        // get firebase auth claims
        const claims = (await auth.currentUser.getIdTokenResult()).claims;

        // redirect user to case if logged in
        if (
          claims.role !== 'case_manager' &&
          claims.role !== 'case_manager_admin'
        ) {
          // user is not logged in => redirect to login
          this.ngZone.run(() => this.router.navigate(['management', 'login']));
        }
      } else {
        // user is not logged in
        this.ngZone.run(() => this.router.navigate(['management', 'login']));
      }
    });

    this.chatMessagesPossibleLangCodes.set(
      'none',
      'case_management_portal.case.chat_page.translation_selection.options.none'
    );
    this.translate.getLangs().forEach((lang_code) => {
      this.chatMessagesPossibleLangCodes.set(
        lang_code,
        'languages.' + lang_code
      );
    });
  }

  async translateCase(langCode: string) {
    // enable loading
    this.isLoading = true;

    if (this.caseDataLangCode === 'none') {
      // show original case data
      this.case.translation = undefined;
    } else {
      const caseData = {
        subject: this.case.subject,
        description: this.case.description,
        date: this.case.date,
        location: this.case.location,
        affected: this.case.affected,
        observation: this.case.observation,
        relationship: this.case.relationship,
        additionalInformation: this.case.additional_information
      };

      const translateCaseFunction = httpsCallable<
        {
          langCode: string;
          caseData: {
            subject: string;
            description: string;
            date: string;
            location: string;
            affected: string;
            observation: string;
            relationship: string;
            additionalInformation: string;
          };
        },
        {
          langCode: string;
          translatedCase: {
            subject: string;
            description: string;
            date: string;
            location: string;
            affected: string;
            observation: string;
            relationship: string;
            additional_information: string;
          };
          error?: FirebaseError;
        }
      >(functions, 'translateCase');

      // translate case
      const { data } = await translateCaseFunction({
        langCode: langCode,
        caseData: caseData
      });

      if (!data.langCode) {
        // errors (handle following error)
        this.caseDataLangCode = 'none';

        // show original case data
        this.case.translation = undefined;
      } else {
        // align lang code
        this.caseDataLangCode = data.langCode;

        // merge translations with case
        this.case.translation = data.translatedCase;
      }
    }
    // disable loading
    this.isLoading = false;
  }

  async updateCaseRecord() {
    try {
      // enable loading
      this.isLoading = true;
      // clear success and error messages
      this.successMessage = this.errorMessage = '';
      // update case doc with new record
      await updateDoc(
        doc(
          firestore,
          'legal_service_providers',
          this.case.lsp_id,
          'clients',
          this.case.client_id,
          'cases',
          this.case.id
        ),
        {
          record: this.case.record
        }
      );
      // show success
      this.translate
        .get(
          'case_management_portal.case.case_overview_page.report_form.record_category_form.record_form_field.success_message.content'
        )
        .subscribe((res) => (this.successMessage = res));
    } catch (error) {
      // show error message
      this.translate
        .get(
          'case_management_portal.case.case_overview_page.report_form.record_category_form.record_form_field.error_message.content'
        )
        .subscribe((res) => (this.errorMessage = res));
    }
    // disable loading
    this.isLoading = false;
  }

  async updateCaseCategory() {
    try {
      // enable loading
      this.isLoading = true;
      // clear success and error messages
      this.successMessage = this.errorMessage = '';
      if (this.case.category !== '') {
        const trimmedStr = this.case.category.trim();

        // encrypt category
        const encryptString = httpsCallable<
          { string: string },
          { encryptedString: string; error?: FirebaseError }
        >(functions, 'encryptString');

        const { data } = await encryptString({
          string: trimmedStr
        });

        if (!data.encryptedString) {
          throw new Error('Error while encrypting category of case.');
        }

        // update case doc with new record and category
        await updateDoc(
          doc(
            firestore,
            'legal_service_providers',
            this.case.lsp_id,
            'clients',
            this.case.client_id,
            'cases',
            this.case.id
          ),
          {
            category: data.encryptedString
          }
        );

        this.formatCaseCategory();

        // show success
        this.translate
          .get(
            'case_management_portal.case.case_overview_page.report_form.record_category_form.category_form_field.success_message.content'
          )
          .subscribe((res) => (this.successMessage = res));
      } else {
        // update case doc with new record and category
        await updateDoc(
          doc(
            firestore,
            'legal_service_providers',
            this.case.lsp_id,
            'clients',
            this.case.client_id,
            'cases',
            this.case.id
          ),
          {
            category: ''
          }
        );
        // show success
        this.translate
          .get(
            'case_management_portal.case.case_overview_page.report_form.record_category_form.category_form_field.success_message.content'
          )
          .subscribe((res) => (this.successMessage = res));
      }
    } catch (error) {
      // show error message
      this.translate
        .get(
          'case_management_portal.case.case_overview_page.report_form.record_category_form.category_form_field.error_message.content'
        )
        .subscribe((res) => (this.errorMessage = res));
    }
    // disable loading
    this.isLoading = false;
  }

  async updateCaseState() {
    try {
      // enable loading
      this.isLoading = true;
      // clear success and error messages
      this.stateSuccessMessage = this.stateErrorMessage = '';
      // update case doc with new state
      await updateDoc(
        doc(
          firestore,
          'legal_service_providers',
          this.case.lsp_id,
          'clients',
          this.case.client_id,
          'cases',
          this.case.id
        ),
        {
          state: this.case.state
        }
      );
      // show success
      this.translate
        .get(
          'case_management_portal.case.case_overview_page.report_form.state_form.state_form_field.success_message.content'
        )
        .subscribe((res) => (this.stateSuccessMessage = res));
    } catch (error) {
      // show error message
      this.translate
        .get(
          'case_management_portal.case.case_overview_page.report_form.state_form.state_form_field.error_message.content'
        )
        .subscribe((res) => (this.stateErrorMessage = res));
    }

    // disable loading
    this.isLoading = false;
  }

  openPdfCaseDocumentationDialog() {
    this.dialog.open(PdfCaseDocumentationDialogComponent, {
      width: '100%',
      maxWidth: '800px',
      data: {
        case: this.case,
        language: this.translate.currentLang
      },
      disableClose: true
    });
  }

  // get default texts of case manager (return depending on state)
  async getDefaultCaseManagerText() {
    // enable loading
    this.isLoading = true;
    // get case manager data
    const docSnap = await getDoc(
      doc(
        firestore,
        'legal_service_providers/' +
          this.case.lsp_id +
          '/case_manager/' +
          auth.currentUser.uid
      )
    );

    if (!docSnap.exists()) {
      throw new Error('Error while getting case manager default texts.');
    }

    // pass case manager default texts to form if existing
    const docData = docSnap.data();

    if (
      this.updateCaseConfirmationState === 1 &&
      docData.default_receipt_confirmation_text
    ) {
      // set message to default text of receipt confirmation
      this.message = docData.default_receipt_confirmation_text;
    } else if (
      this.updateCaseConfirmationState === 2 &&
      docData.default_closing_confirmation_text
    ) {
      // set message to default text of closing confirmation
      this.message = docData.default_closing_confirmation_text;
    }
    // disable loading
    this.isLoading = false;
  }

  // scroll to bottom inside chat
  scrollToBottom(): void {
    this.content.nativeElement.scrollTop =
      this.content.nativeElement.scrollHeight;
  }

  // translate chat messages
  async translateChatMessages(langCode: string, chatMessages: ChatMessage[]) {
    try {
      // enable loading
      this.isLoading = true;

      if (langCode === 'none') {
        // show original messages
        this.chatMessages = this.chatMessages.map((message) => {
          message.translation = undefined;
          return message;
        });
      } else {
        // format chat messages
        const chatMessagesFormatted: TranslationChatMessage[] = [];
        chatMessages.forEach((chatMessage) => {
          // only translate chat messages by case user
          if (chatMessage.is_case_user) {
            chatMessagesFormatted.push({
              id: chatMessage.id,
              text: chatMessage.text
            });
          }
        });

        if (chatMessagesFormatted.length > 0) {
          // translate chat messages in backend
          const translateChatMessages = httpsCallable<
            { chatMessages: TranslationChatMessage[]; langCode: string },
            {
              translatedChatMessages: ChatMessage[];
              langCode: string;
              error?: FirebaseError;
            }
          >(functions, 'translateChatMessages');

          const { data } = await translateChatMessages({
            chatMessages: chatMessagesFormatted,
            langCode: langCode
          });

          if (!data.translatedChatMessages) {
            throw new Error('Error while translate chat messages.');
          }

          // merge translation with chat messages
          data.translatedChatMessages.forEach((chatMessage) => {
            const i = this.chatMessages.findIndex(
              (x) => x.id === chatMessage.id
            );
            this.chatMessages[i].translation = chatMessage.text;
          });

          // align lang code of translation selection
          this.chatMessagesLangCode = data.langCode;
        }
      }
    } catch (error) {
      console.error(error);
    }

    // disable laoding
    this.isLoading = false;
  }

  // send chat message
  async sendChatMessage(clientId: string, caseId: string, messageText: string) {
    try {
      // send chat message
      const sendChatMessageCaseManager = httpsCallable<
        {
          clientId: string;
          caseId: string;
          messageText: string;
          updateCaseConfirmationState: number;
        },
        { result: string; error?: FirebaseError }
      >(functions, 'sendChatMessageCaseManager');

      const { data } = await sendChatMessageCaseManager({
        clientId: clientId,
        caseId: caseId,
        messageText: messageText,
        updateCaseConfirmationState: this.updateCaseConfirmationState
      });

      if (!data.result) {
        throw new Error('Error while send chat message.');
      }

      // update update case confirmation state to false if it is true
      if (this.updateCaseConfirmationState) {
        this.updateCaseConfirmationState = 0;
        this.case.confirmation_state++;
      }

      // clear message
      this.message = '';
    } catch (error) {
      console.error(error);
      // show error to user
      this.translate
        .get(
          'case_management_portal.case.error_messages.send_message_error.content'
        )
        .subscribe((res) => (this.chatErrorMessage = res));
    }
    // deactivate loading
    this.isLoading = false;
  }

  async openUpdateCaseConfirmationStateConfirmationDialog(message: string) {
    let newConfirmationState: string;

    // check and get new confirmation state
    if (this.updateCaseConfirmationState === 1) {
      this.translate
        .get(
          'case_management_portal.case.case_overview_page.report_form.state_form.confirmation_state_form_field.options.option2.content'
        )
        .subscribe((res) => (newConfirmationState = res));
    } else {
      this.translate
        .get(
          'case_management_portal.case.case_overview_page.report_form.state_form.confirmation_state_form_field.options.option3.content'
        )
        .subscribe((res) => (newConfirmationState = res));
    }
    // disable loading
    this.isLoading = false;
    const dialogRef = this.dialog.open(
      UpdateCaseConfirmationStateConfirmationDialogComponent,
      {
        maxWidth: '500px',
        data: {
          newConfirmationState: newConfirmationState,
          language: this.translate.currentLang
        }
      }
    );

    dialogRef.afterClosed().subscribe(async (result: boolean) => {
      // enable loading
      this.isLoading = true;
      if (result !== true) {
        // reset update case confirmation state var
        this.updateCaseConfirmationState = 0;
      }
      // send chat message
      await this.sendChatMessage(this.case.client_id, this.case.id, message);
    });
  }

  // list all case manager of case
  openCaseCaseManagerListDialog(caseData: Case) {
    const dialogRef = this.dialog.open(CaseCaseManagerListDialogComponent, {
      minWidth: '400px',
      data: {
        lspId: this.case.id,
        case: caseData,
        language: this.translate.currentLang
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((caseManager: CaseManager[]) => {
      const clientCaseManager: Manager[] = [];
      const clientCaseManagerUids: string[] = [];

      caseManager.forEach((caseManager: CaseManager) => {
        clientCaseManager.push({
          id: caseManager.id,
          name: caseManager.name,
          email: caseManager.email
        });
        clientCaseManagerUids.push(caseManager.id);
      });

      this.case.client_case_manager = clientCaseManager;
      this.case.client_case_manager_uids = clientCaseManagerUids;
    });
  }

  // validate chat message on submit
  onChatMessageFormSubmit(message: string) {
    // activate loading
    this.isLoading = true;
    // remove whitespaces at beginning and end of string
    message = message.trim();
    // validate message
    if (message.length > this.messageMaxLength) {
      this.translate
        .get(
          'case_management_portal.case.error_messages.message_too_long_error.content'
        )
        .subscribe((res) => (this.chatErrorMessage = res));
    } else if (this.updateCaseConfirmationState > 0) {
      // let user confirm new confirmation state
      this.openUpdateCaseConfirmationStateConfirmationDialog(message);
    } else {
      this.sendChatMessage(this.case.client_id, this.case.id, message);
    }
  }

  // update case user messages to read
  async updateCaseUserMessagesToRead() {
    // check if user is on chat page
    if (this.activeTab === 1) {
      // update new_case_manager_message to false in case doc
      await updateDoc(
        doc(
          firestore,
          'legal_service_providers',
          this.case.lsp_id,
          'clients',
          this.case.client_id,
          'cases',
          this.case.id
        ),
        {
          new_case_user_message: false
        }
      );
    }
  }

  // update case user files to viewed
  async updateCaseUserFilesToViewed() {
    // check if user is on files page
    if (this.activeTab === 2) {
      // update new_case_manager_message to false in case doc
      await updateDoc(
        doc(
          firestore,
          'legal_service_providers',
          this.case.lsp_id,
          'clients',
          this.case.client_id,
          'cases',
          this.case.id
        ),
        {
          new_case_user_file: false
        }
      );
    }
  }

  // download file from firebase storage
  async downloadFile(file: CaseFile) {
    try {
      // enable loading
      this.isLoading = true;
      // get signed download url
      const getFileDownloadUrl = httpsCallable<
        { clientId: string; caseId: string; filePath: string },
        { downloadUrl: string; error?: FirebaseError }
      >(functions, 'getFileDownloadUrl');

      const { data } = await getFileDownloadUrl({
        clientId: this.case.client_id,
        caseId: this.case.id,
        filePath: file.path
      });

      if (!data.downloadUrl) {
        throw new Error(
          'Error occured while trying to get download url for file.'
        );
      }

      // get blow of url from firebase storage
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = () => {
        const blob = xhr.response;

        // trigger file download with blob from api
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = file.id;
        document.body.appendChild(link);
        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
          })
        );

        // remove link from body
        document.body.removeChild(link);
      };
      // send request
      xhr.open('GET', data.downloadUrl);
      xhr.send();
    } catch (error) {
      console.error(error);
    }
    // disable loading
    this.isLoading = false;
  }

  resetFilesMessages() {
    // clear all success and error messages of files
    this.filesErrorMessage = this.filesSuccessMessage = '';
    this.customFileNameError =
      this.customFileNameSuccess =
      this.deleteFileError =
      this.deleteFileSuccess =
        false;
  }

  // update file name
  async updateFileName(file: CaseFile, docRef: DocumentReference) {
    try {
      // enable loading
      this.isLoading = true;
      // clear success and error messages
      this.resetFilesMessages();

      if (!file.name || file.name === '') {
        // update case doc with new file name
        await updateDoc(docRef, {
          name: null
        });
      } else {
        // encrypt new name for file
        const encryptString = httpsCallable<
          { string: string },
          { encryptedString: string; error?: FirebaseError }
        >(functions, 'encryptString');

        const { data } = await encryptString({
          string: file.name
        });

        if (!data.encryptedString) {
          throw new Error('Error while encrypting name of file.');
        }

        // update case doc with new file name
        await updateDoc(docRef, {
          name: data.encryptedString
        });

        // show success to user
        this.customFileNameSuccess = true;
      }
    } catch (error) {
      // show error
      this.customFileNameError = true;
    }
    // disable loading
    this.isLoading = false;
  }

  // pass file to file variable on selection
  onFileChange(event: Event) {
    // clear success and error message
    this.filesSuccessMessage = '';
    this.filesErrorMessage = '';
    // check file size
    if (
      this.files.length +
        this.caseFiles.filter((x) => x.is_case_user_file == false).length +
        event.target['files'].length >
      this.maxFileCount
    ) {
      this.translate
        .get(
          'case_management_portal.case.files_page.upload_files.file_selection_error_messages.too_many_files_error.content'
        )
        .subscribe((res) => (this.filesErrorMessage = res));
    } else {
      if (event.target['files'].length > 0) {
        Array.from(event.target['files']).forEach((file: File) => {
          // check if file was already selected
          if (
            this.files.find(
              (x: File) =>
                x.name === file.name &&
                x.size === file.size &&
                x.lastModified === file.lastModified
            )
          ) {
            this.translate
              .get(
                'case_management_portal.case.files_page.upload_files.file_selection_error_messages.upload_file_once_error.content'
              )
              .subscribe((res) => (this.filesErrorMessage = res));
          } else {
            // check if file size does not exceed 5mb
            if (file.size > this.maxFileSize) {
              this.translate
                .get(
                  'case_management_portal.case.files_page.upload_files.file_selection_error_messages.file_too_large_error.content'
                )
                .subscribe((res) => (this.filesErrorMessage = res));
            } else {
              this.files.push(file);
            }
          }
        });
      }
    }
    this.filesDataSource = new MatTableDataSource(this.files);
  }

  // remove file from files array
  removeFile(file: File) {
    const index = this.files.indexOf(file);
    this.files.splice(index, 1);
    this.filesDataSource = new MatTableDataSource(this.files);
  }

  // upload files
  async uploadFiles(lspId: string, clientId: string, userUid: string) {
    // activate loading
    this.isLoading = true;

    const referenceUrl =
      'legal_service_providers/' +
      lspId +
      '/clients/' +
      clientId +
      '/cases/' +
      userUid +
      '/case_manager';
    try {
      this.files.forEach(async (file: File) => {
        // storage reference for file
        const storageRef = ref(
          storage,
          referenceUrl + '/' + uuid() + '.' + file.name.split('.').pop()
        );
        await uploadBytes(storageRef, file);
      });
      // show success message
      this.translate
        .get(
          'case_management_portal.case.files_page.upload_files.file_upload_success_message.content'
        )
        .subscribe((res) => (this.filesSuccessMessage = res));
      // clear files
      this.files = [];
    } catch (error) {
      console.log(error);
      // display error message
      this.translate
        .get(
          'case_management_portal.case.files_page.upload_files.file_upload_error_message.content'
        )
        .subscribe((res) => (this.filesErrorMessage = res));
    }
    // deactivate loading
    this.isLoading = false;
  }

  onFilesSubmit() {
    // clear files success and error messages
    this.resetFilesMessages();

    // upload case files
    this.uploadFiles(this.case.lsp_id, this.case.client_id, this.case.id);
  }

  // needed to disable sortation of languages
  returnZero() {
    return 0;
  }

  // case
  openDeleteCaseDialog(caseData: Case) {
    const dialogRef = this.dialog.open(DeleteCaseDialogComponent, {
      maxWidth: '400px',
      data: {
        language: this.translate.currentLang,
        caseId: caseData.case_id
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(async (result: boolean) => {
      try {
        if (result) {
          this.isLoading = true;

          const deleteCase = httpsCallable<
            { clientId: string; caseId: string },
            { result: boolean; error?: FirebaseError }
          >(functions, 'deleteCase');

          const { data } = await deleteCase({
            clientId: caseData.client_id,
            caseId: caseData.id
          });

          if (!data.result) {
            throw data.error;
          }

          this.router.navigate(['/management']);
        }
      } catch (error) {
        console.error(error);
      }
    });
  }
}
