import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild
} from '@angular/core';
import { CaseManagementPortalComponent } from '../case-management-portal/case-management-portal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { onAuthStateChanged } from 'firebase/auth';
import { FirebaseAuthService } from 'src/app/shared/services/firebase-auth.service';
import { auth, functions, storage } from 'src/firebase';
import { ref, getDownloadURL } from 'firebase/storage';
import { EChartsOption } from 'echarts';
import { FirebaseError } from 'firebase/app';
import { httpsCallable } from 'firebase/functions';
import * as echarts from 'echarts';
import { CaseCharts } from 'src/app/shared/models/case.model';

@Component({
  selector: 'app-case-management-portal-charts',
  templateUrl: './case-management-portal-charts.component.html',
  styleUrls: ['./case-management-portal-charts.component.scss']
})
export class CaseManagementPortalChartsComponent implements OnInit {
  isLoading = false;

  clientId: string;
  clientName: string;

  logo: string;
  cases: CaseCharts[] = [];
  categories: string[] = [];
  translations: Map<string, string> = new Map<string, string>();

  options: EChartsOption;
  options2: EChartsOption;
  options3: EChartsOption;
  options4: EChartsOption;

  @ViewChild('chart1') chart1Element: ElementRef;
  @ViewChild('chart2') chart2Element: ElementRef;
  @ViewChild('chart3') chart3Element: ElementRef;
  @ViewChild('chart4') chart4Element: ElementRef;

  chart1;
  chart2;
  chart3;
  chart4;

  constructor(
    public caseManagementPortalComponent: CaseManagementPortalComponent,
    private firebaseAuth: FirebaseAuthService,
    private route: ActivatedRoute,
    private router: Router,
    private ngZone: NgZone,
    public translate: TranslateService
  ) {}

  async getCasesByClient(clientId: string) {
    const getCasesByClient = httpsCallable<
      {
        clientId: string;
      },
      {
        cases: CaseCharts[];
        decryptCategories: string[];
        error?: FirebaseError;
      }
    >(functions, 'getCasesByClient');

    const { data } = await getCasesByClient({
      clientId: clientId
    });

    if (!data.cases && !data.decryptCategories) {
      this.router.navigate(['management']);
    }

    this.cases = data.cases;
    this.categories = data.decryptCategories;
  }

  async getLogo(lspId: string, clientId: string) {
    try {
      // firebase storage reference of logo
      const logoRef = ref(
        storage,
        'legal_service_providers/' +
          lspId +
          '/clients/' +
          clientId +
          '/logo_dark.png'
      );

      // get the download URL
      this.logo = await getDownloadURL(logoRef);
    } catch (error) {
      this.logo = null;
    }
  }

  charts() {
    let active = 0;
    let done = 0;
    let deposed = 0;

    let acquisition = 0;
    let finance = 0;
    let production = 0;
    let staff = 0;
    let other = 0;
    let notRelevant = 0;

    this.categories.forEach((category) => {
      switch (category.split('_')[0]) {
        case 'acquisition':
          acquisition++;
          break;
        case 'finance':
          finance++;
          break;
        case 'production':
          production++;
          break;
        case 'staff':
          staff++;
          break;
        case 'other':
          other++;
          break;
        case 'not-relevant':
          notRelevant++;
          break;
      }
    });

    this.cases.forEach((oneCase) => {
      switch (oneCase.state) {
        case 'active':
          active++;
          break;
        case 'done':
          done++;
          break;
        case 'deposed':
          deposed++;
          break;
      }
    });

    const d = new Date();

    this.options = {
      legend: {},
      tooltip: {},
      toolbox: {
        feature: {
          saveAsImage: {
            show: true,
            type: 'png',
            title: this.translations.get(
              'case_management_portal.charts.status.title.content'
            ),
            name:
              this.translations.get(
                'case_management_portal.charts.status.title.content'
              ) +
              ' ' +
              d.toString()
          }
        }
      },
      dataset: {
        // Provide a set of data.
        source: [
          [
            'product',
            this.translations.get(
              'case_management_portal.home.cases_box.cases_table.case_states.active.content'
            ),
            this.translations.get(
              'case_management_portal.home.cases_box.cases_table.case_states.done.content'
            ),
            this.translations.get(
              'case_management_portal.home.cases_box.cases_table.case_states.deposed.content'
            )
          ],
          [
            this.translations.get(
              'case_management_portal.home.charts.cases.content'
            ),
            active,
            done,
            deposed
          ]
        ]
      },
      // Declare an x-axis (category axis).
      // The category map the first column in the dataset by default.
      xAxis: { type: 'category' },
      // Declare a y-axis (value axis).
      yAxis: {},
      // Declare several 'bar' series,
      // every series will auto-map to each column by default.
      series: [{ type: 'bar' }, { type: 'bar' }, { type: 'bar' }]
    };

    this.options2 = {
      title: {
        left: '50%',
        subtext: this.translations.get(
          'case_management_portal.home.charts.categories.subtitle.content'
        ),
        textAlign: 'center'
      },
      tooltip: {
        trigger: 'item',
        formatter: '{b}: {c} ({d}%)'
      },
      toolbox: {
        feature: {
          saveAsImage: {
            show: true,
            type: 'png',
            title: this.translations.get(
              'case_management_portal.charts.category.title.content'
            ),
            name:
              this.translations.get(
                'case_management_portal.charts.category.title.content'
              ) +
              ' ' +
              d.toString()
          }
        }
      },
      calculable: true,
      series: [
        {
          type: 'pie',
          radius: [30, 120],
          roseType: 'area',
          data: [
            {
              value: acquisition,
              name: this.translations.get(
                'case_management_portal.case.case_overview_page.report_form.record_category_form.category_form_field.acquisition'
              )
            },
            {
              value: finance,
              name: this.translations.get(
                'case_management_portal.case.case_overview_page.report_form.record_category_form.category_form_field.finance'
              )
            },
            {
              value: production,
              name: this.translations.get(
                'case_management_portal.case.case_overview_page.report_form.record_category_form.category_form_field.production'
              )
            },
            {
              value: staff,
              name: this.translations.get(
                'case_management_portal.case.case_overview_page.report_form.record_category_form.category_form_field.staff'
              )
            },
            {
              value: other,
              name: this.translations.get(
                'case_management_portal.case.case_overview_page.report_form.record_category_form.category_form_field.other.other'
              )
            },
            {
              value: notRelevant,
              name: this.translations.get(
                'case_management_portal.case.case_overview_page.report_form.record_category_form.category_form_field.not_relevant_option.content'
              )
            }
          ]
        }
      ]
    };

    this.options3 = {
      xAxis: {
        type: 'category',
        data: [
          this.translations.get(
            'case_management_portal.home.charts.cases.content'
          )
        ]
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          data: [this.cases.length],
          type: 'bar'
        }
      ]
    };

    const relevant = acquisition + finance + production + staff + other;

    this.options4 = {
      legend: {
        top: '5%',
        left: 'center'
      },
      toolbox: {
        show: true,
        feature: {
          saveAsImage: {
            show: true,
            type: 'png',
            title: this.translations.get(
              'case_management_portal.charts.relevance.title.content'
            ),
            name:
              this.translations.get(
                'case_management_portal.charts.relevance.title.content'
              ) +
              ' ' +
              d.toString()
          }
        }
      },
      color: ['#5470c6', '#fac858'],
      series: [
        {
          type: 'pie',
          radius: ['40%', '70%'],
          label: {
            show: false
          },
          labelLine: {
            show: false
          },
          avoidLabelOverlap: false,
          data: [
            {
              value: relevant,
              name:
                this.translations.get(
                  'case_management_portal.charts.relevance.relevant.content'
                ) +
                ' (' +
                relevant +
                ')'
            },
            {
              value: notRelevant,
              name:
                this.translations.get(
                  'case_management_portal.charts.relevance.not-relevant.content'
                ) +
                ' (' +
                notRelevant +
                ')'
            }
          ]
        }
      ]
    };

    // Initialize your charts in the ngAfterViewInit lifecycle hook
    this.chart1 = echarts.init(this.chart1Element.nativeElement);
    this.chart2 = echarts.init(this.chart2Element.nativeElement);
    this.chart3 = echarts.init(this.chart3Element.nativeElement);
    this.chart4 = echarts.init(this.chart4Element.nativeElement);
  }

  async ngOnInit() {
    this.isLoading = true;

    if (history.length === 50) {
      this.router.navigate(['management']);
    }

    // wait until auth is initialized
    const user = await this.firebaseAuth.getCurrentUser();

    // check initially if user is logged in
    if (user !== null) {
      // get firebase auth claims
      const claims = (await auth.currentUser.getIdTokenResult()).claims;

      const lspId = claims.lsp_id;
      this.clientId = this.route.snapshot.paramMap.get('clientId');
      this.clientName = this.route.snapshot.paramMap.get('clientName');

      // redirect user to case if logged in
      if (
        claims.role !== 'case_manager' &&
        claims.role !== 'case_manager_admin'
      ) {
        // user is not logged in => redirect to login
        this.router.navigate(['management', 'login']);
      }

      if (
        typeof lspId === 'string' &&
        typeof this.clientId === 'string' &&
        this.clientId
      ) {
        // get cases by client
        await this.getCasesByClient(this.clientId);

        // get logo white
        await this.getLogo(lspId, this.clientId);

        // set translations
        this.loadTranslations();

        this.translate.onLangChange.subscribe(() => {
          this.loadTranslations();
        });

        window.scroll(0, 0);

        this.isLoading = false;
      } else {
        // user is not logged in => redirect to login
        this.router.navigate(['management', 'login']);
      }
    } else {
      // user is not logged in
      this.router.navigate(['management', 'login']);
    }

    // check if user is still logged in (subscribe to auth state)
    onAuthStateChanged(auth, async (user) => {
      if (user !== null) {
        // get firebase auth claims
        const claims = (await auth.currentUser.getIdTokenResult()).claims;

        // redirect user to case if logged in
        if (
          claims.role !== 'case_manager' &&
          claims.role !== 'case_manager_admin'
        ) {
          // user is not logged in => redirect to login
          this.ngZone.run(() => this.router.navigate(['management', 'login']));
        }
      } else {
        // user is not logged in
        this.ngZone.run(() => this.router.navigate(['management', 'login']));
      }
    });
  }

  loadTranslations() {
    this.translate
      .getTranslation(this.translate.currentLang)
      .subscribe((translation) => {
        Object.keys(translation).forEach((key) => {
          this.translations.set(key, translation[key]);
        });

        // charts
        this.charts();

        this.caseManagementPortalComponent.trigger++;

        // disable loading
        this.isLoading = false;
      });
  }

  async pdfCharts(language: string) {
    const generatePdfCharts = httpsCallable<
      {
        clientId: string;
        language: string;
      },
      { downloadUrl: string; error?: FirebaseError }
    >(functions, 'generatePdfCharts');

    const { data } = await generatePdfCharts({
      clientId: this.clientId,
      language: language
    });

    if (!data.downloadUrl) {
      this.router.navigate(['management']);
    }

    // get blow of url from firebase storage
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = () => {
      const blob = xhr.response;

      // trigger file download with blob from api
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = 'analytics';
      document.body.appendChild(link);
      link.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        })
      );

      // remove link from body
      document.body.removeChild(link);
    };

    // send request
    xhr.open('GET', data.downloadUrl);
    xhr.send();
  }

  // pdf charts
  async openPdfChartsDialog() {
    // enable loading
    this.isLoading = true;
    // charts pdf (backend)
    await this.pdfCharts(this.translate.currentLang);
    // disable loading
    this.isLoading = false;
  }
}
