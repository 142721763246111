import { ENTER, COMMA } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FirebaseError } from 'firebase/app';
import { query, collection, getDocs } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { Observable, startWith, map } from 'rxjs';
import { DialogCaseCaseManagerListData } from 'src/app/shared/interfaces/caseManagementCase';
import { CaseManager } from 'src/app/shared/models/caseManager.model';
import { firestore, functions } from 'src/firebase';

@Component({
  selector: 'app-case-case-manager-list-dialog',
  templateUrl: './case-case-manager-list-dialog.component.html',
  styleUrls: ['./case-case-manager-list-dialog.component.scss']
})
export class CaseCaseManagerListDialogComponent implements OnInit {
  // loading animation indicator variable
  isLoading = false;

  // success and error messages
  success = false;
  error = false;

  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  managerCtrl = new FormControl();
  filteredCaseManager: Observable<CaseManager[]>;
  selectCaseManager = this.data.case.client_case_manager_uids;
  caseManager: CaseManager[] = [];
  allCaseManager: CaseManager[] = [];

  @ViewChild('managerInput') managerInput: ElementRef<HTMLInputElement>;

  constructor(
    public dialogRef: MatDialogRef<CaseCaseManagerListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogCaseCaseManagerListData
  ) {}

  async ngOnInit() {
    // get all case manager of legal service provider
    const q = query(
      collection(
        firestore,
        'legal_service_providers',
        this.data.case.lsp_id,
        'case_manager'
      )
    );

    const querySnapshot = await getDocs(q);

    this.allCaseManager = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      if (data.is_active === true) {
        const manager: CaseManager = {
          id: doc.id,
          name: data.name,
          email: data.email,
          role: data.role,
          is_multi_factor_user: data.is_multi_factor_user,
          is_active: data.is_active
        };
        this.allCaseManager.push(manager);
      }
    });

    this.filteredCaseManager = this.managerCtrl.valueChanges.pipe(
      startWith(null),
      map((manager: CaseManager) =>
        manager ? this._filter(manager) : this.allCaseManager.slice()
      )
    );

    for (let i = 0; i < this.selectCaseManager.length; i++) {
      const manager = this.allCaseManager.find(
        (manager) => manager.id === this.selectCaseManager[i]
      );
      this.caseManager.push(manager);
    }
  }

  add(event: MatChipInputEvent): void {
    // Clear the input value
    event.chipInput!.clear();
    this.managerCtrl.setValue(null);
  }

  async remove(manager: CaseManager) {
    const index = this.caseManager.indexOf(manager);

    if (index >= 0 && manager.role === 'case_manager') {
      await this.updateCaseCaseManagerList(manager, 'remove', index);
    }
  }

  async selected(event: MatAutocompleteSelectedEvent) {
    const found = this.caseManager.find(
      (manager: CaseManager) => manager.id === event.option.value.id
    );

    if (!found) {
      await this.updateCaseCaseManagerList(event.option.value, 'add');
    }

    this.managerInput.nativeElement.value = '';
    this.managerCtrl.setValue(null);
  }

  private _filter(manager: CaseManager): CaseManager[] {
    const filterValue = manager.name.toLowerCase();

    return this.allCaseManager.filter((manager) =>
      manager.name.toLowerCase().includes(filterValue)
    );
  }

  async updateCaseCaseManagerList(
    manager: CaseManager,
    change: 'add' | 'remove',
    managerIndex?: number
  ) {
    // enable loading
    this.isLoading = true;

    // clear success and error message
    this.success = this.error = false;

    try {
      // update case manager in case
      const updateInCaseCaseManager = {
        clientId: this.data.case.client_id,
        caseId: this.data.case.id,
        caseManagerId: manager.id
      };

      // callable backend functions
      if (change === 'add') {
        const addCaseCaseManager = httpsCallable<
          { clientId: string; caseId: string; caseManagerId: string },
          { manager: CaseManager; error?: FirebaseError }
        >(functions, 'addCaseCaseManager');

        const { data } = await addCaseCaseManager(updateInCaseCaseManager);

        if (!data.manager) {
          throw data.error;
        }

        this.caseManager.push(manager);
      } else if (change === 'remove') {
        const removeCaseCaseManager = httpsCallable<
          { clientId: string; caseId: string; caseManagerId: string },
          { result: string; error?: FirebaseError }
        >(functions, 'removeCaseCaseManager');

        const { data } = await removeCaseCaseManager(updateInCaseCaseManager);

        if (!data.result) {
          throw data.error;
        }

        this.caseManager.splice(managerIndex, 1);
      }

      // show success to user
      this.success = true;
    } catch (error) {
      // show error to user
      this.error = true;
    }

    // disable loading
    this.isLoading = false;
  }
}
