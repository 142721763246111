<div class="cover-spin" *ngIf="isLoading"></div>
<mat-card class="mt-5 d-block m-auto">
  <div
    class="h4 mb-1"
    [innerHTML]="'admin_portal.home.heading.content' | translate"
  ></div>
  <p
    *ngIf="user"
    class="mb-3"
    [innerHTML]="
      ('admin_portal.home.subheading.first_part.content' | translate) +
      user.displayName +
      ('admin_portal.home.subheading.second_part.content' | translate)
    "
  ></p>
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title
          [innerHTML]="
            'admin_portal.home.legal_service_providers_panel.heading.content'
              | translate
          "
        ></mat-panel-title>
        <mat-panel-description
          [innerHTML]="
            'admin_portal.home.legal_service_providers_panel.description.content'
              | translate
          "
        ></mat-panel-description>
      </mat-expansion-panel-header>
      <mat-form-field>
        <mat-label
          [innerHTML]="
            'admin_portal.home.legal_service_providers_panel.filter_form_field.label.content'
              | translate
          "
        ></mat-label>
        <input
          matInput
          (keyup)="filterLegalServiceProviders($event)"
          [placeholder]="
            'admin_portal.home.legal_service_providers_panel.filter_form_field.placeholder.content'
              | translate
          "
        />
      </mat-form-field>
      <div class="tablecontainer mat-elevation-z8">
        <table mat-table [dataSource]="lspDataSource">
          <!-- Display legal service providers -->

          <!-- Name of legal service provider -->
          <ng-container matColumnDef="name">
            <th
              mat-header-cell
              *matHeaderCellDef
              [innerHTML]="
                'admin_portal.home.legal_service_providers_panel.table.name_column.content'
                  | translate
              "
            ></th>
            <td mat-cell *matCellDef="let lsp">{{ lsp.name }}</td>
          </ng-container>

          <!-- View legal service provider -->
          <ng-container matColumnDef="view">
            <th
              mat-header-cell
              *matHeaderCellDef
              [innerHTML]="
                'admin_portal.home.legal_service_providers_panel.table.view_column.content'
                  | translate
              "
            ></th>
            <td mat-cell *matCellDef="let lsp">
              <button
                mat-icon-button
                [routerLink]="['lsp', lsp.id]"
                routerLinkActive="router-link-active"
                aria-label="View legal service provider"
              >
                <mat-icon
                  aria-hidden="false"
                  aria-label="View legal service provider"
                  color="primary"
                  >login</mat-icon
                >
              </button>
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="lspTableDisplayedColumns; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: lspTableDisplayedColumns"
          ></tr>
        </table>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title
          [innerHTML]="
            'admin_portal.home.add_legal_service_provider_panel.heading.content'
              | translate
          "
        ></mat-panel-title>
        <mat-panel-description
          [innerHTML]="
            'admin_portal.home.add_legal_service_provider_panel.description.content'
              | translate
          "
        ></mat-panel-description>
      </mat-expansion-panel-header>
      <form class="mb-3" [formGroup]="addLspForm" (ngSubmit)="onFormSubmit()">
        <!-- ID of lsp -->
        <mat-form-field class="w-100" appearance="outline">
          <mat-label
            [innerHTML]="
              'admin_portal.home.add_legal_service_provider_panel.add_lsp_form.lsp_id_form_field.label.content'
                | translate
            "
          ></mat-label>
          <input
            matInput
            formControlName="lsp_id"
            [errorStateMatcher]="matcher"
          />
          <mat-error>
            <span
              *ngIf="
                !addLspForm.get('lsp_id').valid &&
                addLspForm.get('lsp_id').touched
              "
              [innerHTML]="
                'admin_portal.home.add_legal_service_provider_panel.add_lsp_form.lsp_id_form_field.error_message.content'
                  | translate
              "
            ></span>
          </mat-error>
        </mat-form-field>

        <!-- Name of lsp -->
        <mat-form-field class="w-100" appearance="outline">
          <mat-label
            [innerHTML]="
              'admin_portal.home.add_legal_service_provider_panel.add_lsp_form.lsp_name_form_field.label.content'
                | translate
            "
          ></mat-label>
          <input
            matInput
            formControlName="lsp_name"
            [errorStateMatcher]="matcher"
          />
          <mat-error>
            <span
              *ngIf="
                !addLspForm.get('lsp_name').valid &&
                addLspForm.get('lsp_name').touched
              "
              [innerHTML]="
                'admin_portal.home.add_legal_service_provider_panel.add_lsp_form.lsp_name_form_field.error_message.content'
                  | translate
              "
            ></span>
          </mat-error>
        </mat-form-field>

        <!-- Name short of lsp -->
        <mat-form-field class="w-100" appearance="outline">
          <mat-label
            [innerHTML]="
              'admin_portal.home.add_legal_service_provider_panel.add_lsp_form.lsp_name_short_form_field.label.content'
                | translate
            "
          ></mat-label>
          <input
            matInput
            formControlName="lsp_name_short"
            [errorStateMatcher]="matcher"
          />
          <mat-error>
            <span
              *ngIf="
                !addLspForm.get('lsp_name_short').valid &&
                addLspForm.get('lsp_name_short').touched
              "
              [innerHTML]="
                'admin_portal.home.add_legal_service_provider_panel.add_lsp_form.lsp_name_short_form_field.error_message.content'
                  | translate
              "
            ></span>
          </mat-error>
        </mat-form-field>

        <!-- Withdrawal email of lsp -->
        <mat-form-field class="w-100" appearance="outline">
          <mat-label
            [innerHTML]="
              'admin_portal.home.add_legal_service_provider_panel.add_lsp_form.lsp_withdrawal_email_form_field.label.content'
                | translate
            "
          ></mat-label>
          <input
            matInput
            formControlName="lsp_withdrawal_email"
            [errorStateMatcher]="matcher"
          />
          <mat-error>
            <span
              *ngIf="
                !addLspForm.get('lsp_withdrawal_email').valid &&
                addLspForm.get('lsp_withdrawal_email').touched
              "
              [innerHTML]="
                'admin_portal.home.add_legal_service_provider_panel.add_lsp_form.lsp_withdrawal_email_form_field.error_message.content'
                  | translate
              "
            ></span>
          </mat-error>
        </mat-form-field>
        <hr />
        <p
          [innerHTML]="
            'admin_portal.home.add_legal_service_provider_panel.add_lsp_form.logo_disclaimer.content'
              | translate
          "
        ></p>
        <!-- Lsp logo selection -->
        <input
          class="d-none"
          (change)="onFileSelected($event)"
          type="file"
          [multiple]="false"
          #fileUpload
        />
        <div class="my-3">
          <button
            type="button"
            class="mb-1 py-1"
            mat-stroked-button
            (click)="fileUpload.click()"
            color="primary"
            [innerHTML]="
              'admin_portal.home.add_legal_service_provider_panel.add_lsp_form.select_logo_button.content'
                | translate
            "
          ></button>
        </div>
        <p
          *ngIf="fileSelectionError"
          class="error"
          [innerHTML]="fileSelectionError"
        ></p>
        <p
          *ngIf="!lspLogoFile"
          [innerHTML]="
            'admin_portal.home.add_legal_service_provider_panel.add_lsp_form.no_file_selected.content'
              | translate
          "
        ></p>
        <p *ngIf="lspLogoFile" class="mb-0">
          {{
            'admin_portal.home.add_legal_service_provider_panel.add_lsp_form.file_selected.content'
              | translate
          }}
          {{ lspLogoFile.name }}
        </p>
        <p
          *ngIf="lspLogoFile"
          class="mt-1 cursor-pointer text-primary"
          (click)="lspLogoFile = undefined"
          (keypress)="lspLogoFile = undefined"
          tabindex="auto"
          [innerHTML]="
            'admin_portal.home.add_legal_service_provider_panel.add_lsp_form.remove_file_button.content'
              | translate
          "
        ></p>

        <button
          class="w-100 mt-1 py-1"
          mat-raised-button
          type="submit"
          [disabled]="!addLspForm.valid || isLoading"
          color="primary"
          [innerHTML]="
            'admin_portal.home.add_legal_service_provider_panel.add_lsp_form.submit_button.content'
              | translate
          "
        ></button>
        <p
          *ngIf="addLspSuccess"
          class="success mt-3 text-center pt-0"
          [innerHTML]="
            'admin_portal.home.add_legal_service_provider_panel.add_lsp_form.success_message.content'
              | translate
          "
        ></p>
        <p
          *ngIf="addLspError"
          class="error mt-3 text-center pt-0"
          [innerHTML]="
            'admin_portal.home.add_legal_service_provider_panel.add_lsp_form.error_message.content'
              | translate
          "
        ></p>
      </form>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title
          [innerHTML]="
            'admin_portal.home.admin_users_panel.heading.content' | translate
          "
        ></mat-panel-title>
        <mat-panel-description
          [innerHTML]="
            'admin_portal.home.admin_users_panel.description.content'
              | translate
          "
        ></mat-panel-description>
      </mat-expansion-panel-header>
      <div class="tablecontainer mat-elevation-z8 mt-3">
        <table mat-table [dataSource]="adminUsersDataSource">
          <!-- Display admin users -->

          <!-- Name of admin user -->
          <ng-container matColumnDef="name">
            <th
              mat-header-cell
              *matHeaderCellDef
              [innerHTML]="
                'admin_portal.home.admin_users_panel.table.name_column.content'
                  | translate
              "
            ></th>
            <td mat-cell *matCellDef="let user">{{ user.name }}</td>
          </ng-container>

          <!-- Username of admin user -->
          <ng-container matColumnDef="username">
            <th
              mat-header-cell
              *matHeaderCellDef
              [innerHTML]="
                'admin_portal.home.admin_users_panel.table.username_column.content'
                  | translate
              "
            ></th>
            <td mat-cell *matCellDef="let user">{{ user.username }}</td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="adminUsersTableDisplayedColumns; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: adminUsersTableDisplayedColumns"
          ></tr>
        </table>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</mat-card>

<div class="charts m-auto mt-5 d-block">
  <div class="col-12 col-md-5">
    <mat-card class="chart" *ngIf="options">
      <div
        class="chart"
        echarts
        [options]="options"
        [merge]="mergeOptions"
      ></div>
    </mat-card>
  </div>
  <div class="col-12 col-md-7"></div>
</div>

<!-- <mat-card class="d-block m-auto mt-5 pb-0">
  <div echarts [options]="options2"></div>
</mat-card>
 -->
