sudo deposedText
<div class="cover-spin" *ngIf="isLoading"></div>
<mat-card class="py-4 px-5 mt-5" *ngIf="!verificationId">
  <div class="py-1 px-3">
    <div
      class="h5 mt-3"
      [innerHTML]="'case_management_portal.login.heading.content' | translate"
    ></div>
    <hr />
    <p
      [innerHTML]="
        'case_management_portal.login.subheading.content' | translate
      "
    ></p>
    <form class="mb-3" [formGroup]="loginForm" (ngSubmit)="onFormSubmit()">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>{{
          'case_management_portal.login.login_form.case_id_form_field.label.content'
            | translate
        }}</mat-label>
        <input
          type="email"
          matInput
          formControlName="email"
          [errorStateMatcher]="matcher"
        />
        <mat-error>
          <span
            *ngIf="
              !loginForm.get('email').valid && loginForm.get('email').touched
            "
            [innerHTML]="
              'case_management_portal.login.login_form.case_id_form_field.error_message.content'
                | translate
            "
          ></span>
        </mat-error>
      </mat-form-field>
      <mat-form-field class="w-100" appearance="outline">
        <mat-label
          [innerHTML]="
            'case_management_portal.login.login_form.password_form_field.label.content'
              | translate
          "
        ></mat-label>
        <input
          type="password"
          matInput
          formControlName="password"
          [type]="hide ? 'password' : 'text'"
          [errorStateMatcher]="matcher"
        />
        <button
          type="button"
          mat-icon-button
          matSuffix
          (click)="hide = !hide"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide"
        >
          <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
        <mat-error>
          <span
            *ngIf="
              !loginForm.get('password').valid &&
              loginForm.get('password').touched
            "
            [innerHTML]="
              'case_management_portal.login.login_form.password_form_field.error_message.content'
                | translate
            "
          ></span>
        </mat-error>
      </mat-form-field>
      <button
        class="w-100 mt-1 py-1"
        mat-raised-button
        type="submit"
        [disabled]="!loginForm.valid || isLoading"
        color="primary"
        [innerHTML]="
          'case_management_portal.login.login_form.submit_button.content'
            | translate
        "
      ></button>
      <p
        *ngIf="errorMessage"
        class="error mt-3 text-center pt-0"
        [innerText]="errorMessage"
      ></p>
    </form>
    <div
      style="
        position: relative;
        z-index: 10;
        width: 100%;
        margin-top: 2em;
        margin-left: 2em;
        margin-right: auto;
      "
    >
      <div id="recaptcha-container"></div>
    </div>
  </div>
</mat-card>
<mat-card class="py-4 px-5 mt-5" *ngIf="verificationId && resolver">
  <div class="py-1 px-3">
    <div
      class="h5 mt-3"
      [innerHTML]="
        'case_management_portal.login.two_factor.heading.content' | translate
      "
    ></div>
    <hr />
    <p
      [innerHTML]="
        'case_management_portal.login.two_factor.subheading1.content'
          | translate
      "
    ></p>
    <p>
      {{
        ('case_management_portal.login.two_factor.subheading2.first_part.content'
          | translate) +
          ' ' +
          getPhoneNumber() +
          ' ' +
          ('case_management_portal.login.two_factor.subheading2.second_part.content'
            | translate)
      }}
    </p>
    <mat-form-field class="w-100" appearance="outline">
      <mat-label
        [innerHTML]="
          'case_management_portal.login.two_factor.verification_code_form.verification_code_form_field.label.content'
            | translate
        "
      ></mat-label>
      <input
        [(ngModel)]="verificationCode"
        matInput
        type="tel"
        pattern="[0-9]*"
        maxlength="6"
      />
    </mat-form-field>
    <button
      class="w-100 mt-1 py-1"
      (click)="verifySMSVerificationCode()"
      mat-raised-button
      [disabled]="isLoading || !(verificationCode.length === 6)"
      color="primary"
      [innerHTML]="
        'case_management_portal.login.two_factor.verification_code_form.submit_button.content'
          | translate
      "
    ></button>
    <p
      *ngIf="errorMessage"
      class="error mt-3 text-center pt-0"
      [innerText]="errorMessage"
    ></p>
  </div>
</mat-card>
