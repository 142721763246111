// Firebase app initialization
import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { environment } from './environments/environment';

// Initialize firebase app (config depending on environment)
const app = initializeApp(environment.firebaseConfig);

if (location.hostname !== 'localhost') {
  // Initialize app check (recaptcha token depends on environment)
  initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(environment.appCheckReCaptchaSiteKey),
    isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
  });
}

// Initalize firebase products instances
export const auth = getAuth(); // firebase auth
export const storage = getStorage(); // firebase storage
export const firestore = getFirestore(); // firestore
export const functions = getFunctions(app, 'europe-west3'); // firebase functions

// connect firebase app to emulator (only if currently on localhost)
if (location.hostname === 'localhost') {
  connectFirestoreEmulator(getFirestore(), 'localhost', 8080);
  connectAuthEmulator(auth, 'http://localhost:9099');
  connectFunctionsEmulator(
    getFunctions(app, 'europe-west3'),
    'localhost',
    5001
  );
  connectStorageEmulator(storage, 'localhost', 9199);
}
