import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FirebaseError } from 'firebase/app';
import { Unsubscribe } from 'firebase/auth';
import {
  onSnapshot,
  doc,
  updateDoc,
  DocumentReference,
  DocumentData
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { COUNTRIES } from 'src/app/shared/data/countries';
import { FirebaseAuthService } from 'src/app/shared/services/firebase-auth.service';
import { functions, firestore, auth } from 'src/firebase';
import { CaseManagementPortalComponent } from '../case-management-portal/case-management-portal.component';
import { CaseManager } from 'src/app/shared/models/caseManager.model';

@Component({
  selector: 'app-case-management-portal-settings',
  templateUrl: './case-management-portal-settings.component.html',
  styleUrls: ['./case-management-portal-settings.component.scss']
})
export class CaseManagementPortalSettingsComponent
  implements OnInit, OnDestroy
{
  caseManager: CaseManager;
  docRef: DocumentReference<DocumentData, DocumentData>;

  // change password form
  changePasswordForm: FormGroup = this.formBuilder.group({
    password: [
      '',
      Validators.compose([
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(100)
      ])
    ],
    new_password: [
      '',
      Validators.compose([
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(100)
      ])
    ],
    new_password2: [
      '',
      Validators.compose([
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(100)
      ])
    ]
  });

  // loading var for spinner
  isLoading = false;

  // unsubscribe for user data subscription
  unsubscribe: Unsubscribe;

  // countries for phone number
  countries = COUNTRIES;

  // success and errors
  // default texts
  defaultTextsSuccess = false;
  defaultTextsError = false;

  // update multi factor
  updateMultiFactorSuccess = false;
  updateMultiFactorError = false;

  // change password
  changePasswordSuccessMessage = '';
  changePasswordErrorMessage = '';

  // hide for password input
  hide = true;

  // angular material case manager table setup
  caseManagerTableDisplayedColumns: string[] = [
    'name',
    'email',
    'role',
    'is_multi_factor_user',
    'de_activate'
  ];
  caseManagerDataSource = new MatTableDataSource();

  // possible roles
  possibleRoles = [];

  constructor(
    private router: Router,
    private firebaseAuth: FirebaseAuthService,
    private formBuilder: FormBuilder,
    public caseManagementPortalComponent: CaseManagementPortalComponent,
    public translate: TranslateService
  ) {}

  // get data of user (subscribe)
  getUserData(lspId: string) {
    try {
      onSnapshot(
        doc(
          firestore,
          'legal_service_providers',
          lspId,
          'case_manager',
          auth.currentUser.uid
        ),
        (querySnapshot) => {
          if (!querySnapshot.exists()) {
            // error while getting user data (navigate to home)
            this.router.navigate(['management/home']);
          }

          const data = querySnapshot.data();

          this.caseManager = {
            id: querySnapshot.id,
            name: data.name,
            email: data.email,
            role: data.role,
            default_receipt_confirmation_text:
              data.default_receipt_confirmation_text,
            default_closing_confirmation_text:
              data.default_closing_confirmation_text,
            is_multi_factor_user: data.is_multi_factor_user,
            country_code: data.country_code,
            phone_number: data.phone_number,
            is_active: data.is_active
          };

          this.docRef = querySnapshot.ref;

          // transform user data
          this.transform();

          // disable loading
          this.isLoading = false;
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  extract(lspId: string) {
    // get user data
    this.getUserData(lspId);
  }

  transform() {
    if (!this.caseManager.default_receipt_confirmation_text) {
      this.caseManager.default_receipt_confirmation_text = '';
    }
    if (!this.caseManager.default_closing_confirmation_text) {
      this.caseManager.default_closing_confirmation_text = '';
    }
    if (!this.caseManager.phone_number) {
      this.caseManager.phone_number = '';
    }
  }

  async ngOnInit() {
    // enable loading
    this.isLoading = true;

    // wait until auth is initialized
    const user = await this.firebaseAuth.getCurrentUser();

    // check initially if user is logged in
    if (user !== null) {
      // get firebase auth
      const claims = (await auth.currentUser.getIdTokenResult()).claims;

      // redirect user to login if not logged in
      if (
        claims.role !== 'case_manager' &&
        claims.role !== 'case_manager_admin'
      ) {
        // user is not logged in => redirect to login
        this.router.navigate(['management']);
      } else {
        this.extract(claims.lsp_id as string);
      }
    } else {
      // user is not logged in
      this.router.navigate(['/management/login']);
    }

    // possible roles
    this.translate
      .getTranslation(this.translate.currentLang)
      .subscribe((result) =>
        Object.keys(result).forEach((key) => {
          if (
            !key.startsWith(
              'case_management_portal.settings.first_panel.body.role_form_field.'
            )
          ) {
            return;
          }

          this.possibleRoles.push(
            key.replace(
              'case_management_portal.settings.first_panel.body.role_form_field.',
              ''
            )
          );
        })
      );
  }

  ngOnDestroy(): void {
    if (this.unsubscribe) {
      // unsubscribe from subscription
      this.unsubscribe();
    }
  }

  async updateCaseManagerDefaultReceiptConfirmationText(newText: string) {
    try {
      // enable loading
      this.isLoading = true;

      // empty success and error message
      this.defaultTextsSuccess = this.defaultTextsError = false;

      const trimmedStr = newText.trim();

      // update case manager doc in firestore with new default receipt confirmation text
      await updateDoc(this.docRef, {
        default_receipt_confirmation_text: trimmedStr
      });
      // show success
      this.defaultTextsSuccess = true;
      // disable loading
      this.isLoading = false;
    } catch (error) {
      // show error to user
      this.defaultTextsError = true;
      // disable loading
      this.isLoading = false;
    }
  }

  async updateCaseManagerDefaultClosingConfirmationText(newText: string) {
    try {
      // enable loading
      this.isLoading = true;

      // empty success and error message
      this.defaultTextsSuccess = this.defaultTextsError = false;

      const trimmedStr = newText.trim();

      // update case manager doc in firestore with new default closing confirmation text
      await updateDoc(this.docRef, {
        default_closing_confirmation_text: trimmedStr
      });
      // show success
      this.defaultTextsSuccess = true;
    } catch (error) {
      // show error to user
      this.defaultTextsError = true;
    }
    // disable loading
    this.isLoading = false;
  }

  async updateCaseManagerMultiFactor() {
    try {
      // enable loading
      this.isLoading = true;

      // clear success and error message
      this.updateMultiFactorSuccess = this.updateMultiFactorError = false;

      if (this.caseManager.is_multi_factor_user === false) {
        this.caseManager.country_code = undefined;
        this.caseManager.phone_number = undefined;
      }

      // firebase callable functions
      const updateUserMultiFactor = httpsCallable<
        {
          countryCode?: string;
          phoneNumber?: string;
        },
        { result: string; error?: FirebaseError }
      >(functions, 'updateUserMultiFactor');

      // update multi factor in functions
      const { data } = await updateUserMultiFactor({
        countryCode: this.caseManager.country_code,
        phoneNumber: this.caseManager.phone_number
      });

      if (!data.result) {
        throw data.error;
      }

      // show success to user
      this.updateMultiFactorSuccess = true;
    } catch (error) {
      // show error to user
      this.updateMultiFactorError = true;
    }

    // disable loading
    this.isLoading = false;
  }

  async updateUserPassword(password: string, newPassword: string) {
    try {
      // firebase callable functions
      const updateUserPassword = httpsCallable<
        { password: string; newPassword: string },
        { result: string; error?: FirebaseError }
      >(functions, 'updateUserPassword');

      // validate and update password in backend
      const { data } = await updateUserPassword({
        password: password,
        newPassword: newPassword
      });

      if (!data.result) {
        throw data.error;
      }

      // reset form
      this.changePasswordForm.reset({
        password: '',
        new_password: '',
        new_password2: ''
      });

      // password was successfully updated (show success message)
      this.translate
        .get(
          'case_management_portal.settings.fourth_panel.body.success_message.content'
        )
        .subscribe((res) => (this.changePasswordSuccessMessage = res));
    } catch (error) {
      // check error type
      if (error.message === 'The password is wrong.') {
        // provided user password was wrong
        this.translate
          .get(
            'case_management_portal.settings.fourth_panel.body.error_messages.wrong_current_password.content'
          )
          .subscribe((res) => (this.changePasswordErrorMessage = res));
      } else {
        // show general error
        this.translate
          .get(
            'case_management_portal.settings.fourth_panel.body.error_messages.general_error.content'
          )
          .subscribe((res) => (this.changePasswordErrorMessage = res));
      }
    }
    // disable loading
    this.isLoading = false;
  }

  // change password
  onFormSubmit() {
    // enable loading
    this.isLoading = true;

    // reset success and error messages
    this.changePasswordSuccessMessage = '';
    this.changePasswordErrorMessage = '';

    // get values of change password form
    const formValues = this.changePasswordForm.value;

    // check if new passwords match
    if (
      formValues.new_password !== formValues.new_password2 ||
      formValues.password === formValues.new_password
    ) {
      // display error to user
      this.translate
        .get(
          'case_management_portal.settings.fourth_panel.body.error_messages.passwords_dont_match.content'
        )
        .subscribe((result) => {
          this.changePasswordErrorMessage = result;
        });
      // disable loading
      this.isLoading = false;
    } else {
      // update password in functions (if password is correct)
      this.updateUserPassword(formValues.password, formValues.new_password);
    }
  }
}
