import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CaseManagementPortalComponent } from './case-management-portal/case-management-portal/case-management-portal.component';
import { CaseManagementPortalCaseComponent } from './case-management-portal/case-management-portal-case/case-management-portal-case.component';
import { CaseManagementPortalHomeComponent } from './case-management-portal/case-management-portal-home/case-management-portal-home.component';
import { CaseManagementPortalLoginComponent } from './case-management-portal/case-management-portal-login/case-management-portal-login.component';
import { CaseManagementPortalSettingsComponent } from './case-management-portal/case-management-portal-settings/case-management-portal-settings.component';
import { CaseCaseManagerListDialogComponent } from './case-management-portal/case-case-manager-list-dialog/case-case-manager-list-dialog.component';
import { ClientCaseManagerListDialogComponent } from './case-management-portal/client-case-manager-list-dialog/client-case-manager-list-dialog.component';
import { PdfCaseDocumentationDialogComponent } from './case-management-portal/pdf-case-documentation-dialog/pdf-case-documentation-dialog.component';
import { UpdateCaseConfirmationStateConfirmationDialogComponent } from './case-management-portal/update-case-confirmation-state-confirmation-dialog/update-case-confirmation-state-confirmation-dialog.component';
import { PrivacyComponent } from './shared/components/privacy/privacy.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { ImprintComponent } from './shared/components/imprint/imprint.component';
import { LogoutConfirmationDialogComponent } from './shared/components/logout-confirmation-dialog/logout-confirmation-dialog.component';
import { CaseReportingPortalComponent } from './case-reporting-portal/case-reporting-portal/case-reporting-portal.component';
import { CaseReportingPortalCaseComponent } from './case-reporting-portal/case-reporting-portal-case/case-reporting-portal-case.component';
import { CaseReportingPortalHomeComponent } from './case-reporting-portal/case-reporting-portal-home/case-reporting-portal-home.component';
import { CaseReportingPortalLoginComponent } from './case-reporting-portal/case-reporting-portal-login/case-reporting-portal-login.component';
import { CaseReportingPortalReportCaseComponent } from './case-reporting-portal/case-reporting-portal-report-case/case-reporting-portal-report-case.component';
import { CaseReportingPortalSelectCountryDialogComponent } from './case-reporting-portal/case-reporting-portal-select-country-dialog/case-reporting-portal-select-country-dialog.component';
import { AdminPortalComponent } from './admin-portal/admin-portal/admin-portal.component';
import { AdminPortalHomeComponent } from './admin-portal/admin-portal-home/admin-portal-home.component';
import { AdminPortalClientComponent } from './admin-portal/admin-portal-client/admin-portal-client.component';
import { AdminPortalLspComponent } from './admin-portal/admin-portal-lsp/admin-portal-lsp.component';
import { AdminPortalLoginComponent } from './admin-portal/admin-portal-login/admin-portal-login.component';
import { AdminPortalSettingsComponent } from './admin-portal/admin-portal-settings/admin-portal-settings.component';
import { AdminPortalLspPasswordDialogComponent } from './admin-portal/admin-portal-lsp-password-dialog/admin-portal-lsp-password-dialog.component';

// angular material design module imports
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRippleModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatMenuModule } from '@angular/material/menu';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDividerModule } from '@angular/material/divider';
import { MatCheckboxModule } from '@angular/material/checkbox';

// external module imports
import { RecaptchaModule } from 'ng-recaptcha';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LanguageMissingTranslationHandler } from './shared/data/missing-translation-handler';
import { NgxRerenderModule } from 'ngx-rerender';
import { NgxEchartsModule } from 'ngx-echarts';
import { DeleteCaseDialogComponent } from './case-management-portal/delete-case-dialog/delete-case-dialog.component';
import { CaseManagementPortalChartsComponent } from './case-management-portal/case-management-portal-charts/case-management-portal-charts.component';

// required for ngx-translate
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    CaseManagementPortalComponent,
    CaseManagementPortalCaseComponent,
    CaseManagementPortalHomeComponent,
    CaseManagementPortalLoginComponent,
    CaseManagementPortalSettingsComponent,
    CaseCaseManagerListDialogComponent,
    ClientCaseManagerListDialogComponent,
    PdfCaseDocumentationDialogComponent,
    DeleteCaseDialogComponent,
    UpdateCaseConfirmationStateConfirmationDialogComponent,
    PrivacyComponent,
    FooterComponent,
    ImprintComponent,
    LogoutConfirmationDialogComponent,
    CaseReportingPortalComponent,
    CaseReportingPortalCaseComponent,
    CaseReportingPortalHomeComponent,
    CaseReportingPortalLoginComponent,
    CaseReportingPortalReportCaseComponent,
    CaseReportingPortalSelectCountryDialogComponent,
    AdminPortalComponent,
    AdminPortalHomeComponent,
    AdminPortalClientComponent,
    AdminPortalLspComponent,
    AdminPortalLoginComponent,
    AdminPortalSettingsComponent,
    AdminPortalLspPasswordDialogComponent,
    CaseManagementPortalChartsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    ClipboardModule,
    MatIconModule,
    MatExpansionModule,
    MatTableModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatToolbarModule,
    MatStepperModule,
    MatSnackBarModule,
    MatTabsModule,
    RecaptchaModule,
    MatRippleModule,
    MatPaginatorModule,
    MatMenuModule,
    MatCheckboxModule,
    CKEditorModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatDividerModule,
    HttpClientModule,
    TranslateModule.forRoot({
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: LanguageMissingTranslationHandler
      },
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxRerenderModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
