import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  // service logs navigations to properly route to previous page within the app
  private history: string[] = [];

  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });
  }

  // go to last route or to landing page if last route was external
  back(): void {
    this.history.pop();
    if (this.history.length > 0) {
      history.back();
    } else {
      this.router.navigateByUrl('/');
    }
  }
}
