import { Component, NgZone, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { onAuthStateChanged } from 'firebase/auth';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { MyErrorStateMatcher } from 'src/app/shared/functions/errorStateMatcher';
import { FirebaseAuthService } from 'src/app/shared/services/firebase-auth.service';
import { auth, firestore } from 'src/firebase';
import { CaseReportingPortalComponent } from '../case-reporting-portal/case-reporting-portal.component';
import { Case } from 'src/app/shared/models/case.model';

@Component({
  selector: 'app-case-reporting-portal-login',
  templateUrl: './case-reporting-portal-login.component.html',
  styleUrls: ['./case-reporting-portal-login.component.scss']
})
export class CaseReportingPortalLoginComponent implements OnInit {
  // form variable
  loginForm: FormGroup;

  // error state matcher for form
  matcher = new MyErrorStateMatcher();

  // hide password at login form (default)
  hide = true;

  // message variables
  errorMessage?: string;

  // prevent duplicated api requests
  isLoading = false;

  // case data (loaded from api)
  case: Case;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    public caseReportingPortalComponent: CaseReportingPortalComponent,
    private firebaseAuth: FirebaseAuthService,
    private ngZone: NgZone,
    private translate: TranslateService
  ) {}

  // setup form
  setupForm() {
    this.loginForm = this.formBuilder.group({
      case_id: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  // save login log to firestore
  async logLogin(lsp_id: string, client_id: string, case_doc_id: string) {
    try {
      // add log doc
      await addDoc(
        collection(
          firestore,
          'legal_service_providers',
          lsp_id,
          'clients',
          client_id,
          'cases',
          case_doc_id,
          'case_log'
        ),
        {
          code: 'Whistleblower logged in.',
          created_at: serverTimestamp()
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  async ngOnInit() {
    // setup form
    this.setupForm();

    // wait until auth is initialized
    const user = await this.firebaseAuth.getCurrentUser();

    if (user !== null) {
      // get firebase auth
      const claims = (await auth.currentUser.getIdTokenResult()).claims;

      // wait until client id has loaded
      while (this.caseReportingPortalComponent.client.id === undefined) {
        await new Promise((resolve) => setTimeout(resolve, 100));
      }

      // redirect user to case if logged in
      if (
        claims.role == 'case_user' &&
        claims.client_id == this.caseReportingPortalComponent.client.id
      ) {
        // user is logged in => redirect to case
        this.router.navigate(['client', 'case']);
      }
    }

    // check if user is still logged in (subscribe to auth state)
    onAuthStateChanged(auth, async (user) => {
      if (user !== null) {
        // get users claims
        const claims = (await auth.currentUser.getIdTokenResult()).claims;

        // redirect user to case if logged in
        if (
          claims.role === 'case_user' &&
          claims.client_id === this.caseReportingPortalComponent.client.id
        ) {
          // user is logged in => redirect to case
          this.ngZone.run(() => this.router.navigate(['client', 'case']));
        }
      }
    });
  }

  // login case user with credentials
  async login(email: string, password: string) {
    try {
      // sign in user
      await this.firebaseAuth.signInUser(email, password);

      // check if user is case user
      const claims = (await auth.currentUser.getIdTokenResult()).claims;

      if (
        claims.role !== 'case_user' ||
        claims.client_id !== this.caseReportingPortalComponent.client.id
      ) {
        // user is not a case user or not a case user of this client
        this.firebaseAuth.signOutUser();
        // display error message
        this.translate
          .get(
            'case_reporting_portal.login.error_messages.wrong_case_id_or_password.content'
          )
          .subscribe((res: string) => {
            this.errorMessage = res;
          });
      } else {
        // log case user login
        await this.logLogin(
          this.caseReportingPortalComponent.client.lsp_id,
          this.caseReportingPortalComponent.client.id,
          auth.currentUser.uid
        );

        // redirect case user to case
        this.router.navigate(['client', 'case']);
      }
    } catch (error) {
      console.error(error);
      // logout user in case already logged in
      this.firebaseAuth.signOutUser();
      // display error message
      this.translate
        .get(
          'case_reporting_portal.login.error_messages.wrong_case_id_or_password.content'
        )
        .subscribe((res: string) => {
          this.errorMessage = res;
        });
    }
  }

  async onFormSubmit() {
    // activate loading var
    this.isLoading = true;

    // clear error message
    this.errorMessage = '';

    // retrieve form data
    const loginCredentials = this.loginForm.value;

    // format email
    const email = loginCredentials.case_id + '@integribox.de';

    // login user
    await this.login(email, loginCredentials.password);

    // deactivate loading var if login failed
    this.isLoading = false;
  }
}
