import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UpdateCaseConfirmationStateConfirmationData } from 'src/app/shared/interfaces/caseManagementCase';

@Component({
  selector: 'app-update-case-confirmation-state-confirmation-dialog',
  templateUrl:
    './update-case-confirmation-state-confirmation-dialog.component.html',
  styleUrls: [
    './update-case-confirmation-state-confirmation-dialog.component.scss'
  ]
})
export class UpdateCaseConfirmationStateConfirmationDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: UpdateCaseConfirmationStateConfirmationData
  ) {}
}
