import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FirebaseError } from 'firebase/app';
import { httpsCallable } from 'firebase/functions';
import { Observable, startWith, map } from 'rxjs';
import { DialogClientCaseManagerListData } from 'src/app/shared/interfaces/caseManagementHome';
import { CaseManager } from 'src/app/shared/models/caseManager.model';
import { functions } from 'src/firebase';

@Component({
  selector: 'app-client-case-manager-list-dialog',
  templateUrl: './client-case-manager-list-dialog.component.html',
  styleUrls: ['./client-case-manager-list-dialog.component.scss']
})
export class ClientCaseManagerListDialogComponent {
  // loading animation indicator variable
  isLoading = false;

  // success and error messages
  success = false;
  error = false;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  managerCtrl = new FormControl();
  filteredCaseManager: Observable<CaseManager[]>;
  selectCaseManager = this.data.client.case_manager;
  caseManager: CaseManager[] = [];
  allCaseManager: CaseManager[] = this.data.caseManager;

  @ViewChild('managerInput') managerInput: ElementRef<HTMLInputElement>;

  constructor(
    public dialogRef: MatDialogRef<ClientCaseManagerListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogClientCaseManagerListData
  ) {
    for (let i = 0; i < this.selectCaseManager.length; i++) {
      const manager = this.allCaseManager.find(
        (manager) => manager.id === this.selectCaseManager[i].id
      );
      this.caseManager.push(manager);
    }

    this.filteredCaseManager = this.managerCtrl.valueChanges.pipe(
      startWith(null),
      map((manager: CaseManager) =>
        manager ? this._filter(manager) : this.allCaseManager.slice()
      )
    );
  }

  add(event: MatChipInputEvent): void {
    // Clear the input value
    event.chipInput!.clear();
    this.managerCtrl.setValue(null);
  }

  async remove(manager: CaseManager) {
    const index = this.caseManager.indexOf(manager);

    if (index >= 0 && manager.role === 'case_manager') {
      await this.updateClientCaseManagerList(manager, 'remove', index);
    }
  }

  async selected(event: MatAutocompleteSelectedEvent) {
    const found = this.caseManager.find(
      (manager: CaseManager) => manager.id === event.option.value.id
    );

    if (!found) {
      await this.updateClientCaseManagerList(event.option.value, 'add');
    }

    this.managerInput.nativeElement.value = '';
    this.managerCtrl.setValue(null);
  }

  private _filter(manager: CaseManager): CaseManager[] {
    const filterValue = manager.name.toLowerCase();

    return this.allCaseManager.filter((manager) =>
      manager.name.toLowerCase().includes(filterValue)
    );
  }

  async updateClientCaseManagerList(
    manager: CaseManager,
    change: 'add' | 'remove',
    managerIndex?: number
  ) {
    // enable loading
    this.isLoading = true;

    // clear success and error message
    this.success = this.error = false;

    try {
      // update case manager in client
      const updateInClientCaseManager = {
        clientId: this.data.client.id,
        caseManagerId: manager.id
      };

      if (change === 'add') {
        const addClientCaseManagerFunction = httpsCallable<
          { clientId: string; caseManagerId: string },
          { manager: CaseManager; error?: FirebaseError }
        >(functions, 'addClientCaseManager');

        const { data } = await addClientCaseManagerFunction(
          updateInClientCaseManager
        );

        if (!data.manager) {
          throw data.error;
        }

        this.caseManager.push(manager);
      } else if (change === 'remove') {
        const removeClientCaseManagerFunction = httpsCallable<
          { clientId: string; caseManagerId: string },
          { result: string; error?: FirebaseError }
        >(functions, 'removeClientCaseManager');

        const { data } = await removeClientCaseManagerFunction(
          updateInClientCaseManager
        );

        if (!data.result) {
          throw data.error;
        }

        this.caseManager.splice(managerIndex, 1);
      }

      // show success to user
      this.success = true;
    } catch (error) {
      // show error to user
      this.error = true;
    }

    // disable loading
    this.isLoading = false;
  }
}
