import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  FormGroupDirective,
  FormGroup,
  Validators,
  FormBuilder
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FirebaseError } from 'firebase/app';
import { User, onAuthStateChanged } from 'firebase/auth';
import { query, collection, where, onSnapshot } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { MyErrorStateMatcher } from 'src/app/shared/functions/errorStateMatcher';
import { applyFilter } from 'src/app/shared/functions/tableFiltering';
import { CaseManager } from 'src/app/shared/models/caseManager.model';
import { Client } from 'src/app/shared/models/client.model';
import { FirebaseAuthService } from 'src/app/shared/services/firebase-auth.service';
import { auth, functions, firestore } from 'src/firebase';
import { CaseManagementPortalComponent } from '../case-management-portal/case-management-portal.component';
import { ClientCaseManagerListDialogComponent } from '../client-case-manager-list-dialog/client-case-manager-list-dialog.component';
import { EChartsOption } from 'echarts';
import * as echarts from 'echarts';
import { AgendaTaskCase, Case } from 'src/app/shared/models/case.model';
import {
  formatCreated,
  formatDeadline
} from 'src/app/shared/functions/dateFormat';
import { FirebaseTimestamp } from 'src/app/shared/models/firebaseTimestamp.model';

@Component({
  selector: 'app-case-management-portal-home',
  templateUrl: './case-management-portal-home.component.html',
  styleUrls: ['./case-management-portal-home.component.scss']
})
export class CaseManagementPortalHomeComponent implements OnInit {
  // make auth accessible for template
  auth = auth;

  // cases array
  cases: Case[];

  // lsp id
  lspId: string;

  // case managers
  caseManager: CaseManager[] = [];

  // var for case filtering by state (default: show active cases)
  caseStateFilter: string;

  // case manager object
  caseManagerRole: string;

  aloneCaseManagers: boolean = false;

  // angular material agenda table setup
  displayedColumnsAgenda: string[] = ['deadline', 'client', 'task', 'view'];
  agendaTasksDataSource = new MatTableDataSource();

  // angular material cases table setup
  displayedColumns: string[] = ['client', 'created', 'state', 'record', 'view'];
  dataSource = new MatTableDataSource();

  // angular material clients table setup
  showTable = true;
  clientsDisplayedColumns: string[] = ['name', 'link', 'view_analytics'];
  clientsDataSource = new MatTableDataSource();

  // angular material case manager table setup
  caseManagerTableDisplayedColumns: string[] = [
    'name',
    'email',
    'role',
    'is_multi_factor_user',
    'de_activate'
  ];
  caseManagerDataSource = new MatTableDataSource();

  // angular material clients admin table setup
  clientsAdminDisplayedColumns: string[] = ['name', 'case_manager_list'];
  clientsAdminDataSource = new MatTableDataSource();

  // clear form
  @ViewChild(FormGroupDirective) formDirective: FormGroupDirective;

  // add case manager form
  addCaseManagerForm: FormGroup = this.formBuilder.group({
    caseManagerName: ['', Validators.required],
    caseManagerEmail: [
      '',
      Validators.compose([Validators.email, Validators.required])
    ],
    caseManagerPassword: [
      '',
      Validators.compose([
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(100)
      ])
    ]
  });

  hide = false;

  // success and error messages for case manager creation
  addCaseManagerSuccessMessage = '';
  addCaseManagerErrorMessage = '';

  // error state matcher for form
  matcher = new MyErrorStateMatcher();

  // loading animation indicator variable
  isLoading = false;

  // host parts for link of clients reporting portal
  hostParts = location.hostname.split('.');

  options: EChartsOption;
  options2: EChartsOption;

  // charts
  activeText = '';
  doneText = '';
  deposedText = '';
  casesText = '';
  reportsText = '';

  // translate chart
  categoriesTitle = '';
  categoriesSubtitle = '';
  acquisition = '';
  finance = '';
  production = '';
  staff = '';
  other = '';
  notRelevant = '';
  noCategory = '';

  // chart
  @ViewChild('chart1') chart1Element: ElementRef;
  @ViewChild('chart2') chart2Element: ElementRef;

  chart1: echarts.EChartsType;
  chart2: echarts.EChartsType;

  constructor(
    public caseManagementPortalComponent: CaseManagementPortalComponent,
    private router: Router,
    private firebaseAuth: FirebaseAuthService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    public translate: TranslateService,
    public cdr: ChangeDetectorRef
  ) {
    this.translate.onLangChange.subscribe(() => {
      cdr.detectChanges();

      // updating charts with new language
      void this.getCases();
    });
  }

  // get cases of case manager (admin)
  async getCases() {
    // enable loading
    this.isLoading = true;
    try {
      const getCasesByCaseManager = httpsCallable<
        Record<string, never>,
        { cases: Case[]; error?: FirebaseError }
      >(functions, 'getCasesByCaseManager');

      const { data } = await getCasesByCaseManager();

      if (!data.cases) {
        // differentiate between errors (handle following errors: account with email exists)
        throw data.error;
      }

      // clear cases
      this.cases = [];

      // options
      let active = 0;
      let done = 0;
      let deposed = 0;

      // options 2
      let staff = 0;
      let acquisition = 0;
      let production = 0;
      let finance = 0;
      let other = 0;
      let notRelevant = 0;

      // format cases
      for (let i = 0; i < data.cases.length; i++) {
        const created = new Date(
          (data.cases[i].created as FirebaseTimestamp)._seconds * 1000
        );
        data.cases[i].created_formatted = formatCreated(
          created,
          this.translate.currentLang
        );

        if (data.cases[i].state === 'active') {
          active++;
        } else if (data.cases[i].state === 'done') {
          done++;
        } else {
          deposed++;
        }

        if (data.cases[i].category) {
          const category = data.cases[i].category.split('_')[0];
          switch (category) {
            case 'staff':
              staff++;
              break;
            case 'acquisition':
              acquisition++;
              break;
            case 'production':
              production++;
              break;
            case 'finance':
              finance++;
              break;
            case 'other':
              other++;
              break;
            case 'not-relevant':
              notRelevant++;
              break;

            default:
              break;
          }
        }

        // add case to cases array
        this.cases.push(data.cases[i]);
      }

      if (
        data.cases.length &&
        data.cases[0].client_case_manager_uids.length === 1
      ) {
        this.aloneCaseManagers = true;
      }

      this.translate
        .get('case_management_portal.home.charts.cases_companies.content')
        .subscribe((res) => {
          this.casesText = res;
        });

      this.translate
        .get(
          'case_management_portal.home.cases_box.cases_table.case_states.active.content'
        )
        .subscribe((res) => (this.activeText = res));

      this.translate
        .get(
          'case_management_portal.home.cases_box.cases_table.case_states.deposed.content'
        )
        .subscribe((res) => (this.deposedText = res));

      this.translate
        .get(
          'case_management_portal.home.cases_box.cases_table.case_states.done.content'
        )
        .subscribe((res) => (this.doneText = res));

      this.options = {
        legend: {},
        tooltip: {},
        toolbox: {
          feature: {
            // saveAsImage: {},
          }
        },
        dataset: {
          // Provide a set of data.
          source: [
            ['product', this.activeText, this.doneText, this.deposedText],
            [this.casesText, active, done, deposed]
          ]
        },
        // Declare an x-axis (category axis).
        // The category map the first column in the dataset by default.
        xAxis: { type: 'category' },
        // Declare a y-axis (value axis).
        yAxis: {},
        // Declare several 'bar' series,
        // every series will auto-map to each column by default.
        series: [{ type: 'bar' }, { type: 'bar' }, { type: 'bar' }]
      };

      this.translate
        .get(
          'case_management_portal.case.case_overview_page.report_form.record_category_form.category_form_field.acquisition'
        )
        .subscribe((res) => (this.acquisition = res));

      this.translate
        .get(
          'case_management_portal.case.case_overview_page.report_form.record_category_form.category_form_field.finance'
        )
        .subscribe((res) => (this.finance = res));

      this.translate
        .get(
          'case_management_portal.case.case_overview_page.report_form.record_category_form.category_form_field.production'
        )
        .subscribe((res) => (this.production = res));

      this.translate
        .get(
          'case_management_portal.case.case_overview_page.report_form.record_category_form.category_form_field.staff'
        )
        .subscribe((res) => (this.staff = res));

      this.translate
        .get(
          'case_management_portal.case.case_overview_page.report_form.record_category_form.category_form_field.other_option.content'
        )
        .subscribe((res) => (this.other = res));

      this.translate
        .get(
          'case_management_portal.case.case_overview_page.report_form.record_category_form.category_form_field.not_relevant_option.content'
        )
        .subscribe((res) => (this.notRelevant = res));

      this.translate
        .get(
          'case_management_portal.case.case_overview_page.report_form.record_category_form.no_category'
        )
        .subscribe((res) => (this.noCategory = res));

      this.translate
        .get('case_management_portal.home.charts.categories.subtitle.content')
        .subscribe((res) => (this.categoriesSubtitle = res));

      this.options2 = {
        title: {
          left: '50%',
          // text: this.categoriesTitle,
          subtext: this.categoriesSubtitle,
          textAlign: 'center'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b}: {c} ({d}%)'
        },
        calculable: true,
        series: [
          {
            type: 'pie',
            radius: [30, 110],
            roseType: 'area',
            data: [
              { value: acquisition, name: this.acquisition },
              { value: finance, name: this.finance },
              { value: production, name: this.production },
              { value: staff, name: this.staff },
              { value: other, name: this.other },
              { value: notRelevant, name: this.notRelevant }
            ]
          }
        ]
      };

      this.translate.onLangChange.subscribe(() => {
        this.cases.forEach((caseData: Case) => {
          caseData.created = formatCreated(
            caseData.created as Date,
            this.translate.currentLang
          );
        });
      });

      // calculate agenda
      this.calculateAgendaList(this.cases);

      this.dataSource = new MatTableDataSource(this.cases);

      // update table (show active cases by default)
      this.caseStateFilter = 'active';
      this.filterCasesByState();

      // Initialize your charts in the ngAfterViewInit lifecycle hook
      this.chart1 = echarts.init(this.chart1Element.nativeElement);
      this.chart2 = echarts.init(this.chart2Element.nativeElement);

      // disable loading
      this.isLoading = false;
    } catch (error) {
      console.log(error);
    }
  }

  // get clients of case manager
  getClients(lspId: string) {
    try {
      // format user for array query
      const user = {
        id: auth.currentUser.uid,
        name: auth.currentUser.displayName,
        email: auth.currentUser.email
      };
      // define query
      const q = query(
        collection(firestore, 'legal_service_providers', lspId, 'clients'),
        where('is_active', '==', true),
        where('case_manager', 'array-contains', user)
      );
      // listen to realtime updates
      onSnapshot(q, (querySnapshot) => {
        const clients = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          data.id = doc.id;
          clients.push(data);
        });

        // array of strings alphabetically
        clients.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });

        // update clients table
        this.clientsDataSource = new MatTableDataSource(clients);
      });
    } catch (error) {
      console.error(error);
    }
  }

  // get all clients of lsp
  getClientsAdmin(lspId: string) {
    try {
      // define query
      const q = query(
        collection(firestore, 'legal_service_providers', lspId, 'clients'),
        where('is_active', '==', true)
      );
      // listen to realtime updates
      onSnapshot(q, (querySnapshot) => {
        const clients = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          data.id = doc.id;
          clients.push(data);
        });

        // array of strings alphabetically
        clients.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });

        // update clients table
        this.clientsAdminDataSource = new MatTableDataSource(clients);
      });
    } catch (error) {
      console.error(error);
    }
  }

  // calculate agenda
  calculateAgendaList(cases: Case[]) {
    // confirmation_state = none => deadline = created + 7 days;
    // confirmation_state = receipt_confirmed => deadline = receipt confirmed (default after 1 week) + 3 months
    const agendaTasks = [];
    // format date for later comparison
    const currentDate = new Date();
    const currentDay = Date.UTC(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate()
    );

    // loop through cases to calculate agenda
    cases.forEach((caseDoc: Case) => {
      if (caseDoc.confirmation_state === 0) {
        // calculate deadline (+7 days)
        const deadline = new Date(
          caseDoc.receipt_confirmation_date._seconds * 1000
        );

        // format deadline
        const deadlineFormatted = deadline.toLocaleDateString(
          this.translate.currentLang,
          {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
          }
        );

        // check for highlight
        let highlighted = false;

        if (
          Date.UTC(
            deadline.getFullYear(),
            deadline.getMonth(),
            deadline.getDate()
          ) <= currentDay
        ) {
          highlighted = true;
        }

        // format agenda task
        const agendaTask: AgendaTaskCase = {
          deadline: deadline,
          deadlineFormatted: deadlineFormatted,
          task: 1,
          case: caseDoc,
          highlighted: highlighted
        };
        // add agenda task to agenda tasks array
        agendaTasks.push(agendaTask);
      }
      if (
        caseDoc.confirmation_state === 0 ||
        caseDoc.confirmation_state === 1
      ) {
        // calculate deadline (+3 months)
        const deadline = new Date(
          caseDoc.receipt_confirmation_date._seconds * 1000
        );
        deadline.setMonth(deadline.getMonth() + 3);

        // check for highlight
        let highlighted = false;

        if (
          Date.UTC(
            deadline.getFullYear(),
            deadline.getMonth(),
            deadline.getDate()
          ) <= currentDay
        ) {
          highlighted = true;
        }

        // format agenda task
        const agendaTask: AgendaTaskCase = {
          deadline: deadline,
          deadlineFormatted: formatDeadline(
            deadline,
            this.translate.currentLang
          ),
          task: 2,
          case: caseDoc,
          highlighted: highlighted
        };
        // add agenda task to agenda tasks array
        agendaTasks.push(agendaTask);
      }
    });
    // sort tasks by date
    agendaTasks.sort(function (a, b) {
      return a.deadline - b.deadline;
    });
    // update agenda tasks table
    this.agendaTasksDataSource = new MatTableDataSource(agendaTasks);
  }

  // get all case manager of legal service provider
  getCaseManager(lspId: string) {
    try {
      // define query
      const q = query(
        collection(firestore, 'legal_service_providers', lspId, 'case_manager')
      );
      // listen to realtime updates
      onSnapshot(q, (querySnapshot) => {
        this.caseManager = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          const manager: CaseManager = {
            id: doc.id,
            name: data.name,
            email: data.email,
            role: data.role,
            is_multi_factor_user: data.is_multi_factor_user,
            is_active: data.is_active
          };
          this.caseManager.push(manager);
        });

        // array of strings alphabetically
        this.caseManager.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });

        // update clients table
        this.caseManagerDataSource = new MatTableDataSource(this.caseManager);
      });
    } catch (error) {
      console.error(error);
    }
  }

  async extract(lspId: string) {
    // host parts at client reporting portal
    if (this.hostParts[0] === 'localhost') {
      this.hostParts[1] = this.hostParts[0];
      this.hostParts[2] = 'com';
    }

    // get clients of case manager
    this.getClients(lspId);

    if (this.caseManagerRole === 'case_manager_admin') {
      // get all clients of lsp
      this.getClientsAdmin(lspId);
      // get case manager of legal service provider
      this.getCaseManager(lspId);
    }

    // get cases of case manager
    await this.getCases();

    // scroll
    window.scroll(0, 0);

    // disable loading
    this.isLoading = false;
  }

  async ngOnInit() {
    // enable loading
    this.isLoading = true;

    // wait until auth is initialized
    const user = await this.firebaseAuth.getCurrentUser();

    // check initially if user is logged in
    if (user !== null) {
      // get firebase auth
      const claims = (await auth.currentUser.getIdTokenResult()).claims;

      // redirect user to login if not logged in
      if (
        claims.role !== 'case_manager' &&
        claims.role !== 'case_manager_admin'
      ) {
        // user is not logged in => redirect to login
        this.router.navigate(['management/login']);
      } else {
        this.caseManagerRole = claims.role;
        this.lspId = claims.lsp_id as string;
        this.extract(this.lspId);
      }
    } else {
      // user is not logged in
      this.router.navigate(['management/login']);
    }

    // check if user is still logged in (subscribe to auth state)
    onAuthStateChanged(auth, async (user: User) => {
      if (user !== null) {
        // get firebase auth
        const claims = (await auth.currentUser.getIdTokenResult()).claims;

        // redirect user to case if logged in
        if (
          claims.role !== 'case_manager' &&
          claims.role !== 'case_manager_admin'
        ) {
          // user is not logged in => redirect to login
          this.router.navigate(['management', 'login']);
        }
      } else {
        // user is not logged in
        this.router.navigate(['management', 'login']);
      }
    });
  }

  // create case manager account
  async createCaseManagerAccount() {
    try {
      // enable loading
      this.isLoading = true;

      // clear success and error messages
      this.addCaseManagerSuccessMessage = this.addCaseManagerErrorMessage = '';

      // retrieve form data
      const { caseManagerName, caseManagerEmail, caseManagerPassword } =
        this.addCaseManagerForm.value;

      const createCaseManager = httpsCallable<
        {
          lspId: string;
          caseManagerName: string;
          caseManagerEmail: string;
          caseManagerPassword: string;
        },
        { uid: string; error?: FirebaseError }
      >(functions, 'createCaseManager');

      const { data } = await createCaseManager({
        lspId: this.lspId,
        caseManagerName: caseManagerName,
        caseManagerEmail: caseManagerEmail.toLowerCase(),
        caseManagerPassword: caseManagerPassword
      });

      if (!data.uid) {
        // differentiate between errors (handle following errors: account with email exists)
        throw data;
      }

      // clear form
      this.formDirective.resetForm();
      this.addCaseManagerForm.reset();

      // display success
      this.translate
        .get(
          'case_management_portal.home.lsp_box.add_case_manager_panel.success_message.content'
        )
        .subscribe((res) => (this.addCaseManagerSuccessMessage = res));
    } catch (error) {
      // print error to user (depending on error)
      if (error.code === 'failed-precondition') {
        this.translate
          .get(
            'case_management_portal.home.lsp_box.add_case_manager_panel.email_already_exists_error_message.content'
          )
          .subscribe((res) => (this.addCaseManagerErrorMessage = res));
      } else {
        this.translate
          .get(
            'case_management_portal.home.lsp_box.add_case_manager_panel.error_message.content'
          )
          .subscribe((res) => (this.addCaseManagerErrorMessage = res));
      }
    }
    // disable loading
    this.isLoading = false;

    setTimeout(() => {
      document.getElementById('adminSettings').scrollIntoView();
    }, 100);
  }

  async updateCaseManagerActiveState(caseManager: CaseManager) {
    try {
      // enable loading
      this.isLoading = true;

      // callable backend function
      const deActivateCaseManagerUser = httpsCallable<
        { caseManagerUid: string },
        { result: string; error?: FirebaseError }
      >(functions, 'deActivateCaseManagerUser');

      // (de) activate case manager account
      const { data } = await deActivateCaseManagerUser({
        caseManagerUid: caseManager.id
      });

      if (!data.result) {
        throw data.error;
      }

      this.getCases();
    } catch (error) {
      // reset active of case manager active state
      const caseManagerArray: CaseManager[] = this.caseManagerDataSource
        .data as CaseManager[];
      const i = caseManagerArray.findIndex(
        (x: CaseManager) => x.id === caseManager.id
      );
      caseManagerArray[i].is_active = !caseManagerArray[i].is_active;
    }
    // disable loading
    this.isLoading = false;
  }

  // case state filtering
  filterCasesByState() {
    // filter cases array
    this.dataSource = new MatTableDataSource(
      this.cases.filter((x: Case) => x.state === this.caseStateFilter)
    );
  }

  // table filtering
  filterCases(event: Event) {
    this.dataSource.filter = applyFilter(event);
  }

  filterClients(event: Event) {
    this.clientsDataSource.filter = applyFilter(event);
  }

  filterClientsAdmin(event: Event) {
    this.clientsAdminDataSource.filter = applyFilter(event);
  }

  filterCaseManager(event: Event) {
    this.caseManagerDataSource.filter = applyFilter(event);
  }

  // list all case manager of company
  openClientCaseManagerListDialog(client: Client) {
    const caseManagersActive = this.caseManager.filter(
      (manager) => manager.is_active === true
    );
    this.dialog.open(ClientCaseManagerListDialogComponent, {
      minWidth: '400px',
      data: {
        lspId: this.lspId,
        caseManager: caseManagersActive,
        client: client,
        language: this.translate.currentLang
      },
      disableClose: true
    });
  }
}
