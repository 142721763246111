<div class="cover-spin" *ngIf="isLoading"></div>
<mat-card *ngIf="client" class="mt-5 d-block m-auto">
  <div
    class="h4 mb-1"
    [innerHTML]="'admin_portal.client.heading.content' | translate"
  ></div>
  <p
    class="mb-4 small"
    [innerHTML]="
      ('admin_portal.client.subheading.first_part.content' | translate) +
      client.name +
      ('admin_portal.client.subheading.second_part.content' | translate)
    "
  ></p>

  <!-- Name of client -->
  <mat-form-field class="w-100" appearance="outline">
    <mat-label
      [innerHTML]="
        'admin_portal.client.client_panel.name_form_field.label.content'
          | translate
      "
    ></mat-label>
    <input matInput disabled [value]="client.name" />
  </mat-form-field>

  <!-- Name short of client -->
  <mat-form-field class="w-100" appearance="outline">
    <mat-label
      [innerHTML]="
        'admin_portal.client.client_panel.name_short_form_field.label.content'
          | translate
      "
    ></mat-label>
    <input matInput disabled [value]="client.name_short" />
  </mat-form-field>

  <!-- Subdomain of client -->
  <mat-form-field class="w-100" appearance="outline">
    <mat-label
      [innerHTML]="
        'admin_portal.client.client_panel.subdomain_form_field.label.content'
          | translate
      "
    ></mat-label>
    <input matInput disabled [value]="client.subdomain" />
  </mat-form-field>

  <form [formGroup]="editClientForm" (ngSubmit)="updateClient()">
    <div class="row">
      <div class="col-12 col-md-6">
        <!-- Shared client indicator -->
        <mat-form-field appearance="outline">
          <mat-label
            [innerHTML]="
              'admin_portal.client.client_panel.sub_clients_selection.label.content'
                | translate
            "
          ></mat-label>

          <mat-select formControlName="is_shared">
            <mat-option [value]="false">{{
              'admin_portal.client.client_panel.sub_clients_selection.responses.no_selection.content'
                | translate
            }}</mat-option>
            <mat-option [value]="true">{{
              'admin_portal.client.client_panel.sub_clients_selection.responses.yes_selection.content'
                | translate
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div
        class="col-12 col-md-6"
        *ngIf="editClientForm.value.is_shared === true"
      >
        <mat-form-field appearance="outline">
          <mat-label
            [innerHTML]="
              'admin_portal.client.client_panel.sub_clients_form_field.label.content'
                | translate
            "
          ></mat-label>
          <mat-chip-grid #chipList>
            <mat-chip-row
              *ngFor="let firm of firms"
              (removed)="removeClientSharedFirm(firm)"
              [editable]="false"
            >
              {{ firm }}
              <button matChipRemove *ngIf="removable">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
            <input
              [placeholder]="
                'admin_portal.client.client_panel.sub_clients_form_field.add_firm_placeholder.content'
                  | translate
              "
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="addOnBlur"
              (matChipInputTokenEnd)="addClientSharedFirm($event)"
            />
          </mat-chip-grid>
        </mat-form-field>
      </div>
    </div>

    <mat-form-field appearance="outline">
      <mat-label
        [innerHTML]="
          'admin_portal.client.client_panel.languages.label.content' | translate
        "
      ></mat-label>

      <mat-chip-grid
        formControlName="languages"
        #chipList
        aria-label="Languages selection"
      >
        <mat-chip-row
          *ngFor="let lang of languages"
          (removed)="removeLang(lang)"
        >
          {{ 'languages.' + lang | translate }}
          <button
            matChipRemove
            *ngIf="removable"
            [attr.aria-label]="'remove ' + lang"
          >
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
      </mat-chip-grid>

      <input
        placeholder="{{
          'admin_portal.client.client_panel.languages.placeholder.content'
            | translate
        }}"
        [formControl]="langCtrl"
        [matChipInputFor]="chipList"
        [matAutocomplete]="auto"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      />

      <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="selectedLang($event)"
      >
        <mat-option
          *ngFor="let lang of filteredLanguages | async"
          [value]="lang"
        >
          {{ 'languages.' + lang | translate }}
        </mat-option>
      </mat-autocomplete>
      <mat-error>
        <span
          *ngIf="!languages"
          [innerHTML]="
            'admin_portal.client.client_panel.languages.error_message.content'
              | translate
          "
        ></span>
      </mat-error>
    </mat-form-field>

    <!-- Default language of client -->
    <mat-form-field class="w-100" appearance="outline">
      <mat-label
        [innerHTML]="
          'admin_portal.client.client_panel.default_language_form_field.label.content'
            | translate
        "
      ></mat-label>
      <mat-select formControlName="default_language">
        <mat-option *ngFor="let lang of languages" [value]="lang">
          {{ 'languages.' + lang | translate }}
        </mat-option>
      </mat-select>
      <mat-error>
        <span
          *ngIf="
            !editClientForm.get('default_language').valid &&
            editClientForm.get('default_language').touched
          "
          [innerHTML]="
            'admin_portal.client.client_panel.default_language_form_field.error_message.content'
              | translate
          "
        ></span>
      </mat-error>
    </mat-form-field>

    <!-- State of client -->
    <mat-form-field class="w-100" appearance="outline">
      <mat-label
        [innerHTML]="
          'admin_portal.client.client_panel.is_active_form_field.label.content'
            | translate
        "
      ></mat-label>
      <mat-select formControlName="is_active">
        <mat-option [value]="true">
          {{
            'admin_portal.client.client_panel.is_active_form_field.options.true'
              | translate
          }}
        </mat-option>
        <mat-option [value]="false">
          {{
            'admin_portal.client.client_panel.is_active_form_field.options.false'
              | translate
          }}
        </mat-option>
      </mat-select>
      <mat-error>
        <span
          *ngIf="
            !editClientForm.get('is_active').valid &&
            editClientForm.get('is_active').touched
          "
          [innerHTML]="
            'admin_portal.client.client_panel.is_active_form_field.error_message.content'
              | translate
          "
        ></span>
      </mat-error>
    </mat-form-field>

    <hr />
    <p
      class="h6 mb-4"
      [innerHTML]="
        'admin_portal.client.several_texts_panel.heading.content'
          | translate: client
      "
    ></p>

    <div class="mb-4">
      <mat-accordion class="">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title
              [innerHTML]="
                'admin_portal.client.client_panel.translate_text_panel.translate.content'
                  | translate
              "
            ></mat-panel-title>
          </mat-expansion-panel-header>
          <div>
            <form
              [formGroup]="translateTextForm"
              (ngSubmit)="translateTexts('reporting_portal_several_texts')"
            >
              <div class="row align-items-center">
                <div class="col-12 col-md">
                  <mat-form-field appearance="outline">
                    <mat-label
                      [innerHTML]="
                        'admin_portal.client.client_panel.translate_text_panel.translate_from.label.content'
                          | translate
                      "
                    ></mat-label>

                    <mat-select formControlName="translate_from">
                      <mat-option [value]="'template'">{{
                        'admin_portal.client.client_panel.translate_text_panel.translate_from.template.content'
                          | translate
                      }}</mat-option>
                      <mat-divider class="my-2"></mat-divider>
                      <mat-option
                        *ngFor="let lang of languages"
                        [value]="lang"
                        >{{ 'languages.' + lang | translate }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-12 col-md-1 text-center">
                  <mat-icon class="menu-icon">arrow_forward</mat-icon>
                </div>
                <div class="col-12 col-md">
                  <mat-form-field appearance="outline">
                    <mat-label
                      [innerHTML]="
                        'admin_portal.client.client_panel.translate_text_panel.translate_to.label.content'
                          | translate
                      "
                    ></mat-label>

                    <mat-select formControlName="translate_to" multiple>
                      <mat-option
                        *ngFor="let lang of languages"
                        [disabled]="
                          this.translateTextForm.value.translate_from === lang
                        "
                        [value]="lang"
                        >{{ 'languages.' + lang | translate }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-12">
                  <button
                    type="submit"
                    class="w-100"
                    mat-raised-button
                    [disabled]="isLoading || !translateTextForm.valid"
                    color="primary"
                    [innerHTML]="
                      'admin_portal.client.client_panel.translate_text_panel.translate_submit_button.content'
                        | translate
                    "
                  ></button>
                </div>

                <p
                  *ngIf="translateTextSuccess"
                  class="success mt-3 text-center pt-0"
                  [innerHTML]="
                    'admin_portal.client.client_panel.translate_text_panel.translate_success_message.content'
                      | translate
                  "
                ></p>
                <p
                  *ngIf="translateTextError"
                  class="error mt-3 text-center pt-0"
                  [innerHTML]="
                    'admin_portal.client.client_panel.translate_text_panel.translate_error_message.content'
                      | translate
                  "
                ></p>
              </div>
            </form>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <!-- Reporting portal several texts -->
    <mat-accordion>
      <mat-expansion-panel *ngFor="let lang of languages">
        <mat-expansion-panel-header>
          <mat-panel-title
            [innerHTML]="'languages.' + lang | translate"
          ></mat-panel-title>
        </mat-expansion-panel-header>
        <div>
          <ckeditor
            [editor]="this.editor"
            [config]="this.ckeditorConfigAdminClient"
            formControlName="{{
              'reporting_portal_text_' + lang.toLowerCase()
            }}"
          ></ckeditor>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <hr />
    <p
      class="h6 mb-4"
      [innerHTML]="
        'admin_portal.client.report_texts_panel.heading.content'
          | translate: client
      "
    ></p>

    <div class="mb-4">
      <mat-accordion class="">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title
              [innerHTML]="
                'admin_portal.client.client_panel.translate_text_panel.translate.content'
                  | translate
              "
            ></mat-panel-title>
          </mat-expansion-panel-header>
          <div>
            <form
              [formGroup]="translateReportTextForm"
              (ngSubmit)="translateTexts('reporting_portal_report_texts')"
            >
              <div class="row align-items-center">
                <div class="col-12 col-md">
                  <mat-form-field appearance="outline">
                    <mat-label
                      [innerHTML]="
                        'admin_portal.client.client_panel.translate_text_panel.translate_from.label.content'
                          | translate
                      "
                    ></mat-label>

                    <mat-select formControlName="translate_from">
                      <mat-option [value]="'template'">{{
                        'admin_portal.client.client_panel.translate_text_panel.translate_from.template.content'
                          | translate
                      }}</mat-option>
                      <mat-divider class="my-2"></mat-divider>
                      <mat-option
                        *ngFor="let lang of languages"
                        [value]="lang"
                        >{{ 'languages.' + lang | translate }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-12 col-md-1 text-center">
                  <mat-icon class="menu-icon">arrow_forward</mat-icon>
                </div>
                <div class="col-12 col-md">
                  <mat-form-field appearance="outline">
                    <mat-label
                      [innerHTML]="
                        'admin_portal.client.client_panel.translate_text_panel.translate_to.label.content'
                          | translate
                      "
                    ></mat-label>

                    <mat-select formControlName="translate_to" multiple>
                      <mat-option
                        *ngFor="let lang of languages"
                        [disabled]="
                          this.translateReportTextForm.value.translate_from ===
                          lang
                        "
                        [value]="lang"
                        >{{ 'languages.' + lang | translate }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-12">
                  <button
                    type="submit"
                    class="w-100"
                    mat-raised-button
                    [disabled]="isLoading || !translateReportTextForm.valid"
                    color="primary"
                    [innerHTML]="
                      'admin_portal.client.client_panel.translate_text_panel.translate_submit_button.content'
                        | translate
                    "
                  ></button>
                </div>

                <p
                  *ngIf="translateTextSuccess"
                  class="success mt-3 text-center pt-0"
                  [innerHTML]="
                    'admin_portal.client.client_panel.translate_text_panel.translate_success_message.content'
                      | translate
                  "
                ></p>
                <p
                  *ngIf="translateTextError"
                  class="error mt-3 text-center pt-0"
                  [innerHTML]="
                    'admin_portal.client.client_panel.translate_text_panel.translate_error_message.content'
                      | translate
                  "
                ></p>
              </div>
            </form>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <!-- Reporting portal report texts -->
    <mat-accordion>
      <mat-expansion-panel *ngFor="let lang of languages">
        <mat-expansion-panel-header>
          <mat-panel-title
            [innerHTML]="'languages.' + lang | translate"
          ></mat-panel-title>
        </mat-expansion-panel-header>
        <div>
          <ckeditor
            [editor]="this.editor"
            [config]="this.ckeditorConfigAdminClient"
            formControlName="{{
              'reporting_portal_report_text_' + lang.toLowerCase()
            }}"
          ></ckeditor>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <hr />
    <p
      [innerHTML]="
        'admin_portal.client.client_panel.logo_disclaimer.content' | translate
      "
    ></p>
    <!-- Client logo selection -->
    <input
      class="d-none"
      (change)="onFileSelected($event, 'light')"
      type="file"
      [multiple]="false"
      #lightThemeFileUpload
    />
    <input
      class="d-none"
      (change)="onFileSelected($event, 'dark')"
      type="file"
      [multiple]="false"
      #darkThemeFileUpload
    />

    <div class="my-3">
      <button
        mat-stroked-button
        type="button"
        class="mb-1 py-1 w-100"
        (click)="lightThemeFileUpload.click()"
        color="primary"
        [innerHTML]="
          'admin_portal.client.client_panel.logo.light_theme.content'
            | translate
        "
      ></button>
    </div>

    <div class="my-3">
      <button
        mat-stroked-button
        type="button"
        class="mb-1 py-1 w-100"
        (click)="darkThemeFileUpload.click()"
        color="primary"
        [innerHTML]="
          'admin_portal.client.client_panel.logo.dark_theme.content' | translate
        "
      ></button>
    </div>

    <p
      *ngIf="fileSelectionError.light"
      class="error"
      [innerHTML]="fileSelectionError.light"
    ></p>
    <p
      *ngIf="fileSelectionError.dark"
      class="error"
      [innerHTML]="fileSelectionError.dark"
    ></p>

    <p
      *ngIf="!clientLogoFiles.light"
      class="mb-0"
      [innerHTML]="
        'admin_portal.client.client_panel.logo.light_theme.error.content'
          | translate
      "
    ></p>
    <p
      *ngIf="!clientLogoFiles.dark"
      [innerHTML]="
        'admin_portal.client.client_panel.logo.dark_theme.error.content'
          | translate
      "
    ></p>

    <p
      *ngIf="clientLogoFiles.light"
      class="mb-0"
      [innerHTML]="
        'admin_portal.client.client_panel.logo.light_theme.selected.content'
          | translate: clientLogoFiles
      "
    ></p>

    <p
      *ngIf="clientLogoFiles.light"
      class="mt-1 cursor-pointer text-primary"
      (click)="removeClientLogoFile('light')"
      (keypress)="removeClientLogoFile('light')"
      tabindex="auto"
      [innerHTML]="
        'admin_portal.client.client_panel.delete.logo.light_theme.content'
          | translate
      "
    ></p>

    <p
      *ngIf="clientLogoFiles.dark"
      class="mb-0"
      [innerHTML]="
        'admin_portal.client.client_panel.logo.dark_theme.selected.content'
          | translate: clientLogoFiles
      "
    ></p>

    <p
      *ngIf="clientLogoFiles.dark"
      class="mt-1 cursor-pointer text-primary"
      (click)="removeClientLogoFile('dark')"
      (keypress)="removeClientLogoFile('dark')"
      tabindex="auto"
      [innerHTML]="
        'admin_portal.client.client_panel.delete.logo.dark_theme.content'
          | translate
      "
    ></p>

    <button
      type="submit"
      class="w-100 mt-1 py-1"
      mat-raised-button
      [disabled]="isLoading || !editClientForm.valid"
      color="primary"
      [innerHTML]="
        'admin_portal.client.client_panel.submit_button.content' | translate
      "
    ></button>
  </form>
  <p
    *ngIf="updateClientSuccess"
    class="success mt-3 text-center pt-0"
    [innerHTML]="
      'admin_portal.client.client_panel.success_message.content' | translate
    "
  ></p>
  <p
    *ngIf="updateClientError"
    class="error mt-3 text-center pt-0"
    [innerHTML]="
      'admin_portal.client.client_panel.error_message.content' | translate
    "
  ></p>
  <div class="mt-3">
    <button
      *ngIf="client && client.lsp_id"
      mat-flat-button
      [routerLink]="['/admin/lsp/' + this.client.lsp_id]"
      routerLinkActive="router-link-active"
      [innerHTML]="'global.back_button.content' | translate"
    ></button>
  </div>
</mat-card>
