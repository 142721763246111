<footer class="py-4 px-4 w-100">
  <div class="row m-0 w-100">
    <div class="col-12 col-md-5 px-5 my-3 text-center">
      <img
        class="d-block m-auto"
        width="200px"
        src="assets/images/integribox_logo_light.png"
        alt="integriBOX Logo"
      />
      <p class="small mt-2">
        {{ 'footer.copyright1.content' | translate }} {{ year }}
        {{ 'footer.copyright2.content' | translate }}<br />{{
          'footer.copyright3.content' | translate
        }}
      </p>
    </div>
    <div class="col-12 col-md-4 my-3 small">
      <a
        [routerLink]="['/privacy/' + translate.currentLang]"
        routerLinkActive="router-link-active"
        [innerHTML]="'footer.privacy.content' | translate"
      ></a>
      <a
        [routerLink]="['/imprint/' + translate.currentLang]"
        routerLinkActive="router-link-active"
        [innerHTML]="'footer.imprint.content' | translate"
      ></a>
      <a
        href="https://www.integribox.com"
        [innerHTML]="'footer.landing_page.content' | translate"
      ></a>
    </div>
  </div>
</footer>
