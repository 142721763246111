import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent {
  lang: string = (
    this.activatedRoute.snapshot.params.language_id as string
  ).toLowerCase();
  texts = {
    de: `<h1 class="text-center font-weight-bold mt-4 mb-3">Hinweise zum Datenschutz</h1>
    <p>Wir erheben, verarbeiten und nutzen personenbezogene Daten ausschließlich in Übereinstimmung mit den anwendbaren Rechtsvorschriften zum Schutz personenbezogener Daten und zur Datensicherheit. Die folgenden Angaben dienen zu Ihrer Information. Bitte beachten Sie, dass die Website der integriBOX Compliance Services GmbH Links zu Websites anderer Anbieter enthalten kann, auf die sich diese Datenschutzerklärung nicht erstreckt.</p>
    <h3 class="mt-4 font-weight-bold">1. Zusammenfassung und allgemeiner Überblick</h3>
        <p class="mt-3">Die nachfolgenden Hinweise sollen die Nutzer der Website darüber informieren, was in Bezug auf die mit dem Besuch der Website verbundenen personenbezogenen Daten durch den Anbieter der Website geschieht. Die unten folgenden Hinweise ermöglichen eine detaillierte Information.</p>
        <ol type="a">
            <li>
            <p>Was sind personenbezogene Daten?</p>
            <ul>
                <li><p>Als personenbezogene Daten gelten alle Daten, mit denen man den Nutzer persönlich identifizieren kann.</p></li>
            </ul>
            </li>
            <li>
            <p>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</p>
            <ul>
                <li><p>Die Datenverarbeitung auf dieser Website erfolgt durch integriBOX Compliance Services GmbH oder auf deren Veranlassung in deren Auftrag. Die Kontaktdaten der verantwortlichen Person entnehmen Sie bitte dem <a [routerLink]="['/impressum']" routerLinkActive="router-link-active" >Impressum</a>.</p></li>
            </ul>
            </li>
            <li>
            <p>Wie erfassen wir Ihre Daten?</p>
            <ul>
                <li><p>Ihre Daten werden zum einen dadurch erhoben, dass Sie diese aktiv mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in ein Kontaktformular eingeben. Andere Daten werden automatisch beim Besuch der Website durch IT-Systeme erfasst. Das sind vor allem technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website betreten.</p></li>
            </ul>
            </li>
            <li>
            <p>Wofür nutzen wir Ihre Daten?</p>
            <ul>
                <li><p>Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.</p></li>
            </ul>
            </li>
            <li>
            <p>Welche Rechte haben Sie bezüglich Ihrer Daten?</p>
            <ul>
                <li><p>Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem das Recht, die Berichtigung, Sperrung oder Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich an die im <a [routerLink]="['/impressum']" routerLinkActive="router-link-active">Impressum</a> angegebene Adresse wenden. Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Datenschutz-Aufsichtsbehörde zu.</p></li>
            </ul>
            </li>
            <li>
            <p>Analyse-Tools und Tools von Drittanbietern</p>
            <ul>
                <li><p>Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor allem mit Cookies und mit sogenannten Analyseprogrammen. Die Analyse Ihres Surf-Verhaltens erfolgt in der Regel anonym; das Surf-Verhalten kann nicht zu Ihnen zurückverfolgt werden. Sie können dieser Analyse widersprechen oder sie durch die Nichtbenutzung bestimmter Tools verhindern.</p></li>
            </ul>
            </li>
    </ol>
    <h3 class="mt-4 font-weight-bold">2. Besuch unserer Website</h3>
    <ul class="mt-3">
        <li class="small">
            <p>Die folgenden Daten werden immer dann erhoben und verarbeitet, wenn Sie unsere Website besuchen, da diese für den Betrieb unserer Website erforderlich sind:</p>
                •	Betriebssystem und Version<br>
                •	Browsertyp, Version und Plug-in-Typen<br>
                •	Browser-Sprache<br>
                •	Verwendetes Endgerät<br>
                •	IP-Adresse<br>
                •	Auflösung der Anzeige<br>
                •	Einstellung der Zeitzone<br>
                •	Standortdaten: Land, Zeitzone<br>
                •	Datum und Uhrzeit der Serveranfrage<br>
                •	Zugriffsstatus/http-Statuscode<br>
                •	Jeweils übertragene Datenmenge<br>
                •	Webseite, von der die Anforderung kommt (Referrer URL)<br>
                •	Informationen zur Anmeldung
            <p class="mt-2">Wenn Sie unsere Websites besuchen, erheben und verarbeiten wir Ihre personenbezogenen Daten auf der Grundlage unseres berechtigten Interesses für die folgenden Zwecke:</p>
                •	Für den effizienten Betrieb unseres Online-Angebots und unseres Internetauftritts.<br>
                •	Sicherstellung des Betriebs von IT-Systemen: Die Sicherstellung des Betriebs umfasst u. a. folgende Tätigkeiten<br>
                •	Sicherung und Wiederherstellung von in IT-Systemen verarbeiteten Daten<br>
                •	Vorfall- und Problemmanagement bei der Behebung von IT-Systemen<br>
                •	IT-Sicherheit sowie Erkennung und Abwehr von unberechtigtem Zugriff<br>
                •	Protokollierung und Überwachung von Transaktionen zur Überprüfung des ordnungsgemäßen Funktionierens der IT-Systeme
            <p class="mt-2">Sie können unsere Websites jederzeit besuchen, ohne uns mitzuteilen, wer Sie sind, und ohne dass Ihre personenbezogenen Daten verarbeitet werden.</p>
        </li>
    </ul>
    <h3 class="mt-4 font-weight-bold">3. Plugins und Tools</h3>
    <ul class="mt-3">
        <li>
            <div class="h6">Google Web Fonts</div>
            <ul>
                <li>
                    <p>Unsere Website nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts, die von Google bereitgestellt werden. Beim Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts in Ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen.</p>
                    <p>Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den Servern von Google aufnehmen. Hierdurch erlangt Google Kenntnis darüber, dass über Ihre IP-Adresse unsere Website aufgerufen wurde. Die Nutzung von Google Web Fonts erfolgt im Interesse einer einheitlichen und ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f) DSGVO dar.</p>
                    <p>Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine Standardschrift von Ihrem Computer genutzt.</p>
                </li>
            </ul>
        </li>
        <li>
            <div class="h6">Datenschutzerklärung für die Nutzung von Google Analytics</div>
            <ul>
                <li>
                    <p>Unsere Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. („Google“). Google Analytics verwendet sog. „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch das Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Im Falle der Aktivierung der IP-Anonymisierung auf dieser Website wird Ihre IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt.</p>
                    <p>Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.</p>
                    <p>Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren: <a href="http://tools.google.com/dlpage/gaoptout?hl=de" target="_blank" rel="nofollow">http://tools.google.com/dlpage/gaoptout?hl=de</a></p>
                    <p>Weitere Informationen zu den Applikationen von Google finden Sie in der Datenschutzerklärung von Google: <a href="https://www.google.com/policies/privacy" target="_blank" rel="nofollow">https://www.google.com/policies/privacy</a></p>
                </li>
            </ul>
        </li>
    </ul>
    <h3 class="mt-4 font-weight-bold">4. Kontaktformular</h3>
    <ul class="mt-3">
        <li>
            <p>Wir erheben und verarbeiten personenbezogene Daten, wenn Sie über das Kontaktformular eine Anfrage an uns richten oder anderweitig mit uns in Kontakt treten, z. B. im Rahmen allgemeiner Korrespondenz durch E-Mail, Telefax oder Briefpost. Die Daten, die Sie uns zur Verfügung stellen, umfassen:</p>
                <p>
                    •	Name (Vor- und Nachname)<br>
                    •	Adresse (Postleitzahl, Ort, Straße und Hausnummer)<br>
                    •	Unternehmen (Name, Rechtsform und Adresse)<br>
                    •	Rufnummern (Telefon und Telefax)<br>
                    •	Website (URL)<br>
                    •	Land<br>
                    •	E-Mail-Adresse
                </p>
            <p>Wir erheben und verarbeiten diese Daten für die Zwecke, für die Sie sie uns zur Verfügung stellen.</p>
            <p>Wir nutzen die Daten auch für Direktmarketing, sofern Sie uns hierfür eine gesonderte Einwilligung erteilt haben oder sich die Berechtigung aus einem bestehenden Vertragsverhältnis oder der Anbahnung eines solchen ergibt. Sie können den Direktmarketingmaßnahmen jederzeit widersprechen.</p>
        </li>
    </ul>
    <h3 class="mt-4 font-weight-bold">5. Dienstleistungen oder sonstige Leistungen der integriBOX Compliance Services GmbH</h3>
    <ul class="mt-3">
        <li>
            <p>Wir erheben und verarbeiten die personenbezogenen Daten, die Sie uns zur Verfügung stellen, wenn wir eine Dienstleistung oder eine andere Leistung für Sie erbringen, oder wenn dies im Rahmen der Erbringung der Dienstleistung erforderlich ist. Die Daten, die Sie uns zur Verfügung stellen, umfassen:</p>
                <p>
                    •	Name (Vor- und Nachname)<br>
                    •	Adresse (Postleitzahl, Ort, Straße und Hausnummer)<br>
                    •	Unternehmen (Name, Rechtsform und Adresse)<br>
                    •	Rufnummern (Telefon und Telefax)<br>
                    •	Website (URL)<br>
                    •	Land<br>
                    •	E-Mail-Adresse<br>
                    •	Informationen zur Anmeldung<br>
                    •	Stammdaten (z. B. Bankverbindung)
                </p>
            <p>Die Erhebung und Verarbeitung Ihrer personenbezogenen Daten erfolgt zur Anbahnung und Erfüllung vertraglicher Verpflichtungen sowie für den Fall, dass wir ein berechtigtes Interesse an der Verwendung Ihrer personenbezogenen Daten haben, z. B.</p>
                <p>
                    •	Gewährleistung der Produktqualität, Entwicklung neuer Produkte,<br>
                    •	um Verkaufs-, Service- und Verwaltungsprozesse zu erfüllen,<br>
                    •	zur Verbesserung und zum wirtschaftlichen Betrieb unserer Online-Angebote, unserer Website und unserer E-Mail-Kommunikation,<br>
                    •	um Ihnen Werbung für ähnliche Produkte wie die bereits gekauften zukommen zu lassen, es sei denn, Sie haben dem widersprochen.
                </p>
        </li>
    </ul>
    <h3 class="mt-4 font-weight-bold">6. Nutzung des Meldeportals durch Hinweisgeber</h3>
    <ul class="mt-3">
        <li>
            <p>Wenn Sie ein von uns für unsere Kunden bereitgestelltes Meldeportal nutzen, um einen Rechtsverstoß oder ein unethisches Verhalten zu melden, sonstige Hinweise zu geben oder Nachrichten im Chat zu senden, verarbeiten wir die Daten, die Sie uns in diesem Zusammenhang zur Verfügung stellen. Ihre Meldung und Nachrichten sowie die damit zusammenhängenden Daten werden verschlüsselt auf dem von uns genutzten Server abgespeichert und dem jeweiligen Fallbearbeiter, der im Meldeportal genannt ist, zum Abruf bereitgestellt. Der Fallbearbeiter verarbeitet diese Daten und Dateien zum Zwecke der weiteren Aufklärung des von Ihnen gemeldeten Sachverhalts. Die integriBOX Compliance Services GmbH hat ihrerseits keinen lesbaren Zugriff auf den Inhalt Ihrer Meldung und Nachrichten.</p>
            <p>Die oben unter Ziffer 2 genannten Daten werden bei Aufruf des Meldeportals erhoben und verarbeitet, soweit dies für den Betrieb des Meldeportals erforderlich ist. Eine Verarbeitung zu sonstigen Zwecken erfolgt nicht, insbesondere nutzen wir die genannten Daten nicht zur Feststellung Ihrer Identität. Der Fallbearbeiter hat mit Ausnahme des Datums und der Uhrzeit der Abgabe des Hinweises oder der Anmeldung im Meldeportal keinen Zugriff auf diese Daten.</p>
            <p>Sofern Sie bei Abgabe einer Meldung Ihre E-Mail-Adresse angeben, wird diese von uns ausschließlich zu dem Zweck verarbeitet, Ihnen (a) automatisiert zu bestätigen, dass Ihre Meldung technisch aufgenommen worden ist, und Sie (b) jeweils automatisiert zu benachrichtigen, sobald der Fallbearbeiter Ihnen in dem Chat des Meldeportals eine Nachricht gesandt hat. Eine Weitergabe Ihrer E-Mail-Adresse an den Kunden oder dessen Fallbearbeiter erfolgt nicht.</p>
        </li>
    </ul>
    <h3 class="mt-4 font-weight-bold">7. Erfüllung rechtlicher Verpflichtungen</h3>
    <ul class="mt-3">
        <li><p>Wir werden personenbezogene Daten auch zu solchen Zwecken verarbeiten, zu denen wir gesetzlich verpflichtet sind. Wir unterliegen einer Vielzahl von gesetzlichen Verpflichtungen. Um diesen Verpflichtungen nachzukommen, verarbeiten wir Ihre Daten in dem hierzu erforderlichen Umfang und geben diese, soweit geboten, im Rahmen gesetzlicher Meldepflichten an die zuständigen Behörden weiter. Erforderlichenfalls verarbeiten wir Ihre Daten auch im Falle eines Rechtsstreits, wenn der Rechtsstreit die Verarbeitung Ihrer Daten erfordert.</p></li>
    </ul>
    <h3 class="mt-4 font-weight-bold">8. Wie lange bewahren wir Ihre Informationen auf?</h3>
    <ul class="mt-3">
        <li>
            <p>Wir bewahren Ihre Daten nur so lange auf, wie es für die Zwecke, für die wir Ihre Daten verarbeiten, erforderlich ist. Wenn wir Daten für mehrere Zwecke verarbeiten, werden sie automatisch gelöscht oder in einem Format gespeichert, das keine direkten Rückschlüsse auf Sie zulässt, sobald der letzte spezifische Zweck erfüllt ist.</p>
            <p>Auch löschen wir die Daten, wenn ihre Speicherung unzulässig ist (insbesondere wenn die Daten unrichtig sind und eine Korrektur nicht möglich ist). integriBOX Compliance Services GmbH richtet sich insbesondere nach den gesetzlichen Aufbewahrungspflichten und anderen rechtlichen Vorgaben. An die Stelle der Löschung tritt die Sperrung, sofern rechtliche oder tatsächliche Hindernisse entgegenstehen (z. B. besondere Aufbewahrungspflichten aufgrund handels- und steuerrechtlicher Vorgaben).</p>
        </li>
    </ul>
    <h3 class="mt-4 font-weight-bold">9. Wem gewähren wir internationalen Zugang zu Ihren Daten und wie schützen wir diese Daten dabei?</h3>
    <ul class="mt-3">
        <li>
            <p>Personenbezogene Daten werden von Mitarbeitern der integriBOX Compliance Services GmbH und der von uns beauftragten Dienstleister verarbeitet, vorzugsweise innerhalb der EU. Sollten Daten in Ländern außerhalb der EU verarbeitet werden, stellen wir durch geeignete Maßnahmen (z. B. EU-Standardvertragsklauseln einschließlich geeigneter technischer und organisatorischer Maßnahmen) sicher, dass Ihre personenbezogenen Daten in Übereinstimmung mit dem europäischen Datenschutzniveau verarbeitet werden.</p>
        </li>
    </ul>
    <h3 class="mt-4 font-weight-bold">10. Kontaktaufnahme mit uns, Ihre Datenschutzrechte und Ihr Recht auf Beschwerde bei der Datenschutzbehörde</h3>
    <ul class="mt-3">
        <li>
            <p>Wenn Sie in die Verarbeitung Ihrer personenbezogenen Daten durch uns eingewilligt haben, haben Sie das Recht, Ihre Einwilligung jederzeit zu widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an die verantwortliche Stelle, die im <a [routerLink]="['/impressum']" routerLinkActive="router-link-active">Impressum</a> angegeben ist. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Verarbeitung Ihrer personenbezogenen Daten wird durch den Widerruf nicht berührt. Auch unser Recht zur Weiterverarbeitung dieser Daten auf einer anderen Rechtsgrundlage, etwa zur Erfüllung gesetzlicher Pflichten, bleibt von einem Widerruf unberührt.</p>
            <p>Gemäß Art. 15 ff. DSGVO haben Sie uns gegenüber folgende Rechte hinsichtlich der Sie betreffenden personenbezogenen Daten:</p>
                <p>
                    •	Recht auf Auskunft,<br>
                    •	Recht auf Berichtigung oder Löschung,<br>
                    •	Recht auf Einschränkung der Verarbeitung,<br>
                    •	Recht auf Widerspruch gegen die Verarbeitung,<br>
                    •	Recht auf Widerruf einer erteilten Einwilligung,<br>
                    •	Recht auf Datenübertragbarkeit.
                </p>
            <p>Wir nehmen Ihre Bedenken und Rechte sehr ernst. Wenn Sie jedoch der Meinung sind, dass wir nicht angemessen auf Ihre Anliegen reagiert haben, haben Sie das Recht, eine Beschwerde bei einer zuständigen Datenschutzbehörde einzureichen.</p>
        </li>
    </ul>
`,
    en: `<h1 class="text-center font-weight-bold mt-4 mb-3">Notes on Data Protection</h1>
    <p>We collect, process and use personal data only in accordance with applicable laws on personal data protection and data security. The following information is provided for your information. Please note that the integriBOX Compliance Services GmbH website may contain links to websites of other providers to which this data protection declaration does not extend.</p>
    <h3 class="mt-4 font-weight-bold">1. Summary and General Overview</h3>
        <p class="mt-3">The following information is intended to inform users of our website about what happens with regard to the personal data associated with visits to the website by the website provider. The further notes below provide detailed information.</p>
        <ol type="a">
            <li>
            <p>What is personal data?</p>
            <ul>
                <li><p>Personal data is any data that can be used to personally identify the user.</p></li>
            </ul>
            </li>
            <li>
            <p>Who is responsible for the data collection on this website?</p>
            <ul>
                <li><p>The data processing on this website is carried out by integriBOX Compliance Services GmbH or on its behalf. Please refer to the imprint for the contact details of the responsible person.</p></li>
            </ul>
            </li>
            <li>
            <p>How do we collect your data?</p>
            <ul>
                <li><p>Your data is collected when you actively provide it. This may be data that you enter in a contact form, for example. Other data is collected automatically by IT systems when you visit the website. This is mainly technical data (e.g., internet browser, operating system or time of page access). This data is collected automatically as soon as you enter this website.</p></li>
            </ul>
            </li>
            <li>
            <p>What do we use your data for?</p>
            <ul>
                <li><p>Part of the data is collected to ensure correct operation of the website. Other data may be used to analyze your user behaviour.</p></li>
            </ul>
            </li>
            <li>
            <p>What rights do you have regarding your data?</p>
            <ul>
                <li><p>You have the right to receive information about the origin, recipient and purpose of your stored personal data free of charge at any time. You also have the right to request the correction, blocking or deletion of this data. For this purpose, as well as for further questions on the subject of data protection, you can contact the address given in the imprint. Furthermore, you have the right to lodge a complaint with the competent data protection supervisory authority.</p></li>
            </ul>
            </li>
            <li>
            <p>Third-party analysis tools and tools</p>
            <ul>
                <li><p>When visiting this website, your surfing behavior may be statistically evaluated. This is done primarily with cookies and so-called analysis programs. The analysis of your surfing behavior is usually anonymous; the surfing behavior cannot be traced back to you. You can object to this analysis or prevent it by not using certain tools.</p></li>
            </ul>
            </li>
        </ol>
    <h3 class="mt-4 font-weight-bold">2. Visit of our Website</h3>
    <ul class="mt-3">
        <li class="small">
            <p>The following data is collected and processed whenever you visit our website, as it is necessary for the operation of our website:</p>
                •	Operating system and version<br>
                •	Browser type, version and plug-in types<br>
                •	Browser language<br>
                •	Terminal used<br>
                •	IP address<br>
                •	Display resolution<br>
                •	Setting the time zone<br>
                •	Location data: Country, time zone<br>
                •	Date and time of the server request<br>
                •	Access status/http status code<br>
                •	Amount of data transmitted in each case<br>
                •	Website from which the request comes (referrer URL)<br>
                •	Registration information
            <p class="mt-2">When you visit our websites, we collect and process your personal data on the basis of our legitimate interest for the following purposes:</p>
                •	For the efficient operation of our online services and our internet presence.<br>
                •	Ensuring the operation of IT systems: Ensuring the operation includes, among others, the following activities.<br>
                •	Backup and recovery of data processed in IT systems.<br>
                •	Incident and problem management in the remediation of IT systems.<br>
                •	IT security and detection of and defence against unauthorized access.<br>
                •	Logging and monitoring of transactions to verify the proper functioning of the IT systems.
            <p class="mt-2">You can visit our websites at any time without telling us who you are and without your personal data being processed.</p>
        </li>
    </ul>
    <h3 class="mt-4 font-weight-bold">3. Plugins and Tools</h3>
    <ul class="mt-3">
        <li>
            <div class="h6">Google Web Fonts</div>
            <ul>
                <li>
                    <p>Our website uses so-called web fonts provided by Google for the uniform display of fonts. When you visit a website, your browser loads the required web fonts into your browser cache in order to display texts and fonts correctly.</p>
                    <p>For this purpose, the browser you use must connect to Google's servers. This enables Google to know that our website has been accessed via your IP address. Google Web Fonts are used in the interest of a uniform and appealing presentation of our online offers. This represents a legitimate interest within the meaning of Art. 6 (1) f) GDPR.</p>
                    <p>If your browser does not support web fonts, a standard font is used by your computer.</p>
                </li>
            </ul>
        </li>
        <li>
            <div class="h6">Privacy policy for the use of Google Analytics</div>
            <ul>
                <li>
                    <p>Our website uses Google Analytics, a web analytics service provided by Google, Inc. ("Google"). Google Analytics uses "cookies", which are text files placed on your computer, to help the website analyze how users use the site. The information generated by the cookie about your use of this website is usually transmitted to a Google server in the US and stored there. In the event that IP anonymization is activated on this website, however, your IP address will be truncated beforehand by Google within Member States of the European Union or in other contracting states to the Agreement on the European Economic Area.</p>
                    <p>Only in exceptional cases will the full IP address be transmitted to a Google server in the US and shortened there. On behalf of the operator of this website, Google will use this information for the purpose of evaluating your use of the website, compiling reports on website activity and providing other services relating to website activity and internet usage to the website operator. The IP address transmitted by your browser as part of Google Analytics will not be merged with other Google data.</p>
                    <p>You may refuse the use of cookies by selecting the appropriate settings on your browser, however please note that if you do this you may not be able to use the full functionality of this website. You can also prevent the collection of data generated by the cookie and related to your use of the website (including your IP address) by Google and the processing of this data by Google by downloading and installing the browser plugin available at the following link: <a href="http://tools.google.com/dlpage/gaoptout?hl=en" target="_blank" rel="nofollow">http://tools.google.com/dlpage/gaoptout?hl=en</a></p>
                    <p>For more information on Google's applications, please see Google's privacy policy: <a href="https://www.google.com/policies/privacy" target="_blank" rel="nofollow">https://www.google.com/policies/privacy</a></p>
                </li>
            </ul>
        </li>
    </ul>
    <h3 class="mt-4 font-weight-bold">4. Contact Form</h3>
    <ul class="mt-3">
        <li>
            <p>We collect and process personal data when you send us an enquiry via the contact form or otherwise get in touch with us, e.g., as part of general correspondence by e-mail, fax or letter post. The data you provide to us includes:</p>
                <p>
                    •	Name (first and last name)<br>
                    •	Address (postcode, town, street and house number)<br>
                    •	Company (name, legal form and address)<br>
                    •	Call numbers (telephone and fax)<br>
                    •	Website (URL)<br>
                    •	Country<br>
                    •	E-mail address
                </p>
            <p>We collect and process this data for the purposes for which you provide it to us.</p>
            <p>We also use the data for direct marketing, provided you have given us separate consent for this or the authorization arises from an existing contractual relationship or the initiation of such. You can object to direct marketing measures at any time.</p>
        </li>
    </ul>
    <h3 class="mt-4 font-weight-bold">5. Services or other Performances of integriBOX Compliance Services GmbH</h3>
    <ul class="mt-3">
        <li>
            <p>We collect and process the personal data you provide to us when we provide a service or other performance to you, or when required in the course of providing the service. The data you provide to us includes:</p>
                <p>
                    •	Name (first and last name)<br>
                    •	Address (postcode, town, street and house number)<br>
                    •	Company (name, legal form and address)<br>
                    •	Call numbers (telephone and fax)<br>
                    •	Website (URL)<br>
                    •	Country<br>
                    •	E-mail address<br>
                    •	Registration information<br>
                    •	Master data (e.g., bank details)
                </p>
            <p>Your personal data is collected and processed for the purpose of initiating and fulfilling contractual obligations and in the event that we have a legitimate interest in using your personal data, e.g.</p>
                <p>
                    •	Ensuring product quality, developing new products,<br>
                    •	to fulfil sales, service and administrative processes,<br>
                    •	to improve and economically operate our online offers, our website and our e-mail communication,<br>
                    •	to send you advertising for products similar to those you have already purchased, unless you have objected to this.
                </p>
        </li>
    </ul>
    <h3 class="mt-4 font-weight-bold">6. Use of the Reporting Portal by Whistleblowers</h3>
    <ul class="mt-3">
        <li>
            <p>If you use a reporting portal provided by us for our customers to report a violation of the law or unethical behaviour, to give other information or to send messages in the chat, we process the data that you provide to us in this context. Your report and messages as well as the related data are stored in encrypted form on the server we use and made available for access by the respective case manager named in the reporting portal. The case manager processes this data and files for the purpose of further investigation of the matter reported by you. integriBOX Compliance Services GmbH, for its part, has no readable access to the content of your report and messages.</p>
            <p>The data mentioned above under Section 2 is collected and processed when the reporting portal is used, insofar as this is necessary for its operation. Processing for other purposes does not happen, in particular we do not use the above data to discover your identity. With the exception of the date and time of the submission of the report or the log-in to the reporting portal, the case manager does not have access to this data.</p>
            <p>If you provide your e-mail address when submitting a report, this will be processed by us solely for the purpose of (a) automatically confirming to you that your report has been technically recorded and (b) automatically notifying you as soon as the case manager has sent you a message in the chat within the reporting portal. Your e-mail address will not be passed on to the customer or their case manager.</p>
        </li>
    </ul>
    <h3 class="mt-4 font-weight-bold">7. Fulfilment of Legal Obligations</h3>
    <ul class="mt-3">
        <li><p>We will also process personal data for those purposes for which we are legally obliged to do so. We are subject to a variety of legal obligations. In order to comply with these obligations, we will process your data to the extent necessary for this purpose and, where required, disclose it to the competent authorities within the scope of legal reporting obligations. If necessary, we also process your data in the event of a legal dispute if the legal dispute requires the processing of your data.</p></li>
    </ul>
    <h3 class="mt-4 font-weight-bold">8. How long do we keep your information?</h3>
    <ul class="mt-3">
        <li>
            <p>We only keep your data for as long as is necessary for the purposes for which we process your data. If we process data for more than one purpose, it will be automatically deleted or stored in a format that does not allow direct conclusions to be drawn about you once the last specific purpose has been fulfilled.</p>
            <p>We also delete the data if its storage is inadmissible (in particular if the data is incorrect and a correction is not possible). integriBOX Compliance Services GmbH complies in particular with the statutory retention obligations and other legal requirements. Deletion shall be replaced by blocking if there are legal or factual obstacles to this (e.g., special retention obligations due to commercial and tax law requirements).</p>
        </li>
    </ul>
    <h3 class="mt-4 font-weight-bold">9. Who do we give international access to your data and how do we protect that data in the process?</h3>
    <ul class="mt-3">
        <li>
            <p>Personal data is processed by employees of integriBOX Compliance Services GmbH and the service providers contracted by us, preferably within the EU. Should data be processed in countries outside the EU, we will ensure through appropriate measures (e.g., EU standard contractual clauses including suitable technical and organizational measures) that your personal data is processed in accordance with the European level of data protection.</p>
        </li>
    </ul>
    <h3 class="mt-4 font-weight-bold">10. Contacting us, your Data Protection Rights and your Right to Complain to the Data Protection Authority</h3>
    <ul class="mt-3">
        <li>
            <p>If you have consented to the processing of your personal data by us, you have the right to revoke your consent at any time. For this purpose, an informal communication by e-mail to the responsible office indicated in the imprint is sufficient. The lawfulness of the processing of your personal data carried out until the revocation is not affected by the revocation. Our right to further process this data on another legal basis, for example to fulfil legal obligations, also remains unaffected by a revocation.</p>
            <p>In accordance with Art. 15 ff. GDPR, you have the following rights vis-à-vis us with regard to the personal data concerning you:</p>
                <p>
                    •	Right to information,<br>
                    •	Right to rectification or erasure,<br>
                    •	Right to restriction of processing,<br>
                    •	Right to object to processing,<br>
                    •	Right to withdraw consent given,<br>
                    •	Right to data portability.
                </p>
            <p>We take your concerns and rights very seriously. However, if you feel that we have not responded appropriately to your concerns, you have the right to lodge a complaint with a competent data protection authority.</p>
        </li>
    </ul>
`
  };
  text = '';

  // rerendering language selection after language is loaded
  public trigger = 0;

  constructor(
    public translate: TranslateService,
    private activatedRoute: ActivatedRoute
  ) {
    this.setLang(translate.currentLang);

    translate.onLangChange.subscribe((event) => {
      this.setLang(event.lang);
      this.trigger++;
    });
  }

  goBack(): void {
    history.back();
  }

  setLang(languageId: string) {
    if (languageId === 'de') {
      this.text = this.texts.de;
    } else {
      this.text = this.texts.en;
    }
  }
}
