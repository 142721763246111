<div class="cover-spin" *ngIf="isLoading"></div>
<mat-card class="mt-5 d-block m-auto">
  <div
    class="h4 mb-1"
    [innerHTML]="'admin_portal.lsp.heading.content' | translate"
  ></div>
  <p
    *ngIf="legalServiceProvider"
    class="mb-3 small"
    [innerHTML]="
      ('admin_portal.lsp.subheading.first_part.content' | translate) +
      legalServiceProvider.name +
      ('admin_portal.lsp.subheading.second_part.content' | translate)
    "
  ></p>
  <mat-accordion *ngIf="legalServiceProvider">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title
          [innerHTML]="
            'admin_portal.lsp.lsp_data_panel.heading.content' | translate
          "
        ></mat-panel-title>
        <mat-panel-description
          [innerHTML]="
            'admin_portal.lsp.lsp_data_panel.description.content' | translate
          "
        ></mat-panel-description>
      </mat-expansion-panel-header>
      <!-- ID of lsp -->
      <mat-form-field class="w-100" appearance="outline">
        <mat-label
          [innerHTML]="
            'admin_portal.lsp.lsp_data_panel.id_form_field.label.content'
              | translate
          "
        ></mat-label>
        <input matInput disabled [value]="legalServiceProvider.id" />
      </mat-form-field>

      <!-- Name of lsp -->
      <mat-form-field class="w-100" appearance="outline">
        <mat-label
          [innerHTML]="
            'admin_portal.lsp.lsp_data_panel.name_form_field.label.content'
              | translate
          "
        ></mat-label>
        <input matInput disabled [value]="legalServiceProvider.name" />
      </mat-form-field>

      <!-- Name short of lsp -->
      <mat-form-field class="w-100" appearance="outline">
        <mat-label
          [innerHTML]="
            'admin_portal.lsp.lsp_data_panel.name_short_form_field.label.content'
              | translate
          "
        ></mat-label>
        <input matInput disabled [value]="legalServiceProvider.name_short" />
      </mat-form-field>

      <!-- Withdrawal email of lsp -->
      <mat-form-field class="w-100" appearance="outline">
        <mat-label
          [innerHTML]="
            'admin_portal.lsp.lsp_data_panel.withdrawal_email_form_field.label.content'
              | translate
          "
        ></mat-label>
        <input
          matInput
          disabled
          [value]="legalServiceProvider.withdrawal_email"
        />
      </mat-form-field>

      <form (ngSubmit)="updateLsp()">
        <hr />
        <p
          [innerHTML]="
            'admin_portal.lsp.lsp_data_panel.logo_disclaimer.content'
              | translate
          "
        ></p>
        <!-- Lsp logo selection -->
        <input
          class="d-none"
          (change)="onFileSelected($event)"
          type="file"
          [multiple]="false"
          #fileUpload
        />
        <div class="my-3">
          <button
            type="button"
            class="mb-1 py-1 w-100"
            mat-stroked-button
            (click)="fileUpload.click()"
            color="primary"
            [innerHTML]="
              'admin_portal.lsp.lsp_data_panel.select_logo_button.content'
                | translate
            "
          ></button>
        </div>
        <p
          *ngIf="fileSelectionError"
          class="error"
          [innerHTML]="fileSelectionError"
        ></p>
        <p
          *ngIf="!lspLogoFile"
          [innerHTML]="
            'admin_portal.lsp.lsp_data_panel.no_file_selected.content'
              | translate
          "
        ></p>
        <p *ngIf="lspLogoFile" class="mb-0">
          {{
            'admin_portal.lsp.lsp_data_panel.file_selected.content' | translate
          }}
          {{ lspLogoFile.name }}
        </p>
        <p
          *ngIf="lspLogoFile"
          class="mt-1 cursor-pointer text-primary"
          (click)="lspLogoFile = undefined"
          (keypress)="lspLogoFile = undefined"
          tabindex="auto"
          [innerHTML]="
            'admin_portal.lsp.lsp_data_panel.remove_file_button.content'
              | translate
          "
        ></p>

        <button
          type="submit"
          class="w-100 mt-1 py-1"
          mat-raised-button
          [disabled]="isLoading"
          color="primary"
          [innerHTML]="
            'admin_portal.lsp.lsp_data_panel.submit_button.content' | translate
          "
        ></button>
      </form>
      <p
        *ngIf="updateLspSuccess"
        class="success mt-3 text-center pt-0"
        [innerHTML]="
          'admin_portal.lsp.lsp_data_panel.success_message.content' | translate
        "
      ></p>
      <p
        *ngIf="updateLspError"
        class="error mt-3 text-center pt-0"
        [innerHTML]="
          'admin_portal.lsp.lsp_data_panel.error_message.content' | translate
        "
      ></p>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title
          [innerHTML]="
            'admin_portal.lsp.lsp_clients_panel.heading.content' | translate
          "
        ></mat-panel-title>
        <mat-panel-description
          [innerHTML]="
            'admin_portal.lsp.lsp_clients_panel.description.content' | translate
          "
        ></mat-panel-description>
      </mat-expansion-panel-header>
      <mat-form-field>
        <mat-label
          [innerHTML]="
            'admin_portal.lsp.lsp_clients_panel.filter_form_field.label.content'
              | translate
          "
        ></mat-label>
        <input
          matInput
          (keyup)="filterClients($event)"
          [placeholder]="
            'admin_portal.lsp.lsp_clients_panel.filter_form_field.placeholder.content'
              | translate
          "
        />
      </mat-form-field>
      <div class="tablecontainer mat-elevation-z8">
        <table mat-table [dataSource]="clientsDataSource">
          <!-- Display clients of legal service provider -->

          <!-- Name of client -->
          <ng-container matColumnDef="name">
            <th
              mat-header-cell
              *matHeaderCellDef
              [innerHTML]="
                'admin_portal.lsp.lsp_clients_panel.table.name_column.content'
                  | translate
              "
            ></th>
            <td mat-cell *matCellDef="let client">{{ client.name }}</td>
          </ng-container>

          <!-- Link to clients reporting page -->
          <ng-container matColumnDef="link">
            <th
              mat-header-cell
              *matHeaderCellDef
              [innerHTML]="
                'admin_portal.lsp.lsp_clients_panel.table.reporting_portal_link_column.content'
                  | translate
              "
            ></th>
            <td mat-cell *matCellDef="let client">
              <a
                *ngIf="hostname === 'localhost'"
                href="http://{{ hostname }}:{{ port }}/mustermann"
                target="_blank"
                >{{ client.subdomain }}.{{ hostname }}</a
              >
              <a
                *ngIf="hostname !== 'localhost'"
                href="https://{{ client.subdomain + partHost }}"
                target="_blank"
                >{{ client.subdomain + partHost }}</a
              >
            </td>
          </ng-container>

          <!-- Client active state -->
          <ng-container matColumnDef="active">
            <th
              mat-header-cell
              *matHeaderCellDef
              [innerHTML]="
                'admin_portal.lsp.lsp_clients_panel.table.active_column.content'
                  | translate
              "
            ></th>
            <td mat-cell *matCellDef="let client">
              <mat-icon class="success" *ngIf="client.is_active">done</mat-icon>
              <mat-icon class="error" *ngIf="!client.is_active">close</mat-icon>
            </td>
          </ng-container>

          <!-- View client -->
          <ng-container matColumnDef="view">
            <th
              mat-header-cell
              *matHeaderCellDef
              [innerHTML]="
                'admin_portal.lsp.lsp_clients_panel.table.view_column.content'
                  | translate
              "
            ></th>
            <td mat-cell *matCellDef="let client">
              <button
                mat-icon-button
                [routerLink]="['client', client.id]"
                routerLinkActive="router-link-active"
                aria-label="View client"
              >
                <mat-icon
                  aria-hidden="false"
                  aria-label="View client"
                  color="primary"
                  >login</mat-icon
                >
              </button>
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="clientsTableDisplayedColumns; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: clientsTableDisplayedColumns"
          ></tr>
        </table>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title
          [innerHTML]="
            'admin_portal.lsp.add_client_panel.heading.content' | translate
          "
        ></mat-panel-title>
        <mat-panel-description
          [innerHTML]="
            'admin_portal.lsp.add_client_panel.description.content' | translate
          "
        ></mat-panel-description>
      </mat-expansion-panel-header>
      <form
        class="mb-3"
        [formGroup]="addClientForm"
        (ngSubmit)="createClient()"
      >
        <!-- Name of client -->
        <mat-form-field class="w-100" appearance="outline">
          <mat-label
            [innerHTML]="
              'admin_portal.lsp.add_client_panel.name_form_field.label.content'
                | translate
            "
          ></mat-label>
          <input
            matInput
            formControlName="client_name"
            [errorStateMatcher]="matcher"
          />
          <mat-error>
            <span
              *ngIf="
                !addClientForm.get('client_name').valid &&
                addClientForm.get('client_name').touched
              "
              [innerHTML]="
                'admin_portal.lsp.add_client_panel.name_form_field.error_message.content'
                  | translate
              "
            ></span>
          </mat-error>
        </mat-form-field>

        <!-- Name short of client -->
        <mat-form-field class="w-100" appearance="outline">
          <mat-label
            [innerHTML]="
              'admin_portal.lsp.add_client_panel.name_short_form_field.label.content'
                | translate
            "
          ></mat-label>
          <input
            matInput
            formControlName="client_name_short"
            [errorStateMatcher]="matcher"
          />
          <mat-error>
            <span
              *ngIf="
                !addClientForm.get('client_name_short').valid &&
                addClientForm.get('client_name_short').touched
              "
              [innerHTML]="
                'admin_portal.lsp.add_client_panel.name_short_form_field.error_message.content'
                  | translate
              "
            ></span>
          </mat-error>
        </mat-form-field>

        <div class="row">
          <div class="col-12 col-md-6">
            <!-- Shared client indicator -->
            <mat-form-field appearance="outline">
              <mat-label
                [innerHTML]="
                  'admin_portal.lsp.add_client_panel.sub_clients_selection.label.content'
                    | translate
                "
              ></mat-label>
              <mat-select
                [(ngModel)]="sharedClient"
                [ngModelOptions]="{ standalone: true }"
              >
                <mat-option [value]="false">{{
                  'admin_portal.lsp.add_client_panel.sub_clients_selection.responses.no_selection.content'
                    | translate
                }}</mat-option>
                <mat-option [value]="true">{{
                  'admin_portal.lsp.add_client_panel.sub_clients_selection.responses.yes_selection.content'
                    | translate
                }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-6" *ngIf="sharedClient">
            <mat-form-field appearance="outline">
              <mat-label
                [innerHTML]="
                  'admin_portal.lsp.add_client_panel.sub_clients_form_field.label.content'
                    | translate
                "
              ></mat-label>

              <mat-chip-grid
                formControlName="firms"
                #chipList
                aria-label="firms selection"
              >
                <mat-chip-row
                  *ngFor="let firm of firms"
                  (removed)="removeClientSharedFirm(firm)"
                >
                  {{ firm }}
                  <button
                    matChipRemove
                    *ngIf="removable"
                    [attr.aria-label]="'remove ' + firm"
                  >
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip-row>
              </mat-chip-grid>

              <input
                [placeholder]="
                  'admin_portal.lsp.add_client_panel.sub_clients_form_field.add_firm_placeholder.content'
                    | translate
                "
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="addClientSharedFirm($event)"
              />
            </mat-form-field>
          </div>
        </div>

        <!-- ID (subdomain) for client -->
        <mat-form-field class="w-100" appearance="outline">
          <mat-label
            [innerHTML]="
              'admin_portal.lsp.add_client_panel.subdomain_form_field.label.content'
                | translate
            "
          ></mat-label>
          <input
            matInput
            formControlName="client_subdomain"
            [errorStateMatcher]="matcher"
          />
          <mat-error>
            <span
              *ngIf="
                !addClientForm.get('client_subdomain').valid &&
                addClientForm.get('client_subdomain').touched
              "
              [innerHTML]="
                'admin_portal.lsp.add_client_panel.subdomain_form_field.error_message.content'
                  | translate
              "
            ></span>
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label
            [innerHTML]="
              'admin_portal.client.client_panel.languages.label.content'
                | translate
            "
          ></mat-label>

          <mat-chip-grid
            formControlName="client_languages"
            #chipList
            aria-label="Languages selection"
          >
            <mat-chip-row
              *ngFor="let lang of languages"
              (removed)="removeLang(lang)"
            >
              {{ 'languages.' + lang | translate }}
              <button
                matChipRemove
                *ngIf="removable"
                [attr.aria-label]="'remove ' + lang"
              >
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
          </mat-chip-grid>

          <input
            placeholder="{{
              'admin_portal.client.client_panel.languages.placeholder.content'
                | translate
            }}"
            [formControl]="langCtrl"
            [matChipInputFor]="chipList"
            [matAutocomplete]="auto"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          />

          <mat-autocomplete
            #auto="matAutocomplete"
            (optionSelected)="selectedLang($event)"
          >
            <mat-option
              *ngFor="let lang of filteredLanguages | async"
              [value]="lang"
            >
              {{ 'languages.' + lang | translate }}
            </mat-option>
          </mat-autocomplete>

          <mat-error>
            <span
              *ngIf="
                !addClientForm.get('client_languages').valid &&
                addClientForm.get('client_languages').touched
              "
              [innerHTML]="
                'admin_portal.client.client_panel.languages.error_message.content'
                  | translate
              "
            ></span>
          </mat-error>
        </mat-form-field>

        <!-- Default language for client -->
        <mat-form-field class="w-100" appearance="outline">
          <mat-label
            [innerHTML]="
              'admin_portal.lsp.add_client_panel.default_language_form_field.label.content'
                | translate
            "
          ></mat-label>
          <mat-select formControlName="client_default_language">
            <mat-option *ngFor="let lang of languages" [value]="lang">
              {{ 'languages.' + lang | translate }}
            </mat-option>
          </mat-select>
          <mat-error>
            <span
              *ngIf="
                !addClientForm.get('client_default_language').valid &&
                addClientForm.get('client_default_language').touched
              "
              [innerHTML]="
                'admin_portal.lsp.add_client_panel.default_language_form_field.error_message.content'
                  | translate
              "
            ></span>
          </mat-error>
        </mat-form-field>

        <!-- Submit button and messages -->
        <button
          class="w-100 mt-1 py-1"
          mat-raised-button
          type="submit"
          [disabled]="!addClientForm.valid || isLoading"
          color="primary"
          [innerHTML]="
            'admin_portal.lsp.add_client_panel.submit_button.content'
              | translate
          "
        ></button>
        <p
          *ngIf="addClientSuccess"
          class="success mt-3 text-center pt-0"
          [innerHTML]="
            'admin_portal.lsp.add_client_panel.success_message.content'
              | translate
          "
        ></p>
        <p
          *ngIf="addClientError"
          class="error mt-3 text-center pt-0"
          [innerHTML]="
            'admin_portal.lsp.add_client_panel.error_message.content'
              | translate
          "
        ></p>
      </form>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title
          [innerHTML]="
            'admin_portal.lsp.case_manager_panel.heading.content' | translate
          "
        ></mat-panel-title>
        <mat-panel-description
          [innerHTML]="
            'admin_portal.lsp.case_manager_panel.description.content'
              | translate
          "
        ></mat-panel-description>
      </mat-expansion-panel-header>
      <mat-form-field>
        <mat-label
          [innerHTML]="
            'admin_portal.lsp.case_manager_panel.filter_form_field.label.content'
              | translate
          "
        ></mat-label>
        <input
          matInput
          (keyup)="filterClients($event)"
          [placeholder]="
            'admin_portal.lsp.case_manager_panel.filter_form_field.placeholder.content'
              | translate
          "
        />
      </mat-form-field>
      <div class="tablecontainer mat-elevation-z8">
        <table mat-table [dataSource]="caseManagerDataSource">
          <!-- Display case manager of legal service provider -->

          <!-- Name of case manager -->
          <ng-container matColumnDef="name">
            <th
              mat-header-cell
              *matHeaderCellDef
              [innerHTML]="
                'admin_portal.lsp.case_manager_panel.table.name_column.content'
                  | translate
              "
            ></th>
            <td mat-cell *matCellDef="let case_manager">
              {{ case_manager.name }}
            </td>
          </ng-container>

          <!-- Email of case manager -->
          <ng-container matColumnDef="email">
            <th
              mat-header-cell
              *matHeaderCellDef
              [innerHTML]="
                'admin_portal.lsp.case_manager_panel.table.email_column.content'
                  | translate
              "
            ></th>
            <td mat-cell *matCellDef="let case_manager">
              {{ case_manager.email }}
            </td>
          </ng-container>

          <!-- Role of case manager -->
          <ng-container matColumnDef="role">
            <th
              mat-header-cell
              *matHeaderCellDef
              [innerHTML]="
                'admin_portal.lsp.case_manager_panel.table.role_column.content'
                  | translate
              "
            ></th>
            <td mat-cell *matCellDef="let case_manager">
              <mat-icon
                class="success"
                *ngIf="case_manager.role === 'case_manager_admin'"
                >done</mat-icon
              >
              <mat-icon
                class="error"
                *ngIf="case_manager.role === 'case_manager'"
                >close</mat-icon
              >
            </td>
          </ng-container>

          <!-- Is multi factor user of case manager -->
          <ng-container matColumnDef="is_multi_factor_user">
            <th
              mat-header-cell
              *matHeaderCellDef
              [innerHTML]="
                'admin_portal.lsp.case_manager_panel.table.is_multi_factor_user_column.content'
                  | translate
              "
            ></th>
            <td mat-cell *matCellDef="let case_manager">
              <mat-icon
                class="success"
                *ngIf="case_manager.is_multi_factor_user"
                >done</mat-icon
              >
              <mat-icon class="error" *ngIf="!case_manager.is_multi_factor_user"
                >close</mat-icon
              >
            </td>
          </ng-container>

          <!-- Change password of user -->
          <ng-container matColumnDef="change_password">
            <th
              mat-header-cell
              *matHeaderCellDef
              [innerHTML]="
                'admin_portal.settings.change_password_panel.heading.content'
                  | translate
              "
            ></th>
            <td mat-cell *matCellDef="let case_manager">
              <button
                mat-icon-button
                (click)="openDialogChangePassword(case_manager.id)"
              >
                <mat-icon aria-hidden="false" color="primary">lock</mat-icon>
              </button>
            </td>
          </ng-container>

          <!-- Activate / Deactivate case manager -->
          <ng-container matColumnDef="de_activate">
            <th
              mat-header-cell
              *matHeaderCellDef
              [innerHTML]="
                'admin_portal.lsp.case_manager_panel.table.de_activate_column.content'
                  | translate
              "
            ></th>
            <td mat-cell *matCellDef="let case_manager">
              <mat-slide-toggle
                [(ngModel)]="case_manager.is_active"
                (change)="updateCaseManagerActiveState(case_manager)"
                color="primary"
              ></mat-slide-toggle>
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="caseManagerTableDisplayedColumns; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: caseManagerTableDisplayedColumns"
          ></tr>
        </table>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title
          [innerHTML]="
            'admin_portal.lsp.add_case_manager_panel.heading.content'
              | translate
          "
        ></mat-panel-title>
        <mat-panel-description
          [innerHTML]="
            'admin_portal.lsp.add_case_manager_panel.description.content'
              | translate
          "
        ></mat-panel-description>
      </mat-expansion-panel-header>
      <form
        class="mb-3"
        [formGroup]="addCaseManagerForm"
        (ngSubmit)="createCaseManagerAccount()"
      >
        <!-- Name of case manager -->
        <mat-form-field class="w-100" appearance="outline">
          <mat-label
            [innerHTML]="
              'admin_portal.lsp.add_case_manager_panel.name_form_field.label.content'
                | translate
            "
          ></mat-label>
          <input
            matInput
            formControlName="caseManagerName"
            [errorStateMatcher]="matcher"
          />
          <mat-error>
            <span
              *ngIf="
                !addCaseManagerForm.get('caseManagerName').valid &&
                addCaseManagerForm.get('caseManagerName').touched
              "
              [innerHTML]="
                'admin_portal.lsp.add_case_manager_panel.name_form_field.error_message.content'
                  | translate
              "
            ></span>
          </mat-error>
        </mat-form-field>

        <!-- Email address of case manager -->
        <mat-form-field class="w-100" appearance="outline">
          <mat-label
            [innerHTML]="
              'admin_portal.lsp.add_case_manager_panel.email_form_field.label.content'
                | translate
            "
          ></mat-label>
          <input
            matInput
            type="email"
            formControlName="caseManagerEmail"
            [errorStateMatcher]="matcher"
          />
          <mat-error>
            <span
              *ngIf="
                !addCaseManagerForm.get('caseManagerEmail').valid &&
                addCaseManagerForm.get('caseManagerEmail').touched
              "
              [innerHTML]="
                'admin_portal.lsp.add_case_manager_panel.email_form_field.error_message.content'
                  | translate
              "
            ></span>
          </mat-error>
        </mat-form-field>

        <!-- Password of case manager -->
        <mat-form-field class="w-100" appearance="outline">
          <mat-label
            [innerHTML]="
              'admin_portal.lsp.add_case_manager_panel.password_form_field.label.content'
                | translate
            "
          ></mat-label>
          <input
            type="password"
            matInput
            formControlName="caseManagerPassword"
            [type]="hide ? 'password' : 'text'"
            [errorStateMatcher]="matcher"
          />
          <button
            type="button"
            mat-icon-button
            matSuffix
            (click)="hide = !hide"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide"
          >
            <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
          <mat-error>
            <span
              *ngIf="
                !addCaseManagerForm.get('caseManagerPassword').valid &&
                addCaseManagerForm.get('caseManagerPassword').touched
              "
              [innerHTML]="
                'admin_portal.lsp.add_case_manager_panel.password_form_field.error_message.content'
                  | translate
              "
            ></span>
          </mat-error>
        </mat-form-field>

        <!-- Role of case manager -->
        <mat-form-field class="w-100" appearance="outline">
          <mat-label
            [innerHTML]="
              'admin_portal.lsp.add_case_manager_panel.role_form_field.label.content'
                | translate
            "
          ></mat-label>
          <mat-select formControlName="caseManagerRole">
            <mat-option *ngFor="let role of possibleRoles" [value]="role">{{
              'admin_portal.lsp.add_case_manager_panel.role_form_field.options.' +
                role | translate
            }}</mat-option>
          </mat-select>
          <mat-error>
            <span
              *ngIf="
                !addCaseManagerForm.get('caseManagerRole').valid &&
                addCaseManagerForm.get('caseManagerRole').touched
              "
              [innerHTML]="
                'admin_portal.lsp.add_case_manager_panel.role_form_field.error_message.content'
                  | translate
              "
            ></span>
          </mat-error>
        </mat-form-field>

        <!-- Submit button and messages -->
        <button
          class="w-100 mt-1 py-1"
          mat-raised-button
          type="submit"
          [disabled]="!addCaseManagerForm.valid || isLoading"
          color="primary"
          [innerHTML]="
            'admin_portal.lsp.add_case_manager_panel.submit_button.content'
              | translate
          "
        ></button>
      </form>
      <p
        *ngIf="addCaseManagerSuccessMessage"
        class="success mt-3 text-center pt-0"
        [innerText]="addCaseManagerSuccessMessage"
      ></p>
      <p
        *ngIf="addCaseManagerErrorMessage"
        class="error mt-3 text-center pt-0"
        [innerText]="addCaseManagerErrorMessage"
      ></p>
    </mat-expansion-panel>
  </mat-accordion>
  <div class="mt-3">
    <button
      mat-flat-button
      [routerLink]="['admin']"
      routerLinkActive="router-link-active"
      [innerHTML]="'global.back_button.content' | translate"
    ></button>
  </div>
</mat-card>
