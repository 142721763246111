import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { onAuthStateChanged } from 'firebase/auth';
import {
  FirestoreError,
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
  updateDoc
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { ref, uploadBytes } from 'firebase/storage';
import { COUNTRIES } from 'src/app/shared/data/countries';
import { auth, firestore, functions, storage } from 'src/firebase';
import { CaseReportingPortalComponent } from '../case-reporting-portal/case-reporting-portal.component';
import { v4 as uuid } from 'uuid';
import { FirebaseError } from 'firebase/app';
import { Case } from 'src/app/shared/models/case.model';
import {
  ChatMessage,
  TranslationChatMessage
} from 'src/app/shared/models/chatMessage.model';
import { CaseFile } from 'src/app/shared/models/caseFile.model';
import {
  formatCaseCreatedWeekday,
  formatCreated
} from 'src/app/shared/functions/dateFormat';
import { FirebaseTimestamp } from 'src/app/shared/models/firebaseTimestamp.model';
import { DocChange } from 'src/app/shared/models/docChange';

@Component({
  selector: 'app-case-reporting-portal-case',
  templateUrl: './case-reporting-portal-case.component.html',
  styleUrls: ['./case-reporting-portal-case.component.scss']
})
export class CaseReportingPortalCaseComponent implements OnInit {
  // name selection in form
  nameSelection = false;

  // countries
  countries = COUNTRIES;

  // case object
  case: Case;

  // chat message
  message = '';

  // encrypted chat messages array
  encryptedChatMessages = [];

  // chat messages
  chatMessages: ChatMessage[] = [];

  // chat message validation
  messageMaxLength = 4096;
  chatErrorMessage = '';

  chatMessagesPossibleLangCodes = new Map();

  // lang code for message translation
  chatMessagesLangCode = 'none';

  @ViewChildren('messages')
  messages: ElementRef[];
  @ViewChild('content')
  content: ElementRef;

  // loading
  isLoading = false;

  // keep chat messages with id
  i = 0;

  // active tab
  activeTab: number;

  // angular material table setup
  displayedColumns: string[] = ['id', 'author', 'uploaded', 'download'];
  dataSource = new MatTableDataSource();

  // case files array
  caseFiles: CaseFile[] = [];

  // files for case
  files: File[] = [];

  // file validation
  maxFileCount = 20;
  maxFileSize = 5000000;
  filesErrorMessage = '';
  filesSuccessMessage = '';

  // file datasource for table
  filesDataSource = new MatTableDataSource();
  displayedColumnsFilesTable: string[] = ['file_name', 'remove'];

  constructor(
    public caseReportingPortalComponent: CaseReportingPortalComponent,
    private router: Router,
    private translate: TranslateService
  ) {}

  async getCase() {
    try {
      // get decrypted case data from functions
      const getCaseByCaseUser = httpsCallable<
        Record<string, never>,
        {
          case: Case;
          error?: FirebaseError;
        }
      >(functions, 'getCaseByCaseUser');

      const { data } = await getCaseByCaseUser();

      if (!data.case) {
        throw new Error('Error while case of user.');
      }

      // format case created date to locale date string
      const created = new Date(
        (data.case.created as FirebaseTimestamp)._seconds * 1000
      );
      data.case.created_formatted = formatCaseCreatedWeekday(
        created,
        this.translate.currentLang
      );

      this.translate.onLangChange.subscribe(
        () =>
          (data.case.created_formatted = formatCaseCreatedWeekday(
            created,
            this.translate.currentLang
          ))
      );

      this.case = data.case;

      // transform data
      this.transform();
    } catch (error) {
      console.error(error);
      // logout user because of error
      // this.logoutCaseUser();
      // navigate user because of error
      // this.router.navigate(['client']);
    }
  }

  async decryptChatCaseUser() {
    if (this.encryptedChatMessages.length > 0 && this.activeTab === 1) {
      // enable loading
      this.isLoading = true;
      try {
        // decrypt all chat messages
        const decryptChatCaseUser = httpsCallable<
          Record<string, never>,
          { decryptedChatMessages: ChatMessage[]; error?: FirebaseError }
        >(functions, 'decryptChatCaseUser');

        const { data } = await decryptChatCaseUser();

        if (!data.decryptedChatMessages) {
          throw new Error('Error while decrypting chat.');
        }

        this.chatMessages = data.decryptedChatMessages;

        for (let i = 0; i < this.chatMessages.length; i++) {
          // format chat message
          this.chatMessages[i].created_formatted = formatCreated(
            new Date(data.decryptedChatMessages[i].created_at._seconds * 1000),
            this.translate.currentLang
          );
        }

        // sort messages by date
        this.chatMessages.sort(
          (a, b) => a.created_at._seconds - b.created_at._seconds
        );

        // update new case manager message read to false
        this.updateCaseManagerMessagesToRead();

        // translate chat messages (depending on lang code)
        this.translateChatMessages(
          this.chatMessagesLangCode,
          this.chatMessages
        );

        // scroll to bottom in chat window
        setTimeout(() => {
          this.scrollToBottom();
        }, 1);

        this.translate.onLangChange.subscribe(() => {
          this.chatMessages.forEach((chatMessage) => {
            chatMessage.created_formatted = formatCreated(
              new Date(chatMessage.created_at._seconds * 1000),
              this.translate.currentLang
            );
          });
        });
      } catch (error) {
        console.error(error);
      }
      // disable loading
      this.isLoading = false;
    }
  }

  getChatMessages(lspId: string, clientId: string, caseId: string) {
    this.chatMessages = [];
    const unsubscribeChatMessages = onSnapshot(
      query(
        collection(
          firestore,
          'legal_service_providers',
          lspId,
          'clients',
          clientId,
          'cases',
          caseId,
          'chat_messages'
        ),
        orderBy('created_at', 'asc')
      ),
      (querySnapshot: { docChanges: () => DocChange[] }) => {
        querySnapshot.docChanges().forEach(async (change: DocChange) => {
          if (change.type === 'added') {
            // add new chat message to encrypted chat messages array
            this.encryptedChatMessages.push(change.doc.id);
          }

          // decrypt chat messages (if on chat page)
          this.decryptChatCaseUser();
        });
      },
      (error: FirestoreError) => {
        console.error(error);
        unsubscribeChatMessages();
      }
    );
  }

  // get case files
  getCaseFiles(lspId: string, clientId: string, caseId: string) {
    // unsubscribe from case files snapshot
    const unsubscribeCaseFiles = onSnapshot(
      query(
        collection(
          firestore,
          'legal_service_providers',
          lspId,
          'clients',
          clientId,
          'cases',
          caseId,
          'case_files'
        ),
        orderBy('uploaded', 'asc')
      ),
      (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            const data = change.doc.data();

            // format file uploaded date
            const caseFile = {
              id: change.doc.id,
              name: data.name,
              path: data.path,
              uploaded: data.uploaded,
              uploaded_formatted: formatCreated(
                new Date(data.uploaded.seconds * 1000),
                this.translate.currentLang
              ),
              is_case_user_file: data.is_case_user_file,
              downloaded: data.downloaded
            };

            // add file object to case files array
            this.caseFiles.push(caseFile);

            // sort case files by date
            this.caseFiles.sort(
              (a, b) => a.uploaded.seconds - b.uploaded.seconds
            );
          }
          // update case files table data source
          this.dataSource = new MatTableDataSource(this.caseFiles);
        });

        this.translate.onLangChange.subscribe(() => {
          this.caseFiles.forEach((caseFile) => {
            caseFile.uploaded_formatted = formatCreated(
              new Date(caseFile.uploaded.seconds * 1000),
              this.translate.currentLang
            );
          });
        });
      },
      (error: FirestoreError) => {
        console.error(error);
        unsubscribeCaseFiles();
      }
    );
  }

  async extract(lspId: string, clientId: string, uid: string) {
    // get case data
    await this.getCase();

    // get chat messages
    this.getChatMessages(lspId, clientId, uid);

    // get case files
    this.getCaseFiles(lspId, clientId, uid);

    // disable loading
    this.isLoading = false;
  }

  transform() {
    // structure data for frontend
    if (this.case.name) {
      this.nameSelection = true;
    }
  }

  async ngOnInit() {
    // enable loading
    this.isLoading = true;

    // check if user is still logged in (subscribe to auth state)
    onAuthStateChanged(auth, async (user) => {
      if (user === null) {
        this.router.navigate(['client', 'login']);
      }

      // get firebase auth
      const claims = (await auth.currentUser.getIdTokenResult()).claims;

      // redirect user to case if logged in
      if (claims['role'] !== 'case_user') {
        // ||
        // claims['client_id'] !== this.caseReportingPortalComponent.client.id
        // user is not logged in => redirect to login
        this.router.navigate(['client', 'login']);
      }

      if (
        typeof claims.lsp_id === 'string' &&
        typeof claims.client_id === 'string' &&
        typeof auth.currentUser.uid === 'string'
      ) {
        await this.extract(
          claims.lsp_id,
          claims.client_id,
          auth.currentUser.uid
        );
      }
    });

    this.chatMessagesPossibleLangCodes.set(
      'none',
      'case_reporting_portal.case.chat_page.translation_selection.options.none'
    );
    this.translate.getLangs().forEach((langCode: string | string) => {
      this.chatMessagesPossibleLangCodes.set(langCode, 'languages.' + langCode);
    });
  }

  // scroll to bottom inside chat
  scrollToBottom(): void {
    this.content.nativeElement.scrollTop =
      this.content.nativeElement.scrollHeight;
  }

  // translate chat messages
  async translateChatMessages(langCode: string, chatMessages: ChatMessage[]) {
    try {
      // enable loading
      this.isLoading = true;

      if (langCode === 'none') {
        // show original messages
        this.chatMessages = this.chatMessages.map((message) => {
          message.translation = undefined;
          return message;
        });
      } else {
        // format chat messages
        const chatMessagesFormatted: TranslationChatMessage[] = [];
        chatMessages.forEach((chatMessage) => {
          // only translate chat messages by case manager
          if (!chatMessage.is_case_user) {
            chatMessagesFormatted.push({
              id: chatMessage.id,
              text: chatMessage.text
            });
          }
        });

        if (chatMessagesFormatted.length > 0) {
          // translate chat messages in backend
          const translateChatMessages = httpsCallable<
            { chatMessages: TranslationChatMessage[]; langCode: string },
            {
              translatedChatMessages: ChatMessage[];
              langCode: string;
              error?: FirebaseError;
            }
          >(functions, 'translateChatMessages');

          const { data } = await translateChatMessages({
            chatMessages: chatMessagesFormatted,
            langCode: langCode
          });

          if (!data.translatedChatMessages) {
            throw new Error('Error while translated chat message.');
          }

          // merge translation with chat messages
          data.translatedChatMessages.forEach((chatMessage) => {
            const i = this.chatMessages.findIndex(
              (x) => x.id === chatMessage.id
            );
            this.chatMessages[i].translation = chatMessage.text;
          });

          // align lang code of translation selection
          this.chatMessagesLangCode = data.langCode;
        }
      }
    } catch (error) {
      console.error(error);
    }

    // disable laoding
    this.isLoading = false;
  }

  // send chat message
  async sendChatMessage(messageText: string) {
    try {
      // encrypt chat message
      const sendChatMessageCaseUser = httpsCallable<
        { messageText: string },
        { result: string; error?: FirebaseError }
      >(functions, 'sendChatMessageCaseUser');

      const { data } = await sendChatMessageCaseUser({
        messageText: messageText
      });

      if (!data.result) {
        throw new Error('Error while encrypt chat message.');
      }

      // clear message
      this.message = '';
    } catch (error) {
      console.error(error);
      // show error to user
      this.translate
        .get(
          'case_reporting_portal.case.error_messages.send_message_error.content'
        )
        .subscribe((res: string) => {
          this.chatErrorMessage = res;
        });
    }
    // deactivate loading
    this.isLoading = false;
  }

  // validate chat message on submit
  async onChatMessageFormSubmit(message: string) {
    // activate loading
    this.isLoading = true;
    // remove whitespaces at beginning and end of string
    message = message.trim();
    // validate message
    if (message.length > this.messageMaxLength) {
      this.translate
        .get(
          'case_reporting_portal.case.error_messages.message_too_long_error.content'
        )
        .subscribe((res: string) => {
          this.chatErrorMessage = res;
        });
    } else {
      await this.sendChatMessage(message);
    }
  }

  // update case manager messages to read
  async updateCaseManagerMessagesToRead() {
    try {
      // update new_case_manager_message to false in case doc
      await updateDoc(
        doc(
          firestore,
          'legal_service_providers',
          this.case.lsp_id,
          'clients',
          this.case.client_id,
          'cases',
          auth.currentUser.uid
        ),
        {
          new_case_manager_message: false
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  // download file from firebase storage
  async downloadFile(file: CaseFile) {
    try {
      // enable loading
      this.isLoading = true;

      // get signed download url
      const getFileDownloadUrl = httpsCallable<
        { filePath: string },
        { downloadUrl: string; error?: FirebaseError }
      >(functions, 'getFileDownloadUrl');

      const { data } = await getFileDownloadUrl({
        filePath: file.path
      });

      if (!data.downloadUrl) {
        throw new Error(
          'Error occured while trying to get download url for file.'
        );
      }

      // get blow of url from firebase storage
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = () => {
        const blob = xhr.response;

        // trigger file download with blob from api
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = file.id;
        document.body.appendChild(link);
        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
          })
        );

        // remove link from body
        document.body.removeChild(link);
      };
      // send request
      xhr.open('GET', data.downloadUrl);
      xhr.send();
    } catch (error) {
      console.error(error);
    }
    // disable loading
    this.isLoading = false;
  }

  // pass file to file variable on selection
  onFileChange(event: Event) {
    // clear success and error message
    this.filesSuccessMessage = '';
    this.filesErrorMessage = '';
    // check file size
    if (
      this.files.length +
        this.caseFiles.filter((x) => x.is_case_user_file == true).length +
        event.target['files'].length >
      this.maxFileCount
    ) {
      this.translate
        .get(
          'case_reporting_portal.case.error_messages.file_selection_errors.too_many_files_error.content'
        )
        .subscribe((res: string) => {
          this.filesErrorMessage = res;
        });
    } else {
      if (event.target['files'].length > 0) {
        Array.from(event.target['files']).forEach((file: File) => {
          // check if file was already selected
          if (
            this.files.find(
              (x: File) =>
                x.name === file.name &&
                x.size === file.size &&
                x.lastModified === file.lastModified
            )
          ) {
            this.translate
              .get(
                'case_reporting_portal.case.error_messages.file_selection_errors.upload_file_once_error.content'
              )
              .subscribe((res: string) => {
                this.filesErrorMessage = res;
              });
          } else {
            // check if file size does not exceed 5mb
            if (file.size > this.maxFileSize) {
              this.translate
                .get(
                  'case_reporting_portal.case.error_messages.file_selection_errors.file_too_large_error.content'
                )
                .subscribe((res: string) => {
                  this.filesErrorMessage = res;
                });
            } else {
              this.files.push(file);
            }
          }
        });
      }
    }
    this.filesDataSource = new MatTableDataSource(this.files);
  }

  // remove file from files array
  removeFile(file: File) {
    const index = this.files.indexOf(file);
    this.files.splice(index, 1);
    this.filesDataSource = new MatTableDataSource(this.files);
  }

  // upload files
  async uploadFiles(lspId: string, clientId: string, userUid: string) {
    // activate loading
    this.isLoading = true;

    const referenceUrl =
      'legal_service_providers/' +
      lspId +
      '/clients/' +
      clientId +
      '/cases/' +
      userUid +
      '/whistleblower';
    try {
      this.files.forEach(async (file: File) => {
        // storage reference for file
        const storageRef = ref(
          storage,
          referenceUrl + '/' + uuid() + '.' + file.name.split('.').pop()
        );
        await uploadBytes(storageRef, file);
      });
      // show success message
      this.translate
        .get(
          'case_reporting_portal.case.files_page.upload_further_files.file_upload_success_message.content'
        )
        .subscribe((res: string) => {
          this.filesSuccessMessage = res;
        });
      // clear files
      this.files = [];
    } catch (error) {
      console.error(error);
      // display error message
      this.translate
        .get(
          'case_reporting_portal.case.error_messages.file_upload_error.content'
        )
        .subscribe((res: string) => {
          this.filesErrorMessage = res;
        });
    }
    // deactivate loading
    this.isLoading = false;
  }

  onFilesSubmit() {
    // clear messages
    this.filesSuccessMessage = this.filesErrorMessage = '';

    // upload case files
    this.uploadFiles(
      this.case.lsp_id,
      this.case.client_id,
      auth.currentUser.uid
    );
  }

  // needed to disable language sortation
  returnZero() {
    return 0;
  }
}
