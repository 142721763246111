<div
  class="h5"
  mat-dialog-title
  [innerHTML]="
    'case_management_portal.case.chat_page.case_confirmation_update_confirm_dialog.title.content'
      | translate
  "
></div>
<div mat-dialog-content class="small">
  {{
    'case_management_portal.case.chat_page.case_confirmation_update_confirm_dialog.confirmation_text_part_one.content'
      | translate
  }}
  <b>{{ data.newConfirmationState }}</b>
  {{
    'case_management_portal.case.chat_page.case_confirmation_update_confirm_dialog.confirmation_text_part_two.content'
      | translate
  }}
</div>
<div mat-dialog-actions>
  <button
    mat-button
    [mat-dialog-close]="false"
    [innerHTML]="
      'case_management_portal.case.chat_page.case_confirmation_update_confirm_dialog.no_answer.content'
        | translate
    "
  ></button>
  <button
    mat-button
    [mat-dialog-close]="true"
    color="primary"
    [innerHTML]="
      'case_management_portal.case.chat_page.case_confirmation_update_confirm_dialog.yes_answer.content'
        | translate
    "
  ></button>
</div>
