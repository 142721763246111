import { ENTER, COMMA } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  FormGroupDirective,
  FormControl,
  FormGroup,
  Validators,
  FormBuilder
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FirebaseError } from 'firebase/app';
import { Unsubscribe, User, onAuthStateChanged } from 'firebase/auth';
import { onSnapshot, doc, query, collection } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { ref, uploadBytes } from 'firebase/storage';
import { Observable, startWith, map } from 'rxjs';
import { applyFilter } from 'src/app/shared/functions/tableFiltering';
import { FirebaseAuthService } from 'src/app/shared/services/firebase-auth.service';
import { auth, firestore, functions, storage } from 'src/firebase';
import { AdminPortalComponent } from '../admin-portal/admin-portal.component';
import { MyErrorStateMatcher } from 'src/app/shared/functions/errorStateMatcher';
import { CaseManager } from 'src/app/shared/models/caseManager.model';
import { LegalServiceProvider } from 'src/app/shared/models/legalServiceProvider.model';
import { Client } from 'src/app/shared/models/client.model';
import { AdminPortalLspPasswordDialogComponent } from '../admin-portal-lsp-password-dialog/admin-portal-lsp-password-dialog.component';

@Component({
  selector: 'app-admin-portal-lsp',
  templateUrl: './admin-portal-lsp.component.html',
  styleUrls: ['./admin-portal-lsp.component.scss']
})
export class AdminPortalLspComponent implements OnInit, OnDestroy {
  // user variable
  user: User;

  // lsp variable
  legalServiceProvider: LegalServiceProvider;

  // loading animation indicator variable
  isLoading = false;

  // unsubscribes for snapshot subscribtions
  unsubscribeLegalServiceProvider: Unsubscribe;
  unsubscribeClients: Unsubscribe;
  unsubscribeCaseManager: Unsubscribe;

  // clear form
  @ViewChild(FormGroupDirective) formDirective: FormGroupDirective;

  // lsp logo file
  lspLogoFile: File;
  fileSelectionError = '';

  // success and error for lsp update
  updateLspSuccess = false;
  updateLspError = false;

  // hostname and port for link of clients reporting portal
  hostname: string = location.hostname;
  port: string = location.port;
  partHost = this.hostname.slice(5);

  // angular material clients table setup
  clientsTableDisplayedColumns: string[] = ['name', 'link', 'active', 'view'];
  clientsDataSource = new MatTableDataSource();

  // sub-clients setup
  subClientsInput: string[] = [''];
  subClients: string[] = [''];

  // chips with input for firms
  sharedClient = false;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  firms: string[] = [];

  // chips with input for languages
  removableLang = true;
  separatorKeysCodesLang: number[] = [ENTER, COMMA];
  langCtrl = new FormControl();
  filteredLanguages: Observable<string[]>;
  selectLanguages = [];
  languages: string[] = [];
  allLanguages: string[] = this.translate.getLangs();

  // add client form
  addClientForm: FormGroup = this.formBuilder.group({
    client_name: ['', Validators.required],
    client_name_short: ['', Validators.required],
    firms: [this.firms],
    client_subdomain: [
      '',
      Validators.compose([Validators.required, Validators.maxLength(63)])
    ],
    client_languages: [this.languages],
    client_default_language: [
      '',
      Validators.compose([Validators.required, Validators.minLength(2)])
    ]
  });

  // client logo file
  clientLogoFile: File;
  clientFileSelectionError = '';

  // success and error for client creation
  addClientSuccess = false;
  addClientError = false;

  // angular material case manager table setup
  caseManagerTableDisplayedColumns: string[] = [
    'name',
    'email',
    'role',
    'is_multi_factor_user',
    'change_password',
    'de_activate'
  ];
  caseManagerDataSource = new MatTableDataSource();

  // add case manager form
  addCaseManagerForm: FormGroup = this.formBuilder.group({
    caseManagerName: ['', Validators.required],
    caseManagerEmail: [
      '',
      Validators.compose([Validators.email, Validators.required])
    ],
    caseManagerPassword: [
      'box2023!',
      Validators.compose([
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(100)
      ])
    ],
    caseManagerRole: [
      ['case_manager', 'case_manager_admin'],
      Validators.required
    ]
  });

  hide = false;

  // success and error messages for case manager creation
  addCaseManagerSuccessMessage = '';
  addCaseManagerErrorMessage = '';

  // possible roles
  possibleRoles = [];

  // error state matcher for form
  matcher = new MyErrorStateMatcher();

  @ViewChild('langInput') langInput: ElementRef<HTMLInputElement>;

  constructor(
    private firebaseAuth: FirebaseAuthService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public adminPortalComponent: AdminPortalComponent,
    private dialog: MatDialog,
    public translate: TranslateService
  ) {
    this.filteredLanguages = this.langCtrl.valueChanges.pipe(
      startWith(null),
      map((lang: string) =>
        lang ? this._filterLang(lang) : this.allLanguages.slice()
      )
    );

    for (let i = 0; i < this.selectLanguages.length; i++) {
      const lang = this.allLanguages.find(
        (lang) => lang === this.selectLanguages[i]
      );
      this.languages.push(lang);
    }
  }

  getLsp(lspId: string) {
    // define query
    this.unsubscribeLegalServiceProvider = onSnapshot(
      doc(firestore, 'legal_service_providers', lspId),
      (doc) => {
        if (!doc.exists()) {
          throw Error();
        } else {
          const data = doc.data();
          this.legalServiceProvider = {
            id: doc.id,
            name: data.name,
            name_short: data.name_short,
            withdrawal_email: data.withdrawal_email
          };
        }
      },
      (error) => {
        console.error(error);
        // redirect to home (lsp does not exist)
        this.router.navigate(['admin']);
      }
    );
  }

  // get all clients of legal service provider
  getClients(lspId: string) {
    // define query
    const q = query(
      collection(firestore, 'legal_service_providers', lspId, 'clients')
    );
    // listen to realtime updates
    this.unsubscribeClients = onSnapshot(
      q,
      (querySnapshot) => {
        const clients = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          const client: Client = {
            id: doc.id,
            subdomain: data.subdomain,
            lsp_id: data.lsp_id,
            lsp_name: data.lsp_name,
            lsp_name_short: data.lsp_name_short,
            lsp_withdrawal_email: data.lsp_withdrawal_email,
            case_manager: data.case_manager,
            name: data.name,
            name_short: data.name_short,
            languages: data.languages,
            default_language: data.default_language,
            is_shared: data.is_shared,
            is_active: data.is_active,
            reporting_portal_several_texts: data.reporting_portal_several_texts,
            reporting_portal_report_texts: data.reporting_portal_report_texts,
            sub_client_names: data.sub_client_names
          };
          clients.push(client);
        });

        // array of strings alphabetically
        clients.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });

        // update clients table
        this.clientsDataSource = new MatTableDataSource(clients);
      },
      (error) => {
        console.error(error);
        this.router.navigate(['admin']);
      }
    );
  }

  // get all case manager of legal service provider
  getCaseManager(lspId: string) {
    // define query
    const q = query(
      collection(firestore, 'legal_service_providers', lspId, 'case_manager')
    );
    // listen to realtime updates
    this.unsubscribeCaseManager = onSnapshot(
      q,
      (querySnapshot) => {
        const caseManager = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          const manager: CaseManager = {
            id: doc.id,
            name: data.name,
            email: data.email,
            role: data.role,
            default_receipt_confirmation_text:
              data.default_receipt_confirmation_text,
            default_closing_confirmation_text:
              data.default_closing_confirmation_text,
            is_multi_factor_user: data.is_multi_factor_user,
            country_code: data.country_code,
            phone_number: data.phone_number,
            is_active: data.is_active
          };
          caseManager.push(manager);
        });

        // array of strings alphabetically
        caseManager.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });

        // update clients table
        this.caseManagerDataSource = new MatTableDataSource(caseManager);
      },
      (error) => {
        console.error(error);
        this.router.navigate(['admin']);
      }
    );
  }

  async extract(lspId: string) {
    // get data of legal service provider
    this.getLsp(lspId);

    // get clients of legal service provider
    this.getClients(lspId);

    // get case manager of legal service provider
    this.getCaseManager(lspId);

    // disable loading
    this.isLoading = false;
  }

  async ngOnInit() {
    // enable loading
    this.isLoading = true;

    // check if user is still logged in (subscribe to auth state)
    onAuthStateChanged(auth, async (user) => {
      if (user !== null) {
        // get firebase auth
        const claims = (await auth.currentUser.getIdTokenResult()).claims;

        // redirect user to case if logged in
        if (claims.role !== 'admin') {
          // user is not logged in => redirect to login
          this.router.navigate(['admin/login']);
        }
        this.extract(this.route.snapshot.params.lsp_id);
        // disable loading
        this.isLoading = false;
      } else {
        // user is not logged in
        this.router.navigate(['admin/login']);
      }
    });

    this.translate
      .getTranslation(this.translate.currentLang)
      .subscribe((result) =>
        Object.keys(result).forEach((key) => {
          if (
            !key.startsWith(
              'admin_portal.lsp.add_case_manager_panel.role_form_field.options.'
            )
          ) {
            return;
          }

          this.possibleRoles.push(
            key.replace(
              'admin_portal.lsp.add_case_manager_panel.role_form_field.options.',
              ''
            )
          );
        })
      );
  }

  ngOnDestroy(): void {
    // unsubscribe from snapshot
    if (this.unsubscribeLegalServiceProvider) {
      this.unsubscribeLegalServiceProvider();
    }
    if (this.unsubscribeClients) {
      this.unsubscribeClients();
    }
    if (this.unsubscribeCaseManager) {
      this.unsubscribeCaseManager();
    }
  }

  // logo file was selected
  onFileSelected(event: Event) {
    // validate file type (only png allowed)
    if (
      event.target['files'].length === 1 &&
      event.target['files'][0].name.split('.').pop() === 'png'
    ) {
      this.lspLogoFile = event.target['files'][0];
      // clear selection error
      this.fileSelectionError = '';
    } else {
      // error during file selection or wrong file type
      this.translate
        .get('global.file_selection_format_error.content')
        .subscribe((res) => (this.fileSelectionError = res));
    }
  }

  async updateLsp() {
    try {
      // enable loading
      this.isLoading = true;

      // clear error and success
      this.updateLspSuccess = this.updateLspError = false;

      if (this.lspLogoFile) {
        // storage reference for file
        const storageRef = ref(
          storage,
          'legal_service_providers/' +
            this.legalServiceProvider.id +
            '/logo.png'
        );

        // upload file
        await uploadBytes(storageRef, this.lspLogoFile);

        // clear file
        this.lspLogoFile = undefined;
      }

      // show success messages
      this.updateLspSuccess = true;
    } catch (error) {
      // show error to user
      this.updateLspError = true;
    }
    // disable loading
    this.isLoading = false;
  }

  // table filtering
  filterClients(event: Event) {
    this.clientsDataSource.filter = applyFilter(event);
  }

  // add client shared firm
  addClientSharedFirm(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // add our firm
    if (value) {
      this.firms.push(value);
    }

    // clear the input value
    event.chipInput!.clear();
  }

  // remove client shared firm
  removeClientSharedFirm(firm: string): void {
    const index = this.firms.indexOf(firm);

    if (index >= 0) {
      this.firms.splice(index, 1);
    }
  }

  removeLang(language: string) {
    const index = this.languages.indexOf(language);

    if (index >= 0) {
      this.languages.splice(index, 1);
    }
  }

  selectedLang(event: MatAutocompleteSelectedEvent) {
    const value: string = event.option.value;
    const found = this.languages.find((lang: string) => lang === value);

    if (!found) {
      // add our language
      this.languages.push(value);
    }

    // clear the input value
    this.langCtrl.setValue(null);
  }

  private _filterLang(lang: string): string[] {
    const filterValue = lang.toLowerCase();

    return this.allLanguages.filter((lang: string) =>
      lang.toLowerCase().includes(filterValue)
    );
  }

  // create client
  async createClient() {
    try {
      // enable loading
      this.isLoading = true;

      // clear success and error messages
      this.addClientSuccess = this.addClientError = false;
      this.clientFileSelectionError = '';

      // retrieve form data
      const clientData = this.addClientForm.value;

      // create client in backend functions
      const createClient = httpsCallable<
        {
          lspId: string;
          clientName: string;
          clientNameShort: string;
          clientSubdomain: string;
          clientLanguages: string[];
          clientDefaultLanguage: string;
          firms?: string[];
        },
        { clientId: string; error?: FirebaseError }
      >(functions, 'createClient');

      const { data } = await createClient({
        lspId: this.legalServiceProvider.id,
        clientName: clientData.client_name,
        clientNameShort: clientData.client_name_short,
        clientSubdomain: clientData.client_subdomain,
        clientLanguages: this.languages,
        clientDefaultLanguage: clientData.client_default_language,
        firms: clientData.firms
      });

      if (!data.clientId) {
        // differentiate between errors (handle following errors)
        throw data.error;
      }

      // clear form and file selection
      this.formDirective.resetForm();
      this.sharedClient = false;
      this.firms = [];
      this.languages = [];
      this.clientLogoFile = undefined;

      // display success
      this.addClientSuccess = true;
    } catch (error) {
      // print error to user
      this.addClientError = true;
    }
    // disable loading
    this.isLoading = false;
  }

  // create case manager account
  async createCaseManagerAccount() {
    try {
      // enable loading
      this.isLoading = true;

      // clear success and error messages
      this.addCaseManagerSuccessMessage = this.addCaseManagerErrorMessage = '';

      // retrieve form data
      const {
        caseManagerName,
        caseManagerEmail,
        caseManagerPassword,
        caseManagerRole
      } = this.addCaseManagerForm.value;

      const trimmedStr = caseManagerEmail.trim();

      const createCaseManager = httpsCallable<
        {
          lspId: string;
          caseManagerName: string;
          caseManagerEmail: string;
          caseManagerPassword: string;
          caseManagerRole: string;
        },
        { uid: string; error?: FirebaseError }
      >(functions, 'createCaseManager');

      const { data } = await createCaseManager({
        lspId: this.legalServiceProvider.id,
        caseManagerName: caseManagerName,
        caseManagerEmail: trimmedStr.toLowerCase(),
        caseManagerPassword: caseManagerPassword,
        caseManagerRole: caseManagerRole
      });

      if (!data.uid) {
        // differentiate between errors (handle following errors: account with email exists)
        throw data.error;
      }

      // clear form
      this.addCaseManagerForm.reset();

      // reset form errors
      Object.keys(this.addCaseManagerForm.controls).forEach((key) => {
        this.addCaseManagerForm.controls[key].setErrors(null);
      });

      // display success
      this.translate
        .get('admin_portal.lsp.add_case_manager_panel.success_message.content')
        .subscribe((res) => (this.addCaseManagerSuccessMessage = res));
    } catch (error) {
      // print error to user (depending on error)
      if (
        error instanceof FirebaseError &&
        error.message ===
          'The email address is already in use by another account.'
      ) {
        this.translate
          .get(
            'admin_portal.lsp.add_case_manager_panel.email_already_exists_error_message.content'
          )
          .subscribe((res) => (this.addCaseManagerErrorMessage = res));
      } else {
        this.translate
          .get('admin_portal.lsp.add_case_manager_panel.error_message.content')
          .subscribe((res) => (this.addCaseManagerErrorMessage = res));
      }
    }
    // disable loading
    this.isLoading = false;
  }

  openDialogChangePassword(uid: string) {
    this.dialog.open(AdminPortalLspPasswordDialogComponent, {
      width: '400px',
      data: {
        caseManagerUid: uid,
        language: this.translate.currentLang
      }
    });
  }

  async updateCaseManagerActiveState(case_manager: CaseManager) {
    try {
      // enable loading
      this.isLoading = true;

      // callable backend function
      const deActivateCaseManagerUser = httpsCallable<
        { caseManagerUid: string },
        { result: string; error?: FirebaseError }
      >(functions, 'deActivateCaseManagerUser');

      // (de) activate case manager account
      const { data } = await deActivateCaseManagerUser({
        caseManagerUid: case_manager.id
      });

      if (!data.result) {
        throw data.error;
      }
    } catch (error) {
      // reset active of case manager active state
      const caseManagerArray: CaseManager[] = this.caseManagerDataSource
        .data as CaseManager[];
      const i = caseManagerArray.findIndex((x) => x.id == case_manager.id);
      caseManagerArray[i].is_active = !caseManagerArray[i].is_active;
    }
    // disable loading
    this.isLoading = false;
  }
}
