<img
  class="integribox-logo non-responsive-lg"
  height="30px"
  src="/assets/images/integribox_logo_light.png"
  alt="logo"
/>
<div class="header">
  <div class="top-nav-bar top-0 position-fixed w-100">
    <div class="d-flex header-flex text-light align-items-center h-100">
      <div
        (click)="navigateTo('/admin')"
        (keypress)="navigateTo('/admin')"
        tabindex="auto"
        class="h6 mt-2 button-orange py-2 px-3"
        [innerHTML]="'admin.toolbar.title.content' | translate"
      ></div>
    </div>
    <button
      mat-icon-button
      *ngIf="loggedIn"
      class="text-white non-responsive settings-button-non-reponsive"
      aria-label="Settings icon"
      [routerLink]="['/admin/settings']"
      routerLinkActive="router-link-active"
    >
      <mat-icon>settings</mat-icon>
    </button>
    <div class="bg-white language-selection-button non-responsive">
      <mat-form-field class="small" appearance="fill" *mcRerender="trigger">
        <mat-label
          [innerHTML]="'toolbar.language_selection_title.content' | translate"
        ></mat-label>
        <mat-select [(ngModel)]="lang" (selectionChange)="translate.use(lang)">
          <mat-option *ngFor="let lang of translate.getLangs()" [value]="lang">
            {{ 'languages.' + lang | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <button
      mat-icon-button
      *ngIf="loggedIn"
      (click)="openLogoutConfirmationDialog()"
      class="text-white non-responsive logout-button-non-reponsive"
      aria-label="Logout icon"
    >
      <mat-icon>power_settings_new</mat-icon>
    </button>
    <div class="responsive">
      <button
        mat-icon-button
        class="text-white menu-button-reponsive"
        [matMenuTriggerFor]="menu"
        aria-label="Open menu button"
      >
        <mat-icon class="menu-icon">menu</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button
          *ngIf="loggedIn"
          mat-menu-item
          [routerLink]="['/admin/settings']"
          routerLinkActive="router-link-active"
        >
          <mat-icon>settings</mat-icon>
          {{ 'admin_portal.settings_button.content' | translate }}
        </button>
        <button
          *ngIf="loggedIn"
          mat-menu-item
          (click)="openLogoutConfirmationDialog()"
        >
          <mat-icon>power_settings_new</mat-icon>
          {{ 'footer.logout_button.content' | translate }}
        </button>
        <button mat-menu-item [matMenuTriggerFor]="language_menu">
          <mat-icon>translate</mat-icon>
          {{ 'toolbar.language_selection_title.content' | translate }}
        </button>
        <mat-menu #language_menu="matMenu">
          <button
            mat-menu-item
            *ngFor="let lang of translate.getLangs()"
            (click)="translate.use(lang)"
          >
            <span [innerHTML]="'languages.' + lang | translate"></span>
          </button>
        </mat-menu>
      </mat-menu>
    </div>
  </div>
</div>
<div class="content">
  <router-outlet></router-outlet>
</div>
