<div
  class="h5"
  mat-dialog-title
  [innerHTML]="
    'case_management_portal.case.case_overview_page.delete_case_dialog.title.content'
      | translate
  "
></div>
<div mat-dialog-content class="small">
  <p
    class="delete-case"
    [innerHTML]="
      'case_management_portal.case.case_overview_page.delete_case_dialog.explanation.content'
        | translate: data
    "
  ></p>
  <mat-form-field color="warn">
    <input
      matInput
      [placeholder]="data.caseId"
      [(ngModel)]="confirmationInput"
      (input)="onInputChange()"
    />
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button
    mat-button
    (click)="onCancel()"
    [innerHTML]="
      'case_management_portal.case.case_overview_page.delete_case_dialog.cancel.content'
        | translate
    "
  ></button>
  <button
    mat-button
    [disabled]="!deleteConfirmed"
    (click)="onConfirm()"
    color="warn"
    [innerHTML]="
      'case_management_portal.case.case_overview_page.delete_case_dialog.submit.content'
        | translate
    "
  ></button>
</div>
