export const formatCreated = (created: Date, currentLang: string) => {
  return created.toLocaleDateString(currentLang, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  });
};

export const formatDeadline = (deadline: Date, currentLang: string) => {
  return deadline.toLocaleDateString(currentLang, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  });
};

export const formatCaseCreatedWeekday = (
  created: Date,
  currentLang: string
) => {
  return created.toLocaleDateString(currentLang, {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  });
};
