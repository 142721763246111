<div
  class="h5"
  mat-dialog-title
  [innerHTML]="'footer.logout_confirmation_dialog.heading.content' | translate"
></div>
<div
  mat-dialog-content
  class="small"
  [innerHTML]="
    'footer.logout_confirmation_dialog.logout_confirmation_question.content'
      | translate
  "
></div>
<div mat-dialog-actions>
  <button
    mat-button
    [mat-dialog-close]="false"
    [innerHTML]="
      'footer.logout_confirmation_dialog.cancel_button.content' | translate
    "
  ></button>
  <button
    mat-button
    [mat-dialog-close]="true"
    color="primary"
    [innerHTML]="
      'footer.logout_confirmation_dialog.confirm_button.content' | translate
    "
  ></button>
</div>
