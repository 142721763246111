import { Component, NgZone, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  onAuthStateChanged,
  getMultiFactorResolver,
  PhoneMultiFactorGenerator,
  PhoneAuthProvider,
  RecaptchaVerifier,
  MultiFactorResolver,
  PhoneMultiFactorInfo
} from 'firebase/auth';
import { MyErrorStateMatcher } from 'src/app/shared/functions/errorStateMatcher';
import { FirebaseAuthService } from 'src/app/shared/services/firebase-auth.service';
import { auth } from 'src/firebase';
import { AdminPortalComponent } from '../admin-portal/admin-portal.component';
import { FirebaseError } from 'firebase/app';

@Component({
  selector: 'app-admin-portal-login',
  templateUrl: './admin-portal-login.component.html',
  styleUrls: ['./admin-portal-login.component.scss']
})
export class AdminPortalLoginComponent implements OnInit {
  // form variable
  loginForm: FormGroup = this.formBuilder.group({
    username: [
      '',
      Validators.compose([Validators.required, Validators.minLength(6)])
    ],
    password: ['', Validators.required]
  });

  // error state matcher for form
  matcher = new MyErrorStateMatcher();

  // hide password at login form (default)
  hide = true;

  // message variables
  errorMessage = '';

  // prevent duplicated api requests
  isLoading = false;

  // mfa variables
  resolver: MultiFactorResolver;
  verificationId: string;
  verificationCode = '';

  // recaptcha verifier
  recaptchaVerifier: RecaptchaVerifier;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private firebaseAuth: FirebaseAuthService,
    private ngZone: NgZone,
    public adminPortalComponent: AdminPortalComponent,
    public translate: TranslateService
  ) {}

  async ngOnInit() {
    // wait until auth is initialized
    const user = await this.firebaseAuth.getCurrentUser();

    if (user !== null) {
      // get firebase auth claims
      const claims = (await auth.currentUser.getIdTokenResult()).claims;

      // redirect user to home if logged in
      if (claims.role === 'admin') {
        // user is logged in => redirect to home
        this.router.navigate(['admin']);
      }
    }

    // check if user is logged in (subscribe to auth state)
    onAuthStateChanged(auth, async (user) => {
      if (user !== null) {
        // get firebase auth claims
        const claims = (await auth.currentUser.getIdTokenResult()).claims;

        // redirect admin to home if logged in
        if (claims.role === 'admin') {
          // user is logged in => redirect to home
          this.ngZone.run(() => this.router.navigate(['admin']));
        }
      }
    });

    // setup google recaptcha identity validation for multi factor authentication
    this.recaptchaVerifier = new RecaptchaVerifier(
      auth,
      'recaptcha-container',
      {
        size: 'invisible',
        callback: () => {
          return null;
        }
      }
    );
  }

  // handle login error
  handleLoginError(error: FirebaseError) {
    // Handle other errors such as wrong password.
    // logout user in case already logged in
    this.firebaseAuth.signOutUser();
    // empty resolver and verification id variables
    this.resolver = this.verificationId = undefined;
    if (
      error.code === 'auth/invalid-verification-code' ||
      error.code === 'auth/multi-factor-auth-required'
    ) {
      // display mfa error message
      this.translate
        .get('admin_portal.login.error_messages.mfa_error.content')
        .subscribe((res) => (this.errorMessage = res));
    } else {
      // display error message
      this.translate
        .get(
          'admin_portal.login.error_messages.wrong_username_or_password.content'
        )
        .subscribe((res) => (this.errorMessage = res));
    }
    // deactivate loading var if login failed
    this.isLoading = false;
  }

  // login case user with credentials
  async login(email: string, password: string) {
    try {
      // sign in user
      await this.firebaseAuth.signInUser(email, password);
      // validate user role for case management portal (user is not enrolled with a second factor)
      this.validateUserRole();
    } catch (error) {
      // check if login failed because of second factor
      if (error.code === 'auth/multi-factor-auth-required') {
        try {
          // get mfa resolver from code
          this.resolver = getMultiFactorResolver(auth, error);
          // check if factor is valid (phone)
          const selectedIndex = 0;
          if (
            this.resolver.hints[selectedIndex].factorId ==
            PhoneMultiFactorGenerator.FACTOR_ID
          ) {
            // set phone info options
            const phoneInfoOptions = {
              multiFactorHint: this.resolver.hints[0],
              session: this.resolver.session
            };
            // initalize phone auth provider
            const phoneAuthProvider = new PhoneAuthProvider(auth);

            // get verification id by sending SMS verification code to user and print verification code input to user
            this.verificationId = await phoneAuthProvider.verifyPhoneNumber(
              phoneInfoOptions,
              this.recaptchaVerifier
            );
          } else {
            throw new Error('Factor ID for MFA is invalid.');
          }
        } catch (error) {
          // logout user in case already logged in
          this.firebaseAuth.signOutUser();
          // unset resolver and verification id variables
          this.resolver = this.verificationId = undefined;
          // display mfa error message
          this.translate
            .get('admin_portal.login.error_messages.mfa_error.content')
            .subscribe((res) => (this.errorMessage = res));
        }
      } else {
        // logout user in case already logged in
        this.firebaseAuth.signOutUser();
        // display wrong password error message
        this.translate
          .get(
            'admin_portal.login.error_messages.wrong_username_or_password.content'
          )
          .subscribe((res) => (this.errorMessage = res));
      }
    }
  }

  // verify SMS verification code (mfa)
  async verifySMSVerificationCode() {
    try {
      // enable loading
      this.isLoading = true;

      // validate required variables for sms verification code validation
      if (
        !this.resolver ||
        !this.verificationId ||
        !(this.verificationCode.length == 6)
      ) {
        throw new Error('Missing data for SMS verification code validation.');
      }

      // verify SMS code with Google Identity Platform API
      const cred = PhoneAuthProvider.credential(
        this.verificationId,
        this.verificationCode
      );
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

      // complete user sign-in
      await this.resolver.resolveSignIn(multiFactorAssertion);

      // validate user role for case management portal
      this.validateUserRole();
    } catch (error) {
      // logout user in case already logged in
      this.firebaseAuth.signOutUser();
      // reset verification code input
      this.verificationCode = '';
      // display mfa error message (depending if invalid code)
      if (error.code === 'auth/invalid-verification-code') {
        this.translate
          .get('admin_portal.login.error_messages.invalid_code.content')
          .subscribe((res) => (this.errorMessage = res));
      } else {
        this.translate
          .get('admin_portal.login.error_messages.mfa_error.content')
          .subscribe((res) => (this.errorMessage = res));
      }
      // disable loading if login failed
      this.isLoading = false;
    }
  }

  // validate user role when login was successful
  async validateUserRole() {
    try {
      // check if authenticated user is case user
      const claims = (await auth.currentUser.getIdTokenResult()).claims;

      if (claims.role !== 'admin') {
        // user is not a case user
        this.firebaseAuth.signOutUser();
        // display error message
        this.translate
          .get(
            'admin_portal.login.error_messages.wrong_username_or_password.content'
          )
          .subscribe((res) => (this.errorMessage = res));
      } else {
        // redirect admin user to home
        this.router.navigate(['admin']);
      }
    } catch (error) {
      // logout user in case already logged in
      this.firebaseAuth.signOutUser();
      // unset resolver and verification id variables
      this.resolver = this.verificationId = undefined;
      // display wrong password error message
      this.translate
        .get(
          'admin_portal.login.error_messages.wrong_username_or_password.content'
        )
        .subscribe((res) => (this.errorMessage = res));
    }
  }

  async onFormSubmit() {
    // activate loading var
    this.isLoading = true;

    // clear error message
    this.errorMessage = '';

    // retrieve form data
    const loginCredentials = this.loginForm.value;

    // format username to email
    const email = loginCredentials.username + '@integrity-box.com';

    // login user
    await this.login(email, loginCredentials.password);

    // disable loading if login failed
    this.isLoading = false;
  }

  getPhoneNumber() {
    return (this.resolver.hints[0] as PhoneMultiFactorInfo).phoneNumber;
  }
}
