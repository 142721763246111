import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper, StepperOrientation } from '@angular/material/stepper';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { httpsCallable } from 'firebase/functions';
import { ref, uploadBytes } from 'firebase/storage';
import jsPDF from 'jspdf';
import { COUNTRIES } from 'src/app/shared/data/countries';
import { MyErrorStateMatcher } from 'src/app/shared/functions/errorStateMatcher';
import { generateRandomString } from 'src/app/shared/functions/generate';
import { environment } from 'src/environments/environment';
import { functions, storage } from 'src/firebase';
import { CaseReportingPortalComponent } from '../case-reporting-portal/case-reporting-portal.component';
import { v4 as uuid } from 'uuid';
import { Client } from 'src/app/shared/models/client.model';
import { ReportedCase } from 'src/app/shared/models/case.model';
import { FirebaseError } from 'firebase/app';

@Component({
  selector: 'app-case-reporting-portal-report-case',
  templateUrl: './case-reporting-portal-report-case.component.html',
  styleUrls: ['./case-reporting-portal-report-case.component.scss']
})
export class CaseReportingPortalReportCaseComponent
  implements OnInit, OnDestroy
{
  // form variable
  reportCaseForm: FormGroup;

  // current client
  client: Client;

  // indicator whether to show sub client selection
  hasSubClient = false;

  // name selection in form
  nameSelection = false;

  // countries
  countries = COUNTRIES;

  // error messages and success variables
  success = false;
  errorMessage = '';

  // error state matcher for form
  matcher = new MyErrorStateMatcher();

  // loading variable to prevent duplicated api requests
  isLoading = false;

  // case user credentials
  caseUserCredentials = {
    caseId: '',
    password: ''
  };

  // hide custom password at report form (default)
  hide = true;

  // files for case
  files: File[] = [];

  // file validation
  maxFileCount = 10;
  maxFileSize = 5000000;

  // file datasource for table
  filesDataSource = new MatTableDataSource();
  displayedColumns: string[] = ['file_name', 'remove'];

  // mat stepper
  @ViewChild('stepper') stepper: MatStepper;

  // mat stepper validation controls
  reCaptchaSiteKey = environment.reCaptchaSiteKey;
  captchaResolved: string;

  // orientation of stepper
  stepperOrientation: StepperOrientation = 'horizontal';

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public caseReportingPortalComponent: CaseReportingPortalComponent,
    public translate: TranslateService
  ) {}

  setupForm() {
    this.reportCaseForm = this.formBuilder.group({
      sub_client_name: [null, this.hasSubClient ? Validators.required : null],
      subject: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(300)])
      ],
      name: [''],
      description: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(4096)])
      ],
      date: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(100)])
      ],
      location: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(1024)])
      ],
      affected: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(1024)])
      ],
      observation: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(1024)])
      ],
      relationship: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(1024)])
      ],
      additional_information: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(1024)])
      ],
      email: [
        '',
        Validators.compose([Validators.email, Validators.maxLength(100)])
      ],
      country: [
        '',
        Validators.compose([Validators.required, Validators.maxLength(2)])
      ],
      password: [
        '',
        Validators.compose([Validators.minLength(8), Validators.maxLength(100)])
      ]
    });
  }

  // resolve the reCaptcha and retrieve a token
  async resolved(captchaResponse: string) {
    // declare token send function with a token parameter
    this.captchaResolved = captchaResponse;
  }

  // check device screen for design of stepper
  checkDeviceScreenSize() {
    // check device width to determine stepper orientation
    const width = window.innerWidth > 0 ? window.innerWidth : screen.width;

    if (width <= 766) {
      this.stepperOrientation = 'vertical';
    }
  }

  ngOnInit(): void {
    // current client
    this.client = this.caseReportingPortalComponent.client;

    if (
      this.client.sub_client_names &&
      this.client.sub_client_names.length > 1
    ) {
      this.hasSubClient = true;
    }

    // check if whistleblower country was selected
    if (!this.caseReportingPortalComponent.country) {
      this.router.navigate([
        'client',
        this.caseReportingPortalComponent.subdomain
      ]);
    }

    // setup form
    this.setupForm();

    // determine devices screen for different layouts
    this.checkDeviceScreenSize();
  }

  ngOnDestroy(): void {
    this.caseReportingPortalComponent.country = undefined;
  }

  // pass file to file variable on selection (with validation)
  onFileChange(event: Event) {
    // clear error message
    this.errorMessage = '';
    // check file size
    if (this.files.length + event.target['files'].length > this.maxFileCount) {
      this.translate
        .get(
          'case_reporting_portal.report_case.error_messages.file_selection_errors.too_many_files_error.content'
        )
        .subscribe((res: string) => {
          this.errorMessage = res;
        });
    } else {
      if (event.target['files'].length > 0) {
        Array.from(event.target['files']).forEach((file: File) => {
          // check if file was already selected
          if (
            this.files.find(
              (x: File) =>
                x.name === file.name &&
                x.size === file.size &&
                x.lastModified === file.lastModified
            )
          ) {
            this.translate
              .get(
                'case_reporting_portal.report_case.error_messages.file_selection_errors.upload_file_once_error.content'
              )
              .subscribe((res: string) => {
                this.errorMessage = res;
              });
          } else {
            // check if file size does not exceed 5MB
            if (file.size > this.maxFileSize) {
              this.translate
                .get(
                  'case_reporting_portal.report_case.error_messages.file_selection_errors.file_too_large_error.content'
                )
                .subscribe((res: string) => {
                  this.errorMessage = res;
                });
            } else {
              this.files.push(file);
            }
          }
        });
      }
    }
    this.filesDataSource = new MatTableDataSource(this.files);
  }

  // remove file from files array
  removeFile(file: File) {
    const index = this.files.indexOf(file);
    this.files.splice(index, 1);
    this.filesDataSource = new MatTableDataSource(this.files);
  }

  // upload files
  async uploadFiles(lspId: string, clientId: string, userId: string) {
    const referenceUrl =
      'legal_service_providers/' +
      lspId +
      '/clients/' +
      clientId +
      '/cases/' +
      userId +
      '/whistleblower';
    try {
      this.files.forEach(async (file: File) => {
        // storage reference for file
        const storageRef = ref(
          storage,
          referenceUrl + '/' + uuid() + '.' + file.name.split('.').pop()
        );
        await uploadBytes(storageRef, file);
      });
    } catch (error) {
      console.error(error);
      // display error message
      this.translate
        .get(
          'case_reporting_portal.report_case.error_messages.file_upload_error.content'
        )
        .subscribe((res: string) => {
          this.errorMessage = res;
        });
    }
  }

  async reportCase(reportedCase: ReportedCase) {
    try {
      // firebase functions
      const reportCase = httpsCallable<
        { reportedCase: ReportedCase },
        {
          userId: string;
          caseId: string;
          password: string;
          error?: FirebaseError;
        }
      >(functions, 'reportCase');

      const { data } = await reportCase({
        reportedCase
      });

      if (!data.userId) {
        throw new Error('Case ID or password were not passed from backend.');
      }

      // retrieve result data
      this.caseUserCredentials.caseId = data.caseId;
      this.caseUserCredentials.password = data.password;

      if (this.files.length > 0) {
        // upload case files
        await this.uploadFiles(
          this.caseReportingPortalComponent.client.lsp_id,
          this.caseReportingPortalComponent.client.id,
          data.userId
        );
      }

      // reset form
      this.setupForm();

      // complete the current step
      this.stepper.selected.completed = true;

      // move to next step (success)
      this.stepper.next();
    } catch (error) {
      console.error(error);
      // display error
      this.translate
        .get(
          'case_reporting_portal.report_case.error_messages.case_reporting_error.content'
        )
        .subscribe((res: string) => {
          this.errorMessage = res;
        });
    }
    this.isLoading = false;
  }

  async onFormSubmit() {
    // enable loading
    this.isLoading = true;

    // clear messages
    this.errorMessage = '';

    // retrieve form data
    const data = this.reportCaseForm.value;

    const reportedCase = {
      captchaResolved: this.captchaResolved,
      lspId: this.caseReportingPortalComponent.client.lsp_id,
      clientId: this.caseReportingPortalComponent.client.id,
      subClientName: data.sub_client_name,
      subject: data.subject,
      name: data.name,
      description: data.description,
      date: data.date,
      location: data.location,
      affected: data.affected,
      observation: data.observation,
      relationship: data.relationship,
      additionalInformation: data.additional_information,
      email: data.email,
      country: data.country,
      password: data.password,
      fileCount: this.files.length,
      reportingCountry: this.caseReportingPortalComponent.country
    };

    // optionally remove sub_client_name
    if (!this.hasSubClient) {
      delete reportedCase.subClientName;
    }

    // remove email if email was not provided
    if (reportedCase.email === null || reportedCase.email === '') {
      delete reportedCase.email;
    }

    // generate password if user did not add custom password
    if (reportedCase.password === null || reportedCase.password === '') {
      reportedCase.password = generateRandomString(8);
    }

    // report case in functions (incl. send confirmation emails)
    await this.reportCase(reportedCase);
  }

  // generate credentials pdf
  downloadCredentialsAsPDF(credentials: { caseId: string; password: string }) {
    const doc = new jsPDF();
    // add credentials to pdf doc
    doc.setFont('courier');
    doc.setFontSize(11);
    doc.text(credentials.caseId, 20, 20);
    doc.text(credentials.password, 20, 26);
    // download file
    doc.save('credentials.pdf');
  }

  // scroll to top
  scrollTop() {
    window.scroll(0, 0);
  }
}
