export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyDYmW5qbqPoxBaBwhDaypkUbdE7zHR0JNs',
    authDomain: 'integritybox-prelive.firebaseapp.com',
    projectId: 'integritybox-prelive',
    storageBucket: 'integritybox-prelive.appspot.com',
    messagingSenderId: '520788811814',
    appId: '1:520788811814:web:c3ccc4e90612c2446f06e7'
  },
  appCheckReCaptchaSiteKey: '6LdqR4AkAAAAAIYmTDk_-QrFwnTJkz_s42eHntpo',
  reCaptchaSiteKey: '6Lditf0cAAAAAEM2Nx_AngVpclMT5Lvf1Xf0kkoG'
};
