<h1
  mat-dialog-title
  [innerHTML]="
    'admin_portal.settings.change_password_panel.heading.content' | translate
  "
></h1>
<div mat-dialog-content>
  <form [formGroup]="changePasswordForm" (ngSubmit)="onFormSubmit()">
    <!-- Admin password -->
    <mat-form-field appearance="outline">
      <mat-label
        [innerHTML]="
          'admin_portal.settings.change_password_panel.password_form_field.label.content'
            | translate
        "
      ></mat-label>
      <input
        matInput
        [type]="hide ? 'password' : 'text'"
        maxlength="100"
        formControlName="password"
      />
      <button
        type="button"
        mat-icon-button
        matSuffix
        (click)="hide = !hide"
        [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hide"
      >
        <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
      </button>
      <mat-hint
        align="end"
        [innerText]="changePasswordForm.value.password.length + ' / 100'"
      ></mat-hint>
      <mat-error>
        <span
          *ngIf="
            !changePasswordForm.get('password').valid &&
            changePasswordForm.get('password').touched
          "
          [innerHTML]="
            'admin_portal.settings.change_password_panel.password_form_field.error_message.content'
              | translate
          "
        ></span>
      </mat-error>
    </mat-form-field>
    <!-- New password -->
    <mat-form-field appearance="outline">
      <mat-label
        [innerHTML]="
          'admin_portal.settings.change_password_panel.new_password_form_field.label.content'
            | translate
        "
      ></mat-label>
      <input
        matInput
        [type]="hide ? 'password' : 'text'"
        maxlength="100"
        formControlName="newPassword"
      />
      <button
        type="button"
        mat-icon-button
        matSuffix
        (click)="hide = !hide"
        [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hide"
      >
        <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
      </button>
      <mat-hint
        align="end"
        [innerText]="changePasswordForm.value.newPassword.length + ' / 100'"
      ></mat-hint>
      <mat-error>
        <span
          *ngIf="
            !changePasswordForm.get('newPassword').valid &&
            changePasswordForm.get('newPassword').touched
          "
          [innerHTML]="
            'admin_portal.settings.change_password_panel.new_password_form_field.error_message.content'
              | translate
          "
        ></span>
      </mat-error>
    </mat-form-field>
    <!-- New password (repeated) -->
    <mat-form-field appearance="outline">
      <mat-label
        [innerHTML]="
          'admin_portal.settings.change_password_panel.repeat_new_password_form_field.label.content'
            | translate
        "
      ></mat-label>
      <input
        matInput
        [type]="hide ? 'password' : 'text'"
        maxlength="100"
        formControlName="newPassword2"
      />
      <button
        type="button"
        mat-icon-button
        matSuffix
        (click)="hide = !hide"
        [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hide"
      >
        <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
      </button>
      <mat-hint
        align="end"
        [innerText]="changePasswordForm.value.newPassword2.length + ' / 100'"
      ></mat-hint>
      <mat-error>
        <span
          *ngIf="
            !changePasswordForm.get('newPassword2').valid &&
            changePasswordForm.get('newPassword2').touched
          "
          [innerHTML]="
            'admin_portal.settings.change_password_panel.repeat_new_password_form_field.error_message.content'
              | translate
          "
        ></span>
      </mat-error>
    </mat-form-field>
    <!-- Submit button -->
    <button
      class="w-100 mt-2"
      type="submit"
      mat-raised-button
      color="primary"
      [disabled]="!changePasswordForm.valid || isLoading"
      [innerHTML]="
        'admin_portal.settings.change_password_panel.submit_button.content'
          | translate
      "
    ></button>
    <p
      class="success mt-3"
      *ngIf="changePasswordSuccessMessage"
      [innerHTML]="changePasswordSuccessMessage"
    ></p>
    <p
      class="error mt-3"
      *ngIf="changePasswordErrorMessage"
      [innerHTML]="changePasswordErrorMessage"
    ></p>
  </form>
</div>
<div mat-dialog-actions>
  <button
    mat-button
    mat-dialog-close
    [innerHTML]="'global.back_button.content' | translate"
  ></button>
</div>
