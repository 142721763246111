import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormGroup,
  Validators,
  FormBuilder,
  FormGroupDirective
} from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FirebaseError } from 'firebase/app';
import { Unsubscribe } from 'firebase/auth';
import { onSnapshot, doc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { COUNTRIES } from 'src/app/shared/data/countries';
import { FirebaseAuthService } from 'src/app/shared/services/firebase-auth.service';
import { firestore, auth, functions } from 'src/firebase';
import { AdminPortalComponent } from '../admin-portal/admin-portal.component';
import { AdminUser } from 'src/app/shared/models/adminUser.model';

@Component({
  selector: 'app-admin-portal-settings',
  templateUrl: './admin-portal-settings.component.html',
  styleUrls: ['./admin-portal-settings.component.scss']
})
export class AdminPortalSettingsComponent implements OnInit, OnDestroy {
  user: AdminUser;
  isMultiFactorUser: boolean;

  // change password form
  changePasswordForm: FormGroup = this.formBuilder.group({
    password: [
      '',
      Validators.compose([
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(100)
      ])
    ],
    new_password: [
      '',
      Validators.compose([
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(100)
      ])
    ],
    new_password2: [
      '',
      Validators.compose([
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(100)
      ])
    ]
  });

  // loading var for spinner
  isLoading = false;

  // unsubscribe for user data subscription
  unsubscribeUser: Unsubscribe;

  // countries for phone number
  countries = COUNTRIES;

  // update multi factor
  updateMultiFactorSuccess = false;
  updateMultiFactorError = false;

  // change password
  changePasswordSuccessMessage = '';
  changePasswordErrorMessage = '';

  // hide for password input
  hide = true;

  constructor(
    private router: Router,
    private firebaseAuth: FirebaseAuthService,
    private formBuilder: FormBuilder,
    public adminPortalComponent: AdminPortalComponent,
    public translate: TranslateService
  ) {}

  // get data of user (subscribe)
  getUser() {
    this.unsubscribeUser = onSnapshot(
      doc(firestore, 'admin_users', auth.currentUser.uid),
      (querySnapshot) => {
        if (!querySnapshot.exists()) {
          // error while getting user data (navigate to home)
          this.router.navigate(['admin']);
        }

        const data = querySnapshot.data();

        this.user = {
          id: querySnapshot.id,
          name: data.name,
          username: data.username,
          is_multi_factor_user: data.is_multi_factor_user,
          country_code: data.country_code,
          phone_number: data.phone_number
        };

        // transform user data
        this.transform();

        // disable loading
        this.isLoading = false;
      },
      (error) => {
        console.error(error);
        this.router.navigate(['admin']);
      }
    );
  }

  extract() {
    // get user data
    this.getUser();
  }

  transform() {
    if (!this.user.phone_number) {
      this.user.phone_number = '';
    }
  }

  async ngOnInit() {
    // enable loading
    this.isLoading = true;

    // wait until auth is initialized
    const user = await this.firebaseAuth.getCurrentUser();

    // check initially if user is logged in
    if (user !== null) {
      // get firebase auth
      const claims = (await auth.currentUser.getIdTokenResult()).claims;

      // redirect user to login if not logged in
      if (claims.role !== 'admin') {
        // user is not logged in => redirect to login
        this.router.navigate(['admin/login']);
      } else {
        this.extract();
      }
    } else {
      // user is not logged in
      this.router.navigate(['admin/login']);
    }
  }

  ngOnDestroy(): void {
    // unsubscribe from user subscription
    if (this.unsubscribeUser) {
      this.unsubscribeUser();
    }
  }

  async updateUsersMultiFactor() {
    try {
      // enable loading
      this.isLoading = true;

      // clear success and error message
      this.updateMultiFactorSuccess = this.updateMultiFactorError = false;

      // firebase callable functions
      const updateUserMultiFactor = httpsCallable<
        {
          countryCode?: string;
          phoneNumber?: string;
        },
        { result: string; error?: FirebaseError }
      >(functions, 'updateUserMultiFactor');

      // update multi factor in functions
      const { data } = await updateUserMultiFactor({
        countryCode: this.user.country_code,
        phoneNumber: this.user.phone_number
      });

      if (!data.result) {
        throw data.error;
      }

      // show success to user
      this.updateMultiFactorSuccess = true;
    } catch (error) {
      // show error to user
      this.updateMultiFactorError = true;
    }

    // disable loading
    this.isLoading = false;
  }

  async updateUserPassword(
    password: string,
    newPassword: string,
    form: FormGroupDirective
  ) {
    try {
      // firebase callable functions
      const updateUserPassword = httpsCallable<
        { password: string; newPassword: string },
        { result: string; error?: FirebaseError }
      >(functions, 'updateUserPassword');

      // validate and update password in backend
      const { data } = await updateUserPassword({
        password: password,
        newPassword: newPassword
      });

      if (!data.result) {
        throw data.error;
      }

      // reset form
      form.resetForm({
        password: '',
        new_password: '',
        new_password2: ''
      });

      // password was successfully updated (show success message)
      this.translate
        .get(
          'admin_portal.settings.change_password_panel.success_message.content'
        )
        .subscribe((res) => (this.changePasswordSuccessMessage = res));
    } catch (error) {
      // check error type
      if (error.message === 'The password is wrong.') {
        // provided user password was wrong
        this.translate
          .get(
            'admin_portal.settings.change_password_panel.old_password_invalid_error_message.content'
          )
          .subscribe((res) => (this.changePasswordErrorMessage = res));
      } else {
        // show general error
        this.translate
          .get(
            'admin_portal.settings.change_password_panel.change_password_panel.content'
          )
          .subscribe((res) => (this.changePasswordErrorMessage = res));
      }
    }
    // disable loading
    this.isLoading = false;
  }

  // change password
  onFormSubmit(form: FormGroupDirective) {
    // enable loading
    this.isLoading = true;

    // reset success and error messages
    this.changePasswordSuccessMessage = '';
    this.changePasswordErrorMessage = '';

    // get values of change password form
    const formValues = this.changePasswordForm.value;

    // check if new passwords match
    if (
      formValues.new_password !== formValues.new_password2 ||
      formValues.password === formValues.new_password
    ) {
      // display error to user
      this.translate
        .get(
          'admin_portal.settings.change_password_panel.new_passwords_dont_match_error_message.content'
        )
        .subscribe((res) => (this.changePasswordErrorMessage = res));
      // disable loading
      this.isLoading = false;
    } else {
      // update password in functions (if password is correct)
      this.updateUserPassword(
        formValues.password,
        formValues.new_password,
        form
      );
    }
  }
}
