import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Router, ActivatedRoute } from '@angular/router';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { TranslateService } from '@ngx-translate/core';
import { Unsubscribe, User, onAuthStateChanged } from 'firebase/auth';
import {
  onSnapshot,
  doc,
  query,
  collection,
  deleteField,
  FieldValue,
  updateDoc
} from 'firebase/firestore';
import { ref, uploadBytes } from 'firebase/storage';
import { Observable, startWith, map } from 'rxjs';
import { ADMIN_CLIENT_CKEDITOR_CONFIG } from 'src/app/app.constant';
import { Client, Manager } from 'src/app/shared/models/client.model';
import { FirebaseAuthService } from 'src/app/shared/services/firebase-auth.service';
import { firestore, auth, storage, functions } from 'src/firebase';
import { AdminPortalComponent } from '../admin-portal/admin-portal.component';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { httpsCallable } from 'firebase/functions';
import { FirebaseError } from 'firebase/app';

@Component({
  selector: 'app-admin-portal-client',
  templateUrl: './admin-portal-client.component.html',
  styleUrls: ['./admin-portal-client.component.scss']
})
export class AdminPortalClientComponent implements OnInit, OnDestroy {
  // user variable
  user: User;

  // client variable
  client: Client;

  // case manager (client) variable
  caseManagerAdmin: Manager[];

  // loading animation indicator variable
  isLoading = false;

  // unsubscribes for snapshot subscribtions
  unsubscribeClient: Unsubscribe;
  unsubscribeCaseManager: Unsubscribe;

  // edit client form
  editClientForm: FormGroup;

  // chips with input for firms
  firms: string[] = [];
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  // chips with input for languages
  removableLang = true;
  separatorKeysCodesLang: number[] = [ENTER, COMMA];
  langCtrl = new FormControl();
  filteredLanguages: Observable<string[]>;
  selectLanguages = [];
  languages: string[] = [];
  allLanguages: string[] = this.translate.getLangs();

  // client logo file
  clientLogoFiles: { light?: File; dark?: File } = {};
  fileSelectionError: { light: string; dark: string } = {
    light: '',
    dark: ''
  };

  // success and error for lsp update
  updateClientSuccess = false;
  updateClientError = false;

  // rich text "ckeditor" for text input
  editor = ClassicEditor;
  ckeditorConfigAdminClient = ADMIN_CLIENT_CKEDITOR_CONFIG;

  translateTextForm: FormGroup;
  translateReportTextForm: FormGroup;
  translateTextSuccess = false;
  translateTextError = false;

  constructor(
    private firebaseAuth: FirebaseAuthService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public adminPortalComponent: AdminPortalComponent,
    public translate: TranslateService
  ) {
    // edit client form
    this.editClientForm = this.formBuilder.group({
      is_shared: [false, Validators.required],
      languages: [[]],
      default_language: [''],
      is_active: [true, Validators.required]
    });

    this.filteredLanguages = this.langCtrl.valueChanges.pipe(
      startWith(null),
      map((lang: string) =>
        lang ? this._filterLang(lang) : this.allLanguages.slice()
      )
    );

    this.translateTextForm = this.formBuilder.group({
      translate_from: ['', Validators.required],
      translate_to: ['', Validators.required]
    });

    this.translateReportTextForm = this.formBuilder.group({
      translate_from: ['', Validators.required],
      translate_to: ['', Validators.required]
    });
  }

  getClient(lspId: string, clientId: string) {
    // define query
    this.unsubscribeClient = onSnapshot(
      doc(firestore, 'legal_service_providers', lspId, 'clients', clientId),
      (doc) => {
        const data = doc.data();

        this.client = {
          id: doc.id,
          subdomain: data.subdomain,
          lsp_id: data.lsp_id,
          lsp_name: data.lsp_name,
          lsp_name_short: data.lsp_name_short,
          lsp_withdrawal_email: data.lsp_withdrawal_email,
          case_manager: data.case_manager,
          name: data.name,
          name_short: data.name_short,
          languages: data.languages,
          default_language: data.default_language,
          is_shared: data.is_shared,
          is_active: data.is_active,
          reporting_portal_several_texts: data.reporting_portal_several_texts,
          reporting_portal_report_texts: data.reporting_portal_report_texts,
          sub_client_names: data.sub_client_names
        };

        if (data.sub_client_names) {
          this.firms = data.sub_client_names;
        }

        this.languages = this.translate
          .getLangs()
          .filter((lang) =>
            this.client.languages.some((clientLang: string) =>
              lang.toLowerCase().startsWith(clientLang.toLowerCase())
            )
          );

        const reportTexts =
          this.client.reporting_portal_report_texts || new Map();

        this.languages.forEach((language) => {
          this.editClientForm.addControl(
            'reporting_portal_text_' + language.toLowerCase(),
            new FormControl(
              this.client.reporting_portal_several_texts[language.toLowerCase()]
            )
          );

          this.editClientForm.addControl(
            'reporting_portal_report_text_' + language.toLowerCase(),
            new FormControl(reportTexts[language.toLowerCase()])
          );
        });

        if (reportTexts.size === 0) {
          this.languages.forEach(async (language) => {
            const text = await this.translateText(
              'reporting_portal_report_texts',
              'template',
              language
            );

            reportTexts.set(language, text);

            this.editClientForm.controls[
              'reporting_portal_report_text_' + language
            ].setValue(reportTexts.get(language));
          });
        }

        const defaultLanguage = this.translate
          .getLangs()
          .find(
            (lang) =>
              lang.toLowerCase() === this.client.default_language.toLowerCase()
          );

        // pass client data to form
        this.editClientForm.patchValue({
          is_shared: this.client.is_shared ? true : false,
          languages: this.languages,
          default_language: defaultLanguage,
          is_active: this.client.is_active
        });
      },
      (error) => {
        console.error(error);
        this.router.navigate(['admin']);
      }
    );
  }

  // get all case manager of legal service provider
  getCaseManager(lspId: string) {
    // define query
    const q = query(
      collection(firestore, 'legal_service_providers', lspId, 'case_manager')
    );
    // listen to realtime updates
    this.unsubscribeCaseManager = onSnapshot(
      q,
      (querySnapshot) => {
        this.caseManagerAdmin = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();

          if (data.role === 'case_manager_admin') {
            const manager: Manager = {
              id: doc.id,
              name: data.name,
              email: data.email
            };

            this.caseManagerAdmin.push(manager);
          }
        });
      },
      (error) => {
        console.error(error);
        this.router.navigate(['admin']);
      }
    );
  }

  async extract(lspId: string, clientId: string) {
    // get data of client
    this.getClient(lspId, clientId);

    // get case manager of lsp
    this.getCaseManager(lspId);

    // disable loading
    this.isLoading = false;
  }

  async ngOnInit() {
    // enable loading
    this.isLoading = true;

    // wait until auth is initialized
    this.user = await this.firebaseAuth.getCurrentUser();

    // check initially if user is logged in
    if (this.user !== null) {
      // get firebase auth
      const claims = (await auth.currentUser.getIdTokenResult()).claims;

      // redirect user to login if not logged in
      if (claims.role !== 'admin') {
        // user is not logged in => redirect to login
        this.router.navigate(['admin/login']);
      } else {
        this.extract(
          this.route.snapshot.params.lsp_id,
          this.route.snapshot.params.client_id
        );
        // disable loading
        this.isLoading = false;
      }
    } else {
      // user is not logged in
      this.router.navigate(['admin/login']);
    }

    // check if user is still logged in (subscribe to auth state)
    onAuthStateChanged(auth, async (user) => {
      if (user !== null) {
        // get firebase auth
        const claims = (await auth.currentUser.getIdTokenResult()).claims;

        // redirect user to case if logged in
        if (claims.role !== 'admin') {
          // user is not logged in => redirect to login
          this.router.navigate(['admin/login']);
        }
      } else {
        // user is not logged in
        this.router.navigate(['admin/login']);
      }
    });
  }

  ngOnDestroy(): void {
    // unsubscribe from snapshot subscribptions
    if (this.unsubscribeClient) {
      this.unsubscribeClient();
    }
    if (this.unsubscribeCaseManager) {
      this.unsubscribeCaseManager();
    }
  }

  // logo file was selected
  onFileSelected(event: Event, theme: 'light' | 'dark') {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length === 1) {
      const selectedFile = input.files[0];
      if (selectedFile.name.endsWith('.png')) {
        this.clientLogoFiles[theme] = selectedFile;
        this.fileSelectionError[theme] = ''; // Clear any previous error
      } else {
        this.translate
          .get('global.file_selection_format_error.content')
          .subscribe((res) => (this.fileSelectionError[theme] = res));
      }
    }
  }

  async removeClientLogoFile(theme: 'light' | 'dark') {
    // Remove the specified clientLogoFile (light or dark)
    this.clientLogoFiles[theme] = undefined;
  }

  // add client shared firm
  addClientSharedFirm(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // add our firm
    if (value) {
      this.firms.push(value);
    }

    // clear the input value
    event.chipInput?.clear();
  }

  // remove client shared firm
  removeClientSharedFirm(firm: string): void {
    const index = this.firms.indexOf(firm);

    if (index >= 0) {
      this.firms.splice(index, 1);
    }
  }

  // remove client language
  removeLang(language: string) {
    const index = this.languages.indexOf(language);

    if (index >= 0) {
      this.languages.splice(index, 1);
    }
  }

  selectedLang(event: MatAutocompleteSelectedEvent) {
    const value = event.option.value;
    const found = this.languages.find((lang: string) => lang === value);

    if (!found) {
      // add our language
      this.languages.push(value);
    }

    // clear the input value
    this.langCtrl.setValue(null);

    // add new form control for text edit
    this.editClientForm.addControl(
      'reporting_portal_text_' + value.toLowerCase(),
      new FormControl(
        this.client.reporting_portal_several_texts[value.toLowerCase()] || ''
      )
    );
  }

  private _filterLang(lang: string): string[] {
    const filterValue = lang.toLowerCase();

    return this.allLanguages.filter((lang: string) =>
      lang.toLowerCase().includes(filterValue)
    );
  }

  async updateClient() {
    try {
      // enable loading
      this.isLoading = true;

      // clear error and success
      this.updateClientSuccess = this.updateClientError = false;

      // use clients with shared for sub client names if not
      let subFirms: string[] | FieldValue = this.firms;
      if (this.editClientForm.value.is_shared === false) {
        this.firms = [];
        subFirms = deleteField();
      }

      // de / active of client (add case manager admin / remove case manager from client)
      const isActive: boolean = this.editClientForm.value.is_active;

      let caseManager: Manager[] | FieldValue = this.caseManagerAdmin;
      if (!isActive) {
        caseManager = deleteField();
      }

      const languages: string[] = this.languages;

      const defaultLanguage: string =
        this.editClientForm.value.default_language;

      if (languages.length === 0) {
        throw Error();
      }

      const clientData = {
        is_shared: this.editClientForm.value.is_shared,
        sub_client_names: subFirms,
        languages: languages,
        default_language: defaultLanguage,
        is_active: isActive,
        case_manager: caseManager,
        reporting_portal_several_texts: {},
        reporting_portal_report_texts: {}
      };

      languages.forEach((language) => {
        clientData.reporting_portal_several_texts[language.toLowerCase()] =
          this.editClientForm.value[
            'reporting_portal_text_' + language.toLowerCase()
          ];

        clientData.reporting_portal_report_texts[language.toLowerCase()] =
          this.editClientForm.value[
            'reporting_portal_report_text_' + language.toLowerCase()
          ];
      });

      // update default language and active state of client
      await updateDoc(
        doc(
          firestore,
          'legal_service_providers/' +
            this.client.lsp_id +
            '/clients/' +
            this.client.id
        ),
        clientData
      );

      if (this.clientLogoFiles.light) {
        // storage reference for file
        const lightStorageRef = ref(
          storage,
          'legal_service_providers/' +
            this.client.lsp_id +
            '/clients/' +
            this.client.id +
            '/logo.png'
        );
        await uploadBytes(lightStorageRef, this.clientLogoFiles.light);
      }

      if (this.clientLogoFiles.dark) {
        // storage reference for file
        const darkStorageRef = ref(
          storage,
          'legal_service_providers/' +
            this.client.lsp_id +
            '/clients/' +
            this.client.id +
            '/logo_dark.png'
        );
        await uploadBytes(darkStorageRef, this.clientLogoFiles.dark);
      }

      // clear file
      this.clientLogoFiles = {};

      // show success messages
      this.updateClientSuccess = true;
    } catch (error) {
      console.error(error);
      // show error to user
      this.updateClientError = true;
    }

    // disable loading
    this.isLoading = false;
  }

  async translateTexts(
    type: 'reporting_portal_several_texts' | 'reporting_portal_report_texts'
  ) {
    try {
      this.isLoading = true;
      this.translateTextSuccess = this.translateTextError = false;

      const form: FormGroup =
        type === 'reporting_portal_report_texts'
          ? this.translateReportTextForm
          : this.translateTextForm;

      const from = form.value.translate_from;
      const to = form.value.translate_to;

      await to.forEach(async (toLanguage: string) => {
        if (to === from) {
          throw Error();
        }

        const text = await this.translateText(type, from, toLanguage);

        if (!text) {
          throw Error();
        }

        if (type === 'reporting_portal_several_texts') {
          this.editClientForm.controls[
            'reporting_portal_text_' + toLanguage.toLowerCase()
          ].setValue(text);
        } else if (type === 'reporting_portal_report_texts') {
          this.editClientForm.controls[
            'reporting_portal_report_text_' + toLanguage.toLowerCase()
          ].setValue(text);
        }
      });

      this.translateTextSuccess = !this.translateTextError;
      this.isLoading = false;

      // reset form
      form.reset();
    } catch (error) {
      console.error(error);
      this.translateTextError = true;
    }
  }

  async translateText(
    type: 'reporting_portal_several_texts' | 'reporting_portal_report_texts',
    from: string,
    to: string
  ) {
    // firebase callable functions
    const translateClientText = httpsCallable<
      {
        lspId: string;
        clientId: string;
        from: string;
        to: string;
        text:
          | 'reporting_portal_several_texts'
          | 'reporting_portal_report_texts';
      },
      { text: string; error?: FirebaseError }
    >(functions, 'translateClientText');

    const { data } = await translateClientText({
      lspId: this.client.lsp_id,
      clientId: this.client.id,
      from: from,
      to: to,
      text: type
    });

    if (!data.text) {
      return null;
    }

    return data.text;
  }
}
