import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { FirebaseError } from 'firebase/app';
import { Unsubscribe } from 'firebase/auth';
import { onSnapshot, query, collection, orderBy } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { formatCreated } from 'src/app/shared/functions/dateFormat';
import { generateRandomString } from 'src/app/shared/functions/generate';
import { Case } from 'src/app/shared/models/case.model';
import { CaseDocumentation } from 'src/app/shared/models/caseDocumentation';
import { CaseFile } from 'src/app/shared/models/caseFile.model';
import { functions, firestore } from 'src/firebase';

@Component({
  selector: 'app-pdf-case-documentation-dialog',
  templateUrl: './pdf-case-documentation-dialog.component.html',
  styleUrls: ['./pdf-case-documentation-dialog.component.scss']
})
export class PdfCaseDocumentationDialogComponent implements OnInit {
  // pdf generation form variable
  generateCaseDocumentationForm: FormGroup;

  // hide custom password at report form (default)
  hide = true;

  // pdf table setup
  displayedColumns = ['id', 'generated', 'language', 'password', 'download'];
  dataSource: MatTableDataSource<CaseDocumentation>;

  // unsubscribe var
  unsubscribe: Unsubscribe;

  // loading var for loading spinner
  isLoading = false;

  // success and error messages
  success = false;
  error = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private formBuilder: FormBuilder,
    public translate: TranslateService
  ) {}

  setupForm() {
    this.generateCaseDocumentationForm = this.formBuilder.group({
      language: [this.data.language, Validators.compose([Validators.required])],
      translation_lang_code: [
        'none',
        Validators.compose([Validators.minLength(2), Validators.maxLength(5)])
      ],
      anonymised: [true, Validators.required],
      password: [
        '',
        Validators.compose([Validators.minLength(8), Validators.maxLength(100)])
      ]
    });
  }

  // get case files
  getCaseDocumentations(lsp_id: string, client_id: string, case_id: string) {
    try {
      const case_documentations: CaseDocumentation[] = [];
      this.unsubscribe = onSnapshot(
        query(
          collection(
            firestore,
            'legal_service_providers',
            lsp_id,
            'clients',
            client_id,
            'cases',
            case_id,
            'case_documentations'
          ),
          orderBy('generated', 'asc')
        ),
        (querySnapshot) => {
          querySnapshot.docChanges().forEach(async (change) => {
            if (change.type === 'added') {
              const caseDocumentation = change.doc.data() as CaseDocumentation;
              caseDocumentation.id = change.doc.id;

              // format file uploaded date
              const created = caseDocumentation.generated.toDate();
              caseDocumentation.generated_formatted = formatCreated(
                created,
                this.translate.currentLang
              );

              // decrypt password of case documentation
              const decryptString = httpsCallable<
                {
                  string: string;
                },
                { decryptedString: string; error?: FirebaseError }
              >(functions, 'decryptString');

              const { data } = await decryptString({
                string: caseDocumentation.password
              });

              if (!data.decryptedString) {
                throw new Error(
                  'Error while decrypting case documentation password.'
                );
              }

              caseDocumentation.password = data.decryptedString;

              // add documentation object to case documentations array
              case_documentations.unshift(caseDocumentation);

              // sort case documentations by generation date
              case_documentations.sort((a, b) =>
                a.generated < b.generated
                  ? 1
                  : b.generated < a.generated
                    ? -1
                    : 0
              );

              // update case documentations table data source
              this.dataSource = new MatTableDataSource(case_documentations);
            }
          });
        }
      );
    } catch (error) {
      console.error(error);
      this.unsubscribe();
      // deactivate loading
      this.isLoading = false;
    }
  }

  extract() {
    // get case documentations
    this.getCaseDocumentations(
      this.data.case.lsp_id,
      this.data.case.client_id,
      this.data.case.id
    );
  }

  ngOnInit(): void {
    // setup form
    this.setupForm();

    // extract data
    this.extract();
  }

  // download file from firebase storage
  async downloadFile(file: CaseFile) {
    try {
      // enable loading
      this.isLoading = true;
      // get signed download url
      const getFileDownloadUrl = httpsCallable<
        { clientId: string; caseId: string; filePath: string },
        { downloadUrl: string; error?: FirebaseError }
      >(functions, 'getFileDownloadUrl');

      const { data } = await getFileDownloadUrl({
        clientId: this.data.case.client_id,
        caseId: this.data.case.id,
        filePath: file.path
      });

      if (!data.downloadUrl) {
        throw new Error(
          'Error occured while trying to get download url for file.'
        );
      }

      // get blow of url from firebase storage
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = () => {
        const blob = xhr.response;

        // trigger file download with blob from api
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = file.id;
        document.body.appendChild(link);
        link.dispatchEvent(
          new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
          })
        );

        // remove link from body
        document.body.removeChild(link);
      };
      // send request
      xhr.open('GET', data.downloadUrl);
      xhr.send();
    } catch (error) {
      console.error(error);
    }
    // disable loading
    this.isLoading = false;
  }

  // generate a new case documentation
  async generatePdfCaseDocumentation(
    anonymised: boolean,
    language: string,
    translationLangCode: string | undefined,
    password: string
  ) {
    try {
      // generate pdf case documentation in functions
      const generateCaseDocumentation = httpsCallable<
        {
          clientId: string;
          caseId: string;
          anonymised: boolean;
          language: string;
          translationLangCode: string | undefined;
          password: string;
        },
        { result: string; error?: FirebaseError }
      >(functions, 'generateCaseDocumentation');

      const { data } = await generateCaseDocumentation({
        clientId: this.data.case.client_id,
        caseId: this.data.case.id,
        anonymised: anonymised,
        language: language,
        translationLangCode:
          translationLangCode === 'none' ? undefined : translationLangCode,
        password: password
      });

      if (!data.result) {
        throw new Error('Error while generate pdf case documentation.');
      }

      // reset form to initial values
      this.generateCaseDocumentationForm.reset();
      this.generateCaseDocumentationForm.patchValue({
        language: this.data.language,
        translation_lang_code: 'none',
        password: '',
        anonymised: true
      });

      // show success message
      this.success = true;
    } catch (error) {
      // log error
      console.error(error);
      // show error message
      this.error = true;
    }
    // disable loading
    this.isLoading = false;
  }

  async onFormSubmit() {
    // enable loading
    this.isLoading = true;
    // clear success and error messages
    this.success = this.error = false;
    // retrieve form data
    const { anonymised, language, translation_lang_code } =
      this.generateCaseDocumentationForm.value;
    let password = this.generateCaseDocumentationForm.value.password;
    // generate password if user did not add custom password
    if (password === null || password === '') {
      password = generateRandomString(12);
    }
    // pdf case in functions
    await this.generatePdfCaseDocumentation(
      anonymised,
      language,
      translation_lang_code,
      password
    );
  }
}

export interface DialogData {
  case: Case;
  language: string;
}
