<div class="datenschutz">
  <mat-toolbar>
    <img
      [routerLink]="['']"
      routerLinkActive="router-link-active"
      width="150px"
      src="assets/images/integribox_logo_dark.png"
      alt="IntegriBox Logo"
    />
    <div class="flex w-100">
      <a
        class="text-dark link"
        tabindex="auto"
        (keypress)="goBack()"
        (click)="goBack()"
        [innerHTML]="'toolbar.back_to_website.content' | translate"
      ></a>
    </div>
  </mat-toolbar>
  <div>
    <mat-form-field
      class="lang-button small"
      appearance="fill"
      *mcRerender="trigger"
    >
      <mat-label
        [innerHTML]="'toolbar.language_selection_title.content' | translate"
      ></mat-label>
      <mat-select [(ngModel)]="lang" (selectionChange)="translate.use(lang)">
        <mat-option *ngFor="let lang of translate.getLangs()" [value]="lang">
          {{ 'languages.' + lang | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="container p-5 text-dark" [innerHTML]="text"></div>
</div>
