import { EditorConfig } from '@ckeditor/ckeditor5-core';

export const ADMIN_CLIENT_CKEDITOR_CONFIG: EditorConfig = {
  toolbar: ['heading', '|', 'bold', 'bulletedList', 'numberedList'],
  heading: {
    options: [
      {
        model: 'heading1',
        view: 'h5',
        title: 'Heading',
        class: 'ck-heading_heading3'
      },
      { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' }
    ]
  }
};
