<div
  class="cover-spin"
  *ngIf="!case || isLoading || isChatMessageLoading"
></div>
<img
  *ngIf="caseManagementPortalComponent.lspLogoUrl"
  class="responsive m-auto mt-3"
  width="80%"
  [src]="caseManagementPortalComponent.lspLogoUrl"
  alt="Logo"
/>
<mat-card class="mt-3 d-block mx-auto" *ngIf="case">
  <mat-tab-group
    mat-stretch-tabs
    (selectedTabChange)="
      scrollToBottom();
      activeTab = $event.index;
      updateCaseUserMessagesToRead();
      updateCaseUserFilesToViewed()
    "
  >
    <!-- Overview of case -->
    <mat-tab
      [label]="
        'case_management_portal.case.carousel_headings.left.content' | translate
      "
    >
      <div class="p-5 tab-content">
        <div class="row">
          <div class="col-md-8">
            <div
              class="h5"
              [innerHTML]="
                'case_management_portal.case.case_overview_page.heading.content'
                  | translate
              "
            ></div>
          </div>
          <div class="col-12 col-md-4">
            <mat-form-field appearance="fill">
              <mat-label
                [innerHTML]="
                  'case_management_portal.case.case_overview_page.translation_selection.label.content'
                    | translate
                "
              ></mat-label>
              <mat-select
                [(ngModel)]="caseDataLangCode"
                (selectionChange)="translateCase(caseDataLangCode)"
                [ngModelOptions]="{ standalone: true }"
              >
                <mat-option
                  *ngFor="
                    let lang of chatMessagesPossibleLangCodes
                      | keyvalue: returnZero
                  "
                  [value]="lang.key"
                  >{{ lang.value | translate }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <hr />
        <p>
          {{
            'case_management_portal.case.case_overview_page.client_of_case.content'
              | translate
          }}
          <b>{{
            case.sub_client_name
              ? case.sub_client_name + ' (' + case.client_name + ')'
              : case.client_name
          }}</b
          ><br />
          {{
            'case_management_portal.case.case_overview_page.date_time_of_reporting.content'
              | translate
          }}
          <b>{{ case.created_formatted }}</b
          ><br />
          {{
            'case_management_portal.case.case_overview_page.case_id.content'
              | translate
          }}
          <b>{{ case.case_id }}</b>
          <span *ngIf="case.record || categoryViewValue"> - </span>
          <span *ngIf="case.record"
            >{{
              'case_management_portal.case.case_overview_page.record_of_case.content'
                | translate
            }}
            <b>{{ case.record }}</b></span
          ><span *ngIf="case.record && categoryViewValue"> - </span
          ><span *ngIf="categoryViewValue"
            >{{
              'case_management_portal.case.case_overview_page.category_of_case.content'
                | translate
            }}
            <b>{{ categoryViewValue }}</b></span
          >
          <br />
          <span *ngIf="case.client_case_manager.length > 0">
            {{
              'case_management_portal.case.case_overview_page.case_manager.content'
                | translate
            }}
            <b *ngFor="let manager of case.client_case_manager"
              ><span *ngIf="case.client_case_manager[0] !== manager">, </span
              >{{ manager.name }}</b
            >
          </span>
        </p>
        <hr />
        <!-- Subject of case -->
        <mat-form-field appearance="outline">
          <mat-label
            [innerHTML]="
              'case_management_portal.case.case_overview_page.report_form.subject_form_field.label.content'
                | translate
            "
          ></mat-label>
          <input
            *ngIf="!case.translation"
            matInput
            [value]="case.subject"
            readonly
          />
          <input
            *ngIf="case.translation"
            matInput
            [value]="case.translation.subject"
            readonly
          />
        </mat-form-field>

        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field appearance="outline">
              <mat-label>{{
                'case_management_portal.case.case_overview_page.report_form.name_selection.label.content'
                  | translate
              }}</mat-label>
              <mat-select
                [(ngModel)]="nameSelection"
                [ngModelOptions]="{ standalone: true }"
                disabled
              >
                <mat-option [value]="false">{{
                  'case_management_portal.case.case_overview_page.report_form.name_selection.responses.no_selection.content'
                    | translate
                }}</mat-option>
                <mat-option [value]="true">{{
                  'case_management_portal.case.case_overview_page.report_form.name_selection.responses.yes_selection.content'
                    | translate
                }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-6" *ngIf="nameSelection">
            <mat-form-field appearance="outline">
              <mat-label
                [innerHTML]="
                  'case_management_portal.case.case_overview_page.report_form.name_form_field.label.content'
                    | translate
                "
              ></mat-label>
              <input matInput [value]="case.name" readonly />
            </mat-form-field>
          </div>
        </div>

        <hr />

        <!-- Description of case -->
        <mat-form-field class="mt-2" appearance="outline">
          <mat-label
            [innerHTML]="
              'case_management_portal.case.case_overview_page.report_form.description_form_field.label.content'
                | translate
            "
          ></mat-label>
          <textarea
            *ngIf="!case.translation"
            matInput
            [value]="case.description"
            cdkTextareaAutosize
            readonly
          ></textarea>
          <textarea
            *ngIf="case.translation"
            matInput
            [value]="case.translation.description"
            cdkTextareaAutosize
            readonly
          ></textarea>
        </mat-form-field>

        <!-- Date of incident -->
        <mat-form-field class="mt-2" appearance="outline">
          <mat-label
            [innerHTML]="
              'case_management_portal.case.case_overview_page.report_form.date_form_field.label.content'
                | translate
            "
          ></mat-label>
          <textarea
            *ngIf="!case.translation"
            matInput
            [value]="case.date"
            cdkTextareaAutosize
            readonly
          ></textarea>
          <textarea
            *ngIf="case.translation"
            matInput
            [value]="case.translation.date"
            cdkTextareaAutosize
            readonly
          ></textarea>
        </mat-form-field>

        <!-- Location of incident -->
        <mat-form-field class="mt-2" appearance="outline">
          <mat-label
            [innerHTML]="
              'case_management_portal.case.case_overview_page.report_form.location_form_field.label.content'
                | translate
            "
          ></mat-label>
          <textarea
            *ngIf="!case.translation"
            matInput
            [value]="case.location"
            cdkTextareaAutosize
            readonly
          ></textarea>
          <textarea
            *ngIf="case.translation"
            matInput
            [value]="case.translation.location"
            cdkTextareaAutosize
            readonly
          ></textarea>
        </mat-form-field>

        <!-- Affected persons -->
        <mat-form-field class="mt-2" appearance="outline">
          <mat-label
            [innerHTML]="
              'case_management_portal.case.case_overview_page.report_form.affected_form_field.label.content'
                | translate
            "
          ></mat-label>
          <textarea
            *ngIf="!case.translation"
            matInput
            [value]="case.affected"
            cdkTextareaAutosize
            readonly
          ></textarea>
          <textarea
            *ngIf="case.translation"
            matInput
            [value]="case.translation.affected"
            cdkTextareaAutosize
            readonly
          ></textarea>
        </mat-form-field>

        <!-- Type of observation -->
        <mat-form-field class="mt-2" appearance="outline">
          <mat-label
            [innerHTML]="
              'case_management_portal.case.case_overview_page.report_form.observation_form_field.label.content'
                | translate
            "
          ></mat-label>
          <textarea
            *ngIf="!case.translation"
            matInput
            [value]="case.observation"
            cdkTextareaAutosize
            readonly
          ></textarea>
          <textarea
            *ngIf="case.translation"
            matInput
            [value]="case.translation.observation"
            cdkTextareaAutosize
            readonly
          ></textarea>
        </mat-form-field>

        <!-- Relationship to affected company -->
        <mat-form-field class="mt-2" appearance="outline">
          <mat-label
            [innerHTML]="
              'case_management_portal.case.case_overview_page.report_form.relationship_form_field.label.content'
                | translate
            "
          ></mat-label>
          <textarea
            *ngIf="!case.translation"
            matInput
            [value]="case.relationship"
            cdkTextareaAutosize
            readonly
          ></textarea>
          <textarea
            *ngIf="case.translation"
            matInput
            [value]="case.translation.relationship"
            cdkTextareaAutosize
            readonly
          ></textarea>
        </mat-form-field>

        <!-- Any additional information -->
        <mat-form-field class="mt-2" appearance="outline">
          <mat-label
            [innerHTML]="
              'case_management_portal.case.case_overview_page.report_form.additional_information_form_field.label.content'
                | translate
            "
          ></mat-label>
          <textarea
            *ngIf="!case.translation"
            matInput
            [value]="case.additional_information"
            cdkTextareaAutosize
            readonly
          ></textarea>
          <textarea
            *ngIf="case.translation"
            matInput
            [value]="case.translation.additional_information"
            cdkTextareaAutosize
            readonly
          ></textarea>
        </mat-form-field>

        <!-- Country of case -->
        <mat-form-field appearance="outline">
          <mat-label
            [innerHTML]="
              'case_management_portal.case.case_overview_page.report_form.country_form_field.label.content'
                | translate
            "
          ></mat-label>
          <mat-select [value]="case.country" disabled>
            <mat-option
              *ngFor="let country of countries"
              [value]="country.code"
              >{{ country.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <hr />
        <div class="rounded-box mt-4">
          <p
            [innerHTML]="
              'case_management_portal.case.case_overview_page.report_form.record_category_disclaimer.content'
                | translate
            "
          ></p>
          <!-- Record of case -->
          <mat-form-field appearance="outline">
            <mat-label
              [innerHTML]="
                'case_management_portal.case.case_overview_page.report_form.record_category_form.record_form_field.label.content'
                  | translate
              "
            ></mat-label>
            <input
              [(ngModel)]="case.record"
              (change)="updateCaseRecord()"
              maxlength="50"
              matInput
              type="text"
            />
            <mat-hint
              align="end"
              [innerText]="(case.record?.length || 0) + '/50'"
            ></mat-hint>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label
              [innerHTML]="
                'case_management_portal.case.case_overview_page.report_form.record_category_form.category_form_field.label.content'
                  | translate
              "
            ></mat-label>
            <mat-select
              [(ngModel)]="case.category"
              (selectionChange)="updateCaseCategory()"
            >
              <mat-optgroup
                *ngFor="let group of recordCategoryGroups | keyvalue"
                [label]="
                  'case_management_portal.case.case_overview_page.report_form.record_category_form.category_form_field.' +
                    group.key | translate
                "
              >
                <mat-option
                  *ngFor="let category of group.value"
                  [value]="category"
                  >{{
                    'case_management_portal.case.case_overview_page.report_form.record_category_form.category_form_field.' +
                      group.key +
                      '.' +
                      category | translate
                  }}
                </mat-option>
              </mat-optgroup>
              <mat-option value="other">{{
                'case_management_portal.case.case_overview_page.report_form.record_category_form.category_form_field.other_option.content'
                  | translate
              }}</mat-option>
              <mat-option value="not-relevant">{{
                'case_management_portal.case.case_overview_page.report_form.record_category_form.category_form_field.not_relevant_option.content'
                  | translate
              }}</mat-option>
            </mat-select>
          </mat-form-field>
          <p
            class="mt-3 p-0 success"
            *ngIf="successMessage"
            [innerHTML]="successMessage"
          ></p>
          <p
            class="mt-3 p-0 error"
            *ngIf="errorMessage"
            [innerHTML]="errorMessage"
          ></p>
        </div>
        <div class="rounded-box mt-4">
          <p
            [innerHTML]="
              'case_management_portal.case.case_overview_page.report_form.state_disclaimer.content'
                | translate
            "
          ></p>
          <!-- State of case -->
          <mat-form-field appearance="outline">
            <mat-label
              [innerHTML]="
                'case_management_portal.case.case_overview_page.report_form.state_form.state_form_field.label.content'
                  | translate
              "
            ></mat-label>
            <mat-select
              [(ngModel)]="case.state"
              (selectionChange)="updateCaseState()"
            >
              <mat-option value="active">{{
                'case_management_portal.case.case_overview_page.report_form.state_form.state_form_field.options.option1.content'
                  | translate
              }}</mat-option>
              <mat-option value="done">{{
                'case_management_portal.case.case_overview_page.report_form.state_form.state_form_field.options.option2.content'
                  | translate
              }}</mat-option>
              <mat-option value="deposed">{{
                'case_management_portal.case.case_overview_page.report_form.state_form.state_form_field.options.option3.content'
                  | translate
              }}</mat-option>
            </mat-select>
          </mat-form-field>
          <!-- Confirmation state of case -->
          <mat-form-field appearance="outline">
            <mat-label
              [innerHTML]="
                'case_management_portal.case.case_overview_page.report_form.state_form.confirmation_state_form_field.label.content'
                  | translate
              "
            ></mat-label>
            <mat-select [value]="case.confirmation_state" disabled>
              <mat-option [value]="0">{{
                'case_management_portal.case.case_overview_page.report_form.state_form.confirmation_state_form_field.options.option1.content'
                  | translate
              }}</mat-option>
              <mat-option [value]="1">{{
                'case_management_portal.case.case_overview_page.report_form.state_form.confirmation_state_form_field.options.option2.content'
                  | translate
              }}</mat-option>
              <mat-option [value]="2">
                {{
                  'case_management_portal.case.case_overview_page.report_form.state_form.confirmation_state_form_field.options.option3.content'
                    | translate
                }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <p
            class="mt-3 p-0 success"
            *ngIf="stateSuccessMessage"
            [innerHTML]="stateSuccessMessage"
          ></p>
          <p
            class="mt-3 p-0 error"
            *ngIf="stateErrorMessage"
            [innerHTML]="stateErrorMessage"
          ></p>
        </div>
        <hr />
        <button
          class="py-1 w-100"
          (click)="openPdfCaseDocumentationDialog()"
          mat-raised-button
          color="primary"
          [innerHTML]="
            'case_management_portal.case.case_overview_page.pdf_case_documentation_button.content'
              | translate
          "
        ></button>
        <div *ngIf="caseManagerRole === 'case_manager_admin'">
          <hr />
          <button
            class="py-1 w-100"
            (click)="openCaseCaseManagerListDialog(case)"
            mat-raised-button
            color="primary"
            [innerHTML]="
              'case_management_portal.case.case_overview_page.case_manager_button.content'
                | translate
            "
          ></button>
        </div>

        <!--         <hr />
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title
                [innerHTML]="
                  'case_management_portal.case.case_overview_page.advanced.content'
                    | translate
                "
              ></mat-panel-title>
            </mat-expansion-panel-header>
            <button
              class="py-1 w-100"
              (click)="openDeleteCaseDialog(case)"
              mat-raised-button
              color="warn"
              [innerHTML]="
                'case_management_portal.case.case_overview_page.advanced.delete_case.content'
                  | translate
              "
            ></button>
          </mat-expansion-panel>
        </mat-accordion> -->
      </div>
    </mat-tab>

    <!-- Chat with case manager -->
    <mat-tab
      [label]="
        'case_management_portal.case.carousel_headings.center.content'
          | translate
      "
    >
      <div class="p-5 tab-content">
        <div class="row">
          <div class="col-12 col-md-8">
            <div
              class="h5"
              [innerHTML]="
                'case_management_portal.case.chat_page.heading.content'
                  | translate
              "
            ></div>
          </div>
          <div class="col-12 col-md-4">
            <mat-form-field appearance="fill">
              <mat-label
                [innerHTML]="
                  'case_management_portal.case.chat_page.translation_selection.label.content'
                    | translate
                "
              ></mat-label>
              <mat-select
                [(ngModel)]="caseDataChatLangCode"
                (selectionChange)="
                  translateChatMessages(caseDataChatLangCode, chatMessages)
                "
                [ngModelOptions]="{ standalone: true }"
              >
                <mat-option
                  *ngFor="
                    let lang of chatMessagesPossibleLangCodes
                      | keyvalue: returnZero
                  "
                  [value]="lang.key"
                  >{{ lang.value | translate }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <hr />
        <p>
          {{
            'case_management_portal.case.case_overview_page.client_of_case.content'
              | translate
          }}
          <b>{{ case.client_name }}</b
          ><br />
          {{
            'case_management_portal.case.case_overview_page.case_id.content'
              | translate
          }}
          <b>{{ case.case_id }}</b>
          <span *ngIf="case.record || categoryViewValue"> - </span>
          <span *ngIf="case.record"
            >{{
              'case_management_portal.case.case_overview_page.record_of_case.content'
                | translate
            }}
            <b>{{ case.record }}</b></span
          ><span *ngIf="case.record && categoryViewValue"> - </span
          ><span *ngIf="categoryViewValue"
            >{{
              'case_management_portal.case.case_overview_page.category_of_case.content'
                | translate
            }}
            <b>{{ categoryViewValue }}</b></span
          >
        </p>
        <hr />
        <div class="chat-box w-100" #content #messages>
          <div class="row w-100 mb-3" *ngFor="let chat_message of chatMessages">
            <div *ngIf="chat_message.is_case_user" class="col-10 col-md-8">
              <!-- Message of case user -->
              <div class="chat-message from-them p-3 text-left">
                <p
                  class="mb-1"
                  *ngIf="!chat_message.translation"
                  [innerText]="chat_message.text"
                ></p>
                <p
                  class="mb-1"
                  *ngIf="chat_message.translation"
                  [innerText]="chat_message.translation"
                ></p>
                <div class="small" *ngIf="!case.name">
                  {{
                    'case_management_portal.case.chat_page.chat.whistleblower.content'
                      | translate
                  }}, {{ chat_message.created_formatted }}<br />
                </div>
                <div class="small" *ngIf="case.name">
                  {{ case.name }} ({{
                    'case_management_portal.case.chat_page.chat.whistleblower.content'
                      | translate
                  }}), {{ chat_message.created_formatted }}<br />
                </div>
              </div>
            </div>
            <div class="col-2 col-md-4"></div>
            <div *ngIf="!chat_message.is_case_user" class="col-10 col-md-8">
              <!-- Message of case manager -->
              <div class="chat-message from-me p-3 text-right">
                <p class="mb-1">
                  {{ chat_message.text }}
                  <mat-icon
                    *ngIf="!chat_message.read"
                    aria-hidden="false"
                    aria-label="Message delivered"
                    >done</mat-icon
                  ><mat-icon
                    *ngIf="chat_message.read"
                    aria-hidden="false"
                    aria-label="Message read by whistleblower"
                    >done_all</mat-icon
                  >
                </p>
                <div class="small">
                  {{ chat_message.case_manager_name }} ({{
                    'case_management_portal.case.chat_page.chat.case_manager.content'
                      | translate
                  }}), {{ chat_message.created_formatted }}<br />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Write and send new chat message  -->
        <mat-form-field class="mt-2" appearance="outline">
          <mat-label
            [innerHTML]="
              'case_management_portal.case.chat_page.chat.message_form_field.label.content'
                | translate
            "
          ></mat-label>
          <textarea
            [(ngModel)]="message"
            matInput
            rows="6"
            [disabled]="isLoading"
            [maxlength]="messageMaxLength"
          ></textarea>
          <mat-hint
            align="end"
            [innerText]="message.length + '/' + messageMaxLength"
          ></mat-hint>
        </mat-form-field>
        <div class="row">
          <div class="col-12 col-md-8">
            <button
              class="py-1 w-100"
              mat-raised-button
              (click)="onChatMessageFormSubmit(message)"
              [disabled]="message.length === 0 || isLoading"
              color="primary"
              [innerHTML]="
                'case_management_portal.case.chat_page.chat.send_message_button.content'
                  | translate
              "
            ></button>
          </div>
          <div class="col-12 col-md-4" *ngIf="case.confirmation_state === 0">
            <button
              class="w-100 mt-2 default-confirmation-button"
              mat-raised-button
              (click)="
                updateCaseConfirmationState = 1; getDefaultCaseManagerText()
              "
              [disabled]="isLoading"
              color="primary"
              [innerHTML]="
                'case_management_portal.case.chat_page.insert_case_receipt_confirmation_default_text.content'
                  | translate
              "
            ></button>
          </div>
          <div
            class="col-12 col-md-4 text-right"
            *ngIf="case.confirmation_state === 1"
          >
            <button
              class="w-100 mt-2 default-confirmation-button"
              mat-raised-button
              (click)="
                updateCaseConfirmationState = 2; getDefaultCaseManagerText()
              "
              [disabled]="isLoading"
              color="primary"
              [innerHTML]="
                'case_management_portal.case.chat_page.insert_case_closing_confirmation_default_text.content'
                  | translate
              "
            ></button>
          </div>
        </div>
        <p
          *ngIf="chatErrorMessage"
          class="error mt-3 text-center pt-0"
          [innerText]="chatErrorMessage"
        ></p>
      </div>
    </mat-tab>

    <!-- Files -->
    <mat-tab
      [label]="
        'case_management_portal.case.carousel_headings.right.content'
          | translate
      "
    >
      <div class="p-5 tab-content">
        <div
          class="h5"
          [innerHTML]="
            'case_management_portal.case.files_page.heading.content' | translate
          "
        ></div>
        <hr />
        <p>
          {{
            'case_management_portal.case.case_overview_page.client_of_case.content'
              | translate
          }}
          <b>{{ case.client_name }}</b
          ><br />
          {{
            'case_management_portal.case.case_overview_page.case_id.content'
              | translate
          }}
          <b>{{ case.case_id }}</b>
          <span *ngIf="case.record || categoryViewValue"> - </span>
          <span *ngIf="case.record"
            >{{
              'case_management_portal.case.case_overview_page.record_of_case.content'
                | translate
            }}
            <b>{{ case.record }}</b></span
          ><span *ngIf="case.record && categoryViewValue"> - </span
          ><span *ngIf="case.category"
            >{{
              'case_management_portal.case.case_overview_page.category_of_case.content'
                | translate
            }}
            <b>{{ categoryViewValue }}</b></span
          >
          <br />
          {{
            'case_management_portal.case.files_page.visibility_notice.content'
              | translate
          }}
        </p>
        <hr />
        <div class="tablecontainer mat-elevation-z8 mb-3">
          <table mat-table [dataSource]="dataSource">
            <!-- Display files -->
            <!-- File ID -->
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef>ID</th>
              <td mat-cell *matCellDef="let file">{{ file.id }}</td>
            </ng-container>

            <!-- Custom file name -->
            <ng-container matColumnDef="name">
              <th
                mat-header-cell
                *matHeaderCellDef
                [innerHTML]="
                  'case_management_portal.case.files_page.files_table.columns.custom_file_name.heading.content'
                    | translate
                "
              ></th>
              <td mat-cell *matCellDef="let file">
                <mat-form-field class="pb-0" appearance="outline">
                  <input
                    [(ngModel)]="file.name"
                    (change)="updateFileName(file, docRef)"
                    maxlength="50"
                    matInput
                    type="text"
                  />
                </mat-form-field>
              </td>
            </ng-container>

            <!-- File author (case user or case manager) -->
            <ng-container matColumnDef="author">
              <th
                mat-header-cell
                *matHeaderCellDef
                [innerHTML]="
                  'case_management_portal.case.files_page.files_table.columns.author.heading.content'
                    | translate
                "
              ></th>
              <td mat-cell *matCellDef="let file">
                <div
                  *ngIf="file.is_case_user_file"
                  [innerHTML]="
                    'case_management_portal.case.files_page.files_table.columns.author.case_user.content'
                      | translate
                  "
                ></div>
                <div
                  *ngIf="!file.is_case_user_file"
                  [innerHTML]="
                    'case_management_portal.case.files_page.files_table.columns.author.case_manager.content'
                      | translate
                  "
                ></div>
              </td>
            </ng-container>

            <!-- File upload timestamp -->
            <ng-container matColumnDef="uploaded">
              <th
                mat-header-cell
                *matHeaderCellDef
                [innerHTML]="
                  'case_management_portal.case.files_page.files_table.columns.uploaded.content'
                    | translate
                "
              ></th>
              <td mat-cell *matCellDef="let file">
                {{ file.uploaded_formatted }}
              </td>
            </ng-container>

            <!-- File downloaded by case user -->
            <ng-container matColumnDef="downloaded">
              <th mat-header-cell *matHeaderCellDef [innerHTML]="''"></th>
              <td mat-cell *matCellDef="let file">
                <mat-icon
                  *ngIf="
                    file.is_case_user_file === false &&
                    file.downloaded === false
                  "
                  aria-hidden="false"
                  aria-label="File not yet downloaded by whistleblower"
                  >done</mat-icon
                >
                <mat-icon
                  *ngIf="
                    file.is_case_user_file === false &&
                    file.downloaded !== false
                  "
                  aria-hidden="false"
                  aria-label="File was downloaded by whistleblower"
                  >done_all</mat-icon
                >
              </td>
            </ng-container>

            <!-- Download file button -->
            <ng-container matColumnDef="download">
              <th
                mat-header-cell
                *matHeaderCellDef
                [innerHTML]="
                  'case_management_portal.case.files_page.files_table.columns.download.content'
                    | translate
                "
              ></th>
              <td mat-cell *matCellDef="let file">
                <button
                  mat-icon-button
                  (click)="downloadFile(file)"
                  color="primary"
                >
                  <mat-icon>download</mat-icon>
                </button>
              </td>
            </ng-container>
            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumns; sticky: true"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
        <div
          class="d-inline-block success"
          *ngIf="customFileNameSuccess"
          [innerHTML]="
            'case_management_portal.case.files_page.files_table.columns.custom_file_name.success_message.content'
              | translate
          "
        ></div>
        <div
          class="d-inline-block error"
          *ngIf="customFileNameError"
          [innerHTML]="
            'case_management_portal.case.files_page.files_table.columns.custom_file_name.error_message.content'
              | translate
          "
        ></div>
        <div
          class="d-inline-block success"
          *ngIf="deleteFileSuccess"
          [innerHTML]="
            'case_management_portal.case.files_page.files_table.columns.withdraw.success_message.content'
              | translate
          "
        ></div>
        <div
          class="d-inline-block error"
          *ngIf="deleteFileError"
          [innerHTML]="
            'case_management_portal.case.files_page.files_table.columns.withdraw.error_message.content'
              | translate
          "
        ></div>
        <hr />
        <p
          [innerHTML]="
            'case_management_portal.case.files_page.upload_files.files_upload_disclaimer.first_disclaimer.content'
              | translate
          "
        ></p>
        <p
          [innerHTML]="
            'case_management_portal.case.files_page.upload_files.files_upload_disclaimer.second_disclaimer.content'
              | translate
          "
        ></p>

        <input
          class="d-none"
          (change)="onFileChange($event)"
          type="file"
          [multiple]="true"
          #fileUpload
        />
        <button
          type="button"
          class="my-3 py-1 d-block w-100"
          mat-stroked-button
          (click)="fileUpload.click()"
          color="primary"
          [innerHTML]="
            'case_management_portal.case.files_page.upload_files.files_upload_form_field.label.content'
              | translate
          "
        ></button>
        <table
          *ngIf="files.length > 0"
          class="my-3 w-100 mat-elevation-z8"
          mat-table
          [dataSource]="filesDataSource"
        >
          <!-- Display file -->
          <!-- File name -->
          <ng-container matColumnDef="file_name">
            <th
              mat-header-cell
              *matHeaderCellDef
              [innerHTML]="
                'case_management_portal.case.files_page.upload_files.selected_files_table.left_column_heading.content'
                  | translate
              "
            ></th>
            <td mat-cell *matCellDef="let file">{{ file.name }}</td>
          </ng-container>
          <!-- Remove file button -->
          <ng-container matColumnDef="remove">
            <th
              mat-header-cell
              *matHeaderCellDef
              [innerHTML]="
                'case_management_portal.case.files_page.upload_files.selected_files_table.right_column_heading.content'
                  | translate
              "
            ></th>
            <td mat-cell *matCellDef="let file">
              <button
                mat-icon-button
                type="button"
                (click)="removeFile(file)"
                color="warn"
                aria-label="Remove file"
              >
                <mat-icon>delete</mat-icon>
              </button>
            </td>
          </ng-container>
          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumnsFilesTable; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumnsFilesTable"
          ></tr>
        </table>
        <button
          mat-raised-button
          class="my-3 mt-4 py-1 d-block w-100"
          (click)="onFilesSubmit()"
          [disabled]="files.length === 0 || isLoading"
          color="primary"
          [innerHTML]="
            'case_management_portal.case.files_page.upload_files.submit_file_upload_button.content'
              | translate
          "
        ></button>
        <p
          *ngIf="filesSuccessMessage"
          class="success mt-3 text-center pt-0"
          [innerText]="filesSuccessMessage"
        ></p>
        <p
          *ngIf="filesErrorMessage"
          class="error mt-3 text-center pt-0"
          [innerText]="filesErrorMessage"
        ></p>
      </div>
    </mat-tab>

    <!-- Case log -->
    <mat-tab
      [label]="
        'case_management_portal.case.carousel_headings.rightest.content'
          | translate
      "
    >
      <div class="p-5 tab-content">
        <div
          class="h5"
          [innerHTML]="
            'case_management_portal.case.case_log_page.heading.content'
              | translate
          "
        ></div>
        <hr />
        <p>
          {{
            'case_management_portal.case.case_overview_page.client_of_case.content'
              | translate
          }}
          <b>{{ case.client_name }}</b
          ><br />
          {{
            'case_management_portal.case.case_overview_page.case_id.content'
              | translate
          }}
          <b>{{ case.case_id }}</b>
          <span *ngIf="case.record || categoryViewValue"> - </span>
          <span *ngIf="case.record"
            >{{
              'case_management_portal.case.case_overview_page.record_of_case.content'
                | translate
            }}
            <b>{{ case.record }}</b></span
          ><span *ngIf="case.record && categoryViewValue"> - </span
          ><span *ngIf="categoryViewValue"
            >{{
              'case_management_portal.case.case_overview_page.category_of_case.content'
                | translate
            }}
            <b>{{ categoryViewValue }}</b
            ><br
          /></span>
          {{
            'case_management_portal.case.case_log_page.visibility_notice.content'
              | translate
          }}
        </p>
        <hr />
        <div class="tablecontainer case-log-table-container mat-elevation-z8">
          <table mat-table [dataSource]="dataSourceCaseLogTable">
            <!-- Display files -->
            <!-- Case log entry code -->
            <ng-container matColumnDef="code">
              <th
                mat-header-cell
                *matHeaderCellDef
                [innerHTML]="
                  'case_management_portal.case.case_log_page.case_log_entries_table.column_headings.code.content'
                    | translate
                "
              ></th>
              <td mat-cell *matCellDef="let case_log_entry">
                {{ case_log_entry.code }}
              </td>
            </ng-container>

            <!-- Case log entry timestamp -->
            <ng-container matColumnDef="created_at">
              <th
                mat-header-cell
                *matHeaderCellDef
                [innerHTML]="
                  'case_management_portal.case.case_log_page.case_log_entries_table.column_headings.created_at.content'
                    | translate
                "
              ></th>
              <td mat-cell *matCellDef="let case_log_entry">
                {{ case_log_entry.created_at_formatted }}
              </td>
            </ng-container>
            <tr
              mat-header-row
              *matHeaderRowDef="displayedColumnsCaseLogTable; sticky: true"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: displayedColumnsCaseLogTable"
            ></tr>
          </table>
          <mat-paginator
            class="mat-paginator-sticky"
            [pageSizeOptions]="[5, 10, 25, 100]"
            [length]="dataSourceCaseLogTable.data.length"
            [pageSize]="5"
          ></mat-paginator>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
  <div class="px-2 pb-2">
    <button
      class="ml-5"
      mat-flat-button
      [routerLink]="['/management']"
      routerLinkActive="router-link-active"
      [innerHTML]="'global.back_button.content' | translate"
    ></button>
  </div>
</mat-card>
