<div class="cover-spin" *ngIf="isLoading"></div>
<img
  *ngIf="caseReportingPortalComponent.logoUrl"
  class="responsive m-auto mb-4"
  width="80%"
  [src]="caseReportingPortalComponent.logoUrl"
  [alt]="caseReportingPortalComponent.client.name + 'logo'"
/>
<mat-card class="py-4 px-5">
  <div class="py-1 px-3">
    <div class="h5 mt-3">
      {{ 'case_reporting_portal.login.heading.content' | translate }}
    </div>
    <hr />
    <p>{{ 'case_reporting_portal.login.subheading.content' | translate }}</p>
    <form class="mb-3" [formGroup]="loginForm" (ngSubmit)="onFormSubmit()">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>{{
          'case_reporting_portal.login.login_form.case_id_form_field.label.content'
            | translate
        }}</mat-label>
        <input
          type="text"
          matInput
          formControlName="case_id"
          [errorStateMatcher]="matcher"
        />
        <mat-error>
          <span
            *ngIf="
              !loginForm.get('case_id').valid &&
              loginForm.get('case_id').touched
            "
            >{{
              'case_reporting_portal.login.login_form.case_id_form_field.error_message.content'
                | translate
            }}</span
          >
        </mat-error>
      </mat-form-field>
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>{{
          'case_reporting_portal.login.login_form.password_form_field.label.content'
            | translate
        }}</mat-label>
        <input
          type="password"
          matInput
          formControlName="password"
          [type]="hide ? 'password' : 'text'"
          [errorStateMatcher]="matcher"
        />
        <button
          type="button"
          mat-icon-button
          matSuffix
          (click)="hide = !hide"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide"
        >
          <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
        </button>
        <mat-error>
          <span
            *ngIf="
              !loginForm.get('password').valid &&
              loginForm.get('password').touched
            "
            >{{
              'case_reporting_portal.login.login_form.password_form_field.error_message.content'
                | translate
            }}</span
          >
        </mat-error>
      </mat-form-field>
      <button
        class="w-100 mt-1 py-1"
        mat-raised-button
        type="submit"
        [disabled]="!loginForm.valid || isLoading"
        color="primary"
      >
        {{
          'case_reporting_portal.login.login_form.submit_button.content'
            | translate
        }}
      </button>
      <p
        *ngIf="errorMessage"
        class="error mt-3 text-center pt-0"
        [innerText]="errorMessage"
      ></p>
    </form>
  </div>
</mat-card>
