<div class="header">
  <div class="top-nav-bar top-0 position-fixed w-100">
    <img
      *ngIf="logoUrl"
      class="client-logo non-responsive"
      height="42px"
      [alt]="client.name + ' logo'"
      [src]="logoUrl"
    />
    <div class="d-flex header-flex text-light align-items-center h-100">
      <div
        (click)="navigateTo('client/' + client.subdomain)"
        (keypress)="navigateTo('client/' + client.subdomain)"
        tabindex="auto"
        class="h6 mt-2 button-orange py-2 px-3"
      >
        {{ 'toolbar.title.content' | translate }}
      </div>
    </div>
    <div class="bg-white language-selection-button" *ngIf="!isLoggedIn">
      <mat-form-field class="small" appearance="fill">
        <mat-label>{{
          'toolbar.language_selection_title.content' | translate
        }}</mat-label>
        <mat-select [(ngModel)]="lang" (selectionChange)="translate.use(lang)">
          <div *ngFor="let langs of languages">
            <mat-option [value]="langs">
              {{ 'languages.' + langs | translate }}
            </mat-option>
          </div>
        </mat-select>
      </mat-form-field>
    </div>
    <button
      mat-icon-button
      *ngIf="isLoggedIn"
      (click)="openLogoutConfirmationDialog()"
      class="text-white non-responsive logout-button-non-reponsive"
      aria-label="Logout icon"
    >
      <mat-icon>power_settings_new</mat-icon>
    </button>
    <div
      class="bg-white non-responsive language-selection-button"
      *ngIf="isLoggedIn"
    >
      <mat-form-field class="small" appearance="fill">
        <mat-label>{{
          'toolbar.language_selection_title.content' | translate
        }}</mat-label>
        <mat-select [(ngModel)]="lang" (selectionChange)="translate.use(lang)">
          <div *ngFor="let lang of languages">
            <!-- *ngIf="client.reporting_portal_several_texts.hasOwnProperty(lang.toLowerCase())" -->
            <mat-option [value]="lang">
              {{ 'languages.' + lang | translate }}
            </mat-option>
          </div>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="responsive" *ngIf="isLoggedIn">
      <button
        mat-icon-button
        class="text-white menu-button-reponsive"
        [matMenuTriggerFor]="menu"
        aria-label="Open menu button"
      >
        <mat-icon class="menu-icon">menu</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button
          *ngIf="isLoggedIn"
          mat-menu-item
          (click)="openLogoutConfirmationDialog()"
        >
          <mat-icon>power_settings_new</mat-icon>
          {{ 'footer.logout_button.content' | translate }}
        </button>
        <button mat-menu-item [matMenuTriggerFor]="language_menu">
          <mat-icon>translate</mat-icon>
          {{ 'toolbar.language_selection_title.content' | translate }}
        </button>
        <mat-menu #language_menu="matMenu">
          <div *ngFor="let lang of languages">
            <!-- *ngIf="client.reporting_portal_several_texts.hasOwnProperty(lang.toLowerCase())" -->
            <button mat-menu-item (click)="translate.use(lang)">
              <span>{{ 'languages.' + lang | translate }}</span>
            </button>
          </div>
        </mat-menu>
      </mat-menu>
    </div>
  </div>
</div>
<div class="content">
  <router-outlet></router-outlet>
</div>
