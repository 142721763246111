import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { COUNTRIES } from 'src/app/shared/data/countries';
import { DialogSelectCountryData } from 'src/app/shared/interfaces/caseReportingHome';

@Component({
  selector: 'app-case-reporting-portal-select-country-dialog',
  templateUrl: './case-reporting-portal-select-country-dialog.component.html',
  styleUrls: ['./case-reporting-portal-select-country-dialog.component.scss']
})
export class CaseReportingPortalSelectCountryDialogComponent {
  // country of whistleblower
  whistleblowerCountry: string;

  // import countries
  countries = COUNTRIES;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogSelectCountryData) {}
}
